import React, { useState } from 'react';
import { Box, Button, Modal, Textarea } from '@amzn/awsui-components-react';
import { APIs, Charge, IInvoiceMetadata, Meter, Source, Subterm, TemplateMap, Usage } from 'src/interface/type-def';
import { SubTemplateMap, initialSubTemplateMapState, TemplateValueOption } from './mapToSchema/map-to-schema';
import { APPLICATION_CONTEXT } from 'src/interface';
import { CreateTemplateContext } from './index';
import { MapHeaderBasedTableValue } from './tabularDataMapping/MapHeaderBasedTableValue';

const initialSubterm = {
  separator: '',
  startTerm: 0,
  endTerm: 0,
};

interface Props {
  templateMap: TemplateMap;
  setTemplateMap: Function;
  subTemplateMapState: SubTemplateMap;
  setSubTemplateMapState: Function;
  manifest?: IInvoiceMetadata;
  selectedTemplateValue: TemplateValueOption;
}

export const SourceMappingForm = ({
  subTemplateMapState,
  setSubTemplateMapState,
  manifest,
  selectedTemplateValue,
  templateMap,
  setTemplateMap,
}: Props) => {
  // TODO: Rewrite subterm logic
  // Story: https://app.asana.com/0/1205742096370708/1206507619555883/f
  const [addSubTermToManifest, setAddSubTermToManifest] = useState<boolean>(false);
  const [addSubTermToStatic, setAddSubTermToStatic] = useState<boolean>(false);
  const [addSubTermToInvoice, setAddSubTermToInvoice] = useState<boolean>(false);
  const [addSubTermToInvoiceTabularData, setAddSubTermToInvoiceTabularData] = useState<boolean>(false);
  const [subTerm, setSubterm] = useState<Subterm>(initialSubterm);

  const context = React.useContext(CreateTemplateContext);
  context.subTemplateMapState = subTemplateMapState;
  context.setSubTemplateMapState = setSubTemplateMapState;

  return (
    <CreateTemplateContext.Consumer>
      {({ textractResultKey, tableNumber, textractResultTableKey }: APPLICATION_CONTEXT) => (
        <div>
          <div style={{ marginBottom: '10px' }}>
            <div>
              <label htmlFor="source" id="source">
                {' '}
                Select source of the data
              </label>
            </div>
            <select
              name="source"
              id="source"
              placeholder="Source of data to add"
              value={subTemplateMapState.source}
              onChange={(event) => {
                event.preventDefault();
                event.persist();
                event.currentTarget.value === Source.NULL
                  ? setSubTemplateMapState({
                      ...initialSubTemplateMapState,
                      source: Source.NULL.toString(),
                      valueRef: {
                        type: 'NullValue',
                      },
                      include: true,
                    })
                  : setSubTemplateMapState({
                      ...initialSubTemplateMapState,
                      source: event.currentTarget.value,
                      include: true,
                    });
              }}
              // @ts-ignore
              style={styles.input}
            >
              <option value={Source.NONE}>Select an Option</option>
              <option value={Source.MANIFEST}>Manifest</option>
              <option value={Source.INVOICE}>Invoice (Key-value)</option>
              <option value={Source.INVOICE_TABLE}>Invoice (Table)</option>
              <option value={Source.STATIC}>Static</option>
              <option value={Source.NULL}>Null</option>
            </select>
          </div>
          {/* If Invoice selected from above starts here */}
          {subTemplateMapState.source === Source.INVOICE && (
            <div>
              <div style={{ marginBottom: '10px' }}>
                <label htmlFor="invoiceData" id="invoiceDataLabel">
                  Add an invoice fieldset
                  <input
                    name="invoiceData"
                    id="invoiceData"
                    onChange={(event) => {
                      event.preventDefault();
                      event.persist();
                      setSubTemplateMapState({
                        ...subTemplateMapState,
                        valueRef: {
                          ...subTemplateMapState.valueRef,
                          type: 'KeyValue',
                          api: APIs.AnalyzeDocument,
                          service: 'Textract',
                          ref: {
                            ...subTemplateMapState.valueRef?.ref,
                            key: textractResultKey,
                          },
                        },
                      });
                    }}
                    type="text"
                    placeholder="Click on the Textract result field to add"
                    value={textractResultKey || subTemplateMapState.valueRef?.ref?.key || ''}
                    // @ts-ignore
                    style={styles.input}
                  />
                </label>
              </div>

              <div style={{ marginBottom: '10px' }}>
                <input
                  type="checkbox"
                  id="subterm"
                  name="subterm"
                  onClick={(event) => {
                    setAddSubTermToInvoice(true);
                  }}
                />
                <label htmlFor="subterm" id="subterm">
                  {' '}
                  Add subterm? - <i>Optional</i>
                </label>
              </div>
              {addSubTermToInvoice && (
                <div>
                  <Modal
                    onDismiss={() => setAddSubTermToInvoice(false)}
                    visible={addSubTermToInvoice}
                    closeAriaLabel="Close modal"
                    size="medium"
                    footer={
                      <Box float="right">
                        <Button variant="link" onClick={() => setAddSubTermToInvoice(false)}>
                          Cancel
                        </Button>
                        <Button
                          onClick={(event) => {
                            event.preventDefault();
                            setSubTemplateMapState({
                              ...subTemplateMapState,
                              valueRef: {
                                ...subTemplateMapState.valueRef,
                                subterm: subTerm,
                              },
                            });
                            setAddSubTermToInvoice(false);
                          }}
                          variant="primary"
                        >
                          Save
                        </Button>
                      </Box>
                    }
                    header="Add a subterm"
                  >
                    <div>
                      <div>
                        <label htmlFor="separator">
                          {' '}
                          <i>Separator</i>
                        </label>
                      </div>
                      <input
                        // @ts-ignore
                        style={styles.input}
                        name="separator"
                        id="separator"
                        type="text"
                        placeholder="Separator"
                        value={subTerm.separator}
                        onChange={(event) => {
                          event.preventDefault();
                          setSubterm({
                            ...subTerm,
                            separator: event.target.value,
                          });
                        }}
                      />
                      <div>
                        <label htmlFor="startTerm">
                          {' '}
                          <i>Start Term</i>
                        </label>
                      </div>
                      <input
                        // @ts-ignore
                        style={styles.input}
                        name="startTerm"
                        id="startTerm"
                        type="number"
                        placeholder="End Term"
                        value={Number(subTerm.startTerm)}
                        onChange={(event) => {
                          event.preventDefault();
                          setSubterm({
                            ...subTerm,
                            startTerm: Number(event.target.value),
                          });
                        }}
                      />
                      <div>
                        <label htmlFor="endTerm">
                          {' '}
                          <i>End Term</i>
                        </label>
                      </div>
                      <input
                        // @ts-ignore
                        style={styles.input}
                        name="endTerm"
                        id="startTerm"
                        type="number"
                        placeholder="End Term"
                        value={Number(subTerm.endTerm)}
                        onChange={(event) => {
                          event.preventDefault();
                          setSubterm({
                            ...subTerm,
                            endTerm: Number(event.target.value),
                          });
                        }}
                      />
                    </div>
                  </Modal>
                </div>
              )}
            </div>
          )}
          {/* If Invoice is selected  ends here */}

          {subTemplateMapState.source === Source.INVOICE_TABLE && (
            <Box margin={{ bottom: 'xs' }}>
              <MapHeaderBasedTableValue
                selectedCellText={
                  textractResultTableKey || subTemplateMapState?.valueRef?.cellRef?.cellCoordinate || ''
                }
                tableRef={subTemplateMapState.valueRef?.tableRef || null}
                cellRef={subTemplateMapState.valueRef?.cellRef || null}
              />

              <div>
                <input
                  type="checkbox"
                  id="subterm"
                  name="subterm"
                  onClick={() => {
                    setAddSubTermToInvoiceTabularData(true);
                  }}
                />
                <label htmlFor="subterm" id="subterm">
                  {' '}
                  Add subterm? - <i>Optional</i>
                </label>
              </div>
              {addSubTermToInvoiceTabularData && (
                <div>
                  <Modal
                    onDismiss={() => setAddSubTermToInvoiceTabularData(false)}
                    visible={addSubTermToInvoiceTabularData}
                    closeAriaLabel="Close modal"
                    size="medium"
                    footer={
                      <Box float="right">
                        <Button variant="link" onClick={() => setAddSubTermToInvoiceTabularData(false)}>
                          Cancel
                        </Button>
                        <Button
                          onClick={(event) => {
                            event.preventDefault();
                            setSubTemplateMapState({
                              ...subTemplateMapState,
                              valueRef: {
                                ...subTemplateMapState.valueRef,
                                subterm: subTerm,
                              },
                            });
                            setAddSubTermToInvoiceTabularData(false);
                          }}
                          variant="primary"
                        >
                          Save
                        </Button>
                      </Box>
                    }
                    header="Add a subterm"
                  >
                    <div>
                      <div>
                        <label htmlFor="separator">
                          {' '}
                          <i>Separator</i>
                        </label>
                      </div>
                      <input
                        // @ts-ignore
                        style={styles.input}
                        name="separator"
                        id="separator"
                        type="text"
                        placeholder="Separator"
                        value={subTerm.separator}
                        onChange={(event) => {
                          event.preventDefault();
                          setSubterm({
                            ...subTerm,
                            separator: event.target.value,
                          });
                        }}
                      />
                      <div>
                        <label htmlFor="startTerm">
                          {' '}
                          <i>Start Term</i>
                        </label>
                      </div>
                      <input
                        // @ts-ignore
                        style={styles.input}
                        name="startTerm"
                        id="startTerm"
                        type="number"
                        placeholder="End Term"
                        value={Number(subTerm.startTerm)}
                        onChange={(event) => {
                          event.preventDefault();
                          setSubterm({
                            ...subTerm,
                            startTerm: Number(event.target.value),
                          });
                        }}
                      />
                      <div>
                        <label htmlFor="endTerm">
                          {' '}
                          <i>End Term</i>
                        </label>
                      </div>
                      <input
                        // @ts-ignore
                        style={styles.input}
                        name="endTerm"
                        id="startTerm"
                        type="number"
                        placeholder="End Term"
                        value={Number(subTerm.endTerm)}
                        onChange={(event) => {
                          event.preventDefault();
                          setSubterm({
                            ...subTerm,
                            endTerm: Number(event.target.value),
                          });
                        }}
                      />
                    </div>
                  </Modal>
                </div>
              )}
            </Box>
          )}

          {/* If Manifest is selected  starts here */}
          {subTemplateMapState.source === Source.MANIFEST && (
            <div>
              <div style={{ marginBottom: '10px' }}>
                <div>
                  <label htmlFor="manifest-data" id="manifest-data">
                    {' '}
                    Select what field to add from manifest
                  </label>
                </div>
                <select
                  name="manifest-data"
                  id="manifest-data"
                  placeholder="Field to add"
                  value={subTemplateMapState.valueRef?.ref?.value}
                  onChange={(event) => {
                    event.preventDefault();
                    event.persist();
                    setSubTemplateMapState({
                      ...subTemplateMapState,
                      valueRef: {
                        ...subTemplateMapState.valueRef,
                        type: 'ManifestValue',
                        ref: {
                          ...subTemplateMapState.valueRef?.ref,
                          value: event.currentTarget.value,
                        },
                      },
                    });
                  }}
                  // @ts-ignore
                  style={styles.input}
                >
                  {Object.keys(manifest?.manifestDocument || {}).map((key: string, idx: number) => (
                    <option key={idx} value={key}>
                      {key}
                    </option>
                  ))}
                </select>
                <div>Current Selection</div>

                <div style={{ marginBottom: '10px' }}>
                  <input
                    type="checkbox"
                    id="subterm"
                    name="subterm"
                    onClick={(event) => {
                      setAddSubTermToManifest(true);
                    }}
                  />
                  <label htmlFor="subterm" id="subterm">
                    {' '}
                    Add subterm? - <i>Optional</i>
                  </label>
                </div>
                {addSubTermToManifest && (
                  <div>
                    <Modal
                      onDismiss={() => setAddSubTermToManifest(false)}
                      visible={addSubTermToManifest}
                      closeAriaLabel="Close modal"
                      size="medium"
                      footer={
                        <Box float="right">
                          <Button variant="link" onClick={() => setAddSubTermToManifest(false)}>
                            Cancel
                          </Button>
                          <Button
                            onClick={(event) => {
                              event.preventDefault();
                              // todo: Save values
                              setSubTemplateMapState({
                                ...subTemplateMapState,
                                valueRef: {
                                  ...subTemplateMapState.valueRef,
                                  subterm: subTerm,
                                },
                              });
                              setAddSubTermToManifest(false);
                            }}
                            variant="primary"
                          >
                            Save
                          </Button>
                        </Box>
                      }
                      header="Add a subterm"
                    >
                      {/* <AdditionalFields keyVal={subTemplateMapState} setKeyVal={setSubTemplateMapState} /> */}
                      <div>
                        <div>
                          <label htmlFor="separator">
                            {' '}
                            <i>Separator</i>
                          </label>
                        </div>
                        <input
                          // @ts-ignore
                          style={styles.input}
                          name="separator"
                          id="separator"
                          type="text"
                          placeholder="Separator"
                          value={subTerm.separator}
                          onChange={(event) => {
                            event.preventDefault();
                            setSubterm({
                              ...subTerm,
                              separator: event.target.value,
                            });
                          }}
                        />
                        <div>
                          <label htmlFor="startTerm">
                            {' '}
                            <i>Start Term</i>
                          </label>
                        </div>
                        <input
                          // @ts-ignore
                          style={styles.input}
                          name="startTerm"
                          id="startTerm"
                          type="number"
                          placeholder="End Term"
                          value={Number(subTerm.startTerm)}
                          onChange={(event) => {
                            event.preventDefault();
                            setSubterm({
                              ...subTerm,
                              startTerm: Number(event.target.value),
                            });
                          }}
                        />
                        <div>
                          <label htmlFor="endTerm">
                            {' '}
                            <i>End Term</i>
                          </label>
                        </div>
                        <input
                          // @ts-ignore
                          style={styles.input}
                          name="endTerm"
                          id="startTerm"
                          type="number"
                          placeholder="End Term"
                          value={Number(subTerm.endTerm)}
                          onChange={(event) => {
                            event.preventDefault();
                            setSubterm({
                              ...subTerm,
                              endTerm: Number(event.target.value),
                            });
                          }}
                        />
                      </div>
                    </Modal>
                  </div>
                )}
              </div>
            </div>
          )}
          {/* When manifest source is selected ends here */}
          {/* When static source is selected starts here */}
          {subTemplateMapState.source === Source.STATIC && (
            <div>
              <div style={{ marginBottom: '10px' }} />

              <div style={{ marginBottom: '10px' }}>
                <label htmlFor="static-data" id="static-data-label">
                  Add a static data fieldset
                  <input
                    name="static-data"
                    id="static-data"
                    onChange={(event) => {
                      event.preventDefault();
                      event.persist();
                      setSubTemplateMapState({
                        ...subTemplateMapState,
                        valueRef: {
                          ...subTemplateMapState.valueRef,
                          type: 'StaticValue',
                          ref: {
                            ...subTemplateMapState.valueRef?.ref,
                            value: event.currentTarget.value,
                          },
                        },
                      });
                    }}
                    type="text"
                    placeholder="Enter the field name here"
                    value={subTemplateMapState.valueRef?.ref?.value}
                    // @ts-ignore
                    style={styles.input}
                  />
                </label>
              </div>

              <div style={{ marginBottom: '10px' }}>
                <input
                  type="checkbox"
                  id="static-subterm"
                  name="static-subterm"
                  onClick={() => {
                    // event.preventDefault();
                    setAddSubTermToStatic(true);
                  }}
                />
                <label htmlFor="static-subterm" id="static-subterm">
                  {' '}
                  Add subterm(s) - <i>Optional</i>
                </label>
                {addSubTermToStatic && (
                  <div>
                    <Modal
                      onDismiss={() => setAddSubTermToStatic(false)}
                      visible={addSubTermToStatic}
                      closeAriaLabel="Close modal"
                      size="medium"
                      footer={
                        <Box float="right">
                          <Button variant="link" onClick={() => setAddSubTermToStatic(false)}>
                            Cancel
                          </Button>
                          <Button
                            onClick={(event) => {
                              event.preventDefault();
                              setSubTemplateMapState({
                                ...subTemplateMapState,
                                valueRef: {
                                  ...subTemplateMapState.valueRef,
                                  subterm: subTerm,
                                },
                              });
                              setAddSubTermToStatic(false);
                            }}
                            variant="primary"
                          >
                            Save
                          </Button>
                        </Box>
                      }
                      header="Add a subterm"
                    >
                      {/* <AdditionalFields keyVal={subTemplateMapState} setKeyVal={setSubTemplateMapState} /> */}
                      <div>
                        <div>
                          <label htmlFor="separator">
                            {' '}
                            <i>Separator</i>
                          </label>
                        </div>
                        <input
                          // @ts-ignore
                          style={styles.input}
                          name="separator"
                          id="separator"
                          type="text"
                          placeholder="Separator"
                          value={subTerm.separator}
                          onChange={(event) => {
                            event.preventDefault();
                            setSubterm({
                              ...subTerm,
                              separator: event.target.value,
                            });
                          }}
                        />
                        <div>
                          <label htmlFor="startTerm">
                            {' '}
                            <i>Start Term</i>
                          </label>
                        </div>
                        <input
                          // @ts-ignore
                          style={styles.input}
                          name="startTerm"
                          id="startTerm"
                          type="number"
                          placeholder="End Term"
                          value={Number(subTerm.startTerm)}
                          onChange={(event) => {
                            event.preventDefault();
                            setSubterm({
                              ...subTerm,
                              startTerm: Number(event.target.value),
                            });
                          }}
                        />
                        <div>
                          <label htmlFor="endTerm">
                            {' '}
                            <i>End Term</i>
                          </label>
                        </div>
                        <input
                          // @ts-ignore
                          style={styles.input}
                          name="endTerm"
                          id="startTerm"
                          type="number"
                          placeholder="End Term"
                          value={Number(subTerm.endTerm)}
                          onChange={(event) => {
                            event.preventDefault();
                            setSubterm({
                              ...subTerm,
                              endTerm: Number(event.target.value),
                            });
                          }}
                        />
                      </div>
                    </Modal>
                  </div>
                )}
              </div>
            </div>
          )}
          <Button
            onClick={(event) => {
              event.preventDefault();
              const addingChargeOrUsageToMeter =
                selectedTemplateValue.label.split(' ')[0] === 'Meter' &&
                selectedTemplateValue.label.split(' ').length > 4;
              const selectedCategory = selectedTemplateValue.label.split(' - ')[0];
              const propertyField = selectedTemplateValue.label.split(' - ')[1];
              if (selectedCategory === 'Account') {
                setTemplateMap({
                  ...templateMap,
                  account: {
                    ...templateMap.account,
                    [propertyField]: subTemplateMapState,
                  },
                });
              } else if (selectedCategory.split(' ')[0] === 'Charge') {
                const idx = Number(selectedTemplateValue.label.split(' ')[1].split('')[1]);
                const stateCopy = templateMap;
                stateCopy.charges.forEach((el: Charge, index: number) => {
                  if (index === idx) {
                    Object.assign(el, { [propertyField]: subTemplateMapState });
                  }
                });
                setTemplateMap(stateCopy);
              } else if (selectedCategory.split(' ')[0] === 'Meter') {
                const saveMeters = () => {
                  const idx = Number(selectedTemplateValue.label.split(' ')[1].split('')[1]);
                  const stateCopy = templateMap;
                  stateCopy.meters.forEach((el: Meter, index: number) => {
                    if (index === idx && addingChargeOrUsageToMeter) {
                      const propField: string = selectedTemplateValue.label.split(' - ')[1];
                      const innerIdx: number = Number(
                        selectedTemplateValue.label.split(' - ')[0].split(' ')[3].split('')[1]
                      );
                      // eslint-disable-next-line no-restricted-syntax
                      for (const key in el) {
                        if (key === 'usages') {
                          // If adding a usage
                          el.usages.forEach((prop: Usage, i: number) => {
                            if (i === innerIdx) {
                              Object.assign(prop, { [propField]: subTemplateMapState });
                            }
                          });
                        } else if (key === 'charges') {
                          // If adding a charge
                          el.charges.forEach((prop: Charge, i: number) => {
                            if (i === innerIdx) {
                              Object.assign(prop, { [propField]: subTemplateMapState });
                            }
                          });
                        }
                      }
                    } else if (index === idx) {
                      Object.assign(el, { [propertyField]: subTemplateMapState });
                    }
                  });
                  setTemplateMap(stateCopy);
                };
                saveMeters();
              }
            }}
            variant="primary"
          >
            Save
          </Button>
          <Button
            onClick={(event) => {
              event.preventDefault();
              context.textractResultKey = '';
              context.textractResultTableKey = '';
              setSubTemplateMapState({
                source: Source.NONE,
              });
            }}
            variant="normal"
          >
            Clear
          </Button>
          <div
            // @ts-ignore
            style={{ marginTop: '10px' }}
          >
            Current Selection
          </div>
          <Textarea readOnly placeholder="output" value={JSON.stringify(subTemplateMapState)} />
        </div>
      )}
    </CreateTemplateContext.Consumer>
  );
};

// todo: remove this inline b4 deployment
const styles = {
  input: {
    padding: '12px 20px',
    border: '1px solid #cc',
    boxSizing: 'border-box',
    margin: '8px 0',
    width: '90%',
  },
};

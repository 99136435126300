import {
  Alert,
  AttributeEditor,
  Box,
  Button,
  ColumnLayout,
  Container,
  DatePicker,
  DatePickerProps,
  ExpandableSection,
  Header,
  NonCancelableCustomEvent,
  SpaceBetween,
  FormField,
  FormFieldProps,
} from '@amzn/awsui-components-react';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useParams, useLocation } from 'react-router';
import { apiGetRequest, apiPaths } from 'src/helpers/api';
import { ProcessedInvoiceData } from 'src/interface/type-def';
import { formDateToISO } from '../../../utils/dates';
import { getPresigneds3Url } from '../../../helpers/storage';
import { Link, RouteChildrenProps } from 'react-router-dom';
import { CURRENCY, USAGE_UNIT, SERVICE_TYPE } from 'src/components/common-components/form/constants';
import { InvoiceOverrideInput } from 'src/components/manifests/overrides/InvoiceOverrideInput';
import { InvoiceOverrideDatePicker } from 'src/components/manifests/overrides/InvoiceOverrideDatePicker';
import { InvoiceOverrideSelect } from './InvoiceOverrideSelect';

import {
  SetAccountFieldProps,
  SetMeterFieldProps,
  SetAccountErrorProps,
  SetMeterErrorProps,
  AddMeterFieldProps,
  RemoveMeterFieldProps,
} from '../hooks/useHydrateInvoiceOverrideForm';

interface AccountFieldsProps {
  account: ProcessedInvoiceData['account'];
  onChange: (args: SetAccountFieldProps) => void;
  onValidate: (args: SetAccountErrorProps) => void;
}

interface MeterFieldsProps {
  meters: ProcessedInvoiceData['meters'];
  onChange: (args: SetMeterFieldProps) => void;
  onValidate: (args: SetMeterErrorProps) => void;
  onAdd: (args: AddMeterFieldProps) => void;
  onRemove: (args: RemoveMeterFieldProps) => void;
}

export const InvoiceOverrideAccountFields: React.FC<AccountFieldsProps> = ({ account, onChange, onValidate }) => (
  <section>
    <SpaceBetween size="xs">
      <fieldset>
        <legend>General</legend>
        <ColumnLayout columns={1}>
          <InvoiceOverrideInput
            value={account.accountId}
            label="Account Id"
            fieldName="accountId"
            fieldRequired
            onFieldChange={onChange}
            onFieldValidate={onValidate}
            errorText="Account Number field is required"
          />
        </ColumnLayout>
      </fieldset>

      <fieldset>
        <legend>Charge</legend>
        <ColumnLayout columns={2}>
          <InvoiceOverrideInput
            value={account.totalChargeAmount}
            label="Total Charge Amount"
            fieldName="totalChargeAmount"
            fieldRequired
            onFieldChange={onChange}
            onFieldValidate={onValidate}
            errorText="Total charge amount field is required"
          />

          <InvoiceOverrideInput
            value={account.chargeCurrency}
            label="Charge Currency"
            fieldName="chargeCurrency"
            fieldRequired
            onFieldChange={onChange}
            onFieldValidate={onValidate}
            errorText="Charge Currency field is required"
          />
        </ColumnLayout>
      </fieldset>

      <fieldset>
        <legend>Usage</legend>
        <ColumnLayout columns={2}>
          <InvoiceOverrideInput
            value={account.totalUsageAmount}
            label="Total Usage Amount"
            fieldName="totalUsageAmount"
            onFieldChange={onChange}
            onFieldValidate={onValidate}
          />

          <InvoiceOverrideInput
            value={account.usageUnit}
            label="Usage Unit"
            fieldName="usageUnit"
            onFieldChange={onChange}
            onFieldValidate={onValidate}
          />
        </ColumnLayout>
      </fieldset>

      <fieldset>
        <legend>Invoice Statement</legend>
        <ColumnLayout columns={2}>
          <InvoiceOverrideDatePicker
            value={account.billStart}
            label="Bill Start Date"
            fieldName="billStart"
            fieldRequired
            onFieldChange={onChange}
            onFieldValidate={onValidate}
            errorText="Bill start field is required"
          />

          <InvoiceOverrideDatePicker
            value={account.billEnd}
            label="Bill End Date"
            fieldName="billEnd"
            onFieldChange={onChange}
            onFieldValidate={onValidate}
            errorText="Bill end field is required"
          />
        </ColumnLayout>
      </fieldset>
    </SpaceBetween>
  </section>
);

export const InvoiceOverrideMeterFields: React.FC<MeterFieldsProps> = ({
  meters,
  onChange,
  onValidate,
  onAdd,
  onRemove,
}) => {
  const serviceTypeOptions = useMemo(() => {
    const serviceTypes = Object.entries(SERVICE_TYPE);
    return serviceTypes.map(([value, label]) => ({
      value,
      label,
    }));
  }, []);

  return (
    <section>
      <div className="subsection__header">
        <h3>Meters</h3>
        <Button formAction="none" onClick={onAdd}>
          Add Meter
        </Button>
      </div>
      <div className="subsection__list">
        <SpaceBetween size="xs">
          {meters.map((meter, index) => (
            <fieldset key={`meterEntry${index}${meters.length}`}>
              <legend>Meter #{index}</legend>
              <SpaceBetween size="xl">
                <div>
                  <SpaceBetween size="xs">
                    <ColumnLayout columns={8}>
                      <InvoiceOverrideInput
                        value={meter.meterId}
                        label="Meter Id"
                        fieldName="meterId"
                        onFieldChange={onChange}
                        onFieldValidate={onValidate}
                        fieldRequired
                        fieldIndex={index}
                        errorText="Meter Id field is required"
                      />
                      <InvoiceOverrideSelect
                        label="Meter Type"
                        selectedOption={meter?.meterType ? { label: meter.meterType, value: meter.meterType } : null}
                        options={serviceTypeOptions}
                        fieldName="meterType"
                        onFieldChange={onChange}
                        onFieldValidate={onValidate}
                        fieldRequired
                        fieldIndex={index}
                        errorText="Meter Type field is required"
                      />
                      <InvoiceOverrideInput
                        value={meter.chargeAmount}
                        label="Meter Charge Amount"
                        fieldName="chargeAmount"
                        onFieldChange={onChange}
                        onFieldValidate={onValidate}
                        fieldIndex={index}
                      />
                      <InvoiceOverrideInput
                        value={meter.chargeCurrency}
                        label="Meter Charge Currency"
                        fieldName="chargeCurrency"
                        onFieldChange={onChange}
                        onFieldValidate={onValidate}
                        fieldIndex={index}
                      />
                      <InvoiceOverrideInput
                        value={meter.usageAmount}
                        label="Meter Usage Amount"
                        fieldName="usageAmount"
                        onFieldChange={onChange}
                        onFieldValidate={onValidate}
                        fieldIndex={index}
                      />
                      <InvoiceOverrideInput
                        value={meter.usageUnit}
                        label="Meter Usage Unit"
                        fieldName="usageUnit"
                        onFieldChange={onChange}
                        onFieldValidate={onValidate}
                        fieldIndex={index}
                      />
                      <InvoiceOverrideDatePicker
                        value={meter.readFromDate}
                        label="Read From Date"
                        fieldName="readFromDate"
                        onFieldChange={onChange}
                        onFieldValidate={onValidate}
                        fieldIndex={index}
                      />

                      <InvoiceOverrideDatePicker
                        value={meter.readToDate}
                        label="Read To Date"
                        fieldName="readToDate"
                        onFieldChange={onChange}
                        onFieldValidate={onValidate}
                        fieldIndex={index}
                      />
                    </ColumnLayout>
                  </SpaceBetween>
                </div>
                <Button formAction="none" onClick={() => onRemove({ index })} disabled={meters.length <= 1}>
                  Remove Meter #{index}
                </Button>
              </SpaceBetween>
            </fieldset>
          ))}
        </SpaceBetween>
      </div>
    </section>
  );
};

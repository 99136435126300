import { useCollection } from '@amzn/awsui-collection-hooks';
import { Alert, Button, Header, Table, TableProps, TextFilter } from '@amzn/awsui-components-react';
import React, { useMemo } from 'react';
import { TextWithBadge } from 'src/components/common-components/text/TextWithBadge';
import { ManifestDocument } from 'src/interface/type-def';

const isSelectedColumnName = (columnName: string, selectedColumnName?: string) => columnName === selectedColumnName;

interface ManifestDocumentSelectorTableRow {
  columnName: keyof ManifestDocument;
  columnValue: string | number;
}

interface ManifestDocumentSelectorProps {
  manifestDocument: ManifestDocument | null;
  onApplyColumn: (columnName: keyof ManifestDocument) => void;
  selectedColumnName?: string;
}

export const ManifestDocumentSelector = ({
  manifestDocument,
  onApplyColumn,
  selectedColumnName,
}: ManifestDocumentSelectorProps) => {
  const tableItems: Array<ManifestDocumentSelectorTableRow> = useMemo(() => {
    if (!manifestDocument) return [];

    return Object.keys(manifestDocument).map((columnName) => {
      const columnNameKey = columnName as keyof ManifestDocument;
      return {
        columnName: columnNameKey,
        columnValue: manifestDocument[columnNameKey],
      };
    });
  }, [manifestDocument]);

  const { items, collectionProps, filterProps } = useCollection(tableItems, {
    filtering: {
      empty: 'No manifest document found for this invoice.',
      noMatch: 'No matching column names found.',
    },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: 'columnName',
        },
      },
    },
  });

  const columnDefinitions: Array<TableProps.ColumnDefinition<ManifestDocumentSelectorTableRow>> = [
    {
      id: 'column',
      header: 'Column',
      cell: (item) =>
        isSelectedColumnName(item.columnName, selectedColumnName) ? (
          <TextWithBadge text={item.columnName} badgeColor="blue" badgeText="Selected" />
        ) : (
          <>{item.columnName}</>
        ),
      minWidth: selectedColumnName
        ? 215 // For most column names, ensures the cell containing both the name and "Selected" badge will not wrap
        : 125,
      sortingField: 'columnName',
    },
    {
      id: 'value',
      header: 'Value',
      cell: (item) => item.columnValue,
      minWidth: 150,
      sortingField: 'columnValue',
    },
    {
      id: 'apply',
      header: 'Apply',
      cell: (item) =>
        isSelectedColumnName(item.columnName, selectedColumnName) ? (
          <Button
            data-testid="apply-manifest-column-btn--disabled"
            variant="inline-link"
            ariaLabel="Applied key"
            disabled
          >
            Applied
          </Button>
        ) : (
          <Button
            data-testid="apply-manifest-column-btn"
            variant="inline-link"
            ariaLabel="Apply manifest column to property"
            onClick={(e) => {
              e.preventDefault();
              onApplyColumn(item.columnName);
            }}
          >
            Apply
          </Button>
        ),
      minWidth: 105, // Ensures text does not wrap; desired because this is a sticky column.
    },
  ];

  if (!manifestDocument) {
    return (
      <Alert type="error" header="Error loading manifest document">
        There was an error loading the manifest document for this invoice.
      </Alert>
    );
  }

  return (
    <Table
      {...collectionProps}
      header={
        <Header variant="h2" counter={`(${Object.keys(manifestDocument).length})`}>
          Manifest file values
        </Header>
      }
      columnDefinitions={columnDefinitions}
      items={items}
      filter={<TextFilter {...filterProps} filteringPlaceholder="Filter by column name or value" />}
      stickyColumns={{ first: 0, last: 1 }}
      wrapLines
    />
  );
};

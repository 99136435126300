import { useCallback, useState } from 'react';
import { getTemplate } from 'src/client/api-gateway';
import { Template } from 'src/interface/type-def';
import { allSettled } from 'src/helpers/api';
import { WaterTemplate } from '../waterTemplates/types/WaterTemplateConfiguration';

export interface UseFetchTemplatesForInvoiceState {
  isLoadingTemplates: boolean;
  utilityTemplate: Template | WaterTemplate | null;
  siteTemplate: Template | WaterTemplate | null;
  accountTemplate: Template | WaterTemplate | null;
  fetchTemplatesForInvoice: (utilityId: string, siteId: string, accountId: string) => Promise<void>;
}

export function useFetchTemplatesForInvoice(): UseFetchTemplatesForInvoiceState {
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);
  const [utilityTemplate, setUtilityTemplate] = useState<Template | WaterTemplate | null>(null);
  const [siteTemplate, setSiteTemplate] = useState<Template | WaterTemplate | null>(null);
  const [accountTemplate, setAccountTemplate] = useState<Template | WaterTemplate | null>(null);

  const fetchTemplatesForInvoice = useCallback(async (utilityId: string, siteId: string, accountId: string) => {
    setIsLoadingTemplates(true);

    const fetchTemplateApiCalls = [
      getTemplate(utilityId),
      getTemplate(`${utilityId}#${siteId}`),
      getTemplate(`${utilityId}#${siteId}#${accountId}`),
    ];

    const templates = await allSettled(fetchTemplateApiCalls);
    if (Array.isArray(templates) && templates.length >= 3) {
      const [utilityTemplateResponse, siteTemplateResponse, accountTemplateResponse] = templates;
      if (utilityTemplateResponse.value) {
        setUtilityTemplate(utilityTemplateResponse.value);
      }
      if (siteTemplateResponse.value) {
        setSiteTemplate(siteTemplateResponse.value);
      }
      if (accountTemplateResponse.value) {
        setAccountTemplate(accountTemplateResponse.value);
      }
    }

    setIsLoadingTemplates(false);
  }, []);

  return {
    isLoadingTemplates,
    utilityTemplate,
    siteTemplate,
    accountTemplate,
    fetchTemplatesForInvoice,
  };
}

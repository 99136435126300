import React, { useState } from 'react';

import { Button, SpaceBetween, Spinner } from '@amzn/awsui-components-react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

export interface PDFComponentProps {
  pdfFile: string;
  pdfFileLoading?: boolean;
}

export function PDFComponent({ pdfFile, pdfFileLoading = false }: PDFComponentProps) {
  const [totalPages, setTotalPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(1);

  const previousPage = () => setPageNumber((prevPageNumber) => prevPageNumber - 1);

  const nextPage = () => setPageNumber((prevPageNumber) => prevPageNumber + 1);

  return (
    <>
      {pdfFileLoading ? (
        <Spinner />
      ) : (
        <SpaceBetween size="s">
          <div style={{ overflow: 'scroll' }}>
            <Document
              file={pdfFile}
              error={<div style={{ color: 'orange' }}>Failed to load Invoice, file extension must be a '.pdf'</div>}
              onLoadSuccess={({ numPages }) => setTotalPages(numPages)}
            >
              <Page
                pageNumber={pageNumber}
                error={
                  <div style={{ color: 'orange' }}>Failed to load Invoice page, file extension must be '.pdf'</div>
                }
              />
            </Document>
          </div>

          {totalPages > 0 && (
            <div>
              <Button disabled={pageNumber <= 1} onClick={previousPage} variant="primary">
                Previous Page
              </Button>
              {'  '}
              <Button disabled={pageNumber === totalPages} onClick={nextPage} variant="primary">
                Next Page
              </Button>
            </div>
          )}
        </SpaceBetween>
      )}
    </>
  );
}

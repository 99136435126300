import { useCallback, useState } from 'react';
import { PageKeyValues } from 'src/helpers/textract';
import { TemplateValue } from 'src/interface/type-def';
import {
  TemplatePropertySuggestedMapping,
  TemplatePropertySuggestedValues,
  getSuggestedTemplateValuesForProperty,
} from '../templateSuggestions/templateSuggestionsUtils';
import { getTemplateSuggestions } from 'src/client/api-gateway';

export interface UseFetchTemplateSuggestionsState {
  isLoadingTemplateSuggestions: boolean;
  templateSuggestions: Array<TemplatePropertySuggestedMapping> | null;
  fetchTemplateSuggestions: (invoiceId: string) => Promise<void>;
}

export const useFetchTemplateSuggestions = (): UseFetchTemplateSuggestionsState => {
  const [isLoadingTemplateSuggestions, setIsLoadingTemplateSuggestions] = useState<boolean>(false);
  const [templateSuggestions, setTemplateSuggestions] = useState<Array<TemplatePropertySuggestedMapping> | null>(null);

  const fetchTemplateSuggestions = useCallback(async (invoiceId: string) => {
    setIsLoadingTemplateSuggestions(true);

    getTemplateSuggestions(invoiceId)
      .then((res) => {
        setTemplateSuggestions(res);
      })
      .finally(() => {
        setIsLoadingTemplateSuggestions(false);
      });
  }, []);

  return {
    isLoadingTemplateSuggestions,
    templateSuggestions,
    fetchTemplateSuggestions,
  };
};

export const useGenerateSuggestedTemplateValues = () => {
  const [suggestedTemplateValues, setSuggestedTemplateValues] = useState<TemplatePropertySuggestedValues | null>(null);

  const generateSuggestedTemplateValues = useCallback(
    (templateSuggestions: Array<TemplatePropertySuggestedMapping>, textractKeyValuePairs: Array<PageKeyValues>) => {
      if (!templateSuggestions.length) {
        setSuggestedTemplateValues(null);
        return null;
      }

      const outputSuggestions: TemplatePropertySuggestedValues = {};

      templateSuggestions.forEach((propertySuggestedConfig) => {
        const { propertyName } = propertySuggestedConfig;
        // For this property, determine which suggestions can actually be applied to this invoice
        const suggestedTemplateValuesForProperty: Array<TemplateValue> = getSuggestedTemplateValuesForProperty(
          propertySuggestedConfig,
          textractKeyValuePairs
        );
        outputSuggestions[propertyName] = suggestedTemplateValuesForProperty;
      });

      setSuggestedTemplateValues(outputSuggestions);
      return outputSuggestions;
    },
    []
  );

  return {
    suggestedTemplateValues,
    generateSuggestedTemplateValues,
  };
};

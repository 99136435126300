import {
  Button,
  Header,
  Icon,
  NonCancelableCustomEvent,
  Link as PolarisLink,
  Pagination,
  PaginationProps,
  SpaceBetween,
  Table,
  TableProps,
  TextFilter,
} from '@amzn/awsui-components-react';
import React, { useContext } from 'react';
import {
  BLUEPRINTS_TABLE_COLUMN_KEYS,
  blueprintColumnDefinitions,
  getMatchesCountText,
  paginationLabels,
} from 'src/components/blueprints/BlueprintsTableConfig';
import { EmptyTableState } from 'src/components/common-components/table/EmptyTableState';
import { HelpContext } from 'src/components/help/HelpProvider';
import { useFetchBlueprints } from 'src/components/blueprints/hooks/useFetchBlueprints';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { BlueprintsTableHelpContent } from 'src/components/blueprints/help/BlueprintsTableHelpContent';
import { BlueprintConfiguration } from 'src/components/blueprints/types';
import { Link } from 'react-router-dom';
import { PAGE_ROUTES } from 'src/components/Routes';

const DEFAULT_TABLE_PAGE_SIZE = 50;
const DEFAULT_COLUMN_DISPLAY_PROPERTIES: ReadonlyArray<TableProps.ColumnDisplayProperties> = [
  {
    id: BLUEPRINTS_TABLE_COLUMN_KEYS.bluePrintName,
    visible: true,
  },
  {
    id: BLUEPRINTS_TABLE_COLUMN_KEYS.blueprintId,
    visible: true,
  },
  {
    id: BLUEPRINTS_TABLE_COLUMN_KEYS.validFrom,
    visible: true,
  },
  {
    id: BLUEPRINTS_TABLE_COLUMN_KEYS.blueprintUpdateLog,
    visible: true,
  },
];

export const BlueprintsTable: React.FC = () => {
  const { allBlueprints, blueprintsLoading, lastEvaluatedKey, fetchBlueprints } = useFetchBlueprints();
  const { setHelpContent, showHelp } = useContext(HelpContext);

  const handleInfoFollow = (helpContent: JSX.Element) => (e: CustomEvent<any>) => {
    e.preventDefault();

    setHelpContent(helpContent);
    showHelp(true);
  };

  const onNextPageClick = (event: NonCancelableCustomEvent<PaginationProps.PageClickDetail>) => {
    if (!event.detail.requestedPageAvailable && lastEvaluatedKey) {
      fetchBlueprints(lastEvaluatedKey);
    }
  };

  const {
    items,
    actions,
    filteredItemsCount = 0,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(allBlueprints || [], {
    filtering: {
      empty: (
        <EmptyTableState
          title="No blueprints"
          subtitle="There are no blueprints. Navigate to the create blueprints page."
        />
      ),
      noMatch: (
        <EmptyTableState
          title="No matches"
          subtitle="There is no blueprint with a matching blueprint ID"
          action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
        />
      ),
    },
    pagination: {
      pageSize: DEFAULT_TABLE_PAGE_SIZE,
    },
    sorting: {},
    selection: {},
  });

  const { selectedItems } = collectionProps;
  const selectedBlueprints: BlueprintConfiguration | null =
    selectedItems && selectedItems.length > 0 ? selectedItems[0] : null;

  return (
    <Table
      {...collectionProps}
      loading={blueprintsLoading}
      loadingText="Loading blueprints"
      selectionType="single"
      header={
        <>
          <Header
            counter={
              allBlueprints &&
              (selectedItems!.length
                ? `(${selectedItems!.length}/${allBlueprints.length})`
                : `(${allBlueprints.length})`)
            }
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button ariaLabel="refresh" onClick={() => fetchBlueprints('', true)}>
                  <Icon name="refresh" size="normal" variant="normal" />
                </Button>

                <Link
                  to={{
                    pathname: `${PAGE_ROUTES.updateBlueprint}/${selectedBlueprints?.id}`,
                    state: {
                      blueprint: selectedBlueprints,
                    },
                    search: `?validFrom=${selectedBlueprints?.validFrom}`,
                  }}
                >
                  <Button ariaLabel="Edit Blueprint" disabled={!selectedBlueprints}>
                    Edit Blueprint
                  </Button>
                </Link>
                <Link
                  to={{
                    pathname: `${PAGE_ROUTES.createBlueprint}`,
                  }}
                >
                  <Button ariaLabel="Create Blueprint" variant="primary">
                    Create Blueprint
                  </Button>
                </Link>
              </SpaceBetween>
            }
            info={
              <PolarisLink variant="info" onFollow={handleInfoFollow(BlueprintsTableHelpContent)}>
                Info
              </PolarisLink>
            }
            description="Manage blueprints"
          >
            Blueprints
          </Header>
        </>
      }
      stickyHeader
      columnDisplay={DEFAULT_COLUMN_DISPLAY_PROPERTIES}
      columnDefinitions={blueprintColumnDefinitions}
      items={items}
      pagination={
        <Pagination openEnd onNextPageClick={onNextPageClick} {...paginationProps} ariaLabels={paginationLabels} />
      }
      filter={
        <TextFilter
          {...filterProps}
          filteringPlaceholder="Filter by Blueprint ID"
          countText={getMatchesCountText(filteredItemsCount)}
          filteringAriaLabel="Filter blueprints"
        />
      }
    />
  );
};

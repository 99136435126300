// Allow feature to be enabled via URL query param.
// ex. `?enableFeature=ConfigureTemplateValidation` would enable the ConfigureTemplateValidation feature.
export const ENABLE_FEATURE_QUERY_PARAM = 'enableFeature';

export enum FEATURE_NAME {
  ConfigureTemplateVerification = 'ConfigureTemplateVerification',
  WaterTemplateCreation = 'WaterTemplateCreation',
}

export type FeatureFlagMapping = {
  [name in FEATURE_NAME]: {
    description: string;
    isEnabled: boolean;
  };
};

export const FEATURE_FLAGS: FeatureFlagMapping = {
  // TODO: Remove feature flag after UAT
  // https://app.asana.com/0/1205742096370708/1206797978087268/f
  ConfigureTemplateVerification: {
    description: 'Template verification workflow that renders when configuring a template',
    isEnabled: true,
  },
  // TODO: Remove feature flag after UAT
  // https://app.asana.com/0/1205742096370708/1206797978087268/f
  WaterTemplateCreation: {
    description: 'Template Creation UX for water invoices',
    isEnabled: true,
  },
};

export const isFeatureFlagEnabled = (featureName: FEATURE_NAME) => FEATURE_FLAGS[featureName].isEnabled;

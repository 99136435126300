import React from 'react';
import startCase from 'lodash/startCase';
import { WaterTemplate } from '../types/WaterTemplateConfiguration';
import { Alert, ColumnLayout, Container, Header, SpaceBetween } from '@amzn/awsui-components-react';
import { IInvoiceMetadata } from 'src/interface/type-def';
import { TEMPLATE_TYPE_CONTENT } from '../templateMetadata/TemplateTypeMetadata';
import { ValueWithLabel } from 'src/components/common-components/keyValuePair/ValueWithLabel';
import { generateTemplateId } from '../../create/createUtils';
import { WaterTemplateMapSummary } from './WaterTemplateMapSummary';
import { TextractResultsState } from 'src/components/textract/hooks/useFetchInvoiceTextractResults';
import { TemplateType } from '../../types';
import { TemplateIdSummary } from '../templateMetadata/TemplateIdSummary';

interface TemplateSummaryStepProps {
  template: WaterTemplate;
  templateType: TemplateType;
  invoice: IInvoiceMetadata | null;
  textractResults: TextractResultsState | null;
}

export const TemplateSummaryStep = ({ template, templateType, invoice, textractResults }: TemplateSummaryStepProps) => {
  if (!invoice) {
    return (
      <Alert type="warning" header="Template Map error">
        There was an error reading the invoice metadata. Unable to display template map.
      </Alert>
    );
  }

  const { manifestDocument } = invoice;

  return (
    <SpaceBetween direction="vertical" size="m">
      <Container header={<Header variant="h2">Template Metadata</Header>}>
        <SpaceBetween direction="vertical" size="s">
          <Alert
            header={`You are creating a${templateType === TemplateType.ACCOUNT ? 'n' : ''} ${startCase(templateType)}`}
          >
            {TEMPLATE_TYPE_CONTENT[templateType].description(manifestDocument)}
          </Alert>

          <TemplateIdSummary templateType={templateType} manifestDocument={manifestDocument} />
        </SpaceBetween>
      </Container>

      <Container header={<Header variant="h2">Template Map</Header>}>
        <WaterTemplateMapSummary
          templateMap={template.templateMap}
          invoice={invoice}
          textractResults={textractResults}
        />
      </Container>
    </SpaceBetween>
  );
};

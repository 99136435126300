import { Alert, AlertProps, Button, Form, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { useHistory } from 'react-router';
import { ProcessedInvoiceData } from 'src/interface/type-def';
import { InvoiceOverrideSection } from './InvoiceOverrideSection';
import { InvoiceOverrideAccountFields, InvoiceOverrideMeterFields } from './InvoiceOverrideFields';
import { useHydrateInvoiceOverrideForm } from '../hooks/useHydrateInvoiceOverrideForm';

export interface InvoiceOverrideFormProps {
  results: ProcessedInvoiceData;
  id: string;
}

const ErrorAlert: React.FC<Pick<AlertProps, 'children'>> = ({ children }) => (
  <Alert data-testid="alertError" type="error" header="Submit Error" dismissible={false}>
    {children}
  </Alert>
);

const SuccessAlert: React.FC<Pick<AlertProps, 'children'>> = ({ children }) => (
  <Alert type="success" header="Submit Success" dismissible={false}>
    {children}
  </Alert>
);

export const InvoiceOverrideForm: React.FC<InvoiceOverrideFormProps> = (props) => {
  const { results, id } = props;
  const history = useHistory();

  const [submitError, setSubmitError] = React.useState<string>('');
  const [submitSuccess, setSubmitSuccess] = React.useState<string>('');

  const handleSubmitSuccess = () => {
    setSubmitSuccess('Successfully overrode invoice! Redirecting to invoice details page...');
    setTimeout(() => history.push(`/invoice/${id}`), 5000);
  };

  const handleSubmitError = (error?: string) => {
    setSubmitError(error ?? 'There was a problem submitting the form');
  };

  const {
    handleSubmit,
    handleReset,
    isSubmitDisabled,
    isSubmitting,
    accountFields,
    meterFields,
    accountErrorCount,
    meterErrorCount,
    setAccountValue,
    setMeterValue,
    setAccountError,
    setMeterError,
    addMeter,
    removeMeter,
  } = useHydrateInvoiceOverrideForm({
    values: results,
    invoiceId: id,
    onSubmitSuccess: handleSubmitSuccess,
    onSubmitError: handleSubmitError,
  });

  return (
    <SpaceBetween size="xs">
      <form onSubmit={handleSubmit}>
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button data-testid="reset" formAction="none" variant="normal" onClick={handleReset}>
                Reset
              </Button>

              <Button
                data-testid="submit"
                loading={isSubmitting}
                loadingText="Submitting invoice override form"
                formAction="submit"
                disabled={isSubmitDisabled}
                variant="primary"
              >
                Submit
              </Button>
            </SpaceBetween>
          }
        >
          <InvoiceOverrideSection headerText="Account Overview" errorCount={accountErrorCount}>
            <SpaceBetween size="xs">
              <InvoiceOverrideAccountFields
                account={accountFields}
                onChange={setAccountValue}
                onValidate={setAccountError}
              />
            </SpaceBetween>
          </InvoiceOverrideSection>

          <InvoiceOverrideSection headerText="Invoice Meters" errorCount={meterErrorCount}>
            <SpaceBetween size="xs">
              <InvoiceOverrideMeterFields
                meters={meterFields}
                onAdd={addMeter}
                onRemove={removeMeter}
                onChange={setMeterValue}
                onValidate={setMeterError}
              />
            </SpaceBetween>
          </InvoiceOverrideSection>
        </Form>
      </form>
      <div>
        {submitError && <ErrorAlert>{submitError}</ErrorAlert>}
        {submitSuccess && <SuccessAlert>{submitSuccess}</SuccessAlert>}
      </div>
    </SpaceBetween>
  );
};

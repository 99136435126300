import React, { useContext, useEffect, useMemo } from 'react';
import { IInvoiceMetadata, TemplateValue } from 'src/interface/type-def';
import { WaterMeter } from '../types/WaterTemplateConfiguration';
import { TextractResultsState } from 'src/components/textract/hooks/useFetchInvoiceTextractResults';
import { CreateWaterTemplateContext, WaterMeterTableItem } from '../CreateWaterTemplate';
import { Header, Table, TableProps } from '@amzn/awsui-components-react';
import {
  getKeyFromValueRef,
  getValueFromValueRef,
  isHeaderBasedTableValueRef,
  isKeyValueRef,
} from 'src/utils/valueRefUtils';
import { SubtermPopoverText } from '../subterm/SubtermPopoverText';

interface ConfigureMeterPropertiesProps {
  invoice: IInvoiceMetadata | null;
  meter: WaterMeter;
  textractResults: TextractResultsState | null;
  variant?: 'editable' | 'readonly';
  originalMeter?: WaterMeter; // If updating a template, represents the existing Meter configuration, before the user has made changes in the UI
}

export const ConfigureMeterProperties = ({
  invoice,
  meter,
  textractResults,
  variant = 'editable',
  originalMeter,
}: ConfigureMeterPropertiesProps) => {
  const createWaterTemplateContext = useContext(CreateWaterTemplateContext);
  const { selectedRow, setSelectedRow } = createWaterTemplateContext;

  const isEditable = variant === 'editable';

  useEffect(() => {
    // By default, select the first property in the table
    setSelectedRow(tableItems[0]);
  }, []);

  const tableItems: Array<WaterMeterTableItem> = useMemo(
    () =>
      Object.keys(meter)
        .filter((keyName) => keyName !== 'tesseractId')
        .map((meterKey) => {
          const propertyName = meterKey as keyof WaterMeter;
          return {
            propertyName,
            templateValue: meter[propertyName] as TemplateValue,
          };
        }),
    [meter]
  );

  const selectedTableRow: WaterMeterTableItem | undefined = useMemo(() => {
    const selectedRowPropertyName = selectedRow?.propertyName;
    return tableItems.find((item) => item.propertyName === selectedRowPropertyName);
  }, [tableItems, selectedRow]);

  const columnDefs: Array<TableProps.ColumnDefinition<WaterMeterTableItem>> = [
    {
      id: 'propertyName',
      header: 'Property',
      cell: (e) => e.propertyName,
    },
    {
      id: 'source',
      header: 'Source',
      cell: (e) => {
        const { valueRef, source } = e.templateValue;
        if (!valueRef || !source) return '-';

        if (isKeyValueRef(valueRef)) {
          return 'Invoice (key-value)';
        }
        if (isHeaderBasedTableValueRef(valueRef)) {
          return 'Invoice (table)';
        }
        return source;
      },
    },
    {
      id: 'key',
      header: 'Key',
      cell: (e) => getKeyFromValueRef(e.templateValue) || '-',
    },
    {
      id: 'value',
      header: 'Value',
      cell: (e) => getValueFromValueRef(e.templateValue, textractResults, invoice?.manifestDocument) || '-',
    },
    {
      id: 'subterm',
      header: 'Subterm',
      cell: (e) => <SubtermPopoverText subterm={e.templateValue.valueRef?.subterm} />,
    },
  ];

  return (
    <Table
      header={<Header>Meter properties</Header>}
      columnDefinitions={columnDefs}
      items={tableItems}
      selectionType={isEditable ? 'single' : undefined}
      selectedItems={isEditable && selectedRow ? [selectedTableRow as WaterMeterTableItem] : undefined}
      onSelectionChange={isEditable ? (e) => setSelectedRow(e.detail.selectedItems[0]) : undefined}
    />
  );
};

import React from 'react';
import { Link } from 'react-router-dom';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { numericDate } from '../../utils/dates';
import { IInvoiceMetadata } from 'src/interface/type-def';
import { SelectProps, CollectionPreferencesProps } from '@amzn/awsui-components-react';
import {
  PROCESSING_STATUS,
  PROCESSING_STATUS_DESCRIPTIONS,
  processingStatusSortingComparator,
} from 'src/interface/processingStatus';
import { toSentenceCase, compareIgnoreCase } from 'src/utils/strings';
import { PAGE_ROUTES } from '../Routes';
import { ProcessingstatusIndicator } from './ProcessingStatusIndicator';

export enum MANIFEST_TABLE_COLUMN_KEYS {
  invoiceId = 'invoiceId',
  lastDocuemntInsight = 'lastDocuemntInsight',
  processingStatus = 'processingStatus',
  utilityType = 'utilityType',
  locationCode = 'locationCode',
  siteName = 'siteName',
  accountNumber = 'accountNumber',
  templateId = 'templateId',
}

export enum ANY_PROCESSING_STATUS_FILTER_OPTION {
  ANY_STATUS = 'Any Status',
}

export function getProcessingStatusOptionDefinition(status: string): SelectProps.Option {
  return {
    label: toSentenceCase(status),
    value: status,
    description:
      status in PROCESSING_STATUS_DESCRIPTIONS ? PROCESSING_STATUS_DESCRIPTIONS[status as PROCESSING_STATUS] : '',
  };
}

export const PIPELINE_STATUS_FILTER_OPTIONS: SelectProps.Options = [
  getProcessingStatusOptionDefinition(ANY_PROCESSING_STATUS_FILTER_OPTION.ANY_STATUS),
  ...Object.values(PROCESSING_STATUS).map((status) => getProcessingStatusOptionDefinition(status)),
];

export const manifestColumnDefinitions: TableProps.ColumnDefinition<IInvoiceMetadata>[] = [
  {
    id: MANIFEST_TABLE_COLUMN_KEYS.invoiceId,
    header: 'Invoice ID',
    cell: (item) => (
      <Link
        to={{
          pathname: `${PAGE_ROUTES.invoice}/${item.invoicePdfName}`,
          state: { manifest: item },
        }}
      >
        {item.invoicePdfName}
      </Link>
    ),
    minWidth: 100,
    sortingField: MANIFEST_TABLE_COLUMN_KEYS.invoiceId,
  },
  {
    id: MANIFEST_TABLE_COLUMN_KEYS.lastDocuemntInsight,
    header: 'Last document insight',
    cell: (item) => numericDate(item.lastDocumentInsightGeneratedTime, true),
    minWidth: 100,
    sortingField: MANIFEST_TABLE_COLUMN_KEYS.lastDocuemntInsight,
  },
  {
    id: MANIFEST_TABLE_COLUMN_KEYS.processingStatus,
    header: 'Status',
    cell: (item) => <ProcessingstatusIndicator status={item.processingStatus} renderPopover />,
    minWidth: 150,
    sortingComparator: (a, b) => processingStatusSortingComparator(a?.processingStatus, b?.processingStatus),
  },
  {
    id: MANIFEST_TABLE_COLUMN_KEYS.utilityType,
    header: 'Utility type',
    cell: (item) => item.manifestDocument?.serviceRelatedTo ?? '-',
    minWidth: 100,
    sortingComparator: (a, b) =>
      compareIgnoreCase(
        a?.manifestDocument?.serviceRelatedTo?.trim() ?? '',
        b?.manifestDocument?.serviceRelatedTo?.trim() ?? ''
      ),
  },
  {
    id: MANIFEST_TABLE_COLUMN_KEYS.locationCode,
    header: 'Location code',
    cell: (item) => item.manifestDocument.locationCode,
    sortingComparator: (a, b) =>
      compareIgnoreCase(
        a?.manifestDocument?.locationCode?.trim() ?? '',
        b?.manifestDocument?.locationCode?.trim() ?? ''
      ),
  },
  {
    id: MANIFEST_TABLE_COLUMN_KEYS.siteName,
    header: 'Site name',
    cell: (item) => item.manifestDocument?.siteName ?? '-',
    sortingComparator: (a, b) =>
      compareIgnoreCase(a?.manifestDocument?.siteName?.trim() ?? '', b?.manifestDocument?.siteName?.trim() ?? ''),
  },
  {
    id: MANIFEST_TABLE_COLUMN_KEYS.accountNumber,
    header: 'Account number',
    cell: (item) => item.manifestDocument.accountNumber,
    sortingComparator: (a, b) =>
      compareIgnoreCase(
        a?.manifestDocument?.accountNumber?.trim() ?? '',
        b?.manifestDocument?.accountNumber?.trim() ?? ''
      ),
  },
];

export const paginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page',
};

export const pageSizePreference: CollectionPreferencesProps.PageSizePreference = {
  title: 'Select page size',
  options: [
    {
      value: 10,
      label: '10 invoices',
    },
    {
      value: 50,
      label: '50 invoices',
    },
  ],
};

export const visibleContentPreference: CollectionPreferencesProps.VisibleContentPreference = {
  title: 'Select visble content',
  options: [
    {
      label: 'Main properties',
      options: manifestColumnDefinitions.map(
        ({ id, header }) => ({ id, label: header } as CollectionPreferencesProps.VisibleContentOption)
      ),
    },
  ],
};

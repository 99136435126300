import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { getInvoice } from 'src/client/api-gateway';
import { IInvoiceMetadata } from 'src/interface/type-def';

export interface UseFetchManifestById {
  invoiceId: string;
  setInvoiceId: Dispatch<SetStateAction<string>>;
  manifest: IInvoiceMetadata | null;
  manifestLoading: boolean;
  fetchManifestById: (id: string) => Promise<void>;
}

export function useFetchManifestById() {
  const [invoiceId, setInvoiceId] = useState<string>('');
  const [manifest, setManifest] = useState<IInvoiceMetadata | null>(null);
  const [manifestLoading, setManifestLoading] = useState(false);

  const fetchManifestById = useCallback(async (id: string) => {
    setInvoiceId(id);
    setManifest(null);

    if (!id) return;

    try {
      setManifestLoading(true);
      const manifest = await getInvoice(id);
      setManifest(manifest || null);
    } catch (e) {
      setManifest(null);
    } finally {
      setManifestLoading(false);
    }
  }, []);

  return {
    invoiceId,
    setInvoiceId,
    manifest,
    manifestLoading,
    fetchManifestById,
  };
}

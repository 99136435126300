import * as React from 'react';
import { Container, Button, Link } from '@amzn/awsui-components-react';

export type ContainerErrorProps = {
  onRetry: () => unknown;
};
export function ContainerError(props: ContainerErrorProps) {
  return (
    <Container header={<h3>Oops Something happened</h3>}>
      <p>
        You can retry loading the page or <Link href="#/">return to home page</Link>{' '}
      </p>
      <Button variant="primary" onClick={props.onRetry}>
        Try again
      </Button>
    </Container>
  );
}

import React, { useCallback, useEffect, useMemo } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { PDFComponent } from 'src/components/common-components/pdf-component';
import { InvoiceMetadata, Template } from 'src/interface/type-def';

import {
  Alert,
  Container,
  DatePicker,
  FormField,
  Grid,
  Header,
  Input,
  SpaceBetween,
  Button,
} from '@amzn/awsui-components-react';
import Tiles from '@amzn/awsui-components-react/polaris/tiles';

import { numericDate } from '../../../utils/dates';
import { Link } from 'react-router-dom';
import { getTemplateToExtendForAccountTemplate, getTemplateToExtendForSiteTemplate } from './templateHierarchyUtils';
import { ExistingTemplateAlert } from './alerts/ExistingTemplateAlert';
import { TemplatePropertySuggestedValues } from '../templateSuggestions/templateSuggestionsUtils';
import { autoApplySuggestedTemplateValues } from './createUtils';
import { TemplateType } from '../types';
import { PAGE_ROUTES } from 'src/components/Routes';

export const isExistingTemplate = (validFrom: number, template?: Template) =>
  template !== undefined && template.validFrom === validFrom;

export const hasNewerParentTemplate = (validFrom: number, template?: Template) =>
  template?.validFrom !== undefined && template?.validFrom >= validFrom;

export const getTemplateWarnings = (
  validFrom: number,
  utilTemplate?: Template,
  siteTemplate?: Template,
  accountTemplate?: Template,
  utilityId?: string,
  siteId?: string
) => {
  let utilWarning = '';
  let siteWarning = '';
  let accountWarning = '';

  if (isExistingTemplate(validFrom, utilTemplate)) {
    utilWarning =
      'A template already exists for this ID and Valid From date. Proceed to edit this template or specify a more recent Valid From date to create a new version. Refer to the Tesseract User Guide for more information on versioning templates.';
  }

  if (siteTemplate === undefined) {
    if (utilTemplate === undefined) {
      siteWarning = `No templates exist for this site or the parent utility ${utilityId}. It is recommended to create a utility-level template first before creating a template deeper in the hierarchy. Proceed to create a site template.`;
    } else if (utilTemplate.validFrom && hasNewerParentTemplate(validFrom, utilTemplate)) {
      siteWarning = `A utility template for ${utilityId} was found but only with a Valid From date ${new Date(
        utilTemplate.validFrom
      ).toDateString()} later than your indicated Valid From date ${new Date(
        validFrom
      ).toDateString()}. This means if you proceed, you will create a site-level template with no utility-level template to extend. It is recommended to create a utility-level template first before creating a template deeper in the hierarchy. Proceed to create a site template.`;
    }
  } else if (isExistingTemplate(validFrom, siteTemplate)) {
    siteWarning =
      'A template already exists for this ID and Valid From date. Proceed to edit this template or specify a more recent Valid From date to create a new version. Refer to the Tesseract User Guide for more information on versioning templates.';
  }

  if (accountTemplate === undefined) {
    if (utilTemplate === undefined && siteTemplate === undefined) {
      accountWarning =
        'No templates exist for this account and there are none found to extend. It is recommended to create a utility-level template first before creating a template deeper in the hierarchy. Proceed to create an account template';
    } else if (utilTemplate?.validFrom && hasNewerParentTemplate(validFrom, utilTemplate)) {
      accountWarning = `A utility template for ${utilityId} was found but only with a Valid From date ${new Date(
        utilTemplate.validFrom
      ).toDateString()} later than your indicated Valid From date ${new Date(
        validFrom
      ).toDateString()}. This means if you proceed, you will create a site-level template with no utility-level template to extend. It is recommended to create a utility-level template first before creating a template deeper in the hierarchy. Proceed to create a site template.`;
    } else if (siteTemplate?.validFrom && hasNewerParentTemplate(validFrom, siteTemplate)) {
      accountWarning = `A site template for ${siteId} was found but only with a Valid From date ${new Date(
        siteTemplate?.validFrom
      ).toDateString()} later than your indicated Valid From date ${new Date(
        validFrom
      ).toDateString()}. This means if you proceed, you will create a site-level template with no utility-level template to extend. It is recommended to create a utility-level template first before creating a template deeper in the hierarchy. Proceed to create a site template.`;
    }
  } else if (isExistingTemplate(validFrom, accountTemplate)) {
    accountWarning =
      'A template already exists for this ID and Valid From date. Proceed to edit this template or specify a more recent Valid From date to create a new version. Refer to the Tesseract User Guide for more information on versioning templates.';
  }

  return {
    [TemplateType.UTILITY]: utilWarning,
    [TemplateType.SITE]: siteWarning,
    [TemplateType.ACCOUNT]: accountWarning,
  };
};

interface DefineMetadataProps {
  // If creating: empty template that the new template is created from
  blankTemplate: Template;
  // Stores configuration that will be submitted for template that is being created/updated
  newTemplate: Template;
  setNewTemplate: Function;
  // Path to S3 presigned URL that renders invoice PDF
  pdfFile: string;
  // Invoice for which the template is being created/updated
  existingInvoice?: InvoiceMetadata;
  // If updating: the existing template that is being updated
  templateToUpdate: Template;
  // True if updating an existing template, false otherwise
  isUpdatingTemplate: boolean;
  // Type of template being created, or type of existing template if updating
  templateType: TemplateType;
  // Callback function to update the selected template type
  setTemplateType: Function;
  // Existing utility template, or null if does not exist. If creating a site template, it will extend this template if applicable
  existingUtilityTemplate: Template | null;
  // Existing site template, or null if does not exist. If creating an account template, it will extend this template if applicable
  existingSiteTemplate: Template | null;
  // Existing account template, or null if does not exist.
  existingAccountTemplate: Template | null;
  // `Valid from date` selected in the Template Creation wizard for this template
  validDate: string;
  setValidDate: (date: string) => void;
  suggestedTemplateValues: TemplatePropertySuggestedValues | null;
}

function DefineMetadata(props: DefineMetadataProps) {
  const {
    blankTemplate,
    newTemplate,
    setNewTemplate,
    pdfFile,
    existingInvoice,
    templateToUpdate,
    isUpdatingTemplate,
    templateType,
    setTemplateType,
    existingUtilityTemplate,
    existingSiteTemplate,
    existingAccountTemplate,
    validDate,
    setValidDate,
    suggestedTemplateValues,
  } = props;
  const utilityIdFromManifest = isUpdatingTemplate
    ? templateToUpdate?.utilityId
    : existingInvoice?.manifestDocument.supplierNumber;

  const siteIdFromManifest = isUpdatingTemplate ? templateToUpdate.siteId : existingInvoice?.manifestDocument.siteId;

  const accountIdFromManifest = isUpdatingTemplate
    ? templateToUpdate.accountId
    : existingInvoice?.manifestDocument.accountNumber;

  const [utilityId, setUtilityId] = React.useState(utilityIdFromManifest);
  const [siteId, setSiteId] = React.useState(siteIdFromManifest);
  const [accountId, setAccountId] = React.useState(accountIdFromManifest);

  const [showWarning, setShowWarning] = React.useState(false);

  const [warning, setWarning] = React.useState({
    [TemplateType.UTILITY]: '',
    [TemplateType.SITE]: '',
    [TemplateType.ACCOUNT]: '',
    [TemplateType.ADVANCED]: '',
  });

  const isAdvancedTemplate = templateType === TemplateType.ADVANCED;

  useEffect(() => {
    if (templateType === TemplateType.ADVANCED || isUpdatingTemplate) return;

    setUtilityId(utilityIdFromManifest);
    setSiteId(siteIdFromManifest);
    setAccountId(accountIdFromManifest);

    // The backend constructs the template ID based on the utility/site/account IDs in the create
    // template request.
    // Only include the site ID for non-utility templates (i.e. site and account templates)
    const siteIdForNewTemplate = templateType === TemplateType.UTILITY ? undefined : siteIdFromManifest;
    // Only include the account ID for account templates
    const accountIdForNewTemplate = templateType === TemplateType.ACCOUNT ? accountIdFromManifest : undefined;

    if (templateType === TemplateType.UTILITY) {
      const updatedTemplateMap = cloneDeep(newTemplate.templateMap);
      // When creating a new utility template, auto-apply all suggested values that can be pulled from the manifest file
      if (suggestedTemplateValues) {
        autoApplySuggestedTemplateValues(suggestedTemplateValues, updatedTemplateMap);
      }
      setNewTemplate({
        ...newTemplate,
        validFrom: newTemplate.validFrom,
        templateMap: updatedTemplateMap,
        utilityId: utilityIdFromManifest,
        siteId: siteIdForNewTemplate,
        accountId: accountIdForNewTemplate,
      });
    } else if (templateType === TemplateType.SITE) {
      const baseTemplate = getTemplateToExtendForSiteTemplate(newTemplate, existingUtilityTemplate, blankTemplate);
      setNewTemplate({
        ...newTemplate,
        templateMap: baseTemplate.templateMap,
        validFrom: newTemplate.validFrom,
        utilityId: utilityIdFromManifest,
        siteId: siteIdForNewTemplate,
        accountId: accountIdForNewTemplate,
      });
    } else if (templateType === TemplateType.ACCOUNT) {
      const baseTemplate = getTemplateToExtendForAccountTemplate(
        newTemplate,
        existingUtilityTemplate,
        existingSiteTemplate,
        blankTemplate
      );
      setNewTemplate({
        ...newTemplate,
        templateMap: baseTemplate.templateMap,
        utilityId: utilityIdFromManifest,
        siteId: siteIdForNewTemplate,
        accountId: accountIdForNewTemplate,
      });
    }
  }, [templateType, validDate, suggestedTemplateValues]);

  useEffect(() => {
    if (templateType === TemplateType.ADVANCED) {
      setNewTemplate({
        ...newTemplate,
        utilityId,
        siteId,
        accountId,
      });
    }
  }, [utilityId, siteId, accountId]);

  useEffect(() => {
    const validFrom = new Date(validDate).getTime();

    setWarning({
      ...warning,
      ...getTemplateWarnings(
        validFrom,
        existingUtilityTemplate || undefined,
        existingSiteTemplate || undefined,
        existingAccountTemplate || undefined,
        utilityId,
        siteId
      ),
    });
  }, [existingUtilityTemplate, existingSiteTemplate, existingAccountTemplate, validDate]);

  useEffect(() => {
    setShowWarning(templateType !== TemplateType.ADVANCED && warning[templateType] !== '');
  }, [templateType, warning]);

  const getExistingTemplateAlert = useCallback(() => {
    if (isUpdatingTemplate) return null;

    let existingTemplateForSelectedType: Template | null = null;
    if (templateType === TemplateType.UTILITY && existingUtilityTemplate) {
      existingTemplateForSelectedType = existingUtilityTemplate;
    } else if (templateType === TemplateType.SITE && existingSiteTemplate) {
      existingTemplateForSelectedType = existingSiteTemplate;
    } else if (templateType === TemplateType.ACCOUNT && existingAccountTemplate) {
      existingTemplateForSelectedType = existingAccountTemplate;
    }

    return existingTemplateForSelectedType ? (
      <ExistingTemplateAlert existingTemplate={existingTemplateForSelectedType} templateType={templateType} />
    ) : null;
  }, [templateType, isUpdatingTemplate]);

  const MemoizedPDFComponent = useMemo(() => PDFComponent, [pdfFile]);

  return (
    <SpaceBetween size="xs">
      <Container header={<Header variant="h2">Select Template</Header>}>
        <SpaceBetween size="m">
          {getExistingTemplateAlert()}
          <Tiles
            onChange={({ detail }) => setTemplateType(detail.value as TemplateType)}
            value={templateType}
            columns={4}
            items={[
              {
                label: 'Utility Template',
                description: 'Create or use an existing utility template',
                value: TemplateType.UTILITY,
                disabled: isUpdatingTemplate && templateType !== TemplateType.UTILITY,
              },
              {
                label: 'Site Template',
                description: 'Create or use an existing site template',
                value: TemplateType.SITE,
                disabled: isUpdatingTemplate && templateType !== TemplateType.SITE,
              },
              {
                label: 'Account Template',
                description: 'Create or use an existing account template',
                value: TemplateType.ACCOUNT,
                disabled: isUpdatingTemplate && templateType !== TemplateType.ACCOUNT,
              },
              {
                label: 'New Template',
                description: 'Create a new template (advanced)',
                value: TemplateType.ADVANCED,
                disabled: isUpdatingTemplate && templateType !== TemplateType.ADVANCED,
              },
            ]}
          />
          <Alert visible={showWarning} type="warning">
            {warning[templateType]}
          </Alert>
        </SpaceBetween>
      </Container>
      <Grid gridDefinition={[{ colspan: { default: 4 } }, { colspan: { default: 8 } }]}>
        <Container header={<Header variant="h2">Define the Metadata for your New Template</Header>}>
          <SpaceBetween size="l">
            <FormField label="Utility Provider (ID)" description="Fixed value from manifest">
              {isAdvancedTemplate ? (
                <Input
                  ariaRequired
                  readOnly={!isAdvancedTemplate}
                  disabled={!isAdvancedTemplate}
                  value={utilityId as string}
                  onChange={({ detail }) => setUtilityId(detail.value)}
                  placeholder="Utility ID"
                />
              ) : (
                <div>{utilityId}</div>
              )}
            </FormField>

            {templateType !== TemplateType.UTILITY && (
              <FormField label="Site (ID)" description="Fixed value from manifest">
                {isAdvancedTemplate ? (
                  <Input
                    readOnly={!isAdvancedTemplate}
                    disabled={!isAdvancedTemplate}
                    ariaRequired
                    value={siteId as string}
                    onChange={({ detail }) => setSiteId(detail.value)}
                    placeholder="Site ID"
                  />
                ) : (
                  <div>{siteId}</div>
                )}
              </FormField>
            )}

            {templateType !== TemplateType.UTILITY && templateType !== TemplateType.SITE && (
              <FormField label="Account:" description="Fixed value from manifest">
                {isAdvancedTemplate ? (
                  <Input
                    value={accountId as string}
                    onChange={({ detail }) => setAccountId(detail.value)}
                    placeholder="Account Number from manifest"
                    readOnly={!isAdvancedTemplate}
                    disabled={!isAdvancedTemplate}
                    ariaRequired
                  />
                ) : (
                  <div>{accountId}</div>
                )}
              </FormField>
            )}

            <FormField label="Invoice Used (ID)" description="ID of Invoice used for template creation.">
              <div>
                {!isUpdatingTemplate
                  ? existingInvoice?.invoicePdfName || ''
                  : templateToUpdate.createdFromInvoiceId || ''}
              </div>
            </FormField>

            <FormField
              label="Valid From Date"
              description="Templates will be applied to invoices created on or after this date"
              constraintText="Defaults to 1970/01/01 (YYYY/MM/DD)"
            >
              <DatePicker
                locale="en-US"
                onChange={({ detail }) => {
                  setValidDate(detail.value);
                  setNewTemplate({
                    ...newTemplate,
                    validFrom: !isUpdatingTemplate ? Date.parse(detail.value) : templateToUpdate.validFrom,
                  });
                }}
                value={validDate}
                openCalendarAriaLabel={(selectedDate) =>
                  `Choose Date${selectedDate ? `, selected date is ${selectedDate}` : ''}`
                }
                nextMonthAriaLabel="Next month"
                placeholder={numericDate(Date.now())}
                previousMonthAriaLabel="Previous month"
                todayAriaLabel="Today"
              />
            </FormField>
          </SpaceBetween>
        </Container>
        <Container
          header={
            <div>
              <Header
                variant="h2"
                actions={
                  <Link
                    to={{
                      pathname: `${PAGE_ROUTES.invoice}/${
                        isUpdatingTemplate
                          ? (templateToUpdate.createdFromInvoiceId as string)
                          : existingInvoice?.invoicePdfName
                      }`,
                    }}
                    target="_blank"
                  >
                    <Button
                      ariaLabel="View Invoice"
                      iconAlign="right"
                      iconName="external"
                      target="_blank"
                      variant="icon"
                    />
                  </Link>
                }
              >
                Invoice
              </Header>
            </div>
          }
        >
          <MemoizedPDFComponent pdfFile={pdfFile} />
        </Container>
      </Grid>
    </SpaceBetween>
  );
}

export { DefineMetadata };

import { Box, Container, Header, Icon } from '@amzn/awsui-components-react';
import React from 'react';

// TODO: Populate this component with links to external resources, or remove if we don't have any relevant links
export const MoreResourcesContainer = () => {
  const externalIcon = (
    <span role="img" aria-label="Links open in a new tab">
      <Icon name="external" size="inherit" />
    </span>
  );

  return (
    <Container
      header={
        <Header variant="h2">
          <span>More resources {externalIcon}</span>
        </Header>
      }
    >
      <Box margin={{ horizontal: 'n', bottom: 'xs' }}>User Guide</Box>
      <Box margin={{ horizontal: 'n', bottom: 'xs' }}>Report a Bug</Box>
    </Container>
  );
};

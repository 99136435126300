import React from 'react';
import { Box, ColumnLayout, SpaceBetween } from '@amzn/awsui-components-react';
import { convertSecondsToMilliseconds, numericDate } from 'src/utils/dates';
import { BlueprintConfiguration } from 'src/components/blueprints/types';

export interface BlueprintDetailsProps {
  blueprint: BlueprintConfiguration;
}

export const BlueprintDetails = ({ blueprint }: BlueprintDetailsProps) => {
  const { id, blueprintName, updateLog, validFrom } = blueprint;

  return (
    <ColumnLayout columns={3} variant="text-grid">
      <SpaceBetween size="l">
        <div>
          <Box variant="small">Blueprint name</Box>
          <div>{blueprintName || '-'}</div>
        </div>
      </SpaceBetween>

      <SpaceBetween size="l">
        <div>
          <Box variant="small">Vendor ID</Box>
          <div>{id || '-'}</div>
        </div>
      </SpaceBetween>

      <SpaceBetween size="l">
        <div>
          <Box variant="small">Valid from</Box>
          <div>{numericDate(convertSecondsToMilliseconds(validFrom || 0), true) || '-'}</div>
        </div>
      </SpaceBetween>

      <SpaceBetween size="l">
        <div>
          <Box variant="small">Last updated</Box>
          <div>{numericDate(convertSecondsToMilliseconds(updateLog[0]?.timestamp || 0), true) || '-'}</div>
        </div>
      </SpaceBetween>
    </ColumnLayout>
  );
};

import {
  Badge,
  Box,
  Button,
  ColumnLayout,
  Container,
  Grid,
  Header,
  Icon,
  Link as AWSUILink,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { BreadcrumbGroupWithRouting } from 'src/components/breadcrumbs/BreadcrumbGroupWithRouting';
import { ROOT_BREADCRUMB } from 'src/components/breadcrumbs/breadcrumbs';

import 'src/components/home/HomePage.scss';
import { PAGE_ROUTES } from '../Routes';
import { MoreResourcesContainer } from './MoreResourcesContainer';

export const HomePage = () => (
  <Box>
    <SpaceBetween size="l">
      <BreadcrumbGroupWithRouting
        items={[
          {
            text: ROOT_BREADCRUMB.homepage.text,
            href: ROOT_BREADCRUMB.homepage.getHref(),
          },
        ]}
      />
      <Box margin={{ bottom: 'xl' }}>
        <Grid
          gridDefinition={[
            { colspan: { xl: 6, l: 5, s: 6, xxs: 10 }, offset: { l: 2, xxs: 1 } },
            { colspan: { xl: 2, l: 3, s: 4, xxs: 10 }, offset: { s: 0, xxs: 1 } },
          ]}
        >
          <Box>
            <Box variant="h1" fontWeight="heavy" fontSize="display-l" color="inherit">
              Tesseract
            </Box>
            <Box fontWeight="normal" padding={{ bottom: 's' }} fontSize="display-l" color="inherit">
              Automate utility data extraction from invoices
            </Box>
          </Box>

          <Container header={<Header variant="h2">Start extracting utility data</Header>}>
            <SpaceBetween size="l">
              <Box> Get started by creating a blueprint using an invoice as a reference.</Box>
              <Link
                to={{
                  pathname: `${PAGE_ROUTES.createBlueprint}`,
                }}
              >
                <Button variant="primary">Create a blueprint</Button>
              </Link>
            </SpaceBetween>
          </Container>
        </Grid>
      </Box>

      <Grid
        gridDefinition={[
          { colspan: { xl: 6, l: 5, s: 6, xxs: 10 }, offset: { l: 2, xxs: 1 } },
          { colspan: { xl: 2, l: 3, s: 4, xxs: 10 }, offset: { s: 0, xxs: 1 } },
        ]}
      >
        <Container header={<Header variant="h2">How it works</Header>}>
          <ColumnLayout columns={1}>
            <Box>
              <Box padding={{ right: 'xs' }} display="inline">
                <Badge color="blue">1</Badge>
              </Box>
              Upload a manifest CSV file, and the PDFs for each invoice in the manifest, into Tesseract.
            </Box>
            <Box>
              <Box padding={{ right: 'xs' }} display="inline">
                <Badge color="blue">2</Badge>
              </Box>
              <Link to={{ pathname: PAGE_ROUTES.createBlueprint }}>Create a Blueprint</Link>, which describes how
              Tesseract should extract data from invoices from the selected vendor.
            </Box>
            <Box>
              <Box padding={{ right: 'xs' }} display="inline">
                <Badge color="blue">3</Badge>
              </Box>
              <Link to={{ pathname: PAGE_ROUTES.invoices }}>Process an invoice</Link>, which will leverage the Blueprint
              you created to extract the required utility data.
            </Box>
            <Box>
              <Box padding={{ right: 'xs' }} display="inline">
                <Badge color="blue">4</Badge>
              </Box>
              Compare the processed results for each invoice to the PDF. The results may not always be accurate, so you
              can manually override any of the results as needed.
            </Box>
          </ColumnLayout>
        </Container>

        <MoreResourcesContainer />
      </Grid>
    </SpaceBetween>
  </Box>
);

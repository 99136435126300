import React, { useCallback, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { WaterTemplate } from '../waterTemplates/types/WaterTemplateConfiguration';
import { upsertWaterTemplate } from 'src/client/api-gateway';
import { TemplateType } from '../types';
import { generateTesseractId } from '../create/createUtils';

export const useUpsertWaterTemplate = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [requestError, setRequestError] = useState<string>('');

  const upsertTemplate = useCallback(async (template: WaterTemplate) => {
    setIsLoading(true);
    setRequestError('');

    try {
      const response = await upsertWaterTemplate(template);
      return response.templateId;
    } catch (e) {
      const errorMsg = e as string;
      setRequestError(errorMsg);
      throw new Error(errorMsg);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    isLoading,
    requestError,
    upsertTemplate,
  };
};

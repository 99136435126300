import { Alert, Box, Spinner } from '@amzn/awsui-components-react';
import React from 'react';

export interface InvoiceProcessingResultsProps {
  processingResults: string;
  isLoadingProcessingResults: boolean;
}

export const InvoiceProcessingResults = ({
  processingResults,
  isLoadingProcessingResults,
}: InvoiceProcessingResultsProps) => (
  <Box>
    {isLoadingProcessingResults ? (
      <>
        <Spinner />
        <Box variant="span" margin={{ left: 'xs' }}>
          Loading invoice processing results...
        </Box>
      </>
    ) : // TODO: Create common component to display JSON results in more user-friendly format
    // https://app.asana.com/0/1205742096370708/1206555286740456/f
    processingResults ? (
      <aside data-testid="invoice-processing-results" className="whitespace--pre">
        {processingResults}
      </aside>
    ) : (
      <Alert type="warning" header="No processing results">
        There are no results for this invoice. It either hasn't been processed yet, or there was an error while
        processing. If there was an error, you can find more details under the Processing Errors tab.
      </Alert>
    )}
  </Box>
);

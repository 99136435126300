import { TemplateLog } from 'src/interface/type-def';

export interface BlueprintPropertyValueSettings {
  billing: BlueprintBillingConfigurationSettings;
  meter: BlueprintMeterConfigurationSettings;
}

export interface BlueprintConfiguration {
  id: string;
  blueprintName: string;
  blueprintDescription: string;
  blueprintConfigurationSettings: BlueprintPropertyValueSettings;
  updateLog: Array<TemplateLog>;
  keystoneBlueprintId: string;
  validFrom: number; // Epoch (seconds)
  lastUpdatedBy: string;
}

export enum BlueprintPropertyValueType {
  Prompt = 'PROMPT',
  Static = 'STATIC',
}

export interface BlueprintPropertyValueSetting {
  type: BlueprintPropertyValueType;
  prompt?: string; // populated if type is PROMPT
  value?: string; // populated if type is STATIC
}

export interface BlueprintBillingConfigurationSettings {
  [propertyName: string]: BlueprintPropertyValueSetting;
}

export interface BlueprintMeterConfigurationSettings {
  [propertyName: string]: BlueprintPropertyValueSetting;
}

export enum BlueprintPropertyDefinitionFieldType {
  Object = 'OBJECT', // Used for top-level keys, ex. `billing` or `meter`, that contain nested properties
  Prompt = 'PROMPT',
  Static = 'STATIC',
}

export interface BlueprintPropertyDefinition {
  fieldType: BlueprintPropertyDefinitionFieldType;
  selectElements?: Array<string>; // Populated if fieldType = `STATIC`
  nestedFields?: {
    // Populated if fieldType = `OBJECT`
    [propertyName: string]: BlueprintPropertyDefinition;
  };
}

export interface BlueprintUtilityDefinition {
  billing: BlueprintPropertyDefinition;
  meter: BlueprintPropertyDefinition;
}

import { TableProps, Button, Table } from '@amzn/awsui-components-react';
import React from 'react';
import { PageKeyValues } from 'src/helpers/textract';
import { IInvoiceMetadata, ManifestDocument, TemplateValue, ValueRefTypes } from 'src/interface/type-def';
import { getMatchingKeyValuePairForKey } from './templateSuggestionsUtils';

const MIN_COLUMN_WIDTH_PX = 125;

interface SuggestedPropertyMappingTableProps {
  invoice?: IInvoiceMetadata;
  suggestedMappings: Array<TemplateValue>;
  textractKeyValuePairs: Array<PageKeyValues>;
  onApplyMapping: (templateValue: TemplateValue) => void;
}

export const SuggestedPropertyMappingTable = ({
  invoice,
  suggestedMappings,
  textractKeyValuePairs,
  onApplyMapping,
}: SuggestedPropertyMappingTableProps) => {
  const columnDefinitions: Array<TableProps.ColumnDefinition<TemplateValue>> = [
    {
      id: 'source',
      header: 'Source',
      cell: (item: TemplateValue) => item.source,
    },
    {
      id: 'key',
      header: 'Key',
      cell: (item: TemplateValue) => {
        const { valueRef } = item;
        if (valueRef?.type === ValueRefTypes.KeyValue) {
          return valueRef.ref.key;
        }
        if (valueRef?.type === ValueRefTypes.ManifestValue) {
          return valueRef.ref.value;
        }

        return '-';
      },
    },
    {
      id: 'value',
      header: 'Value',
      cell: (item: TemplateValue) => {
        const { valueRef } = item;
        if (valueRef?.type === ValueRefTypes.KeyValue) {
          return getMatchingKeyValuePairForKey(textractKeyValuePairs, valueRef.ref.key)?.value || '';
        }
        if (valueRef?.type === ValueRefTypes.StaticValue) {
          return valueRef.ref.value;
        }
        if (valueRef?.type === ValueRefTypes.ManifestValue) {
          return invoice?.manifestDocument[valueRef.ref.value as keyof ManifestDocument];
        }

        return '';
      },
    },
    {
      id: 'action',
      header: 'Apply',
      cell: (item: TemplateValue) => (
        <Button variant="inline-link" ariaLabel="Apply template mapping" onClick={() => onApplyMapping(item)}>
          Apply
        </Button>
      ),
      minWidth: MIN_COLUMN_WIDTH_PX,
    },
  ];

  return (
    <Table items={suggestedMappings} columnDefinitions={columnDefinitions} stickyColumns={{ first: 0, last: 1 }} />
  );
};

import React, { useEffect, useContext, useCallback, useState } from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Button,
  CollectionPreferences,
  CollectionPreferencesProps,
  Header,
  Icon,
  Link as PolarisLink,
  NonCancelableCustomEvent,
  Pagination,
  PaginationProps,
  Select,
  SpaceBetween,
  Table,
} from '@amzn/awsui-components-react';
import { Link } from 'react-router-dom';
import {
  paginationLabels,
  visibleContentPreference,
  pageSizePreference,
  manifestColumnDefinitions,
  ANY_PROCESSING_STATUS_FILTER_OPTION,
  PIPELINE_STATUS_FILTER_OPTIONS,
  getProcessingStatusOptionDefinition,
  MANIFEST_TABLE_COLUMN_KEYS,
} from '../manifest-table-config';
import { reprocessInvoicesForBlueprint } from '../../../client/api-gateway';
import { AppContext } from '../../App';
import { useFetchManifests } from '../hooks/useFetchManifests';
import { useFetchManifestById } from '../hooks/useFetchManifestById';
import { ManifestPropertyFilter } from '../ManifestPropertyFilter';

import 'src/components/manifests/ManifestTable.scss';
import { InvoicesTableHelpContent } from 'src/components/manifests/help/InvoicesTableHelpContent';
import { HelpContext } from 'src/components/help/HelpProvider';
import { EmptyTableState } from 'src/components/common-components/table/EmptyTableState';
import { PAGE_ROUTES } from 'src/components/Routes';
import { IInvoiceMetadata } from 'src/interface/type-def';

const DEFAULT_TABLE_PAGE_SIZE = 50;

const DEFAULT_COLUMN_DISPLAY_PROPERTIES: CollectionPreferencesProps.Preferences['visibleContent'] =
  Object.values(MANIFEST_TABLE_COLUMN_KEYS);

interface InvoiceMetadataTableProps {
  // `createBlueprint` variant used when rendering the table in the Create Blueprint workflow
  variant?: 'standard' | 'createBlueprint';
  onItemSelect?: (selectedItems: Array<IInvoiceMetadata>) => void;
}

export const InvoiceMetadataTable = ({ variant = 'standard', onItemSelect }: InvoiceMetadataTableProps) => {
  const { setNotificationsItems } = useContext(AppContext);
  const { setHelpContent, showHelp } = useContext(HelpContext);

  const { allManifests, manifestsLoading, lastEvaluatedKey, processingStatus, setProcessingStatus, fetchManifests } =
    useFetchManifests(ANY_PROCESSING_STATUS_FILTER_OPTION.ANY_STATUS);
  const {
    invoiceId: filteredInvoiceId,
    setInvoiceId: setFilteredInvoiceId,
    manifest: manifestFilteredById,
    manifestLoading: manifestFilteredByIdLoading,
    fetchManifestById,
  } = useFetchManifestById();

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    pageSize: DEFAULT_TABLE_PAGE_SIZE,
    visibleContent: DEFAULT_COLUMN_DISPLAY_PROPERTIES,
  });
  const [selectedItems, setSelectedItems] = useState<Array<IInvoiceMetadata>>([]);

  // Clean up notifications on unmount
  useEffect(() => () => setNotificationsItems([]), [setNotificationsItems]);

  const getManifestFilteredById = useCallback(() => {
    if (!filteredInvoiceId || !manifestFilteredById) return [];

    return [manifestFilteredById];
  }, [filteredInvoiceId, manifestFilteredById]);

  const getInvoicesToRender = useCallback(() => {
    if (filteredInvoiceId) {
      return getManifestFilteredById();
    }

    return allManifests;
  }, [filteredInvoiceId, getManifestFilteredById, allManifests]);

  const onNextPageClick = (event: NonCancelableCustomEvent<PaginationProps.PageClickDetail>) => {
    if (!event.detail.requestedPageAvailable && lastEvaluatedKey) {
      fetchManifests({ lastEvaluatedKey });
    }
  };

  function callReprocessInvoicesAPI(invoiceIds: string[]) {
    setNotificationsItems([
      { type: 'info', loading: true, content: <>Attempting Reprocess for invoices : {invoiceIds.toString()}</> },
    ]);
    reprocessInvoicesForBlueprint(invoiceIds)
      .then((res) => {
        if (res['failedInvoiceIds'].length === 0) {
          setNotificationsItems([
            {
              type: 'success',
              content: `Submitted invoices: ${invoiceIds.toString()} for reprocessing. Please check their status again in a few moments.`,
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setNotificationsItems([]),
            },
          ]);
        } else {
          setNotificationsItems([
            {
              type: 'error',
              content: `Failed to reprocess ${invoiceIds.toString()}`,
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setNotificationsItems([]),
            },
          ]);
        }
      })
      .catch((e) =>
        setNotificationsItems([
          {
            type: 'error',
            content: `Failed to reprocess ${invoiceIds.toString()}`,
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setNotificationsItems([]),
          },
        ])
      );
  }

  const handleSelectedItemsChange = (items: Array<IInvoiceMetadata>) => {
    setSelectedItems(items);
    if (onItemSelect) {
      onItemSelect(items);
    }
  };

  const handleInvoiceIdChange = (selectedInvoiceId: string) => {
    setFilteredInvoiceId(selectedInvoiceId);
    fetchManifestById(selectedInvoiceId);
  };

  const handleTemplateIdChange = (selectedTemplateId: string) => {
    fetchManifests({ templateId: selectedTemplateId });
  };

  const handleUtilityIdChange = (selectedUtilityId: string) => {
    fetchManifests({ utilityId: selectedUtilityId });
  };

  const handleUtilityTypeChange = (selectedUtilityType: string) => {
    fetchManifests({ utilityType: selectedUtilityType });
  };

  const handleFilterInvoicesByPipelineStatus = (selectedStatus: string) => {
    setProcessingStatus(selectedStatus);
    fetchManifests({ keystoneProcessingStatus: selectedStatus });
  };

  const handleRefresh = () => {
    if (filteredInvoiceId) {
      fetchManifestById(filteredInvoiceId);
    } else {
      fetchManifests({});
    }
  };

  const handleReset = () => {
    setProcessingStatus(ANY_PROCESSING_STATUS_FILTER_OPTION.ANY_STATUS);
    setFilteredInvoiceId('');
    fetchManifests({
      keystoneProcessingStatus: ANY_PROCESSING_STATUS_FILTER_OPTION.ANY_STATUS,
      lastEvaluatedKey: '',
      resetQueryParams: true,
    });
  };

  const handleInfoFollow = (helpContent: JSX.Element) => (e: CustomEvent<any>) => {
    e.preventDefault();

    setHelpContent(helpContent);
    showHelp(true);
  };

  const invoicesToRenders = getInvoicesToRender();

  const { items, actions, collectionProps, paginationProps } = useCollection(invoicesToRenders, {
    filtering: {
      empty: <EmptyTableState title="No Invoices" subtitle="No invoices are available to display" />,
      noMatch: (
        <EmptyTableState
          title="No matches"
          subtitle="We can't find a match"
          action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
        />
      ),
    },
    pagination: {
      pageSize: preferences.pageSize,
    },
    sorting: { defaultState: { sortingColumn: manifestColumnDefinitions[3], isDescending: true } },
    selection: {},
  });

  const tableItemCount = lastEvaluatedKey ? `${invoicesToRenders.length}+` : invoicesToRenders.length;

  return (
    <Table
      {...collectionProps}
      loading={manifestsLoading || manifestFilteredByIdLoading}
      loadingText="Loading invoices"
      selectionType={variant === 'standard' ? 'multi' : 'single'}
      selectedItems={selectedItems}
      onSelectionChange={(e) => {
        const { selectedItems } = e.detail;
        setSelectedItems(selectedItems);
        if (onItemSelect) onItemSelect(selectedItems);
      }}
      header={
        <>
          <Header
            counter={selectedItems?.length ? `(${selectedItems?.length}/${tableItemCount})` : `(${tableItemCount})`}
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button ariaLabel="refresh" onClick={handleRefresh}>
                  <Icon name="refresh" size="normal" variant="normal" />
                </Button>

                {variant === 'standard' ? (
                  <Button
                    disabled={!selectedItems.length}
                    onClick={() => callReprocessInvoicesAPI(selectedItems.map((invoice) => invoice.invoicePdfName))}
                  >
                    {`Reprocess (${selectedItems.length})`}
                  </Button>
                ) : (
                  <Link
                    to={{
                      pathname: `${PAGE_ROUTES.createBlueprintWizard}/${selectedItems[0]?.invoicePdfName}`,
                      state: { invoice: selectedItems[0] },
                    }}
                  >
                    <Button data-testid="createBlueprintButton" disabled={selectedItems.length !== 1} variant="primary">
                      Create Blueprint
                    </Button>
                  </Link>
                )}
              </SpaceBetween>
            }
            info={
              <PolarisLink variant="info" onFollow={handleInfoFollow(InvoicesTableHelpContent)}>
                Info
              </PolarisLink>
            }
            description="View and filter invoice metadata that has been uploaded into Tesseract"
          >
            Invoices
          </Header>
        </>
      }
      stickyHeader
      columnDefinitions={manifestColumnDefinitions}
      items={!filteredInvoiceId ? items : getManifestFilteredById()}
      pagination={
        <Pagination
          {...paginationProps}
          openEnd
          disabled={manifestsLoading || manifestFilteredByIdLoading}
          onNextPageClick={onNextPageClick}
          ariaLabels={paginationLabels}
        />
      }
      filter={
        <div className="input-container">
          <div className="input-filter">
            <ManifestPropertyFilter
              onInvoiceIdChange={handleInvoiceIdChange}
              onUtilityIdChange={handleUtilityIdChange}
              onUtilityTypeChange={handleUtilityTypeChange}
              onReset={handleReset}
            />
          </div>
          <div className="select-filter">
            <Select
              data-testid="processing-status-filter"
              options={PIPELINE_STATUS_FILTER_OPTIONS}
              selectedAriaLabel="Selected"
              selectedOption={getProcessingStatusOptionDefinition(processingStatus)}
              onChange={({ detail }) => handleFilterInvoicesByPipelineStatus(detail.selectedOption.value || '')}
              expandToViewport
            />
          </div>
        </div>
      }
      preferences={
        <CollectionPreferences
          preferences={preferences}
          pageSizePreference={pageSizePreference}
          visibleContentPreference={visibleContentPreference}
          cancelLabel="Cancel"
          confirmLabel="Confirm"
          title="Invoice Preferences"
          onConfirm={({ detail }) => setPreferences(detail)}
        />
      }
    />
  );
};

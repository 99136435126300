import { SpaceBetween, StatusIndicator, Button } from '@amzn/awsui-components-react';
import React from 'react';
import { Usage } from 'src/interface/type-def';
import { FieldType } from './map-to-schema';

interface UsagesSummaryProps {
  usages: Usage[];
  makeActive: Function;
  removeItemFromList: Function;
  meterIndex: number;
}

export const UsagesSummary = ({ usages, makeActive, removeItemFromList, meterIndex }: UsagesSummaryProps) => {
  const usagesSummary = usages.map((usage, idx) => (
    <SpaceBetween size="s" direction="horizontal" key={idx}>
      <span>{usage.usageAmount ? <StatusIndicator type="success" /> : <StatusIndicator type="error" />}</span>
      <Button iconName="edit" variant="inline-icon" onClick={() => makeActive(idx, FieldType.METERS_USAGES)} />
      <Button
        iconName="close"
        variant="inline-icon"
        onClick={() => removeItemFromList(idx, FieldType.METERS_USAGES, meterIndex)}
      />
      <span>Usage #{idx}</span>
    </SpaceBetween>
  ));
  return <div>{usagesSummary}</div>;
};

import React from 'react';
import { ManifestDocument } from 'src/interface/type-def';
import { Box, ColumnLayout, SpaceBetween, TextContent } from '@amzn/awsui-components-react';
import { ValueWithLabel } from 'src/components/common-components/keyValuePair/ValueWithLabel';
import { generateTemplateId } from '../../create/createUtils';
import { TemplateType } from '../../types';
import { TemplateIdSummary } from './TemplateIdSummary';

export const TEMPLATE_TYPE_CONTENT: {
  [key in TemplateType]: { header: string; description: (manifestDocument: ManifestDocument) => JSX.Element | string };
} = {
  utilityTemplate: {
    header: 'Utility Template',
    description: (manifestDocument: ManifestDocument) => (
      <>
        This utility template will be applied to invoices for this vendor, <b>{manifestDocument.vendorName}</b>.
      </>
    ),
  },
  siteTemplate: {
    header: 'Site Template',
    description: (manifestDocument: ManifestDocument) => (
      <>
        This site template will be applied to invoices for this vendor, <b>{manifestDocument.vendorName}</b>, with
        location code <b>{manifestDocument.locationCode}</b>.
      </>
    ),
  },
  accountTemplate: {
    header: 'Account Template',
    description: (manifestDocument: ManifestDocument) => (
      <>
        This account template will be applied to invoices for this vendor, <b>{manifestDocument.vendorName}</b>, with
        location code <b>{manifestDocument.locationCode}</b> and account number <b>{manifestDocument.accountNumber}</b>.
      </>
    ),
  },
  advancedTemplate: {
    header: 'Advanced Template',
    description: (manifestDocument: ManifestDocument) =>
      `(Not recommended) Advanced templates override any previously-created templates.`,
  },
};

interface TemplateTypeMetadataProps {
  templateType: TemplateType;
  manifestDocument: ManifestDocument;
}

export const TemplateTypeMetadata = ({ templateType, manifestDocument }: TemplateTypeMetadataProps) => {
  const templateTypeContent = TEMPLATE_TYPE_CONTENT[templateType];

  return (
    <TextContent>
      <SpaceBetween direction="vertical" size="xs">
        <Box variant="p" color="text-body-secondary">
          {templateTypeContent.description(manifestDocument)}
        </Box>
        <TemplateIdSummary templateType={templateType} manifestDocument={manifestDocument} />
      </SpaceBetween>
    </TextContent>
  );
};

import { Cell, Table } from 'amazon-textract-response-parser/dist/cjs';
import * as React from 'react';
import { PageTables } from 'src/helpers/textract';
import { CreateTemplateContext } from '../templates/create/index';
import { ApplyTabularDataCell } from '../templates/create/tabularDataMapping/ApplyTabularDataCell';

import './ListTables.scss';
import { TABULAR_DATA_CELL_TYPES } from '../templates/create/tabularDataMapping/ApplyTabularDataCellButton';

interface TableViewProps {
  table: Table;
  tableNumber: number;
  pageNumber: number;
  onApplyTabularDataCell?: (
    cellType: TABULAR_DATA_CELL_TYPES,
    selectedCell: Cell,
    pageNumber: number,
    tableNumber: number
  ) => void;
}

const TableView: React.FC<TableViewProps> = ({ table, tableNumber, pageNumber, onApplyTabularDataCell }) => {
  const context = React.useContext(CreateTemplateContext);
  context.keyValue = false;
  context.tableNumber = tableNumber;

  const rows = table.listRows().map((row, rowIdx) => {
    const cells = row.listCells().map((cell, cellIdx) => (
      <td key={`${tableNumber}-${rowIdx}-${cellIdx}`} className="tabular-data-cell">
        <ApplyTabularDataCell
          cell={cell}
          pageNumber={pageNumber}
          tableNumber={tableNumber}
          onApplyCell={onApplyTabularDataCell}
        >
          {cell.text}
        </ApplyTabularDataCell>
      </td>
    ));

    return <tr key={`${rowIdx}`}>{cells}</tr>;
  });

  return (
    <div key={tableNumber}>
      <h5>Table number: {tableNumber}</h5>
      <table style={{ border: '1px solid black' }}>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
};

interface ListTablesProps {
  pageTables: PageTables;
  onApplyTabularDataCell?: (
    cellType: TABULAR_DATA_CELL_TYPES,
    selectedCell: Cell,
    pageNumber: number,
    tableNumber: number
  ) => void;
}

export const ListTables: React.FC<ListTablesProps> = ({ pageTables, onApplyTabularDataCell }) => {
  const tables = pageTables.tables.map((kv, tableNumber) => (
    // Textract indices are 1-based; ensure the table number matches what Textract expects
    <TableView
      key={tableNumber}
      pageNumber={pageTables.pageNumber}
      table={kv}
      tableNumber={tableNumber + 1}
      onApplyTabularDataCell={onApplyTabularDataCell}
    />
  ));
  return (
    <div>
      <h4>Page number: {pageTables.pageNumber}</h4>
      {tables}
    </div>
  );
};

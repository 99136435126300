import { Button, Container, Link } from '@amzn/awsui-components-react';
import * as React from 'react';

export type PageErrorProps = {
  onRetry?: () => void;
  header?: string;
};
export function PageError(props: PageErrorProps) {
  return (
    <Container header={<h3>{props.header || `Oops Something happened`}</h3>}>
      <p>
        You can retry loading the page or <Link href="#/">return to home page</Link>{' '}
      </p>
      <Button variant="primary" onClick={props.onRetry}>
        Try again
      </Button>
    </Container>
  );
}

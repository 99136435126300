import {
  Button,
  Box,
  FormField,
  Grid,
  Select,
  SelectProps,
  SpaceBetween,
  ExpandableSection,
  Container,
  Header,
  Alert,
} from '@amzn/awsui-components-react';
import cloneDeep from 'lodash/cloneDeep';
import React, { useCallback, useEffect, useState } from 'react';
import { IInvoiceMetadata, ManifestDocument, Source, TemplateValue, ValueRefTypes } from 'src/interface/type-def';
import { TextractResultsState } from 'src/components/textract/hooks/useFetchInvoiceTextractResults';
import { TextractKeyValue } from 'src/helpers/textract';
import { TabularDataCellField } from '../../create/tabularDataMapping/TabularDataCellField';
import {
  getHeadersFromValueRef,
  getKeyFromValueRef,
  getTableHeadersFromValueRef,
  getValueFromValueRef,
  isHeaderBasedTableValueRef,
  isKeyValueRef,
  isManifestValueRef,
} from 'src/utils/valueRefUtils';
import { TabularDataHeadersField } from '../../create/tabularDataMapping/TabularDataHeadersField';
import { TABULAR_DATA_CELL_TYPES } from '../../create/tabularDataMapping/ApplyTabularDataCellButton';
import { Cell } from 'amazon-textract-response-parser/dist/cjs';
import { usePropertyEditor } from '../hooks/usePropertyEditor';
import { ValueWithLabel } from 'src/components/common-components/keyValuePair/ValueWithLabel';
import { TemplatePropertySuggestedValues } from '../../templateSuggestions/templateSuggestionsUtils';
import { SuggestedPropertyMappings } from './SuggestedPropertyMappings';
import { StandardPropertyValueSelector } from './StandardPropertyValueSelector';
import { SubtermEditorModal } from '../subterm/SubtermEditorModal';

export enum STANDARD_PROPERTY_EDITOR_SOURCE {
  InvoiceKeyValue = 'Invoice (key-value)',
  InvoiceTable = 'Invoice (table)',
  Manifest = 'Manifest',
  Static = 'Static value',
}

const SOURCE_DROPDOWN_OPTIONS: Array<SelectProps.Option> = [
  { value: STANDARD_PROPERTY_EDITOR_SOURCE.InvoiceKeyValue },
  { value: STANDARD_PROPERTY_EDITOR_SOURCE.InvoiceTable },
  { value: STANDARD_PROPERTY_EDITOR_SOURCE.Manifest },
];

const getSourceDropdownOption = (templateValue: TemplateValue | null) => {
  if (!templateValue) return { value: STANDARD_PROPERTY_EDITOR_SOURCE.InvoiceKeyValue };
  const { source } = templateValue;

  switch (source) {
    case Source.INVOICE:
      return templateValue.valueRef?.type === ValueRefTypes.HeaderBasedTableValue
        ? { value: STANDARD_PROPERTY_EDITOR_SOURCE.InvoiceTable }
        : { value: STANDARD_PROPERTY_EDITOR_SOURCE.InvoiceKeyValue };
    case Source.MANIFEST:
      return { value: STANDARD_PROPERTY_EDITOR_SOURCE.Manifest };
    case Source.STATIC || Source.NULL:
      return { value: STANDARD_PROPERTY_EDITOR_SOURCE.Static };
    default:
      return { value: STANDARD_PROPERTY_EDITOR_SOURCE.InvoiceKeyValue };
  }
};

interface StandardPropertyEditorProps {
  propertyName: string; // The name of the selected template property
  templateValue: TemplateValue; // The selected property's TemplateValue, sent in the body of the upsertTemplate request
  setTemplateValue: (templateValue: TemplateValue) => void; // Update the selected property's TemplateValue
  textractResults: TextractResultsState | null; // Textract results for the invoice from which the template is being created
  suggestedTemplateValues: TemplatePropertySuggestedValues | null; // Key: property name in template map; value: list of `TemplateValue` objects for each suggested mapping for that property
  invoice: IInvoiceMetadata | null; // Invoice from which the template is being created
  onSaveProperty: (propertyName: string, templateValue: TemplateValue, templateValueSubitemIdx?: number) => void; // Invoked when saving an Account-level property
  templateValueSubitemIdx?: number; // If this property is stores as an array of TemplateValues, represents the index of the TemplateValue being edited
  originalTemplateValue?: TemplateValue; // If updating the field, represents the original configuration
}

export const StandardPropertyEditor = ({
  propertyName,
  templateValue,
  setTemplateValue,
  textractResults,
  suggestedTemplateValues,
  invoice,
  onSaveProperty,
  templateValueSubitemIdx,
  originalTemplateValue,
}: StandardPropertyEditorProps) => {
  const { getKeyValuePairTemplateValue, getTabularDataTemplateValue, getManifestColumnTemplateValue } =
    usePropertyEditor();

  const [selectedSource, setSelectedSource] = useState<SelectProps.Option | null>(null);
  const [isSubtermModalVisible, setIsSubtermModalVisible] = useState<boolean>(false);
  const [isSaveConfirmationVisible, setIsSaveConfirmationVisible] = useState<boolean>(false);

  // Ensure the Source dropdown matches the selected property's templateValue
  useEffect(() => {
    setSelectedSource(getSourceDropdownOption(templateValue));
  }, [templateValue]);

  // If the property has been modified at all, remove the "Save" confirmation
  useEffect(() => {
    setIsSaveConfirmationVisible(false);
  }, [templateValue, selectedSource]);

  const onApplyKeyValuePair = useCallback(
    (keyValue: TextractKeyValue) => {
      const updatedTemplateValue = getKeyValuePairTemplateValue(templateValue, keyValue);
      if (updatedTemplateValue) {
        setTemplateValue(updatedTemplateValue);
      }
    },
    [templateValue, getKeyValuePairTemplateValue]
  );

  const onApplyTabularDataCell = useCallback(
    (cellType: TABULAR_DATA_CELL_TYPES, selectedCell: Cell, pageNumber: number, tableNumber: number) => {
      const updatedTemplateValue = getTabularDataTemplateValue(
        templateValue,
        cellType,
        selectedCell,
        pageNumber,
        tableNumber
      );
      if (updatedTemplateValue) {
        setTemplateValue(updatedTemplateValue);
      }
    },
    [templateValue]
  );

  const onRemoveCellHeader = useCallback(
    (headerIdx: number) => {
      const updatedTemplateValue = cloneDeep(templateValue);
      if (!updatedTemplateValue.valueRef || !isHeaderBasedTableValueRef(updatedTemplateValue.valueRef)) return;

      const updatedHeaderRefs = [...(updatedTemplateValue.valueRef?.cellRef?.headerRefs || [])];

      if (headerIdx >= updatedHeaderRefs.length) {
        return;
      }

      updatedHeaderRefs.splice(headerIdx, 1);
      updatedTemplateValue.valueRef.cellRef.headerRefs = updatedHeaderRefs;

      setTemplateValue(updatedTemplateValue);
    },
    [templateValue]
  );

  const onRemoveTableHeader = useCallback(
    (headerIdx: number) => {
      const updatedTemplateValue = cloneDeep(templateValue);
      if (!updatedTemplateValue.valueRef || !isHeaderBasedTableValueRef(updatedTemplateValue.valueRef)) return;

      const updatedHeaderRefs = [...(updatedTemplateValue.valueRef?.tableRef?.headers || [])];

      if (headerIdx >= updatedHeaderRefs.length) {
        return;
      }

      updatedHeaderRefs.splice(headerIdx, 1);
      updatedTemplateValue.valueRef.tableRef.headers = updatedHeaderRefs;

      setTemplateValue(updatedTemplateValue);
    },
    [templateValue]
  );

  const onApplyManifestColumn = useCallback(
    (columnName: keyof ManifestDocument) => {
      const updatedTemplateValue = getManifestColumnTemplateValue(templateValue, columnName);
      if (updatedTemplateValue) {
        setTemplateValue(updatedTemplateValue);
      }
    },
    [invoice, templateValue]
  );

  const onApplySubterm = useCallback(
    (separator: string, startIdx: number, endIdx: number) => {
      const updatedTemplateValue = cloneDeep(templateValue);
      if (updatedTemplateValue.valueRef) {
        updatedTemplateValue.valueRef.subterm = {
          separator,
          startTerm: startIdx, // startTerm is inclusive
          endTerm: endIdx + 1, // endTerm is not inclusive
        };
        setTemplateValue(updatedTemplateValue);
      }

      setIsSubtermModalVisible(false);
    },
    [templateValue, setIsSubtermModalVisible]
  );

  const onDeleteSubterm = useCallback(() => {
    const updatedTemplateValue = cloneDeep(templateValue);
    if (!updatedTemplateValue.valueRef?.subterm) return;

    updatedTemplateValue.valueRef.subterm = undefined;
    setTemplateValue(updatedTemplateValue);
  }, [templateValue]);

  const onApplySuggestedTemplateValue = useCallback((suggestedTemplateValue: TemplateValue) => {
    setTemplateValue(suggestedTemplateValue);
  }, []);

  return (
    <Box>
      <Grid gridDefinition={[{ colspan: 4 }, { colspan: 8 }]}>
        <Container header={<Header variant="h2">Edit property</Header>}>
          <SpaceBetween direction="vertical" size="s">
            <SuggestedPropertyMappings
              propertyName={propertyName}
              suggestedTemplateValues={suggestedTemplateValues}
              textractResults={textractResults}
              invoice={invoice}
              onApplySuggestedMapping={onApplySuggestedTemplateValue}
            />

            <ExpandableSection
              headerText="Source - Required"
              headerDescription="Source of data Tesseract will use for this property"
              defaultExpanded
            >
              <FormField label="Source">
                <Select
                  options={SOURCE_DROPDOWN_OPTIONS}
                  selectedOption={selectedSource}
                  onChange={(e) => setSelectedSource(e.detail.selectedOption)}
                />
              </FormField>
            </ExpandableSection>

            {selectedSource?.value === STANDARD_PROPERTY_EDITOR_SOURCE.InvoiceKeyValue && (
              <ExpandableSection
                headerText="Key - Required"
                headerDescription="The key from which this property's value should come from"
                defaultExpanded
              >
                <SpaceBetween direction="vertical" size="s">
                  <ValueWithLabel
                    label="Selected key"
                    labelDataTestId="keyValue-keyLabel"
                    valueDataTestId="keyValue-keyContent"
                  >
                    <Box>{getKeyFromValueRef(templateValue) || <i>Select a key from the Key-Value pairs table</i>}</Box>
                  </ValueWithLabel>

                  <ValueWithLabel
                    label="Value"
                    labelDataTestId="keyValue-valueLabel"
                    valueDataTestId="keyValue-valueContent"
                  >
                    <Box>{getValueFromValueRef(templateValue, textractResults) || <i>-</i>}</Box>
                  </ValueWithLabel>
                </SpaceBetween>
              </ExpandableSection>
            )}

            {selectedSource?.value === STANDARD_PROPERTY_EDITOR_SOURCE.InvoiceTable && (
              <>
                <ExpandableSection
                  headerText="Configure cell - Required"
                  headerDescription="Configuration to help Tesseract identify the correct cell in the table"
                  defaultExpanded
                >
                  <SpaceBetween direction="vertical" size="s">
                    <TabularDataCellField cellText={getValueFromValueRef(templateValue, textractResults)} />
                    <TabularDataHeadersField
                      headers={getHeadersFromValueRef(templateValue)}
                      label="Cell header(s)"
                      emptyMessage={
                        getValueFromValueRef(templateValue, textractResults)
                          ? 'No cell headers selected'
                          : 'Select a cell before adding a cell header'
                      }
                      onRemoveHeader={onRemoveCellHeader}
                    />
                  </SpaceBetween>
                </ExpandableSection>

                <ExpandableSection
                  headerText="Identify table - Required"
                  headerDescription="Configuration to help Tesseract identify the correct table the cell is in"
                  defaultExpanded
                >
                  <TabularDataHeadersField
                    headers={getTableHeadersFromValueRef(templateValue)}
                    label="Table header(s)"
                    emptyMessage={
                      getValueFromValueRef(templateValue, textractResults)
                        ? 'No table headers selected'
                        : 'Select a cell before adding a table header'
                    }
                    onRemoveHeader={onRemoveTableHeader}
                    isCellHeader={false}
                  />
                </ExpandableSection>
              </>
            )}

            {selectedSource?.value === STANDARD_PROPERTY_EDITOR_SOURCE.Manifest && (
              <ExpandableSection
                headerText="Column name - Required"
                headerDescription="The manifest file column from which this property's value should come from"
                defaultExpanded
              >
                <SpaceBetween direction="vertical" size="s">
                  <ValueWithLabel
                    label="Selected column"
                    labelDataTestId="manifest-columnNameLabel"
                    valueDataTestId="manifest-columnNameContent"
                  >
                    <Box>{getKeyFromValueRef(templateValue) || <i>Select a column from the Manifest table</i>}</Box>
                  </ValueWithLabel>

                  <ValueWithLabel
                    label="Value"
                    labelDataTestId="manifest-columnValueLabel"
                    valueDataTestId="manifest-columnValueContent"
                  >
                    <Box>{getValueFromValueRef(templateValue, null, invoice?.manifestDocument) || <i>-</i>}</Box>
                  </ValueWithLabel>
                </SpaceBetween>
              </ExpandableSection>
            )}

            {isSubtermModalVisible && (
              <SubtermEditorModal
                propertyName={propertyName}
                isModalVisible={isSubtermModalVisible}
                value={getValueFromValueRef(templateValue, textractResults, invoice?.manifestDocument)}
                existingSubterm={templateValue.valueRef?.subterm}
                onSave={onApplySubterm}
                onDismiss={() => setIsSubtermModalVisible(false)}
              />
            )}

            <ExpandableSection
              headerText="Subterm - Optional"
              headerDescription="Configure a separator if you only need part of the extracted value"
            >
              {templateValue.valueRef?.subterm ? (
                <SpaceBetween direction="horizontal" size="s">
                  <Box>{JSON.stringify(templateValue.valueRef?.subterm)}</Box>
                  <Button onClick={() => setIsSubtermModalVisible(true)}>Edit</Button>
                  <Button onClick={onDeleteSubterm}>Delete</Button>
                </SpaceBetween>
              ) : (
                <SpaceBetween direction="horizontal" size="s">
                  <Button iconName="add-plus" iconAlign="left" onClick={() => setIsSubtermModalVisible(true)}>
                    Add subterm
                  </Button>
                </SpaceBetween>
              )}
            </ExpandableSection>

            {isSaveConfirmationVisible && <Alert type="success">Your changes have been saved</Alert>}

            <SpaceBetween direction="horizontal" size="s">
              <Button
                variant="primary"
                onClick={() => {
                  setIsSaveConfirmationVisible(true);
                  onSaveProperty(propertyName, templateValue, templateValueSubitemIdx);
                }}
              >
                Save changes
              </Button>
              <Button iconName="redo">Reset</Button>
            </SpaceBetween>
          </SpaceBetween>
        </Container>

        <Box>
          {selectedSource?.value && (
            <StandardPropertyValueSelector
              source={selectedSource?.value}
              textractResults={textractResults}
              manifestDocument={invoice?.manifestDocument || null}
              onApplyKeyValuePair={onApplyKeyValuePair}
              onApplyTabularDataCell={onApplyTabularDataCell}
              onApplyManifestColumn={onApplyManifestColumn}
              selectedKey={
                templateValue.valueRef && isKeyValueRef(templateValue.valueRef)
                  ? {
                      keyName: templateValue.valueRef.ref.key,
                      pageNumber: templateValue.valueRef.page || 1,
                    }
                  : undefined
              }
              selectedColumnName={
                templateValue.valueRef && isManifestValueRef(templateValue.valueRef)
                  ? templateValue.valueRef.ref.value
                  : undefined
              }
            />
          )}
        </Box>
      </Grid>
    </Box>
  );
};

const awsConfiguration = {
  aws_project_region: 'us-west-2',
  aws_cognito_identity_pool_id: 'us-west-2:90745689-2fc5-4687-8d79-5d4ba3c1e1d0',
  aws_cognito_region: 'us-west-2',
  aws_user_pools_id: 'us-west-2_iv8zLQSvE',
  aws_user_pools_web_client_id: '6j8tsqe6n8djk6gcm80722k9pj',
  oauth: {
    domain: 'taproot-tesseract-website-beta.auth.us-west-2.amazoncognito.com',
    scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
    redirectSignIn: 'http://localhost:3000',
    redirectSignOut: 'http://localhost:3000',
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: [],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_user_files_s3_bucket: 'tesseract-invoice-output-beta-us-west-2',
  aws_user_files_s3_bucket_region: 'us-west-2',
  aws_cloud_logic_custom: [
    {
      name: 'TesseractFrontendAPI',
      endpoint: 'https://6w880jpgf0.execute-api.us-west-2.amazonaws.com/beta',
      region: 'us-west-2',
    },
  ],
};

export default awsConfiguration;

import { useCallback, useState } from 'react';
import { reprocessInvoicesForTemplate } from 'src/client/api-gateway';

export const useReprocessInvoicesForTemplate = () => {
  const [isReprocessRequestLoading, setIsReprocessRequestLoading] = useState<boolean>(false);
  const [reprocessRequestError, setReprocessRequestError] = useState<string>('');
  // Invoice IDs for which the reprocess request failed to successfully send for reprocessing
  const [failedInvoiceIds, setFailedInvoiceIds] = useState<Array<string>>([]);

  const reprocessAllInvoices = useCallback(async (templateId: string) => {
    setIsReprocessRequestLoading(true);
    setFailedInvoiceIds([]);

    try {
      const reprocessInvoicesResponse = await reprocessInvoicesForTemplate(templateId);
      const { failedInvoiceIds } = reprocessInvoicesResponse;
      setFailedInvoiceIds(failedInvoiceIds);
      return failedInvoiceIds;
    } catch (e) {
      const errorMsg = e as string;
      setReprocessRequestError(errorMsg);
      throw new Error(errorMsg);
    } finally {
      setIsReprocessRequestLoading(false);
    }
  }, []);

  return {
    isReprocessRequestLoading,
    reprocessAllInvoices,
    failedInvoiceIds,
    reprocessRequestError,
  };
};

import { ENABLE_FEATURE_QUERY_PARAM, FEATURE_NAME, isFeatureFlagEnabled } from './featureFlags';
import { useQuery } from 'src/hooks/useQuery';

export interface UseFeatureFlagsState {
  isFeatureEnabled: (featureName: FEATURE_NAME) => boolean;
}

export const useFeatureFlags = (): UseFeatureFlagsState => {
  const query = useQuery();

  const isFeatureEnabled = (featureName: FEATURE_NAME) =>
    isFeatureFlagEnabled(featureName) || query.get(ENABLE_FEATURE_QUERY_PARAM) === featureName.toString();

  return { isFeatureEnabled };
};

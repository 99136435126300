import * as React from 'react';
import { TopNavigation } from '@amzn/awsui-components-react';

export const TopNavigationBar = () => (
  <TopNavigation
    data-layout-id="mainNavigation"
    identity={{
      href: '/',
      title: 'Tesseract',
    }}
    utilities={[
      {
        type: 'button',
        text: 'User Guide',
        href: 'https://w.amazon.com/bin/view/Sustainability/NetZeroCarbon/NetZeroTech/NZT-Footprint/Tesseract/UserGuide/',
        external: true,
      },
    ]}
    i18nStrings={{
      searchIconAriaLabel: 'Search',
      searchDismissIconAriaLabel: 'Close search',
      overflowMenuTriggerText: 'More',
    }}
  />
);

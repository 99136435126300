import { DatePicker, FormField } from '@amzn/awsui-components-react';
import React, { useMemo } from 'react';
import { getDatePickerValueFromTimestamp } from 'src/utils/datePickerUtils';

interface BlueprintValidFromDateSelectProps {
  validFromTimestamp?: number; // Epoch seconds
  onDateChange: (value: string) => void;
}

export const BlueprintValidFromDateSelect = ({
  validFromTimestamp,
  onDateChange,
}: BlueprintValidFromDateSelectProps) => {
  const datePickerValue = useMemo(() => getDatePickerValueFromTimestamp(validFromTimestamp), [validFromTimestamp]);

  return (
    <FormField
      label="Valid from date"
      description="This blueprint will be applied to invoices created on or after this date"
    >
      <DatePicker locale="en-US" onChange={(e) => onDateChange(e.detail.value)} value={datePickerValue} />
    </FormField>
  );
};

import React, { createContext, useMemo, useState } from 'react';

interface HelpContextValue {
  helpContent: JSX.Element | null;
  setHelpContent: React.Dispatch<React.SetStateAction<JSX.Element | null>>;
  isSuppressed: boolean;
  isOpen: boolean;
  showHelp: (isVisible: boolean) => void;
}

const defaultHelpContextValue: HelpContextValue = {
  helpContent: null,
  setHelpContent: () => {},
  isSuppressed: true,
  isOpen: false,
  showHelp: () => {},
};

export const HelpContext = createContext<HelpContextValue>(defaultHelpContextValue);

export const HelpProvider: React.FC = ({ children }) => {
  const [helpContent, setHelpContent] = useState<JSX.Element | null>(null);
  const [isSuppressed, setIsSuppressed] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const showHelp = useMemo(
    () => (isVisible: boolean) => {
      setIsSuppressed(!isVisible);
      setIsOpen(isVisible);
    },
    [setIsSuppressed, setIsOpen]
  );

  const value = useMemo(
    () => ({
      helpContent,
      setHelpContent,
      isSuppressed,
      isOpen,
      showHelp,
    }),
    [helpContent, setHelpContent, isSuppressed, isOpen, showHelp]
  );

  return <HelpContext.Provider value={value}>{children}</HelpContext.Provider>;
};

import { SpaceBetween, Button } from '@amzn/awsui-components-react';
import React from 'react';
import { Meter } from 'src/interface/type-def';
import { ChargesSummary } from './ChargesSummary';
import { ChargeSummary } from './ChargeSummary';
import { FieldType } from './map-to-schema';
import { TableOfProperties } from './TableOfProperties';
import { UsagesSummary } from './UsagesSummary';
import { UsageSummary } from './UsageSummary';
import { TemplatePropertySuggestedValues } from '../../templateSuggestions/templateSuggestionsUtils';

interface MeterSummaryProps {
  meters: Meter[];
  meterIndex: number;
  editTemplateValue: Function;
  excludeTemplateValue: Function;
  addUsageToMeter: Function;
  addChargeToMeter: Function;
  indexOfAddedUsage: number;
  indexOfAddedCharge: number;
  removeItemFromList: Function;
  makeActive: Function;
  suggestedTemplateValues: TemplatePropertySuggestedValues | null;
}

export const MeterSummary = ({
  meters,
  meterIndex,
  editTemplateValue,
  excludeTemplateValue,
  addUsageToMeter,
  addChargeToMeter,
  indexOfAddedUsage,
  indexOfAddedCharge,
  removeItemFromList,
  makeActive,
  suggestedTemplateValues,
}: MeterSummaryProps) => (
  <div>
    <TableOfProperties
      fieldType={FieldType.METERS}
      fieldIndex={meterIndex}
      obj={meters[meterIndex]}
      editTemplateValue={editTemplateValue}
      excludeTemplateValue={excludeTemplateValue}
      suggestedTemplateValues={suggestedTemplateValues}
    />
    <SpaceBetween size="xs">
      <p>Usages: </p>
      <UsagesSummary
        usages={meters[meterIndex]?.usages || []}
        makeActive={makeActive}
        removeItemFromList={removeItemFromList}
        meterIndex={meterIndex}
      />
      <Button onClick={() => addUsageToMeter(meterIndex)}>Add new usage to meter</Button>
      {meters[meterIndex]?.usages.length !== 0 && (
        <>
          <p>Current selected usage: Usage #{indexOfAddedUsage}</p>
          <UsageSummary
            usages={meters[meterIndex]?.usages || []}
            index={indexOfAddedUsage}
            editTemplateValue={editTemplateValue}
            excludeTemplateValue={excludeTemplateValue}
            meterIndex={meterIndex}
            suggestedTemplateValues={suggestedTemplateValues}
          />
        </>
      )}
      <p>Charges: </p>
      <ChargesSummary
        charges={meters[meterIndex]?.charges || []}
        makeActive={makeActive}
        removeItemFromList={removeItemFromList}
        meterIndex={meterIndex}
        suggestedTemplateValues={suggestedTemplateValues}
      />
      <Button onClick={() => addChargeToMeter(meterIndex)}>Add new charge to meter</Button>
      {meters[meterIndex]?.charges.length !== 0 && (
        <>
          <p>Current selected charge: Charge #{indexOfAddedCharge}</p>
          <ChargeSummary
            charges={meters[meterIndex]?.charges || []}
            index={indexOfAddedCharge}
            editTemplateValue={editTemplateValue}
            excludeTemplateValue={excludeTemplateValue}
            fieldType={FieldType.METERS_CHARGES}
            meterIndex={meterIndex}
            suggestedTemplateValues={suggestedTemplateValues}
          />
        </>
      )}
    </SpaceBetween>
  </div>
);

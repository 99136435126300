import { TokenGroup, TokenGroupProps } from '@amzn/awsui-components-react';
import React, { useCallback } from 'react';
import { ValueWithLabel } from 'src/components/common-components/keyValuePair/ValueWithLabel';

export interface TabularDataHeadersFieldProps {
  headers: Array<string>;
  label: string;
  emptyMessage: string;
  onRemoveHeader: (headerIdx: number) => void;
  isCellHeader?: boolean;
}

export const TabularDataHeadersField = ({
  headers,
  label,
  emptyMessage,
  onRemoveHeader,
  isCellHeader = true,
}: TabularDataHeadersFieldProps) => {
  const getHeaderTokens = useCallback(
    (): Array<TokenGroupProps.Item> =>
      headers.map((text) => ({
        label: text,
        dismissLabel: `Remove ${text}`,
      })),
    [headers]
  );

  return (
    <ValueWithLabel
      label={label}
      labelDataTestId={`tabular-data-${isCellHeader ? 'cell' : 'table'}-header-label`}
      valueDataTestId={`tabular-data-${isCellHeader ? 'cell' : 'table'}-header-value`}
    >
      {headers.length ? (
        <TokenGroup
          items={getHeaderTokens()}
          alignment="vertical"
          onDismiss={({ detail: { itemIndex } }) => {
            onRemoveHeader(itemIndex);
          }}
        />
      ) : (
        <i>{emptyMessage}</i>
      )}
    </ValueWithLabel>
  );
};

import React, { useMemo } from 'react';
import startCase from 'lodash/startCase';
import { getTemplateTypeFromId } from '../create/createUtils';
import { Alert } from '@amzn/awsui-components-react';
import { Template } from 'src/interface/type-def';
import { TemplateType } from '../types';
import { WaterTemplate } from '../waterTemplates/types/WaterTemplateConfiguration';

interface TemplateTypeAlertProps {
  template: Template | WaterTemplate;
}

export const TemplateTypeAlert = ({ template }: TemplateTypeAlertProps) => {
  const templateType = useMemo(() => getTemplateTypeFromId(template.templateId || ''), [template]);

  const alertContent = useMemo(() => {
    switch (templateType) {
      case TemplateType.UTILITY: {
        return `When processing invoices, this template is applied to all invoices with vendor number ${template.utilityId}.`;
      }
      case TemplateType.SITE: {
        return `When processing invoices, this template is applied to all invoices with vendor number ${template.utilityId} with location code ${template.siteId}.`;
      }
      case TemplateType.ACCOUNT: {
        return `When processing invoices, this template is applied to all invoices with vendor number ${template.utilityId}, with location code ${template.siteId} and account number ${template.accountId}.`;
      }
      default: {
        return `Unable to identify this template's type.`;
      }
    }
  }, [templateType, template.accountId, template.siteId, template.utilityId]);

  return (
    <Alert
      type="info"
      header={`You are viewing a${templateType === TemplateType.ACCOUNT ? 'n' : ''} ${startCase(templateType)}`}
    >
      {alertContent}
    </Alert>
  );
};

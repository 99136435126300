import * as React from 'react';
import { PageError } from './page-error';
import { ContainerError } from './container-error';

export type ErrorBoundaryProps = { isPage?: boolean };
export type ErrorBoundaryState = { hasError: boolean };
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  render() {
    const { isPage = false } = this.props;
    const resetErrorBoundary = () => {
      this.setState({ hasError: false });
    };

    if (this.state.hasError) {
      return isPage ? <PageError onRetry={resetErrorBoundary} /> : <ContainerError onRetry={resetErrorBoundary} />;
    }

    return this.props.children;
  }
}

import { Template } from 'src/interface/type-def';

export const shouldExtendParentTemplate = (childTemplate: Template, parentTemplate: Template) => {
  if (!parentTemplate.validFrom || !childTemplate.validFrom) return false;

  return parentTemplate.validFrom <= childTemplate.validFrom;
};

export const getTemplateToExtendForSiteTemplate = (
  currentTemplate: Template,
  utilityTemplate: Template | null,
  blankTemplate: Template
) => {
  if (!utilityTemplate || !utilityTemplate.validFrom || !currentTemplate.validFrom) {
    return blankTemplate;
  }

  return shouldExtendParentTemplate(currentTemplate, utilityTemplate) ? utilityTemplate : blankTemplate;
};

export const getTemplateToExtendForAccountTemplate = (
  currentTemplate: Template,
  utilityTemplate: Template | null,
  siteTemplate: Template | null,
  blankTemplate: Template
) => {
  if (!currentTemplate.validFrom) {
    return blankTemplate;
  }

  // If the site template exists, and it should be extended, return the site template. Otherwise, perform
  // the same check on the utility template.
  if (siteTemplate && siteTemplate.validFrom && shouldExtendParentTemplate(currentTemplate, siteTemplate)) {
    return siteTemplate;
  }
  if (utilityTemplate && utilityTemplate.validFrom && shouldExtendParentTemplate(currentTemplate, utilityTemplate)) {
    return utilityTemplate;
  }

  return blankTemplate;
};

export function numericDate(date?: number | string, showTime: boolean = false) {
  if (!date) {
    return '-';
  }

  let dateOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };
  if (showTime) {
    dateOptions = {
      ...dateOptions,
      hour: 'numeric',
      minute: 'numeric',
    };
  }

  return new Date(date).toLocaleDateString(undefined, dateOptions);
}

export const formDateToISO = (date?: number | string) =>
  !date
    ? ''
    : new Date(Number.isNaN(+date) ? date : convertSecondsToMilliseconds(+date)).toLocaleDateString('en-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });

export const formDateToEpochSeconds = (date?: string): string => {
  if (!date) return '';
  const parsedDate = convertMillisecondsToSeconds(Date.parse(date));
  return Number.isNaN(parsedDate) ? '' : parsedDate.toString();
};

export const convertSecondsToMilliseconds = (timeInSeconds: number) => timeInSeconds * 1000;
export const convertMillisecondsToSeconds = (timeInSeconds: number) => timeInSeconds / 1000;

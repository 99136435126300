import {
  Form,
  FormField,
  FormFieldProps,
  Input,
  InputProps,
  NonCancelableCustomEvent,
} from '@amzn/awsui-components-react';
import React, { useState, useMemo } from 'react';

interface InvoiceOverrideInputProps
  extends Omit<InputProps, 'onChange' | 'onBlur'>,
    Pick<FormFieldProps, 'constraintText' | 'description' | 'errorText' | 'info' | 'label'> {
  fieldName: string;
  fieldRequired?: boolean;
  fieldIndex?: number | null;
  fieldMeterIndex?: number | null;
  onFieldChange: (args: any) => void;
  onFieldValidate?: (args: any) => void;
}

export const InvoiceOverrideInput: React.FC<InvoiceOverrideInputProps> = (props) => {
  const {
    constraintText,
    description,
    label,
    info,
    fieldName,
    fieldIndex = null,
    fieldMeterIndex = null,
    errorText,
    onFieldChange,
    onFieldValidate = () => {},
    fieldRequired = false,
    value: initialValue = '',
    ...additionalProps
  } = props;

  const [inputValue, setInputValue] = useState<string>(initialValue);
  const [error, setError] = useState<boolean>(fieldRequired && !initialValue?.length);

  const commonEventArgs = useMemo(
    () => ({
      name: fieldName,
      ...(fieldIndex !== null && { index: fieldIndex }),
      ...(fieldMeterIndex !== null && { meterIndex: fieldMeterIndex }),
    }),
    [fieldName, fieldIndex, fieldMeterIndex]
  );

  const handleInputChange = (event: NonCancelableCustomEvent<InputProps.ChangeDetail>) => {
    const { value } = event.detail;
    setInputValue(value);
  };

  const handleInputBlur = (event: NonCancelableCustomEvent<null>) => {
    const isInvalid = fieldRequired && !inputValue?.length;

    setError(isInvalid);
    onFieldValidate?.({ invalid: isInvalid, ...commonEventArgs });
    onFieldChange({ value: inputValue, ...commonEventArgs });
  };

  return (
    <FormField
      constraintText={constraintText}
      description={description}
      label={label}
      info={info}
      errorText={error ? errorText : ''}
      stretch
    >
      <Input onChange={handleInputChange} value={inputValue} onBlur={handleInputBlur} {...additionalProps} />
    </FormField>
  );
};

import { Tabs } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { BlueprintPromptsTable } from 'src/components/blueprints/prompts/BlueprintPromptsTable';
import {
  BlueprintConfiguration,
  BlueprintPropertyValueSetting,
  BlueprintUtilityDefinition,
} from 'src/components/blueprints/types';

export enum BLUEPRINT_PROMPTS_EDITOR_TABS {
  Billing = 'Billing',
  Meters = 'Meters',
}

interface BlueprintPromptsEditorProps {
  blueprint: BlueprintConfiguration;
  blueprintDefinition: BlueprintUtilityDefinition;
  onSaveBillingProperty: (propertyName: string, propertyValue: BlueprintPropertyValueSetting) => void;
  onSaveMeterProperty: (propertyName: string, propertyValue: BlueprintPropertyValueSetting) => void;
}

export const BlueprintPromptsEditor = ({
  blueprint,
  blueprintDefinition,
  onSaveBillingProperty,
  onSaveMeterProperty,
}: BlueprintPromptsEditorProps) => {
  const [selectedTabId, setSelectedTabId] = useState<BLUEPRINT_PROMPTS_EDITOR_TABS>(
    BLUEPRINT_PROMPTS_EDITOR_TABS.Billing
  );

  return (
    <Tabs
      onChange={(e) => setSelectedTabId(e.detail.activeTabId as BLUEPRINT_PROMPTS_EDITOR_TABS)}
      tabs={[
        {
          id: BLUEPRINT_PROMPTS_EDITOR_TABS.Billing,
          label: BLUEPRINT_PROMPTS_EDITOR_TABS.Billing,
          content: (
            <BlueprintPromptsTable
              blueprintProperties={blueprint.blueprintConfigurationSettings.billing}
              blueprintPropertyDefinition={blueprintDefinition.billing}
              onSaveProperty={onSaveBillingProperty}
            />
          ),
        },
        {
          id: BLUEPRINT_PROMPTS_EDITOR_TABS.Meters,
          label: BLUEPRINT_PROMPTS_EDITOR_TABS.Meters,
          content: (
            <BlueprintPromptsTable
              blueprintProperties={blueprint.blueprintConfigurationSettings.meter}
              blueprintPropertyDefinition={blueprintDefinition.meter}
              onSaveProperty={onSaveMeterProperty}
            />
          ),
        },
      ]}
    />
  );
};

import {
  Form,
  FormField,
  FormFieldProps,
  Select,
  SelectProps,
  NonCancelableCustomEvent,
} from '@amzn/awsui-components-react';
import React, { useState, useMemo } from 'react';

interface InvoiceOverrideSelectProps
  extends Omit<SelectProps, 'onChange' | 'onBlur' | 'errorText'>,
    Pick<FormFieldProps, 'constraintText' | 'description' | 'errorText' | 'info' | 'label'> {
  fieldName: string;
  fieldRequired?: boolean;
  fieldIndex?: number | null;
  fieldMeterIndex?: number | null;
  onFieldChange: (args: any) => void;
  onFieldValidate?: (args: any) => void;
}

export const InvoiceOverrideSelect: React.FC<InvoiceOverrideSelectProps> = (props) => {
  const {
    selectedOption: initialSelectedOption = null,
    constraintText,
    description,
    label,
    info,
    fieldName,
    fieldIndex = null,
    fieldMeterIndex = null,
    errorText,
    onFieldChange,
    onFieldValidate = () => {},
    fieldRequired = false,
    ...additionalProps
  } = props;

  const [selectedOption, setSelectedOption] = useState<SelectProps.Option | null>(initialSelectedOption);
  const [error, setError] = useState<boolean>(fieldRequired && !initialSelectedOption?.value);

  const commonEventArgs = useMemo(
    () => ({
      name: fieldName,
      ...(fieldIndex !== null && { index: fieldIndex }),
      ...(fieldMeterIndex !== null && { meterIndex: fieldMeterIndex }),
    }),
    [fieldName, fieldIndex, fieldMeterIndex]
  );

  const handleSelectChange = (event: NonCancelableCustomEvent<SelectProps.ChangeDetail>) => {
    const { selectedOption: option } = event.detail;
    const isInvalid = fieldRequired && !option?.value?.length;
    onFieldValidate?.({ invalid: isInvalid, ...commonEventArgs });
    onFieldChange({
      value: option?.value ?? '',
      ...commonEventArgs,
    });
    setSelectedOption(option);
    setError(isInvalid);
  };

  return (
    <FormField
      constraintText={constraintText}
      description={description}
      label={label}
      info={info}
      errorText={error ? errorText : ''}
      stretch
    >
      <Select onChange={handleSelectChange} selectedOption={selectedOption} {...additionalProps} />
    </FormField>
  );
};

import { StatusIndicatorProps } from '@amzn/awsui-components-react';

export enum PROCESSING_STATUS {
  MISSING_PDF = 'MISSING_PDF',
  UNPROCESSED = 'UNPROCESSED',
  PROCESSING = 'PROCESSING',
  MISSING_BLUEPRINT = 'MISSING_BLUEPRINT',
  PROCESSING_SUCCESSFUL = 'PROCESSING_SUCCESSFUL',
  PROCESSING_FAILED = 'PROCESSING_FAILED',
  VALIDATION_SUCCESSFUL = 'VALIDATION_SUCCESSFUL',
  VALIDATION_FAILED = 'VALIDATION_FAILED',
}

export enum PROCESSING_STATUS_DESCRIPTIONS {
  PROCESSING_FAILED = 'There was an error applying template to this invoice',
  PROCESSING_SUCCESSFUL = 'This invoice was processed successfully',
  VALIDATION_FAILED = 'There was a data validation error aggregating invoice data',
  VALIDATION_SUCCESSFUL = 'The invoice data was validated and transformed successfully',
  MISSING_PDF = 'The invoice PDF was missing',
  UNPROCESSED = 'The invoice has not been processed yet',
  PROCESSING = 'The invoice is processing',
  MISSING_BLUEPRINT = 'The blueprint is missing',
}

export enum PROCESSING_OVERRIDABLE_STATUS {
  MISSING_PDF = 'MISSING_PDF',
  UNPROCESSED = 'UNPROCESSED',
  PROCESSING = 'PROCESSING',
  MISSING_BLUEPRINT = 'MISSING_BLUEPRINT',
  PROCESSING_SUCCESSFUL = 'PROCESSING_SUCCESSFUL',
  PROCESSING_FAILED = 'PROCESSING_FAILED',
  VALIDATION_SUCCESSFUL = 'VALIDATION_SUCCESSFUL',
  VALIDATION_FAILED = 'VALIDATION_FAILED',
}

export const PROCESSING_STATUS_TYPES: { [key in PROCESSING_STATUS]: StatusIndicatorProps.Type } = {
  UNPROCESSED: 'pending',
  PROCESSING: 'in-progress',
  MISSING_BLUEPRINT: 'error',
  MISSING_PDF: 'error',
  PROCESSING_FAILED: 'error',
  PROCESSING_SUCCESSFUL: 'success',
  VALIDATION_FAILED: 'error',
  VALIDATION_SUCCESSFUL: 'success',
};

const PROCESSING_STATUS_SEQUENCE: Array<PROCESSING_STATUS> = [
  PROCESSING_STATUS.UNPROCESSED,
  PROCESSING_STATUS.MISSING_PDF,
  PROCESSING_STATUS.MISSING_BLUEPRINT,
  PROCESSING_STATUS.PROCESSING_FAILED,
  PROCESSING_STATUS.PROCESSING_SUCCESSFUL,
  PROCESSING_STATUS.VALIDATION_FAILED,
  PROCESSING_STATUS.VALIDATION_SUCCESSFUL,
];

export const processingStatusSortingComparator = (
  a: PROCESSING_STATUS | undefined,
  b: PROCESSING_STATUS | undefined
): number => (a ? PROCESSING_STATUS_SEQUENCE.indexOf(a) : -1) - (b ? PROCESSING_STATUS_SEQUENCE.indexOf(b) : -1);

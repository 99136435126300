import React, { useState, useEffect, useCallback } from 'react';
import { getBlueprints } from 'src/client/api-gateway';
import { BlueprintConfiguration } from 'src/components/blueprints/types';

export interface UseFetchBlueprints {
  allBlueprints: Array<BlueprintConfiguration>;
  blueprintsLoading: boolean;
  lastEvaluatedKey: string;
  fetchBlueprints: (lastEvalutedKey?: string, resetList?: boolean) => void;
}

export function useFetchBlueprints(): UseFetchBlueprints {
  const [allBlueprints, setAllBlueprints] = useState<Array<BlueprintConfiguration>>([]);
  const [blueprintsLoading, setBlueprintsLoading] = useState<boolean>(false);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<string>('');

  const fetchBlueprints = useCallback(
    async (lastEvaluatedKey?: string, resetList?: boolean) => {
      setBlueprintsLoading(true);
      setLastEvaluatedKey('');

      getBlueprints(lastEvaluatedKey)
        .then((data) => {
          const { blueprintList = [], lastEvaluatedKey = '' } = data;
          const updatedBlueprints = !resetList ? [...allBlueprints, ...blueprintList] : blueprintList;
          setAllBlueprints(updatedBlueprints as Array<BlueprintConfiguration>);
          setLastEvaluatedKey(lastEvaluatedKey ? JSON.stringify(lastEvaluatedKey) : '');
        })
        .catch((err) => {
          setAllBlueprints([]);
          setLastEvaluatedKey('');
        })
        .finally(() => {
          setBlueprintsLoading(false);
        });
    },
    [allBlueprints]
  );

  useEffect(() => {
    fetchBlueprints(lastEvaluatedKey);
  }, []);

  return {
    allBlueprints,
    blueprintsLoading,
    lastEvaluatedKey,
    fetchBlueprints,
  };
}

import { Container, Header } from '@amzn/awsui-components-react';
import React, { createContext } from 'react';
import { ListTables } from './ListTables';
import { PageTables } from 'src/helpers/textract';
import { TABULAR_DATA_CELL_TYPES } from '../templates/create/tabularDataMapping/ApplyTabularDataCellButton';
import { Cell } from 'amazon-textract-response-parser/dist/cjs';

interface TextractTabularDataProps {
  textractPageTables: Array<PageTables>;
  onApplyTabularDataCell: (
    cellType: TABULAR_DATA_CELL_TYPES,
    selectedCell: Cell,
    pageNumber: number,
    tableNumber: number
  ) => void;
}

export const TextractTabularData = ({ textractPageTables, onApplyTabularDataCell }: TextractTabularDataProps) => (
  <Container header={<Header variant="h2">Tabular Data</Header>}>
    {textractPageTables.map((pageTables, idx) => (
      <ListTables key={idx} pageTables={pageTables} onApplyTabularDataCell={onApplyTabularDataCell} />
    ))}
  </Container>
);

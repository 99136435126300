import { HelpPanel } from '@amzn/awsui-components-react';
import React from 'react';

export const InvoicesTableHelpContent = (
  <HelpPanel header={<h2>Invoices</h2>}>
    <div>
      <p>Invoices are documents from utility providers that have been uploaded to Tesseract.</p>
      <p>
        From these invoices, you can create one or more templates that instruct Tesseract how to process data extracted
        form the invoice.
      </p>
      <p>From the Invoices table, you can view invoices, create a template for an invoice, or re-process an invoice.</p>
    </div>
  </HelpPanel>
);

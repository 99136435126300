import { PropertyFilter, PropertyFilterProps } from '@amzn/awsui-components-react';
import React, { useCallback, useState } from 'react';

const INVOICE_ID_FILTER_KEY = 'invoiceId';
const UTILITY_ID_FILTER_KEY = 'utilityId';
const UTILITY_TYPE_FILTER_KEY = 'utilityType';

const filteringProperties: Array<PropertyFilterProps.FilteringProperty> = [
  {
    key: INVOICE_ID_FILTER_KEY,
    operators: ['='],
    propertyLabel: 'Invoice ID',
    groupValuesLabel: '',
  },
  {
    key: UTILITY_ID_FILTER_KEY,
    operators: ['='],
    propertyLabel: 'Utility ID',
    groupValuesLabel: '',
  },
  {
    key: UTILITY_TYPE_FILTER_KEY,
    operators: ['='],
    propertyLabel: 'Utility Type',
    groupValuesLabel: '',
  },
];

export interface ManifestFilterProps {
  onInvoiceIdChange: (invoiceId: string) => void;
  onUtilityIdChange: (utilityId: string) => void;
  onUtilityTypeChange: (utilityType: string) => void;
  onReset: () => void;
}

export const ManifestPropertyFilter = ({
  onInvoiceIdChange,
  onUtilityIdChange,
  onUtilityTypeChange,
  onReset,
}: ManifestFilterProps) => {
  const [query, setQuery] = useState<PropertyFilterProps.Query>({ tokens: [], operation: 'and' });

  const handleFilterChange = useCallback(
    (detail: PropertyFilterProps.Query) => {
      if (!detail?.tokens?.length) {
        setQuery({
          ...query,
          tokens: [],
        });
        onReset();
      }

      const token = detail.tokens[detail.tokens.length - 1];
      setQuery({
        ...query,
        tokens: token ? [token] : [],
      });

      const { propertyKey = '', value = '' } = token || {};

      if (propertyKey === INVOICE_ID_FILTER_KEY) {
        onInvoiceIdChange(value);
      } else if (propertyKey === UTILITY_ID_FILTER_KEY) {
        onUtilityIdChange(value);
      } else if (propertyKey === UTILITY_TYPE_FILTER_KEY) {
        onUtilityTypeChange(value);
      }
    },
    [onInvoiceIdChange, onUtilityIdChange, onUtilityTypeChange, onReset]
  );

  return (
    <PropertyFilter
      data-testid="manifestPropertyFilter"
      query={query}
      hideOperations
      filteringProperties={filteringProperties}
      onChange={({ detail }) => handleFilterChange(detail)}
      i18nStrings={{
        filteringAriaLabel: 'Invoice ID, Template ID, Utility ID, or Utility Type',
        dismissAriaLabel: 'Dismiss',
        filteringPlaceholder: 'Filter by Invoice ID, Utility ID, or Utility Type',
        groupValuesText: 'Values',
        groupPropertiesText: 'Properties',
        operatorsText: 'Operators',
        operationAndText: 'and',
        operationOrText: 'or',
        operatorLessText: 'Less than',
        operatorLessOrEqualText: 'Less than or equal',
        operatorGreaterText: 'Greater than',
        operatorGreaterOrEqualText: 'Greater than or equal',
        operatorContainsText: 'Contains',
        operatorDoesNotContainText: 'Does not contain',
        operatorEqualsText: 'Equals',
        operatorDoesNotEqualText: 'Does not equal',
        editTokenHeader: 'Edit filter',
        propertyText: 'Property',
        operatorText: 'Operator',
        valueText: 'Value',
        cancelActionText: 'Cancel',
        applyActionText: 'Apply',
        allPropertiesLabel: 'All properties',
        tokenLimitShowMore: 'Show more',
        tokenLimitShowFewer: 'Show fewer',
        clearFiltersText: 'Clear filters',
        removeTokenButtonAriaLabel: (token) => `Remove token ${token.propertyKey} ${token.operator} ${token.value}`,
        enteredTextLabel: (text) => `Use: '${text}'`,
      }}
    />
  );
};

import React from 'react';
import { WaterTemplateMap } from '../types/WaterTemplateConfiguration';
import { SpaceBetween } from '@amzn/awsui-components-react';
import { ConfigureAccountProperties, ConfigureAccountTableVariant } from '../propertyEditor/ConfigureAccountProperties';
import { IInvoiceMetadata } from 'src/interface/type-def';
import { TextractResultsState } from 'src/components/textract/hooks/useFetchInvoiceTextractResults';
import { ConfigureMeterProperties } from '../propertyEditor/ConfigureMeterProperties';
import { ConfigureChargeProperties } from '../propertyEditor/ConfigureChargeProperties';

interface WaterTemplateMapSummaryProps {
  templateMap: WaterTemplateMap;
  invoice: IInvoiceMetadata;
  textractResults: TextractResultsState | null;
}

export const WaterTemplateMapSummary = ({ templateMap, invoice, textractResults }: WaterTemplateMapSummaryProps) => (
  <SpaceBetween size="m" direction="vertical">
    <ConfigureAccountProperties
      invoice={invoice}
      account={templateMap.account}
      textractResults={textractResults}
      variant={ConfigureAccountTableVariant.Readonly}
    />
    <ConfigureChargeProperties
      invoice={invoice}
      charge={templateMap.charge}
      textractResults={textractResults}
      variant={ConfigureAccountTableVariant.Readonly}
    />
    <ConfigureMeterProperties
      invoice={invoice}
      meter={templateMap.meter}
      textractResults={textractResults}
      variant={ConfigureAccountTableVariant.Readonly}
    />
  </SpaceBetween>
);

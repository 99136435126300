import API from '@aws-amplify/api-rest';
import { API_NAME, CORAL_API_NAME } from 'src/utils/env';

// TODO: Remove tempalte paths. Asana task - https://app.asana.com/0/1207424189339925/1207425848039325
export const apiPaths = {
  // Get invoice by invoice ID
  invoice: '/invoice-metadata',
  // List all invoices
  invoices: '/all-invoice-metadata',
  // Get S3 presigned URL for invoice PDF file
  invoiceInput: '/invoice-s3-url',
  // Get/update invoice override values
  override: '/override',
  // Reprocess invoice(s) by ID
  reprocess: '/reprocess',
  // Reprocess all invoices by template ID
  reprocessTemplate: '/reprocess-template',
  // Get invoice processing results for invoice ID
  results: '/results',
  // Get suggested template mappings for a template based on its invoice's utility type
  suggestedTemplateMapping: '/suggested-template-mapping',
  // Get template by template ID
  template: '/template',
  // List all templates
  templates: '/templates',
  // Get raw textract results for invoice ID
  textractResult: '/textract-results',
  // List all blueprints
  blueprints: '/blueprints',
  blueprint: '/blueprint',
};

interface ApiCallProps {
  path: string;
  settings: any;
}

// Wrapper for get request
export const apiGetRequest = async (props: ApiCallProps) => {
  const { path, settings } = props;

  return API.get(API_NAME, path, {
    ...settings,
    headers: {},
  });
};
// Wrapper for post request
export const apiPostRequest = async (props: ApiCallProps) => {
  const { path, settings } = props;

  return API.post(API_NAME, path, {
    ...settings,
    headers: {},
  });
};
// Wrapper for delete request
export const apiDeleteRequest = async (props: ApiCallProps) => {
  const { path, settings } = props;

  return API.del(API_NAME, path, {
    ...settings,
    headers: {},
  });
};

/**
 * Coral API
 */

// TODO: Add return types using generics. Asana task - https://app.asana.com/0/1207424189339925/1207425848039331
export const coralApiGetRequest = async (props: ApiCallProps) => {
  const { path, settings } = props;

  return API.get(CORAL_API_NAME, path, {
    ...settings,
    headers: {},
  });
};

export const coralApiPostRequest = async (props: ApiCallProps) => {
  const { path, settings } = props;

  return API.post(CORAL_API_NAME, path, {
    ...settings,
    headers: {},
  });
};

type PromiseAllSettledResultStatus = 'fulfilled' | 'rejected';

interface PromiseAllSettledResult<T> {
  status: PromiseAllSettledResultStatus;
  value?: T;
  error?: Error | string;
}

/**
 * Executes the given promises in parallel, and returns an array of promises containing either the result if
 * successful, or error if rejected.
 *
 * @param promises array of promises to execute in parallel
 * @returns array of results
 */
export async function allSettled<T>(promises: Array<Promise<T>>): Promise<Array<PromiseAllSettledResult<T>>> {
  return Promise.all(
    promises.map((promise) =>
      promise
        .then((value: T) => ({ status: 'fulfilled' as const, value }))
        .catch((error: Error | string) => ({ status: 'rejected' as const, error }))
    )
  );
}

import React from 'react';
import { TableProps } from '@amzn/awsui-components-react';
import { Link } from 'react-router-dom';
import { PAGE_ROUTES } from 'src/components/Routes';
import { TextWithBadge } from 'src/components/common-components/text/TextWithBadge';
import { ProcessingstatusIndicator } from 'src/components/manifests/ProcessingStatusIndicator';
import { IInvoiceMetadata } from 'src/interface/type-def';
import { numericDate } from 'src/utils/dates';

export enum TEMPLATE_VERIFICATION_COLUMN_IDS {
  INVOICE_ID = 'invoiceId',
  LAST_STATUS_DATE = 'lastStatusDate',
  PROCESSING_STATUS = 'processingStatus',
  SITE_NAME = 'siteName',
  LOCATION_CODE = 'locationCode',
  ACCOUNT_NUMBER = 'accountNumber',
}

export const getCommonColumnDefinitions = (
  selectedInvoiceIds?: Array<string>
): Array<TableProps.ColumnDefinition<IInvoiceMetadata>> => [
  {
    id: TEMPLATE_VERIFICATION_COLUMN_IDS.INVOICE_ID,
    header: 'Invoice ID',
    cell: (e) => (
      <Link
        to={{
          pathname: `${PAGE_ROUTES.invoice}/${e.invoicePdfName}`,
          state: { manifest: e },
        }}
        target="_blank"
      >
        {selectedInvoiceIds?.includes(e.invoicePdfName) ? (
          <TextWithBadge text={e.invoicePdfName} badgeColor="blue" badgeText="Selected" />
        ) : (
          e.invoicePdfName
        )}
      </Link>
    ),
    sortingField: TEMPLATE_VERIFICATION_COLUMN_IDS.INVOICE_ID,
  },
  {
    id: TEMPLATE_VERIFICATION_COLUMN_IDS.LAST_STATUS_DATE,
    header: 'Last Status Update',
    cell: (item) => numericDate(item.lastStatusDate, true),
    minWidth: 100,
    sortingField: TEMPLATE_VERIFICATION_COLUMN_IDS.LAST_STATUS_DATE,
  },
  {
    id: TEMPLATE_VERIFICATION_COLUMN_IDS.PROCESSING_STATUS,
    header: 'Status',
    cell: (e) => <ProcessingstatusIndicator status={e.processingStatus} renderPopover />,
    minWidth: 180,
    sortingField: TEMPLATE_VERIFICATION_COLUMN_IDS.PROCESSING_STATUS,
  },
  {
    id: TEMPLATE_VERIFICATION_COLUMN_IDS.SITE_NAME,
    header: 'Site name',
    cell: (e) => e.manifestDocument.siteName,
    sortingField: TEMPLATE_VERIFICATION_COLUMN_IDS.SITE_NAME,
  },
  {
    id: TEMPLATE_VERIFICATION_COLUMN_IDS.LOCATION_CODE,
    header: 'Location code',
    cell: (invoice) => invoice.manifestDocument.locationCode,
    sortingComparator: (invoiceA, invoiceB) =>
      invoiceB.manifestDocument.locationCode.localeCompare(invoiceA.manifestDocument.locationCode),
  },
  {
    id: TEMPLATE_VERIFICATION_COLUMN_IDS.ACCOUNT_NUMBER,
    header: 'Account number',
    cell: (invoice) => invoice.manifestDocument.accountNumber,
    sortingComparator: (invoiceA, invoiceB) =>
      invoiceB.manifestDocument.accountNumber.localeCompare(invoiceA.manifestDocument.accountNumber),
  },
];

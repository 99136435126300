import { Alert, Container, FormField, Header, Input, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { BlueprintConfiguration } from 'src/components/blueprints/types';
import { BlueprintValidFromDateSelect } from 'src/components/blueprints/blueprintMetadata/BlueprintValidFromSelect';

interface ConfigureBlueprintMetadataProps {
  blueprint: BlueprintConfiguration;
  vendorName: string;
  vendorId: string;
  onBlueprintNameChange: (name: string) => void;
  blueprintNameError: string;
  onBlueprintDescriptionChange: (description: string) => void;
  onValidFromChange: (date: string) => void;
}

export const ConfigureBlueprintMetadata = ({
  blueprint,
  vendorName,
  vendorId,
  onBlueprintNameChange,
  blueprintNameError,
  onBlueprintDescriptionChange,
  onValidFromChange,
}: ConfigureBlueprintMetadataProps) => (
  <Container header={<Header variant="h2">Blueprint Metadata</Header>}>
    <SpaceBetween direction="vertical" size="l">
      {vendorName && vendorId && (
        <Alert type="info">
          You are creating a Blueprint that will be applied to all invoices for vendor <b>{vendorName}</b> (vendor ID:{' '}
          <b>{vendorId}</b>).
        </Alert>
      )}
      <FormField
        label="Blueprint name"
        constraintText="Use only letters, numbers, hyphens, or underscores with no spaces."
        errorText={blueprintNameError || undefined}
      >
        <Input
          value={blueprint.blueprintName}
          placeholder="Blueprint name"
          onChange={(e) => onBlueprintNameChange(e.detail.value)}
        />
      </FormField>

      <FormField label="Blueprint description">
        <Input
          value={blueprint.blueprintDescription}
          placeholder="Brief description of this blueprint"
          onChange={(e) => onBlueprintDescriptionChange(e.detail.value)}
        />
      </FormField>

      <BlueprintValidFromDateSelect validFromTimestamp={blueprint.validFrom} onDateChange={onValidFromChange} />
    </SpaceBetween>
  </Container>
);

import { useCallback, useState } from 'react';
import { IInvoiceMetadata } from 'src/interface/type-def';
import { getInvoices } from 'src/client/api-gateway';
import { BlueprintConfiguration } from 'src/components/blueprints/types';

export interface UseFetchInvoicesForBlueprintState {
  isLoadingInvoices: boolean;
  invoices: Array<IInvoiceMetadata>;
  errorMessage: string | null;
  fetchInvoicesForBlueprint: (blueprint: BlueprintConfiguration) => Promise<void>;
  setInvoices: React.Dispatch<React.SetStateAction<IInvoiceMetadata[]>>;
}

export const useFetchInvoicesForBlueprint = () => {
  const [isLoadingInvoices, setIsLoadingInvoices] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [invoices, setInvoices] = useState<Array<IInvoiceMetadata>>([]);

  const fetchInvoicesForBlueprint = useCallback(async (blueprint: BlueprintConfiguration) => {
    setIsLoadingInvoices(true);

    try {
      // TODO: The query patterns for this will change in the future when the invoice table is migrated. Asana task - https://app.asana.com/0/1207424189339925/1207425848039329
      const invoiceResponse = await getInvoices(undefined, undefined, undefined, undefined, blueprint.id);
      setInvoices(invoiceResponse.invoiceMetadataList);
    } catch (e) {
      setErrorMessage(e as string);
    } finally {
      setIsLoadingInvoices(false);
    }
  }, []);

  return {
    isLoadingInvoices,
    invoices,
    errorMessage,
    setInvoices,
    fetchInvoicesForBlueprint,
  };
};

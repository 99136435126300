import React from 'react';
import { TableProps } from '@amzn/awsui-components-react';
import { BlueprintConfiguration } from 'src/components/blueprints/types';
import { convertSecondsToMilliseconds, numericDate } from 'src/utils/dates';
import { Link } from 'react-router-dom';
import { PAGE_ROUTES } from 'src/components/Routes';

export enum BLUEPRINTS_TABLE_COLUMN_KEYS {
  blueprintId = 'blueprintId',
  bluePrintName = 'bluePrintName',
  blueprintUpdateLog = 'blueprintUpdateLog',
  validFrom = 'validFrom',
  vendorNumber = 'vendorNumber',
}

const DEFAULT_ROW_VALUE = '-';

export const blueprintColumnDefinitions: TableProps.ColumnDefinition<BlueprintConfiguration>[] = [
  {
    id: BLUEPRINTS_TABLE_COLUMN_KEYS.bluePrintName,
    header: 'Blueprint name',
    cell: (item) => (
      <Link
        to={{
          pathname: `${PAGE_ROUTES.blueprint}/${item.id}`,
          state: { blueprint: item },
          search: `?validFrom=${item.validFrom}`,
        }}
      >
        {item.blueprintName}
      </Link>
    ),
    minWidth: '100px',
  },
  {
    id: BLUEPRINTS_TABLE_COLUMN_KEYS.blueprintId,
    header: 'Vendor ID',
    cell: (item) => item.id || DEFAULT_ROW_VALUE,
    minWidth: '100px',
  },
  {
    id: BLUEPRINTS_TABLE_COLUMN_KEYS.validFrom,
    header: 'Valid From',
    cell: (item) =>
      item.validFrom ? numericDate(convertSecondsToMilliseconds(item.validFrom), true) : DEFAULT_ROW_VALUE,
    minWidth: '100px',
  },
  {
    id: BLUEPRINTS_TABLE_COLUMN_KEYS.blueprintUpdateLog,
    header: 'Last updated',
    cell: (item) =>
      item.updateLog && item.updateLog.length > 0
        ? numericDate(convertSecondsToMilliseconds(item.updateLog[0].timestamp), true)
        : DEFAULT_ROW_VALUE,
    minWidth: '100px',
  },
];

export const collectionPreferencesProps = {
  title: 'Preferences',
  confirmLabel: 'Confirm',
  cancelLabel: 'Cancel',
  pageSizePreference: {
    title: 'Select page size',
    options: [
      { value: 20, label: '20 blueprints' },
      { value: 30, label: '30 blueprints' },
      { value: 50, label: '50 blueprints' },
    ],
  },
  visibleContentPreference: {
    title: 'Select visible content',
    options: [
      {
        label: 'Main distribution properties',
        options: [
          { id: 'bluePrintName', label: 'Blueprint name' },
          { id: 'blueprintId', label: 'ID' },
          { id: 'blueprintUpdateLog', label: 'Last updated' },
        ],
      },
    ],
  },
};

export const paginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page',
};

export function getMatchesCountText(count: number) {
  return count === 1 ? `1 match` : `${count} matches`;
}

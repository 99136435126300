import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Wizard,
  Link as AwsUILink,
  Container,
  Header,
  Box,
  SpaceBetween,
  Spinner,
  SplitPanel,
  Alert,
  NonCancelableCustomEvent,
  WizardProps,
} from '@amzn/awsui-components-react';
import cloneDeep from 'lodash/cloneDeep';
import { useHistory, useLocation } from 'react-router-dom';
import { WaterTemplate } from 'src/components/templates/waterTemplates/types/WaterTemplateConfiguration';
import { emptyWaterTemplate } from 'src/data-store/initial-state-new-template';
import { ErrorBoundary } from 'src/components/common-components/error-boundary';
import { HelpContext } from 'src/components/help/HelpProvider';
import { TemplateWizardStep1HelpContent } from 'src/components/manifests/help/TemplateWizardHelpContent';
import { IInvoiceMetadata } from 'src/interface/type-def';
import { useFetchInvoiceTextractResults } from 'src/components/textract/hooks/useFetchInvoiceTextractResults';
import { useFetchTemplateSuggestions, useGenerateSuggestedTemplateValues } from '../hooks/useTemplateSuggestions';
import { getInvoice } from 'src/client/api-gateway';
import { useFetchTemplatesForInvoice } from '../hooks/useFetchTemplatesForInvoice';
import { generateTesseractId, getTemplateMapWithAutoAppliedValues, getTemplateTypeFromId } from '../create/createUtils';
import { DefineTemplateMetadata } from './templateMetadata/DefineTemplateMetadata';
import { TemplateTypeMetadata } from './templateMetadata/TemplateTypeMetadata';
import { TemplateValidFromDateSelect } from './templateMetadata/TemplateValidFromDateSelect';
import { useFetchPdf } from 'src/components/common-components/hooks/useFetchPdf';
import { SplitPanelContext } from 'src/components/split-panel/SplitPanelProvider';
import SplitPanelI18nStrings from 'src/components/split-panel/strings';
import { PDFComponent } from 'src/components/common-components/pdf-component';
import { useConfigureTemplate } from '../hooks/useConfigureTemplate';
import { getCreateTemplateWizardI18nStrings } from './strings';
import { TemplateType } from '../types';
import { CreateWaterTemplate } from './CreateWaterTemplate';
import { WATER_TEMPLATE_DEFINITION } from './types/WaterTemplateUtilityDefinition';
import { TemplateSummaryStep } from './templateSummary/TemplateSummaryStep';
import { VerifyTemplate } from '../templateVerification/VerifyTemplate';
import { useUpsertWaterTemplate } from '../hooks/useUpsertWaterTemplate';
import { CancelWizardModal } from './CancelWizardModal';
import { FlashMessage } from 'src/components/common-components/FlashMessage';
import { PAGE_ROUTES } from 'src/components/Routes';
import { AppContext } from 'src/components/App';
import { useIsMounted } from 'usehooks-ts';
import { BlueprintPromptsTable } from '../../blueprints/prompts/BlueprintPromptsTable';

const CreateTemplateWizardSplitPanel: React.FC = ({ children }) => (
  <SplitPanel data-testid="createTemplateSplitPanel" i18nStrings={SplitPanelI18nStrings} header="View Invoice">
    {children}
  </SplitPanel>
);

const DEFAULT_TEMPLATE_TYPE = TemplateType.UTILITY;

interface UseLocationState {
  manifest?: IInvoiceMetadata;
  template?: WaterTemplate; // Expected to be defined if updating a template
  renderTemplateValidationStep?: boolean;
}

export const CreateWaterTemplateWizard = () => {
  const history = useHistory();

  const { user } = useContext(AppContext);
  const { setHelpContent, showHelp } = useContext(HelpContext);
  const { setSplitPanel, showSplitPanel } = useContext(SplitPanelContext);

  const { state } = useLocation<UseLocationState>();
  const { template: templateToUpdate, manifest: existingInvoice } = state || {};
  const isUpdatingTemplate = !!templateToUpdate;

  const {
    isLoadingTemplates,
    utilityTemplate: existingUtilityTemplate,
    siteTemplate: existingSiteTemplate,
    accountTemplate: existingAccountTemplate,
    fetchTemplatesForInvoice,
  } = useFetchTemplatesForInvoice();
  const { pdf, pdfLoading, fetchPdf } = useFetchPdf();
  const { textractResults, isLoadingTextractResults, fetchTextractResults } = useFetchInvoiceTextractResults();
  const { isLoadingTemplateSuggestions, templateSuggestions, fetchTemplateSuggestions } = useFetchTemplateSuggestions();
  const { isLoading: isSubmittingTemplate, requestError: submissionError, upsertTemplate } = useUpsertWaterTemplate();

  const { template, setTemplate, updateAccountProperty, updateChargeProperty, updateMeterProperty } =
    useConfigureTemplate(
      templateToUpdate || { ...emptyWaterTemplate, createdFromInvoiceId: existingInvoice?.invoicePdfName || '' }
    );
  const { suggestedTemplateValues, generateSuggestedTemplateValues } = useGenerateSuggestedTemplateValues();

  // Invoice used to create the template
  const [invoice, setInvoice] = useState<IInvoiceMetadata | null>(existingInvoice || null);
  const [isLoadingInvoice, setIsLoadingInvoice] = useState<boolean>(false);
  const [templateType, setTemplateType] = useState<TemplateType>(
    isUpdatingTemplate ? getTemplateTypeFromId(templateToUpdate.templateId || '') : DEFAULT_TEMPLATE_TYPE
  );

  // Modal that renders when user clicks "Cancel" button in Wizard
  const [isCancelModalVisible, setIsCancelModalVisible] = useState<boolean>(false);

  // Wizard-specific state
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);

  const handleValidFromDateChange = useCallback(
    (validFromDate: string) => {
      setTemplate({
        ...template,
        validFrom: Date.parse(validFromDate),
      });
    },
    [template, setTemplate]
  );

  const fetchInvoice = useCallback((invoiceId: string) => {
    if (!invoiceId) return;

    setIsLoadingInvoice(true);

    getInvoice(invoiceId)
      .then((res) => setInvoice(res))
      .catch((err) => console.error(`Failed to fetch invoice ${invoiceId}: ${err}`))
      .finally(() => setIsLoadingInvoice(false));
  }, []);

  const fetchExistingTemplatesForInvoice = useCallback(
    (invoice?: IInvoiceMetadata) => {
      if (!invoice) return;

      const { manifestDocument } = invoice;
      const { vendorNumber: utilityId, locationCode, accountNumber: accountId } = manifestDocument;
      fetchTemplatesForInvoice(utilityId, locationCode, accountId);
    },
    [fetchTemplatesForInvoice]
  );

  // Fetch initial set of data data needed to create or update this template
  useEffect(() => {
    const invoiceIdForTemplate = isUpdatingTemplate
      ? templateToUpdate.createdFromInvoiceId
      : existingInvoice?.invoicePdfName;

    if (!invoiceIdForTemplate) return;

    fetchTextractResults(invoiceIdForTemplate);
    fetchTemplateSuggestions(invoiceIdForTemplate);
    fetchPdf(invoiceIdForTemplate);

    if (isUpdatingTemplate) {
      fetchInvoice(invoiceIdForTemplate);
    } else {
      fetchExistingTemplatesForInvoice(existingInvoice);
    }
  }, []);

  // From the suggested template values for this utility type, find the values that appear on this particular invoice
  // that the user can choose to auto-apply
  useEffect(() => {
    if (
      isLoadingTemplateSuggestions ||
      isLoadingTextractResults ||
      !templateSuggestions ||
      !textractResults?.pageKeyValuesList.length
    ) {
      return;
    }

    // Of all the template map suggestions, some suggested keys may not actually appear on this invoice.
    // Here, generate the suggestions that can actually be applied to this invoice.
    const suggestionsToApply = generateSuggestedTemplateValues(
      templateSuggestions || [],
      textractResults.pageKeyValuesList
    );
    if (suggestionsToApply) {
      // Of the suggestions that can be applied, auto-apply any that can be pulled from the manifest file.
      const updatedTemplateMap = getTemplateMapWithAutoAppliedValues(suggestionsToApply, template.templateMap);
      setTemplate((prevTemplate) => ({
        ...prevTemplate,
        templateMap: updatedTemplateMap,
      }));
    }
  }, [
    templateSuggestions,
    isLoadingTemplateSuggestions,
    isLoadingTextractResults,
    textractResults?.pageKeyValuesList,
    generateSuggestedTemplateValues,
  ]);

  // Initialize the split panel state, which renders the invoice PDF
  useEffect(() => {
    setSplitPanel(
      <CreateTemplateWizardSplitPanel>
        <PDFComponent pdfFile={pdf} pdfFileLoading={pdfLoading} />
      </CreateTemplateWizardSplitPanel>
    );
    showSplitPanel(true);

    return () => {
      setSplitPanel(undefined);
      showSplitPanel(false);
    };
  }, [pdf, pdfLoading, setSplitPanel, showSplitPanel]);

  const onNavigate = async ({ detail }: NonCancelableCustomEvent<WizardProps.NavigateDetail>) => {
    const { requestedStepIndex } = detail;

    // If creating a template, populate template-level IDs after the Template Type has been selected
    if (requestedStepIndex === 1 && !isUpdatingTemplate) {
      setTemplate((prevTemplate) => ({
        ...prevTemplate,
        utilityId: invoice?.manifestDocument.vendorNumber,
        siteId: templateType !== TemplateType.UTILITY ? invoice?.manifestDocument.locationCode : undefined,
        accountId: templateType === TemplateType.ACCOUNT ? invoice?.manifestDocument.accountNumber : undefined,
      }));
    } else if (requestedStepIndex === 3) {
      const templateBody = cloneDeep(template);
      templateBody.updatedBy = user;
      // Populate `tesseractId` for charge and meter
      // TODO: Support multi-meter use case. Today, we just pass one meter to the API.
      if (!templateBody.templateMap.charge.tesseractId) {
        templateBody.templateMap.charge.tesseractId = generateTesseractId(templateType);
      }
      if (!templateBody.templateMap.meter.tesseractId) {
        templateBody.templateMap.meter.tesseractId = generateTesseractId(templateType);
      }

      setTemplate(templateBody);

      try {
        const templateId = await upsertTemplate(templateBody);
        setActiveStepIndex(requestedStepIndex);
        setTemplate((prevTemplate) => ({ ...prevTemplate, templateId }));
      } catch (e) {
        console.error(e);
      }
      return;
    }

    setActiveStepIndex(requestedStepIndex);
  };

  const handleInfoFollow = (helpContent: JSX.Element) => (e: CustomEvent<any>) => {
    e.preventDefault();

    setHelpContent(helpContent);
    showHelp(true);
  };

  const isFetchingData =
    isLoadingInvoice || isLoadingTemplates || isLoadingTextractResults || isLoadingTemplateSuggestions;

  if (isFetchingData) {
    return (
      <Container>
        <Box textAlign="center">
          <SpaceBetween direction="vertical" size="s">
            <Spinner size="large" />
            Loading invoice details
          </SpaceBetween>
        </Box>
      </Container>
    );
  }

  return (
    <SpaceBetween size="m" direction="vertical">
      {isUpdatingTemplate && <Alert type="info">{`You are updating template ${template.templateId}`}</Alert>}
      {isCancelModalVisible && (
        <CancelWizardModal
          isVisible={isCancelModalVisible}
          onDismiss={() => setIsCancelModalVisible(false)}
          onDone={() => history.push('/')}
        />
      )}
      <Wizard
        i18nStrings={getCreateTemplateWizardI18nStrings(activeStepIndex)}
        onCancel={() => setIsCancelModalVisible(true)}
        onSubmit={() => history.push(`${PAGE_ROUTES.template}/${template.templateId}`)}
        onNavigate={onNavigate}
        activeStepIndex={activeStepIndex}
        isLoadingNextStep={activeStepIndex === 2 && isSubmittingTemplate}
        steps={[
          {
            title: 'Define Template Metadata',
            content: (
              <ErrorBoundary>
                <SpaceBetween direction="vertical" size="m">
                  <Container header={<Header variant="h2">Select Template Type</Header>}>
                    <SpaceBetween direction="vertical" size="s">
                      <DefineTemplateMetadata
                        templateType={templateType}
                        setTemplateType={(type) => setTemplateType(type)}
                        isUpdatingTemplate={isUpdatingTemplate}
                      />
                      {invoice?.manifestDocument && (
                        <TemplateTypeMetadata
                          templateType={templateType}
                          manifestDocument={invoice?.manifestDocument}
                        />
                      )}
                    </SpaceBetween>
                  </Container>

                  <Container header={<Header variant="h2">Set Valid From Date</Header>}>
                    <TemplateValidFromDateSelect
                      validFromTimestamp={template.validFrom}
                      onDateChange={handleValidFromDateChange}
                    />
                  </Container>
                </SpaceBetween>
              </ErrorBoundary>
            ),
            info: (
              <AwsUILink variant="info" onFollow={handleInfoFollow(TemplateWizardStep1HelpContent)}>
                Info
              </AwsUILink>
            ),
          },
          {
            title: 'Configure Template',
            content: (
              <CreateWaterTemplate
                invoice={invoice}
                template={template}
                templateDefinition={WATER_TEMPLATE_DEFINITION}
                textractResults={textractResults}
                suggestedTemplateValues={suggestedTemplateValues}
                onSaveAccountProperty={updateAccountProperty}
                onSaveChargeProperty={updateChargeProperty}
                onSaveMeterProperty={updateMeterProperty}
              />
            ),
          },
          {
            title: 'Review and Save Template',
            content: (
              <SpaceBetween direction="vertical" size="s">
                <TemplateSummaryStep
                  template={template}
                  templateType={templateType}
                  invoice={invoice}
                  textractResults={textractResults}
                />
                {isSubmittingTemplate && (
                  <FlashMessage message="Saving changes to this template" type="in-progress" dismissible={false} />
                )}
                {submissionError && (
                  <FlashMessage
                    header="There was an error creating this template"
                    message={`Error: ${submissionError}`}
                    type="error"
                  />
                )}
              </SpaceBetween>
            ),
          },
          {
            title: 'Verify Template',
            content: <VerifyTemplate isVerifyingBlueprint={false} template={template} />,
          },
        ]}
      />
    </SpaceBetween>
  );
};

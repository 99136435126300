import {
  Alert,
  Box,
  Button,
  Container,
  ExpandableSection,
  Header,
  SpaceBetween,
  Spinner,
  Tabs,
} from '@amzn/awsui-components-react';
import React, { useState, useEffect, useMemo } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { getParentTemplates, getTemplate } from '../../../client/api-gateway';
import { PDFComponent } from '../../common-components/pdf-component';
import { getPresigneds3Url } from 'src/helpers/storage';
import { TemplateMapSummary } from '../template-map-summary';
import { Template } from 'src/interface/type-def';
import { PageError } from '../../common-components/error-boundary/page-error';
import { TemplateDetails } from './template-details';
import { TemplateLogTable } from './template-log-table';
import { getTemplateIdFromLocation } from './templateDetailsUtils';
import { useFeatureFlags } from 'src/featureFlags/useFeatureFlags';
import { FEATURE_NAME } from 'src/featureFlags/featureFlags';
import { InvoicesForTemplateTab } from './InvoicesForTemplateTab';
import { useFetchInvoicesForTemplate } from '../hooks/useFetchInvoicesForTemplate';
import { TemplateTypeAlert } from './TemplateTypeAlert';
import {
  WaterAccount,
  WaterCharge,
  WaterMeter,
  WaterTemplate,
} from '../waterTemplates/types/WaterTemplateConfiguration';
import { useFetchManifestById } from 'src/components/manifests/hooks/useFetchManifestById';
import { useFetchInvoiceTextractResults } from 'src/components/textract/hooks/useFetchInvoiceTextractResults';
import { isLegacyTemplate, isLegacyTemplateMap } from '../create/createUtils';
import { isWaterInvoice } from 'src/components/manifests/utils/manifestDocumentUtils';
import { PAGE_ROUTES } from 'src/components/Routes';

export interface TemplateDetailsUseLocationState {
  template?: Template;
}

export const TemplateDetailsPage = () => {
  const { isFeatureEnabled } = useFeatureFlags();
  const location = useLocation<TemplateDetailsUseLocationState>();

  const { manifest, manifestLoading, fetchManifestById } = useFetchManifestById();
  const { textractResults, isLoadingTextractResults, fetchTextractResults } = useFetchInvoiceTextractResults();
  // Invoices that this template would be applied to at invoice processing time
  const { isLoadingInvoices, invoices, fetchInvoicesForTemplate } = useFetchInvoicesForTemplate();

  const [template, setTemplate] = useState<Template | WaterTemplate>();
  // Loading state that indicates template details are loading, if the template is not passed via location state
  const [isLoadingTemplate, setIsLoadingTemplate] = useState(false);

  const [pdfFile, setPdfFile] = useState('');
  const [pdfFileLoading, setPdfFileLoading] = useState(false);

  const [parentTemplates, setParentTemplates] = useState<Template[]>([]);
  const [parentTemplatesLoading, setParentTemplatesLoading] = useState(false);

  const templateId = useMemo(() => {
    const { hash, pathname } = location;
    return getTemplateIdFromLocation(pathname, hash);
  }, [location]);

  useEffect(() => {
    if (template !== undefined) {
      setPdfFileLoading(true);
      setParentTemplatesLoading(true);

      fetchManifestById(template.createdFromInvoiceId || '');
      fetchTextractResults(template.createdFromInvoiceId || '');
      fetchInvoicesForTemplate(template);
      getPresigneds3Url(template.createdFromInvoiceId || '')
        .then((pdfUrl) => setPdfFile(pdfUrl))
        .finally(() => setPdfFileLoading(false));
      getParentTemplates(templateId)
        .then((ids) => setParentTemplates(ids))
        .finally(() => setParentTemplatesLoading(false));
    }
  }, [template]);

  useEffect(() => {
    if (location.state?.template) {
      setTemplate(location.state.template);
      return;
    }

    setIsLoadingTemplate(true);
    getTemplate(templateId)
      .then((templateResponse) => {
        // Filter out null values in templateMap. Backend returns extraneous template properties that are set to
        // `null`, so this handles that by simply removing those values.
        const parsedTemplate = cloneDeep(templateResponse);
        if (parsedTemplate.templateMap && !isLegacyTemplateMap(parsedTemplate.templateMap)) {
          parsedTemplate.templateMap.account = Object.entries(parsedTemplate.templateMap.account).reduce(
            (acc, [k, v]) => (v ? { ...acc, [k]: v } : acc),
            {} as WaterAccount
          );
          parsedTemplate.templateMap.charge = Object.entries(parsedTemplate.templateMap.charge).reduce(
            (acc, [k, v]) => (v ? { ...acc, [k]: v } : acc),
            {} as WaterCharge
          );
          parsedTemplate.templateMap.meter = Object.entries(parsedTemplate.templateMap.meter).reduce(
            (acc, [k, v]) => (v ? { ...acc, [k]: v } : acc),
            {} as WaterMeter
          );
        }
        setTemplate(parsedTemplate);
      })
      .catch((err) => setTemplate(undefined)) // This component renders error message in the case a template is undefined
      .finally(() => setIsLoadingTemplate(false));
  }, [templateId]);

  if (isLoadingTemplate) {
    return (
      <Box textAlign="center">
        <SpaceBetween direction="vertical" size="s">
          <Spinner size="large" />
          <span>Loading template</span>
        </SpaceBetween>
      </Box>
    );
  }

  if (!template) {
    return <PageError header="Template does not exist" />;
  }

  return (
    <SpaceBetween size="s">
      <TemplateTypeAlert template={template} />

      <Container
        header={
          <Header
            variant="h2"
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Link
                  to={{
                    pathname:
                      !isLegacyTemplate(template) && isWaterInvoice(manifest?.manifestDocument)
                        ? `${PAGE_ROUTES.updateWaterTemplate}/${template?.templateId}`
                        : `${PAGE_ROUTES.updateTemplate}/${template?.templateId}`,
                    state: {
                      template,
                      renderTemplateValidationStep: isFeatureEnabled(FEATURE_NAME.ConfigureTemplateVerification),
                    },
                  }}
                >
                  <Button>Edit</Button>
                </Link>
              </SpaceBetween>
            }
          >
            Template {template.templateId}
          </Header>
        }
      >
        <TemplateDetails
          template={template}
          parentTemplates={parentTemplates}
          parentTemplatesLoading={parentTemplatesLoading}
        />
      </Container>

      <Tabs
        tabs={[
          {
            id: 'templateMap',
            label: 'Template map',
            content: (
              <Container
                header={
                  <Header
                    variant="h2"
                    description="The mapping for each template field instructs Tesseract how to extract the desired value from the invoice for that field"
                  >
                    Template Map
                  </Header>
                }
              >
                {template.templateMap ? (
                  <TemplateMapSummary
                    templateMap={template.templateMap}
                    isLoading={manifestLoading || isLoadingTextractResults}
                    invoice={manifest}
                    textractResults={textractResults}
                  />
                ) : (
                  <Alert type="warning">There is no Template Map for this template.</Alert>
                )}
              </Container>
            ),
          },
          {
            id: 'templateLog',
            label: 'Update log',
            content: (
              <Container
                header={
                  <Header variant="h2" description="Log of users that have updated this template">
                    Template Update Log
                  </Header>
                }
              >
                <TemplateLogTable templateLogEntries={template.templateUpdateLog || []} />
              </Container>
            ),
          },
          {
            id: 'invoices',
            label: 'Invoices',
            content: (
              <SpaceBetween size="l" direction="vertical">
                <ExpandableSection variant="container" headerText="Invoice used to create template">
                  <PDFComponent pdfFile={pdfFile} pdfFileLoading={pdfFileLoading} />
                </ExpandableSection>
                <InvoicesForTemplateTab
                  templateId={template.templateId || ''}
                  allInvoices={invoices}
                  isLoadingInvoices={isLoadingInvoices}
                  onRefreshTable={() => fetchInvoicesForTemplate(template)}
                />
              </SpaceBetween>
            ),
          },
        ]}
      />
    </SpaceBetween>
  );
};

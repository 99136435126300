import React, { FC, ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { SplitPanel, AppLayoutProps } from '@amzn/awsui-components-react';

export interface SplitPanelContextValue {
  splitPanel: ReactNode | undefined;
  setSplitPanel: React.Dispatch<React.SetStateAction<ReactNode>>;
  splitPanelSize: number;
  setSplitPanelSize: React.Dispatch<React.SetStateAction<number>>;
  splitPanelPosition: AppLayoutProps.SplitPanelPosition;
  setSplitPanelPosition: React.Dispatch<React.SetStateAction<AppLayoutProps.SplitPanelPosition>>;
  isSplitPanelOpen: boolean;
  showSplitPanel: (isVisible: boolean) => void;
}

export const defaultSplitPanelContext: SplitPanelContextValue = {
  splitPanel: undefined,
  setSplitPanel: () => {},
  splitPanelSize: 500,
  setSplitPanelSize: () => {},
  splitPanelPosition: 'side',
  setSplitPanelPosition: () => {},
  isSplitPanelOpen: false,
  showSplitPanel: () => {},
};

export const SplitPanelContext = createContext<SplitPanelContextValue>(defaultSplitPanelContext);

export const SplitPanelProvider: React.FC = ({ children }) => {
  const [splitPanel, setSplitPanel] = useState<SplitPanelContextValue['splitPanel']>(undefined);
  const [splitPanelSize, setSplitPanelSize] = useState<SplitPanelContextValue['splitPanelSize']>(500);
  const [splitPanelPosition, setSplitPanelPosition] = useState<SplitPanelContextValue['splitPanelPosition']>('side');
  const [isSplitPanelOpen, setIsSplitPanelOpen] = useState<SplitPanelContextValue['isSplitPanelOpen']>(false);

  const showSplitPanel = useMemo(
    () => (isVisible: boolean) => {
      setIsSplitPanelOpen(isVisible);
    },
    [setIsSplitPanelOpen]
  );

  const value = useMemo<SplitPanelContextValue>(
    () => ({
      splitPanel,
      setSplitPanel,
      splitPanelSize,
      setSplitPanelSize,
      splitPanelPosition,
      setSplitPanelPosition,
      isSplitPanelOpen,
      showSplitPanel,
    }),
    [
      splitPanel,
      setSplitPanel,
      splitPanelSize,
      setSplitPanelSize,
      splitPanelPosition,
      setSplitPanelPosition,
      isSplitPanelOpen,
      showSplitPanel,
    ]
  );

  return <SplitPanelContext.Provider value={value}>{children}</SplitPanelContext.Provider>;
};

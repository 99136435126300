import * as React from 'react';
import { withRouter } from 'react-router';
import { Container, Link } from '@amzn/awsui-components-react/polaris';

const NotFoundPage = () => (
  <Container header={<h1>Oops! We don't have that page</h1>}>
    <h3>
      You can retry checking the link & retry or{' '}
      <Link href="#/">
        <i>return to our Home page</i>
      </Link>{' '}
    </h3>
  </Container>
);

export default withRouter(NotFoundPage);

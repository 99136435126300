import {
  Box,
  Button,
  Container,
  ExpandableSection,
  Grid,
  Header,
  SpaceBetween,
  Link as PolarisLink,
  Alert,
  Icon,
} from '@amzn/awsui-components-react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { PDFComponent } from 'src/components/common-components/pdf-component';
import { TextractResultsContainer } from 'src/components/textract/TextractResultsContainer';
import {
  Account,
  CellRef,
  Charge,
  IInvoiceMetadata,
  Meter,
  TableRef,
  Template,
  TemplateMap,
  TemplateValue,
  TextractAnalyzeDocumentHeaderBasedTableValueRef,
  Usage,
  ValueRefTypes,
} from 'src/interface/type-def';
import { SourceMappingForm } from '../source-mapping-form';
import {
  DEFAULT_CHARGE,
  DEFAULT_METER,
  DEFAULT_USAGE,
  addIncludePropertyToTemplateMap,
  generateTesseractId,
  getLabelForFieldType,
  getTemplateMapValueForClient,
} from '../createUtils';
import { AccountSummary } from './AccountSummary';
import { ChargesSummary } from './ChargesSummary';
import { ChargeSummary } from './ChargeSummary';
import { MetersSummary } from './MetersSummary';
import { MeterSummary } from './MeterSummary';
import { TemplateValuePicker } from './TemplateValuePicker';
import { HelpContext } from 'src/components/help/HelpProvider';
import { TextractResultsHelpContent } from 'src/components/manifests/help/TemplateWizardHelpContent';
import { TextractResultsState } from 'src/components/textract/hooks/useFetchInvoiceTextractResults';
import { SuggestedPropertyMappingTable } from '../../templateSuggestions/SuggestedPropertyMappingTable';
import { TemplatePropertySuggestedValues } from '../../templateSuggestions/templateSuggestionsUtils';
import { TemplateType } from '../../types';

export type Offset = {
  row: number;
  column: number;
};
// TODO: Remove duplicate types in favor of those defined in `type-def.ts`
// SIM: https://sim.amazon.com/issues/FootprintData-1705
export type ValueRef = {
  type: string;
  api?: string;
  service?: string;
  ref?: Ref;
  tableRef?: TableRef;
  cellRef?: CellRef;
};
export type Ref = {
  [key: string]: any;
};
export type SubTemplateMap = {
  source?: string;
  valueRef?: ValueRef;
};

export interface TemplateValueOption {
  label: string;
  value: any;
}

interface SelectionTemplateMap {
  templateMapKey: string;
  templateMapPosition: string;
  templateMapValue: TemplateValue;
  listIndex: number;
  meterIndex?: number;
}

interface MapTextractToSchemaProps {
  pdfFile: string;
  template: Template;
  setTemplate: Function;
  manifest?: IInvoiceMetadata;
  templateType: TemplateType;
  textractResults: TextractResultsState | null;
  suggestedTemplateValues: TemplatePropertySuggestedValues | null;
}

export const initialSubTemplateMapState: TemplateValue = {
  source: undefined,
  valueRef: undefined,
  include: true,
};

export enum FieldType {
  ACCOUNT = 'Account',
  CHARGES = 'Charge',
  METERS = 'Meter',
  METERS_CHARGES = 'MeterCharge',
  METERS_USAGES = 'MeterUsage',
}

function MapTextractToSchema({
  pdfFile,
  template,
  setTemplate,
  manifest,
  templateType,
  textractResults,
  suggestedTemplateValues,
}: MapTextractToSchemaProps) {
  // By default, ensure all template map properties are included
  const initTemplateMap = template.templateMap ? addIncludePropertyToTemplateMap(template.templateMap) : undefined;

  const [templateMap, setTemplateMap] = useState<TemplateMap>(initTemplateMap as TemplateMap);
  const [subTemplateMapState, setSubTemplateMapState] = useState<TemplateValue>(initialSubTemplateMapState);

  const [indexOfCurrentCharge, setIndexOfCurrentCharge] = useState(0);
  const [indexOfCurrentMeter, setIndexOfCurrentMeter] = useState(0);

  // Default state to -1 as there are no added usages and charges for meters for a new template.
  const [indexOfAddedUsage, setIndexOfAddedUsage] = useState(-1);
  const [indexOfAddedCharge, setIndexOfAddedCharge] = useState(-1);

  const [selectedTemplateValue, setSelectedTemplateValue] = useState<TemplateValueOption>({
    label: 'Account - accountNumber',
    value: {
      templateMapKey: 'accountNumber',
      templateMapPosition: FieldType.ACCOUNT,
      templateMapValue: initTemplateMap?.account.accountId || initialSubTemplateMapState,
    },
  });

  const { setHelpContent, showHelp } = useContext(HelpContext);

  const handleInfoFollow = (helpContent: JSX.Element) => (e: CustomEvent<any>) => {
    e.preventDefault();

    setHelpContent(helpContent);
    showHelp(true);
  };

  useEffect(() => {
    /*
       Current implementation: For every subTemplateMapState saved (saved to templateMap), update template
     */

    setTemplate({
      ...template,
      templateMap,
    });
  }, [templateMap]);

  useEffect(() => {
    const selectedTemplateMap = selectedTemplateValue.value as SelectionTemplateMap;
    setSubTemplateMapState(selectedTemplateMap.templateMapValue);
  }, [selectedTemplateValue]);

  const onApplySuggestedMapping = (templateValueToApply: TemplateValue) => {
    setSubTemplateMapState(templateValueToApply);
  };

  const editTemplateValue = (item: any, fieldType: FieldType, fieldIndex: number = 0, meterIndex: number = 0) => {
    const templateMapKey = item[0];
    const templateMapValue = getTemplateMapValueForClient(item[1]);

    const label = getLabelForFieldType(fieldType, templateMapKey, fieldIndex, meterIndex);
    let value = {
      templateMapKey,
      templateMapPosition: fieldType,
      templateMapValue,
      listIndex: fieldIndex,
    };

    if (fieldType === FieldType.METERS_CHARGES || fieldType === FieldType.METERS_USAGES) {
      value = Object.assign(value, { meterIndex });
    }

    setSelectedTemplateValue({
      label,
      value,
    });

    document.getElementById('editTemplate')?.scrollIntoView({ block: 'center' });
  };

  const excludeTemplateValue = (property: string, field: any, checked: boolean) => {
    if (Object.prototype.hasOwnProperty.call(field, property)) {
      field[property]['include'] = checked;
      setTemplateMap({ ...templateMap });
    }
  };

  const excludeAllPropsfromAccount = () => {
    const accountDetails = templateMap.account;
    Object.keys(accountDetails).forEach((k) => {
      accountDetails[k as keyof Account] = { ...accountDetails[k as keyof Account], include: false };
    });
    setTemplateMap({ ...templateMap });
  };

  const makeActive = (index: number, fieldType: FieldType) => {
    switch (fieldType) {
      case FieldType.CHARGES:
        setIndexOfCurrentCharge(index);
        document.getElementById(fieldType)?.scrollIntoView({ block: 'center' });
        break;
      case FieldType.METERS:
        setIndexOfCurrentMeter(index);
        document.getElementById(fieldType)?.scrollIntoView({ block: 'center' });
        break;
      case FieldType.METERS_CHARGES:
        setIndexOfAddedCharge(index);
        document.getElementById(fieldType)?.scrollIntoView({ block: 'center' });
        break;
      case FieldType.METERS_USAGES:
        setIndexOfAddedUsage(index);
        document.getElementById(fieldType)?.scrollIntoView({ block: 'center' });
        break;
      default:
    }
  };

  /**
   * Filters list(charges/ meters/ meter.charges/ meter. usages) to remove selected item and updates the TemplateMap state.
   * @param index
   * @param fieldType
   * @param meterIndex
   */
  const removeItemFromList = (index: number, fieldType: FieldType, meterIndex = 0) => {
    const meterList = [...templateMap.meters];

    switch (fieldType) {
      case FieldType.CHARGES:
        const newChargeList = templateMap.charges.filter((_, i) => i !== index);
        setIndexOfCurrentCharge(0);
        setTemplateMap({ ...templateMap, charges: newChargeList });
        break;
      case FieldType.METERS:
        const newMeterList = templateMap.meters.filter((_, i) => i !== index);
        setIndexOfCurrentMeter(0);
        setTemplateMap({ ...templateMap, meters: newMeterList });
        break;
      case FieldType.METERS_USAGES:
        const newUsageList = templateMap.meters[meterIndex].usages.filter((_, i) => i !== index);
        meterList[meterIndex].usages = newUsageList;
        setIndexOfAddedUsage(0);
        setTemplateMap({ ...templateMap, meters: meterList });
        break;
      case FieldType.METERS_CHARGES:
        const newMeterChargeList = templateMap.meters[meterIndex].charges.filter((_, i) => i !== index);
        meterList[meterIndex].charges = newMeterChargeList;
        setIndexOfAddedCharge(0);
        setTemplateMap({ ...templateMap, meters: meterList });
        break;
      default:
    }
  };

  const addCharge = () => {
    if (template.templateMap) {
      const newLength = template.templateMap.charges.push({
        ...DEFAULT_CHARGE,
        tesseractId: generateTesseractId(templateType),
      });
      setTemplate(template);
      setIndexOfCurrentCharge(newLength - 1);
    }
  };

  const addMeter = () => {
    if (template.templateMap) {
      const newLength = template.templateMap.meters.push({
        ...DEFAULT_METER,
        tesseractId: generateTesseractId(templateType),
      });
      setTemplate(template);
      setIndexOfCurrentMeter(newLength - 1);
    }
  };

  // takes the index of the current meter and adds charges or usages
  const addChargeToMeter = (currentMeterIndex: number) => {
    if (template.templateMap) {
      let newLength: number;
      template.templateMap.meters.forEach((prop, idx) => {
        if (prop.charges && idx === currentMeterIndex) {
          newLength = prop.charges.push({
            ...DEFAULT_CHARGE,
            tesseractId: generateTesseractId(templateType),
          });
        }
        setTemplate(template);
        setIndexOfAddedCharge(newLength - 1);
      });
    }
  };
  const addUsageToMeter = (currentMeterIndex: number) => {
    if (template.templateMap) {
      let newLength: number;
      template.templateMap.meters.forEach((prop, idx) => {
        if (prop.usages && idx === currentMeterIndex) {
          newLength = prop.usages.push({
            ...DEFAULT_USAGE,
            tesseractId: generateTesseractId(templateType),
          });
        }
        setTemplate(template);
        setIndexOfAddedUsage(newLength - 1);
      });
    }
  };

  const MemoizedPDFComponent = useMemo(() => PDFComponent, []);

  const selectedTemplateProperty = selectedTemplateValue.value?.templateMapKey as string;
  // List of suggested mappings that the user could apply to the selected property
  const suggestedMappingsForSelectedProperty: Array<TemplateValue> =
    suggestedTemplateValues && selectedTemplateProperty in suggestedTemplateValues
      ? suggestedTemplateValues[selectedTemplateProperty]
      : [];

  return (
    <Grid gridDefinition={[{ colspan: { default: 5 } }, { colspan: { default: 7 } }]}>
      {/* Mapping Textract Results to Schema Begins */}
      <Box>
        <SpaceBetween size="l" direction="vertical">
          {Object.keys(suggestedTemplateValues || {}).length > 0 && (
            <Alert type="info" header="There are auto-detected suggestions for this template">
              Tesseract has generated suggested mappings for {Object.keys(suggestedTemplateValues || {}).length}{' '}
              properties in this template. When editing these properties, you can choose to use any of these
              suggestions, or proceed to manually configure the mapping.
            </Alert>
          )}

          <Container header={<Header variant="h2">Map the Result from Textract to Schema</Header>}>
            <SpaceBetween size="l">
              <ExpandableSection headerText="Account">
                <SpaceBetween direction="vertical" size="xs">
                  <AccountSummary
                    account={templateMap?.account || {}}
                    editTemplateValue={editTemplateValue}
                    excludeTemplateValue={excludeTemplateValue}
                    suggestedTemplateValues={suggestedTemplateValues}
                  />
                  <Button onClick={excludeAllPropsfromAccount}>Exclude All</Button>
                </SpaceBetween>
              </ExpandableSection>

              <ExpandableSection headerText="Charges">
                <SpaceBetween direction="vertical" size="xs">
                  <ChargesSummary
                    charges={templateMap?.charges || []}
                    makeActive={makeActive}
                    removeItemFromList={removeItemFromList}
                    suggestedTemplateValues={suggestedTemplateValues}
                  />
                  <Button onClick={addCharge}>Add new charge</Button>
                  <p>Current selected charge: Charge #{indexOfCurrentCharge}</p>
                  <ChargeSummary
                    charges={templateMap?.charges || []}
                    index={indexOfCurrentCharge}
                    editTemplateValue={editTemplateValue}
                    excludeTemplateValue={excludeTemplateValue}
                    fieldType={FieldType.CHARGES}
                    suggestedTemplateValues={suggestedTemplateValues}
                  />
                </SpaceBetween>
              </ExpandableSection>

              <ExpandableSection headerText="Meters">
                <SpaceBetween direction="vertical" size="xs">
                  <MetersSummary
                    meters={templateMap?.meters || []}
                    makeActive={makeActive}
                    removeItemFromList={removeItemFromList}
                    suggestedTemplateValues={suggestedTemplateValues}
                  />
                  <Button onClick={addMeter}>Add new meter</Button>
                  <p>Current selected meter: Meter #{indexOfCurrentMeter}</p>
                  <MeterSummary
                    meters={templateMap?.meters || []}
                    meterIndex={indexOfCurrentMeter}
                    editTemplateValue={editTemplateValue}
                    excludeTemplateValue={excludeTemplateValue}
                    addUsageToMeter={addUsageToMeter}
                    addChargeToMeter={addChargeToMeter}
                    indexOfAddedUsage={indexOfAddedUsage}
                    indexOfAddedCharge={indexOfAddedCharge}
                    removeItemFromList={removeItemFromList}
                    makeActive={makeActive}
                    suggestedTemplateValues={suggestedTemplateValues}
                  />
                </SpaceBetween>
              </ExpandableSection>

              <TemplateValuePicker
                templateMap={template.templateMap}
                selectedTemplateValue={selectedTemplateValue}
                setSelectedTemplateValue={setSelectedTemplateValue}
              />

              <ExpandableSection
                headerText={
                  <>
                    <Icon name="suggestions" /> Suggested mappings ({suggestedMappingsForSelectedProperty.length})
                  </>
                }
              >
                {suggestedMappingsForSelectedProperty.length > 0 ? (
                  <SuggestedPropertyMappingTable
                    invoice={manifest}
                    suggestedMappings={suggestedMappingsForSelectedProperty}
                    textractKeyValuePairs={textractResults?.pageKeyValuesList || []}
                    onApplyMapping={onApplySuggestedMapping}
                  />
                ) : (
                  <>No suggested mappings for this property</>
                )}
              </ExpandableSection>

              <form
                id="editTemplate"
                onChange={(event) => {
                  event.preventDefault();
                }}
              >
                <SourceMappingForm
                  templateMap={templateMap}
                  setTemplateMap={setTemplateMap}
                  setSubTemplateMapState={setSubTemplateMapState}
                  subTemplateMapState={subTemplateMapState}
                  selectedTemplateValue={selectedTemplateValue}
                  manifest={manifest}
                />
              </form>
            </SpaceBetween>
          </Container>
          <Container header={<Header variant="h2">Invoice</Header>}>
            <MemoizedPDFComponent pdfFile={pdfFile} />
          </Container>
        </SpaceBetween>
      </Box>

      {/* Mapping Textract Results to Schema Ends */}

      <Container
        header={
          <Header
            variant="h2"
            info={
              <PolarisLink variant="info" onFollow={handleInfoFollow(TextractResultsHelpContent)}>
                Info
              </PolarisLink>
            }
          >
            Textract Results
          </Header>
        }
      >
        <TextractResultsContainer
          pageKeyValuesList={textractResults?.pageKeyValuesList || []}
          pageTablesList={textractResults?.pageTablesList || []}
        />
      </Container>
    </Grid>
  );
}

export { MapTextractToSchema };

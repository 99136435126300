import { SpaceBetween } from '@amzn/awsui-components-react';
import React, { useCallback, useContext, useState } from 'react';
import { IInvoiceMetadata } from 'src/interface/type-def';
import { AppContext } from 'src/components/App';
import { useReprocessInvoicesForBlueprint } from 'src/components/blueprints/hooks/useReprocessInvoicesForBlueprint';
import { ReprocessInvoicesForBlueprintModal } from 'src/components/blueprints/ReprocessInvoicesForBlueprintModal';
import { BlueprintInvoicesTable } from 'src/components/blueprints/BlueprintInvoicesTable';

interface BlueprintInvoicesTabProps {
  blueprintId: string;
  allInvoices: Array<IInvoiceMetadata>;
  isLoadingInvoices: boolean;
  onRefreshTable: () => void;
}

export const BlueprintInvoicesTab = ({
  blueprintId,
  allInvoices,
  isLoadingInvoices,
  onRefreshTable,
}: BlueprintInvoicesTabProps) => {
  const { setNotificationsItems } = useContext(AppContext);
  const { isReprocessRequestLoading, reprocessAllInvoices, reprocessRequestError } = useReprocessInvoicesForBlueprint();

  const [isReprocessAllModalVisible, setIsReprocessAllModalVisible] = useState<boolean>(false);

  const handleReprocessAllInvoices = useCallback(() => {
    reprocessAllInvoices(allInvoices.map((invoice) => invoice.invoicePdfName))
      .then((failedIds) => {
        const failedInvoicesCount = failedIds.length;
        setNotificationsItems([
          {
            type: 'info',
            header: failedInvoicesCount
              ? `Submitted request to reprocess all invoices for blueprint ${blueprintId}`
              : undefined,
            content: failedInvoicesCount
              ? `Failed to submit ${failedInvoicesCount} out of ${
                  allInvoices.length
                } invoices for reprocessing: ${failedIds.join(
                  ', '
                )}. This is most likely due to missing PDF files for these invoices. For each, please verify that a PDF has been uploaded before re-processing the invoice.`
              : `Successfully submitted request to reprocess all invoices for blueprint ${blueprintId}. Please check back in a few minutes to view the updated processing results for each invoice.`,
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setNotificationsItems([]),
          },
        ]);
      })
      .catch((e) => {
        setNotificationsItems([
          {
            type: 'error',
            header: `Failed to reprocess invoices`,
            content: `There was an error submitting request to reprocess all invoices for blueprint ${blueprintId}: ${reprocessRequestError}`,
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setNotificationsItems([]),
          },
        ]);
      })
      .finally(() => setIsReprocessAllModalVisible(false));
  }, [blueprintId, allInvoices.length, reprocessRequestError, setNotificationsItems, reprocessAllInvoices]);

  const handleReprocessAllButtonClick = useCallback(() => {
    if (!blueprintId) return;

    setIsReprocessAllModalVisible(true);
  }, [blueprintId]);

  return (
    <SpaceBetween direction="vertical" size="s">
      <ReprocessInvoicesForBlueprintModal
        allInvoices={allInvoices}
        isVisible={isReprocessAllModalVisible}
        onDismiss={() => setIsReprocessAllModalVisible(false)}
        onReprocessAll={handleReprocessAllInvoices}
        isSubmittingReprocessRequest={isReprocessRequestLoading}
      />
      <BlueprintInvoicesTable
        allInvoices={allInvoices}
        isLoadingInvoices={isLoadingInvoices}
        onClickReprocessAll={handleReprocessAllButtonClick}
        onRefreshTable={onRefreshTable}
      />
    </SpaceBetween>
  );
};

import { PAGE_ROUTES } from 'src/components/Routes';

/**
 * Given the pathname and hash of a React-Router location, returns the template ID.
 * Template IDs contain hash characters (`#`), but all characters starting from the first hash are
 * stripped from the route's pathname and stored in the location's hash. So, we must combine
 * the two to construct the template ID.
 *
 * @param pathname pathname from React-Router location
 * @param hash hash from React-Router location
 * @returns template ID
 */
export const getTemplateIdFromLocation = (pathname: string, hash: string = ''): string => {
  const templateDetailsPagePath = `${PAGE_ROUTES.template}/`;

  return `${pathname.replace(templateDetailsPagePath, '')}${hash}`;
};

/**
 * Returns the encoded template ID such that it can be passed in the path of an API request.
 *
 * @param templateId template ID
 * @returns encoded template ID
 */
export const encodeTemplateId = (templateId: string) => {
  const templateIdDelimiterPattern = /#/g;
  return templateId.replace(templateIdDelimiterPattern, '%23');
};

import { SpaceBetween } from '@amzn/awsui-components-react';
import React, { useCallback, useContext, useState } from 'react';
import { InvoicesForTemplateTable } from './InvoicesForTemplateTable';
import { IInvoiceMetadata } from 'src/interface/type-def';
import { ReprocessInvoicesForTemplateModal } from './ReprocessInvoicesForTemplateModal';
import { useReprocessInvoicesForTemplate } from '../hooks/useReprocessInvoicesForTemplate';
import { AppContext } from 'src/components/App';

interface InvoicesForTemplateTabProps {
  templateId: string;
  allInvoices: Array<IInvoiceMetadata>;
  isLoadingInvoices: boolean;
  onRefreshTable: () => void;
}

export const InvoicesForTemplateTab = ({
  templateId,
  allInvoices,
  isLoadingInvoices,
  onRefreshTable,
}: InvoicesForTemplateTabProps) => {
  const { setNotificationsItems } = useContext(AppContext);
  const { isReprocessRequestLoading, reprocessAllInvoices, reprocessRequestError } = useReprocessInvoicesForTemplate();

  const [isReprocessAllModalVisible, setIsReprocessAllModalVisible] = useState<boolean>(false);

  const handleReprocessAllInvoices = useCallback(() => {
    reprocessAllInvoices(templateId)
      .then((failedIds) => {
        const failedInvoicesCount = failedIds.length;
        setNotificationsItems([
          {
            type: 'info',
            header: failedInvoicesCount
              ? `Submitted request to reprocess all invoices for template ${templateId}`
              : undefined,
            content: failedInvoicesCount
              ? `Failed to submit ${failedInvoicesCount} out of ${
                  allInvoices.length
                } invoices for reprocessing: ${failedIds.join(
                  ', '
                )}. This is most likely due to missing PDF files for these invoices. For each, please verify that a PDF has been uploaded before re-processing the invoice.`
              : `Successfully submitted request to reprocess all invoices for template ${templateId}. Please check back in a few minutes to view the updated processing results for each invoice.`,
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setNotificationsItems([]),
          },
        ]);
      })
      .catch((e) => {
        setNotificationsItems([
          {
            type: 'error',
            header: `Failed to reprocess invoices`,
            content: `There was an error submitting request to reprocess all invoices for template ${templateId}: ${reprocessRequestError}`,
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setNotificationsItems([]),
          },
        ]);
      })
      .finally(() => setIsReprocessAllModalVisible(false));
  }, [templateId, allInvoices.length, reprocessRequestError, setNotificationsItems, reprocessAllInvoices]);

  const handleReprocessAllButtonClick = useCallback(() => {
    if (!templateId) return;

    setIsReprocessAllModalVisible(true);
  }, [templateId]);

  return (
    <SpaceBetween direction="vertical" size="s">
      <ReprocessInvoicesForTemplateModal
        allInvoices={allInvoices}
        isVisible={isReprocessAllModalVisible}
        onDismiss={() => setIsReprocessAllModalVisible(false)}
        onReprocessAll={handleReprocessAllInvoices}
        isSubmittingReprocessRequest={isReprocessRequestLoading}
      />
      <InvoicesForTemplateTable
        allInvoices={allInvoices}
        isLoadingInvoices={isLoadingInvoices}
        onClickReprocessAll={handleReprocessAllButtonClick}
        onRefreshTable={onRefreshTable}
      />
    </SpaceBetween>
  );
};

import { Select } from '@amzn/awsui-components-react';
import React, { useContext } from 'react';
import { Account, Charge, Meter, Source, TemplateMap, Usage } from 'src/interface/type-def';
import { TemplateValueOption } from './map-to-schema';
import { CreateTemplateContext } from '..';
import { getTemplateMapValueForClient } from '../createUtils';

interface TemplateValuePickerProps {
  templateMap?: TemplateMap;
  selectedTemplateValue: TemplateValueOption;
  setSelectedTemplateValue: Function;
}

const getAccountTemplateValue = (account: Account) =>
  Object.entries(account).map((accountProperty) => ({
    label: `Account - ${accountProperty[0]}`,
    value: {
      templateMapPosition: 'Account',
      templateMapKey: accountProperty[0],
      templateMapValue: getTemplateMapValueForClient(accountProperty[1]),
    },
  }));

const getChargeTemplateValue = (charge: Charge, chargeIdx: number) =>
  Object.entries(charge).map((chargeProperty) => ({
    label: `Charge #${chargeIdx} - ${chargeProperty[0]}`,
    value: {
      templateMapPosition: 'Charges',
      templateMapKey: chargeProperty[0],
      templateMapValue: getTemplateMapValueForClient(chargeProperty[1]),
      listIndex: chargeIdx,
    },
  }));

const getMeterTemplateValue = (meter: Meter, meterIdx: number) =>
  Object.entries(meter)
    .filter((meterProperty) => ['meterId', 'meterNumber', 'serviceType', 'usageAmount'].includes(meterProperty[0]))
    .map((meterProperty) => ({
      label: `Meter #${meterIdx} - ${meterProperty[0]}`,
      value: {
        templateMapPosition: 'Meters',
        templateMapKey: meterProperty[0],
        templateMapValue: getTemplateMapValueForClient(meterProperty[1]),
        listIndex: meterIdx,
      },
    }));

const getMeterUsageTemplateValue = (usage: Usage, meterIdx: number, usageIdx: number) =>
  Object.entries(usage).map((usageProperty) => ({
    label: `Meter #${meterIdx}, Usage #${usageIdx} - ${usageProperty[0]}`,
    value: {
      templateMapPosition: 'MeterUsage',
      templateMapKey: usageProperty[0],
      templateMapValue: getTemplateMapValueForClient(usageProperty[1]),
      meterIndex: meterIdx,
      listIndex: usageIdx,
    },
  }));

const getMeterChargeTemplateValue = (charge: Charge, meterIdx: number, chargeIdx: number) =>
  Object.entries(charge).map((chargeProperty) => ({
    label: `Meter #${meterIdx}, Charge #${chargeIdx} - ${chargeProperty[0]}`,
    value: {
      templateMapPosition: 'MeterCharge',
      templateMapKey: chargeProperty[0],
      templateMapValue: getTemplateMapValueForClient(chargeProperty[1]),
      meterIndex: meterIdx,
      listIndex: chargeIdx,
    },
  }));

export const TemplateValuePicker = ({
  templateMap,
  selectedTemplateValue,
  setSelectedTemplateValue,
}: TemplateValuePickerProps) => {
  const createTemplateContext = useContext(CreateTemplateContext);

  const options: TemplateValueOption[] = [];

  if (templateMap) {
    options.push(...getAccountTemplateValue(templateMap.account));

    templateMap.charges.forEach((charge, idx) => {
      options.push(...getChargeTemplateValue(charge, idx));
    });

    templateMap.meters.forEach((meter, meterIndex) => {
      options.push(...getMeterTemplateValue(meter, meterIndex));

      meter.usages.forEach((usage, usageIndex) => {
        options.push(...getMeterUsageTemplateValue(usage, meterIndex, usageIndex));
      });

      meter.charges.forEach((charge, chargeIndex) => {
        options.push(...getMeterChargeTemplateValue(charge, meterIndex, chargeIndex));
      });
    });
  }

  return (
    <Select
      selectedOption={selectedTemplateValue}
      onChange={({ detail }) => {
        // Reset source dropdown when toggling between properties. If the selected property
        // already has a source, this will be overriden in the next call to `setSelectedTemplateValue`.
        createTemplateContext.setSubTemplateMapState({
          ...createTemplateContext.subTemplateMapState,
          source: Source.NONE,
        });
        setSelectedTemplateValue(detail.selectedOption);
      }}
      options={options}
      selectedAriaLabel="Selected"
    />
  );
};

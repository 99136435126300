import { PageKeyValues, TextractKeyValue } from 'src/helpers/textract';
import { TemplateValue, ValueRefTypes, Services, APIs, Source } from 'src/interface/type-def';

// Key: template property name
// Value: List of suggested `TemplateValue`s that the user can auto-apply when creating a template
export interface TemplatePropertySuggestedValues {
  [propertyName: string]: Array<TemplateValue>;
}

// Suggested key-value pair mapping for a template property
export interface TextractKeySuggestedMapping {
  textractKeyName: string;
  isSeparatorRecommended?: boolean;
}

// Configuration that represents a suggested mapping(s) for the template property specified by `propertyName`
export interface TemplatePropertySuggestedMapping {
  // Tesseract template property name
  propertyName: string;
  textractKeys?: Array<TextractKeySuggestedMapping>;
  manifestColumnName?: string;
  staticValue?: string;
}

export const getManifestColumnTemplateValue = (manifestColumnName: string): TemplateValue => ({
  source: Source.MANIFEST,
  valueRef: {
    type: ValueRefTypes.ManifestValue,
    ref: {
      value: manifestColumnName,
    },
  },
  include: true,
});

export const getInvoiceKeyValuePairTemplateValue = (keyName: string): TemplateValue => ({
  source: Source.INVOICE,
  valueRef: {
    service: Services.Textract,
    type: ValueRefTypes.KeyValue,
    api: APIs.AnalyzeDocument,
    ref: {
      key: keyName,
    },
  },
  include: true,
});

export const getStaticValueTemplateValue = (staticValue: string): TemplateValue => ({
  source: Source.STATIC,
  valueRef: {
    type: ValueRefTypes.StaticValue,
    ref: {
      value: staticValue,
    },
  },
  include: true,
});

export const getSanitizedTextractKey = (key: string) => key.trim().replace(':', '').replace('*', '');

const sanitizedTextractKeysAreEqual = (key1: string, key2: string) =>
  getSanitizedTextractKey(key1).localeCompare(getSanitizedTextractKey(key2), undefined, { sensitivity: 'base' }) === 0;

export const isKeyInTextractResults = (textractResults: Array<PageKeyValues>, desiredKey: string) =>
  textractResults.some((page) =>
    page.keyValues.some((keyValuePair) => sanitizedTextractKeysAreEqual(keyValuePair.key, desiredKey))
  );

export const getMatchingKeyValuePairForKey = (
  textractResults: Array<PageKeyValues>,
  desiredKey: string,
  desiredKeyPageNumber: number = 1
) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const page of textractResults) {
    if (page.pageNumber === desiredKeyPageNumber) {
      // eslint-disable-next-line no-restricted-syntax
      for (const keyValuePair of page.keyValues) {
        if (sanitizedTextractKeysAreEqual(keyValuePair.key, desiredKey)) {
          return keyValuePair;
        }
      }
    }
  }

  return null;
};

export const getSuggestedTemplateValuesForProperty = (
  suggestedMappingForProperty: TemplatePropertySuggestedMapping,
  textractKeyValuePairs: Array<PageKeyValues>
) => {
  const { textractKeys, manifestColumnName, staticValue } = suggestedMappingForProperty;
  const suggestedTemplateValues: Array<TemplateValue> = [];

  if (manifestColumnName) {
    suggestedTemplateValues.push(getManifestColumnTemplateValue(manifestColumnName));
  }

  if (textractKeys?.length) {
    // Get suggested keys that also appear in the Textract results for this invoice
    const keysToSuggest = textractKeys.filter((suggestedKeyConfig) =>
      isKeyInTextractResults(textractKeyValuePairs, suggestedKeyConfig.textractKeyName)
    );
    if (keysToSuggest.length) {
      suggestedTemplateValues.push(
        // Convert each suggested key-value pair to a TemplateValue that the user can apply to the template
        ...keysToSuggest.map((suggestedKey) => {
          // Key-value pair associated with the suggested key
          const matchingKeyValuePair = getMatchingKeyValuePairForKey(
            textractKeyValuePairs,
            suggestedKey?.textractKeyName
          );
          return getInvoiceKeyValuePairTemplateValue(matchingKeyValuePair?.key || '');
        })
      );
    }
  }

  if (staticValue) {
    suggestedTemplateValues.push(getStaticValueTemplateValue(staticValue));
  }

  return suggestedTemplateValues;
};

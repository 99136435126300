import { Alert, Box, Button, Container, Header, SpaceBetween, Spinner, Tabs } from '@amzn/awsui-components-react';
import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { getBlueprint } from 'src/client/api-gateway';
import { PageError } from 'src/components/common-components/error-boundary/page-error';
import { BlueprintConfiguration } from 'src/components/blueprints/types';
import { BlueprintDetails } from 'src/components/blueprints/BlueprintDetails';
import { BlueprintLogTable } from 'src/components/blueprints/BlueprintLogTable';
import { BlueprintConfigurationSummary } from 'src/components/blueprints/BlueprintConfigurationSummary';
import { BlueprintInvoicesTab } from 'src/components/blueprints/BlueprintInvoicesTab';
import { useFetchInvoicesForBlueprint } from 'src/components/blueprints/hooks/useFetchInvoicesForBlueprint';
import { PAGE_ROUTES } from 'src/components/Routes';
import { BreadcrumbGroupWithRouting } from 'src/components/breadcrumbs/BreadcrumbGroupWithRouting';
import { BLUEPRINT_BREADCRUMBS, ROOT_BREADCRUMB } from 'src/components/breadcrumbs/breadcrumbs';

export interface BlueprintDetailsUseLocationState {
  blueprint?: BlueprintConfiguration;
}

export const BlueprintDetailsPage = () => {
  const location = useLocation<BlueprintDetailsUseLocationState>();
  const { isLoadingInvoices, invoices, fetchInvoicesForBlueprint } = useFetchInvoicesForBlueprint();
  const [blueprint, setBlueprint] = useState<BlueprintConfiguration>();
  const [isLoadingBlueprint, setIsLoadingBlueprint] = useState(false);

  const { blueprintId, validFrom } = useMemo(() => {
    const { hash, pathname, search } = location;
    // /blueprint/47012346?validFrom=946684800
    const pathArr = pathname.split('/');
    const blueprintId = pathArr[pathArr.length - 1];
    const searchArr = search.split('=');
    const validFrom = parseInt(searchArr[searchArr.length - 1]);

    return { blueprintId, validFrom };
  }, [location]);

  useEffect(() => {
    if (blueprint !== undefined) {
      fetchInvoicesForBlueprint(blueprint);
    }
  }, [blueprint]);

  useEffect(() => {
    if (location.state?.blueprint) {
      setBlueprint(location.state.blueprint);
      return;
    }

    setIsLoadingBlueprint(true);
    getBlueprint(blueprintId, validFrom)
      .then((response: { blueprint: BlueprintConfiguration }) => {
        setBlueprint(response.blueprint);
      })
      .catch((_) => setBlueprint(undefined))
      .finally(() => setIsLoadingBlueprint(false));
  }, [blueprintId]);

  if (isLoadingBlueprint) {
    return (
      <Box textAlign="center">
        <SpaceBetween direction="vertical" size="s">
          <Spinner size="large" />
          <span>Loading blueprint</span>
        </SpaceBetween>
      </Box>
    );
  }

  if (!blueprint) {
    return <PageError header="Blueprint does not exist" />;
  }

  return (
    <SpaceBetween size="l">
      <BreadcrumbGroupWithRouting
        items={[
          {
            text: ROOT_BREADCRUMB.homepage.text,
            href: ROOT_BREADCRUMB.homepage.getHref(),
          },
          {
            text: BLUEPRINT_BREADCRUMBS.blueprints.text,
            href: BLUEPRINT_BREADCRUMBS.blueprints.getHref(),
          },
          {
            text: BLUEPRINT_BREADCRUMBS.blueprintDetails.text,
            href: BLUEPRINT_BREADCRUMBS.blueprintDetails.getHref(blueprintId),
          },
        ]}
      />

      <Container
        header={
          <Header
            variant="h2"
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Link
                  to={{
                    pathname: `${PAGE_ROUTES.updateBlueprint}/${blueprint?.id}`,
                    state: {
                      blueprint,
                    },
                    search: `?validFrom=${blueprint.validFrom}`,
                  }}
                >
                  <Button>Edit</Button>
                </Link>
              </SpaceBetween>
            }
          >
            Blueprint {blueprint?.blueprintName}
          </Header>
        }
      >
        <BlueprintDetails blueprint={blueprint} />
      </Container>

      <Tabs
        tabs={[
          {
            id: 'blueprintConfiguration',
            label: 'Blueprint configuration',
            content: (
              <Container
                header={
                  <Header
                    variant="h2"
                    description="The configuration for each blueprint field instructs Tesseract how to extract the desired value from the invoice for that field"
                  >
                    Blueprint Configuration Settings
                  </Header>
                }
              >
                {blueprint.blueprintConfigurationSettings ? (
                  <BlueprintConfigurationSummary
                    blueprintConfigurationSettings={blueprint.blueprintConfigurationSettings}
                  />
                ) : (
                  <Alert type="warning">There are no Blueprint Configuration Settings for this blueprint.</Alert>
                )}
              </Container>
            ),
          },
          {
            id: 'blueprintLog',
            label: 'Update log',
            content: (
              <Container
                header={
                  <Header variant="h2" description="Log of users that have updated this blueprint">
                    Blueprint Update Log
                  </Header>
                }
              >
                <BlueprintLogTable blueprintLogEntries={blueprint.updateLog || []} />
              </Container>
            ),
          },
          {
            id: 'invoices',
            label: 'Invoices',
            content: (
              <SpaceBetween size="l" direction="vertical">
                <BlueprintInvoicesTab
                  blueprintId={blueprint.id || ''}
                  allInvoices={invoices}
                  isLoadingInvoices={isLoadingInvoices}
                  onRefreshTable={() => fetchInvoicesForBlueprint(blueprint)}
                />
              </SpaceBetween>
            ),
          },
        ]}
      />
    </SpaceBetween>
  );
};

import { Alert, Box, Button, ColumnLayout, Container, Header, SpaceBetween } from '@amzn/awsui-components-react';
import * as React from 'react';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router';
import { IInvoiceMetadata } from 'src/interface/type-def';
import { numericDate } from '../../utils/dates';
import { PDFComponent } from '../common-components/pdf-component';
import { reprocessInvoicesForBlueprint } from 'src/client/api-gateway';
import { AppContext } from '../App';
import { isInvoiceOverridable } from 'src/helpers/data';
import { PAGE_ROUTES } from '../Routes';
import { useFetchManifestById } from './hooks/useFetchManifestById';
import { useFetchInvoiceProcessingResults } from './hooks/useFetchInvoiceProcessingResults';
import { useFetchPdf } from '../common-components/hooks/useFetchPdf';
import { BreadcrumbGroupWithRouting } from '../breadcrumbs/BreadcrumbGroupWithRouting';
import { INVOICE_BREADCRUMBS, ROOT_BREADCRUMB } from '../breadcrumbs/breadcrumbs';
import { ProcessingstatusIndicator } from './ProcessingStatusIndicator';

export interface ManifestDetailsParams {
  id: string;
}

const ManifestDetailsTable = ({ manifest }: { manifest: IInvoiceMetadata }) => (
  <SpaceBetween direction="vertical" size="l">
    <ColumnLayout columns={4} variant="text-grid">
      <div>
        <Box variant="small">Invoice ID</Box>
        <div>{manifest.invoicePdfName || '-'}</div>
      </div>

      <div>
        <Box variant="small">Processing Status</Box>
        <div>
          <ProcessingstatusIndicator status={manifest.processingStatus} renderPopover />
        </div>
      </div>
      <div>
        <Box variant="small">Overridden</Box>
        <div>{manifest.blueprintResultOverride ? <span>Yes</span> : <span>No</span>}</div>
      </div>
      <div>
        <Box variant="small">Last Document Insight</Box>
        <div>{numericDate(manifest.lastDocumentInsightGeneratedTime, true)}</div>
      </div>
    </ColumnLayout>

    <ColumnLayout columns={4} variant="text-grid">
      <div>
        <Box variant="small">Location Description</Box>
        <div>{manifest.manifestDocument.locationDescription || '-'}</div>
      </div>

      <div>
        <Box variant="small">Modified On</Box>
        <div>{numericDate(manifest.manifestDocument.modifiedOn)}</div>
      </div>

      <div>
        <Box variant="small">Account Name</Box>
        <div>{manifest.manifestDocument.accountName || '-'}</div>
      </div>

      <div>
        <Box variant="small">Account Number</Box>
        <div>{manifest.manifestDocument.accountNumber || '-'}</div>
      </div>

      <div>
        <Box variant="small">Account Code</Box>
        <div>{manifest.manifestDocument.accountCode || '-'}</div>
      </div>

      <div>
        <Box variant="small">Account Status</Box>
        <div>{manifest.manifestDocument.accountStatus || '-'}</div>
      </div>

      <div>
        <Box variant="small">Company Name</Box>
        <div>{manifest.manifestDocument.companyName || '-'}</div>
      </div>

      <div>
        <Box variant="small">Vendor Name</Box>
        <div>{manifest.manifestDocument.vendorName || '-'}</div>
      </div>

      <div>
        <Box variant="small">Vendor Number</Box>
        <div>{manifest.manifestDocument.vendorNumber || '-'}</div>
      </div>

      <div>
        <Box variant="small">Suplier Site Code</Box>
        <div>{manifest.manifestDocument.supplierSiteCode || '-'}</div>
      </div>

      <div>
        <Box variant="small">Service Type</Box>
        <div>{manifest.manifestDocument.serviceType || '-'}</div>
      </div>
      <div>
        <Box variant="small">Service Related To</Box>
        <div>{manifest.manifestDocument.serviceRelatedTo || '-'}</div>
      </div>

      <div>
        <Box variant="small">Country</Box>
        <div>{manifest.manifestDocument.country || '-'}</div>
      </div>

      <div>
        <Box variant="small">Run Date</Box>
        <div>{numericDate(manifest.manifestDocument.runDate)}</div>
      </div>

      <div>
        <Box variant="small">Invoice Number</Box>
        <div>{manifest.manifestDocument.invoiceNumber || '-'}</div>
      </div>

      <div>
        <Box variant="small">Invoice Date</Box>
        <div>{manifest.manifestDocument.invoiceDate || '-'}</div>
      </div>

      <div>
        <Box variant="small">Invoice Currency</Box>
        <div>{manifest.manifestDocument.invoiceCurrency || '-'}</div>
      </div>
      <div>
        <Box variant="small">Amazon Invoice Amount</Box>
        <div>{manifest.manifestDocument.amzInvoiceAmt || '-'}</div>
      </div>
    </ColumnLayout>
  </SpaceBetween>
);

export const ManifestDetails = () => {
  const location = useLocation();
  const { id } = useParams<ManifestDetailsParams>();
  const { setNotificationsItems } = React.useContext(AppContext);
  const { manifest, manifestLoading, fetchManifestById } = useFetchManifestById();
  const {
    isLoadingResults,
    results,
    errorMessage: resultsErrorMessage,
    fetchInvoiceProcessingResults,
  } = useFetchInvoiceProcessingResults();
  const { pdf, fetchPdf, pdfErrorMessage, pdfLoading } = useFetchPdf();

  const isOverrideButtonDisabled = !isInvoiceOverridable(manifest);
  const invoiceOverrideHref = `/#${location.pathname}${PAGE_ROUTES.invoiceOverride}`;

  const resultsData = JSON.stringify(results, null, '\t');

  // Clean up notifications on unmount
  useEffect(
    () => () => {
      setNotificationsItems([]);
    },
    []
  );

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchManifestById(id);
      fetchInvoiceProcessingResults(id);
    }
    return () => {
      isMounted = false;
    };
  }, [id, fetchManifestById, fetchInvoiceProcessingResults]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && manifest) {
      fetchPdf(manifest.manifestDocument.s3FilePath);
    }
    return () => {
      isMounted = false;
    };
  }, [id, fetchPdf, manifest]);

  function callReprocessInvoicesAPI() {
    setNotificationsItems([{ type: 'info', loading: true, content: <>Attempting Reprocess for invoices : {id}</> }]);
    reprocessInvoicesForBlueprint([id])
      .then((res) => {
        if (res['failedInvoiceIds'].length === 0) {
          setNotificationsItems([
            {
              type: 'success',
              content: `Successfully reprocessed ${id}`,
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setNotificationsItems([]),
            },
          ]);
        } else {
          setNotificationsItems([
            {
              type: 'error',
              content: `Failed to reprocess ${id}`,
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setNotificationsItems([]),
            },
          ]);
        }
      })
      .catch((e) =>
        setNotificationsItems([
          {
            type: 'error',
            content: `Failed to reprocess ${id}`,
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setNotificationsItems([]),
          },
        ])
      );
  }

  return (
    <SpaceBetween size="l">
      <BreadcrumbGroupWithRouting
        items={[
          {
            text: ROOT_BREADCRUMB.homepage.text,
            href: ROOT_BREADCRUMB.homepage.getHref(),
          },
          {
            text: INVOICE_BREADCRUMBS.invoices.text,
            href: INVOICE_BREADCRUMBS.invoices.getHref(),
          },
          {
            text: INVOICE_BREADCRUMBS.invoiceDetails.text,
            href: INVOICE_BREADCRUMBS.invoiceDetails.getHref(id),
          },
        ]}
      />
      <Container
        header={
          <Header
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button disabled={isOverrideButtonDisabled} href={invoiceOverrideHref}>
                  Override
                </Button>
                <Button variant="primary" onClick={() => callReprocessInvoicesAPI()}>
                  Reprocess
                </Button>
              </SpaceBetween>
            }
            variant="h2"
          >
            Invoice details
          </Header>
        }
      >
        {manifest ? <ManifestDetailsTable manifest={manifest} /> : <div>Loading invoice details...</div>}
      </Container>

      <Container header={<Header variant="h2">Invoice file</Header>}>
        <SpaceBetween size="xs">
          <Container>
            <PDFComponent pdfFile={pdf} />
          </Container>
        </SpaceBetween>
      </Container>

      {manifest?.errorLog?.otherError?.map((error, idx) => (
        <Alert key={`errorOther${idx}`} dismissAriaLabel="Close alert" type="error" header="Other Error">
          {error}
        </Alert>
      ))}
      {manifest?.errorLog?.requiredAttributesError?.map((error, idx) => (
        <Alert
          key={`errorRequired${idx}`}
          dismissAriaLabel="Close alert"
          type="error"
          header="Required Attribute Error"
        >
          {error}
        </Alert>
      ))}
      {manifest?.errorLog?.optionalAttributesError?.map((error, idx) => (
        <Alert
          key={`errorOptional${idx}`}
          dismissAriaLabel="Close alert"
          type="warning"
          header="Optional Attribute Warning"
        >
          {error}
        </Alert>
      ))}
      <Container header={<Header variant="h2">Tesseract Results</Header>}>
        {isLoadingResults ? (
          <p>Loading Tesseract results...</p>
        ) : resultsData ? (
          <aside className="whitespace--pre">{resultsData}</aside>
        ) : (
          <p>No Tesseract results yet.</p>
        )}
      </Container>
    </SpaceBetween>
  );
};

import { SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { BreadcrumbGroupWithRouting } from '../breadcrumbs/BreadcrumbGroupWithRouting';
import { INVOICE_BREADCRUMBS, ROOT_BREADCRUMB } from '../breadcrumbs/breadcrumbs';
import { InvoiceMetadataTable } from './table/InvoiceMetadataTable';

export const InvoiceMetadataPage = () => (
  <SpaceBetween size="l">
    <BreadcrumbGroupWithRouting
      items={[
        {
          text: ROOT_BREADCRUMB.homepage.text,
          href: ROOT_BREADCRUMB.homepage.getHref(),
        },
        {
          text: INVOICE_BREADCRUMBS.invoices.text,
          href: INVOICE_BREADCRUMBS.invoices.getHref(),
        },
      ]}
    />

    <InvoiceMetadataTable />
  </SpaceBetween>
);

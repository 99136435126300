import { ExpandableSection, Icon } from '@amzn/awsui-components-react';
import React, { useMemo } from 'react';
import { SuggestedPropertyMappingTable } from '../../templateSuggestions/SuggestedPropertyMappingTable';
import { IInvoiceMetadata, TemplateValue } from 'src/interface/type-def';
import { TextractResultsState } from 'src/components/textract/hooks/useFetchInvoiceTextractResults';
import { TemplatePropertySuggestedValues } from '../../templateSuggestions/templateSuggestionsUtils';

interface SuggestedPropertyMappingsProps {
  propertyName: string;
  suggestedTemplateValues: TemplatePropertySuggestedValues | null;
  textractResults: TextractResultsState | null;
  invoice: IInvoiceMetadata | null;
  onApplySuggestedMapping: (templateValue: TemplateValue) => void;
}

export const SuggestedPropertyMappings = ({
  propertyName,
  suggestedTemplateValues,
  invoice,
  textractResults,
  onApplySuggestedMapping,
}: SuggestedPropertyMappingsProps) => {
  // List of suggested mappings that the user could apply to the selected property
  const suggestedMappingsForSelectedProperty: Array<TemplateValue> = useMemo(
    () =>
      suggestedTemplateValues && propertyName in suggestedTemplateValues ? suggestedTemplateValues[propertyName] : [],
    [propertyName, suggestedTemplateValues]
  );

  return (
    <ExpandableSection
      headerText={
        <>
          <Icon name="suggestions" /> Suggested mappings ({suggestedMappingsForSelectedProperty.length})
        </>
      }
      headerDescription="Before applying a suggestion, please check the invoice to verify that it the value is correct"
    >
      {suggestedMappingsForSelectedProperty.length > 0 ? (
        <SuggestedPropertyMappingTable
          invoice={invoice || undefined}
          suggestedMappings={suggestedMappingsForSelectedProperty}
          textractKeyValuePairs={textractResults?.pageKeyValuesList || []}
          onApplyMapping={onApplySuggestedMapping}
        />
      ) : (
        <>No suggested mappings for this property</>
      )}
    </ExpandableSection>
  );
};

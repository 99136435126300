import { HelpPanel } from '@amzn/awsui-components-react';
import React from 'react';

export const TemplateWizardStep1HelpContent = (
  <HelpPanel header={<h2>Configure Template</h2>}>
    <div>
      <p>Templates are used to instruct Tesseract how to process data extracted from an invoice.</p>
      <p>Follow the steps in the template wizard to configure your template.</p>
      <h3>Step 1: Define Template Metadata</h3>
      <p>First, select the type of template you'd like to create. Choose from the following:</p>
      <ul>
        <li>Utility template: Base template to apply on invoices from the given utility provider</li>
        <li>
          Site template: Template to apply on invoices for a specific site. By default, site templates will extend the
          invoice's utility template. You can configure the site template with site-specific changes that will override
          the corresponding properties in the utility template's schema.
        </li>
        <li>
          Account template: Template to apply on invoices for a specific account. By default, account templates will
          extend the invoice's utility template. You can configure the account template with account-specific changes
          that will override the corresponding properties in the utility template's schema.
        </li>
      </ul>
    </div>
  </HelpPanel>
);

export const TemplateWizardStep2HelpContent = (
  <HelpPanel header={<h2>Configure Template</h2>}>
    <div>
      <p>Templates are used to instruct Tesseract how to process data extracted from an invoice.</p>
      <p>Follow the steps in the template wizard to configure your template.</p>
      <h3>Step 2: Map Textract results to schema</h3>
      <p>
        Next, configure the template by mapping the data extracted from the invoice to the schema. The schema properties
        are listed in the "Account", "Charges", and "Meters" sections. Click the "Edit" icon for a property to update
        the source of the data you'd like to map to that property.{' '}
        <b>Don't forget to click Save after making changes.</b>
      </p>
      <p>You can choose from the following sources:</p>
      <ul>
        <li>Manifest: Get the value for this property directly from the manifest document.</li>
        <li>Invoice: Get the value from the extracted invoice data under the "Textract Results" section.</li>
        <li>Static: Get the value from a static field that you input.</li>
        <li>Null: Choose this option to explicitly ignore this property in the template.</li>
      </ul>
    </div>
  </HelpPanel>
);

export const TextractResultsHelpContent = (
  <HelpPanel header={<h2>Textract Results</h2>}>
    <div>
      <p>
        This section contains the data extracted from the invoice that you can map to specific properties for this
        template.
      </p>
      <h3>Key-Value pairs</h3>
      <p>
        This section contains extracted key-value pairs for each page of the invoice. When editing a property, simply
        click which key-value pair you'd like to associate with that property.
      </p>
      <h3>Tabular Data</h3>
      <p>
        Textract also extracts data from tables. This section contains each extracted table from each page of the
        invoice. When editing a property, simply click the table cell you'd like to associate with that property.
      </p>
    </div>
  </HelpPanel>
);

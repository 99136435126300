import { useCallback, useEffect, useState } from 'react';
import { getInvoiceTextractResults } from 'src/client/api-gateway';
import { PageKeyValues, PageTables, listTextractKeyValues, listTextractTables } from 'src/helpers/textract';

export interface TextractResultsState {
  pageKeyValuesList: Array<PageKeyValues>;
  pageTablesList: Array<PageTables>;
}

export interface UseFetchInvoiceTextractResultsState {
  textractResults: TextractResultsState;
  isLoadingTextractResults: boolean;
  fetchTextractResultsError: string;
  fetchTextractResults: () => void;
}

export const useFetchInvoiceTextractResults = () => {
  const [textractResults, setTextractResults] = useState<TextractResultsState | null>(null);
  const [isLoadingTextractResults, setIsLoadingTextractResults] = useState<boolean>(false);
  const [fetchTextractResultsError, setFetchTextractResultsError] = useState<string>('');

  const fetchTextractResults = useCallback((invoiceId: string) => {
    setIsLoadingTextractResults(true);
    setFetchTextractResultsError('');

    getInvoiceTextractResults(invoiceId)
      .then((results) => {
        // TODO: Migrate logic to parse textract results into key-value pairs and tables
        // from UI to API: https://sim.amazon.com/issues/FootprintData-1681
        const keyValuePairs = listTextractKeyValues(results);
        const pages = listTextractTables(results);
        setTextractResults({ pageKeyValuesList: keyValuePairs, pageTablesList: pages });
      })
      .catch((err) => {
        setFetchTextractResultsError(err);
      })
      .finally(() => {
        setIsLoadingTextractResults(false);
      });
  }, []);

  return {
    textractResults,
    isLoadingTextractResults,
    fetchTextractResultsError,
    fetchTextractResults,
  };
};

import { useCallback, useState } from 'react';
import { Auth, CognitoUser } from '@aws-amplify/auth';

// Prefix prepended to all usernames by returned by `Auth.currentAuthenticatedUser`
const AMAZON_FEDERATE_USERNAME_PREFIX = 'AmazonFederate_';

export interface UseFetchAuthenticatedUserState {
  isFetchingUser: boolean;
  fetchUserCredentials: () => Promise<string | null>;
}

export const useFetchAuthenticatedUser = (): UseFetchAuthenticatedUserState => {
  const [isFetchingUser, setIsFetchingUser] = useState<boolean>(false);

  const fetchUserCredentials = useCallback(async () => {
    setIsFetchingUser(true);

    try {
      // We expect this call to fail if the user hasn't already signed in with Midway (i.e. the user's Cognito
      // credentials aren't cached in localstorage)
      const userCredentials = (await Auth.currentAuthenticatedUser()) as CognitoUser;
      const username = userCredentials.getUsername().replace(AMAZON_FEDERATE_USERNAME_PREFIX, '');
      return username;
    } catch (e) {
      console.error(`There was an error fetching user credentials:`, e);
      return null;
    } finally {
      setIsFetchingUser(false);
    }
  }, []);

  return {
    isFetchingUser,
    fetchUserCredentials,
  };
};

import { Tabs } from '@amzn/awsui-components-react';
import React, { useCallback, useContext } from 'react';
import { PageKeyValues, PageTables, TextractKeyValue } from 'src/helpers/textract';
import { ListTables } from './ListTables';
import { TextractKeyValuePairs } from './TextractKeyValuePairs';
import { CreateTemplateContext } from '../templates/create';
import { APIs, Services, Source, ValueRefTypes } from 'src/interface/type-def';

const TAB_IDS = {
  keyValuePairs: 'keyValuePairs',
  tabularData: 'tabularData',
};

interface TextractResultsContainerProps {
  pageKeyValuesList: Array<PageKeyValues>;
  pageTablesList: Array<PageTables>;
  textractResultsError?: string;
}

export const TextractResultsContainer: React.FC<TextractResultsContainerProps> = ({
  pageKeyValuesList,
  pageTablesList,
  textractResultsError,
}: TextractResultsContainerProps) => {
  const createTemplateContext = useContext(CreateTemplateContext);
  const { subTemplateMapState, setSubTemplateMapState } = createTemplateContext;

  const onApplyKeyValuePair = useCallback(
    (keyValuePair: TextractKeyValue) => {
      // Required to ensure a previously-selected tabular data key is no longer selected
      createTemplateContext.textractResultTableKey = '';
      createTemplateContext.textractResultKey = keyValuePair.key;

      setSubTemplateMapState({
        ...subTemplateMapState,
        source: Source.INVOICE,
        valueRef: {
          ...subTemplateMapState.valueRef,
          type: ValueRefTypes.KeyValue,
          api: APIs.AnalyzeDocument,
          service: Services.Textract,
          page: keyValuePair.pageNumber,
          ref: {
            key: keyValuePair.key,
          },
          tableRef: undefined,
          cellRef: undefined,
        },
      });
    },
    [createTemplateContext]
  );

  // TODO: Refactor this as part of work for https://sim.amazon.com/issues/FootprintData-1792
  const renderedTables = pageTablesList.map((pageTables, idx) => <ListTables key={idx} pageTables={pageTables} />);

  const TableValues =
    renderedTables.length > 0 ? (
      renderedTables
    ) : (
      <p>No data - Make sure the selected Manifest has an invoice if Invoice is your data source</p>
    );

  return (
    <div>
      <Tabs
        tabs={[
          {
            label: 'Key-Value Pairs',
            id: TAB_IDS.keyValuePairs,
            content: (
              <TextractKeyValuePairs
                textractKeyValuePairs={pageKeyValuesList}
                onApplyKeyValuePair={onApplyKeyValuePair}
                errorMessage={textractResultsError}
              />
            ),
          },
          {
            label: 'Tabular Data',
            id: TAB_IDS.tabularData,
            content: TableValues,
          },
        ]}
      />
    </div>
  );
};

/* eslint-disable max-classes-per-file */
import { RecursivePartial } from './genericTypes';
import { PIPELINE_STATUS, PipelineHistory } from './pipeline';
import { PROCESSING_STATUS } from './processingStatus';

// including local Textract types I couldn't find in the AWS SDK
export type DocumentLocation = {
  S3ObjectName: string;
  S3Bucket: string;
};

export type JobSummary = {
  JobId: string;
  Status: string;
  API: string;
  DocumentLocation: DocumentLocation;
};

// application types
export class IManifestDocument {
  accountCode: string = '';

  accountName: string = '';

  accountNumber: string = '';

  accountStatus: string = '';

  amzInvoiceAmt: string = '';

  companyName: string = '';

  country: string = '';

  imageId: string = '';

  invoiceCurrency: string = '';

  invoiceDate: string = '';

  invoiceId: string = '';

  invoiceNumber: string = '';

  ledgerName: string = '';

  locationCode: string = '';

  locationDescription: string = '';

  manifestCsvFilename: string = '';

  modifiedOn: string = '';

  region: string = '';

  runDate: string = '';

  s3FilePath: string = '';

  serviceRelatedTo: string = '';

  serviceType: string = '';

  siteName: string = '';

  siteId: string = '';

  status: string = '';

  supplierNumber: string = '';

  supplierSiteCode: string = '';

  uuidCvam: string = '';

  vendorName: string = '';

  vendorNumber: string = '';
}

// ManifestDocument represents one row of the manifest Excel spreadsheet and is a child property
//   of the InvoiceMetadata class that represents one row in the Manifest table in DynamoDB
export class ManifestDocument extends IManifestDocument {
  constructor(input: any) {
    super();
    // stringify desired properties of input
    Object.keys(input)
      .filter((key) => Object.keys(this).includes(key))
      .forEach((key) => {
        // convert acceptable types of values to string
        if (input[key] instanceof Date) {
          this[key as keyof IManifestDocument] = (input[key] as Date).toISOString();
        } else if (['number', 'boolean'].includes(typeof input[key])) {
          this[key as keyof IManifestDocument] = String(input[key as keyof IManifestDocument]);
        } else if (typeof input[key] === 'string') {
          this[key as keyof IManifestDocument] = input[key as keyof IManifestDocument];
        } else {
          console.log(`discarding input property: ${key} - ${input[key]}`);
        }
      });
    try {
      const invoiceDate = new Date(this.invoiceDate);
    } catch (err) {
      console.error(`Failed to parse string as Date: ${this.invoiceDate}.`);
      // WARNING this check will stop working as intended in 27000 years
      if (this.invoiceDate.length > 4 && this.invoiceDate.length < 8) {
        console.warn(
          'Assuming an Excel date was found with number of days since 1900-01-01 and converting to usable date'
        );
        const defaultExcelStartDate = new Date(1900, 0, 1);
        this.invoiceDate = defaultExcelStartDate
          .setDate(defaultExcelStartDate.getDate() + Number(this.invoiceDate))
          .toString();
      } else {
        // unsure what went wrong with the date, lift up the thrown error
        throw err;
      }
    }
  }
}

// Map of the latest Textract results by API and where the results are stored in S3.
// This can be expanded upon for new APIs (and other feature extraction mechanisms).
export interface LatestResults {
  analyzeDocument: string;
}

// An invoice row from Manifest DynamoDB table
// TODO migrate this and several other models here to a common package to minimize breaking changes
export interface IInvoiceMetadata {
  invoicePdfName: string;
  manifestDocument: ManifestDocument;
  lastStatusDate?: number;
  pipelineStatus?: PIPELINE_STATUS;
  pipelineHistory?: PipelineHistory;
  templatesUsed?: string[]; // list of templateid PKs in templates table
  latestResults?: LatestResults;
  errorLog?: ManifestErrorLog;
  blueprintResultOverride?: InvoiceOverrideMetadata | null;
  // Java Domain Model Fields
  invoiceDate?: number;
  vendorId?: string;
  processingStatus?: PROCESSING_STATUS;
  keystoneBlueprintId?: string;
  lastDocumentInsightGeneratedTime?: number;
  utilityType?: string;
}

export enum ProcessingStatus {
  MISSING_PDF,
  UNPROCESSED,
  PROCESSING,
  MISSING_BLUEPRINT,
  PROCESSING_SUCCESSFUL,
  PROCESSING_FAILED,
  VALIDATION_SUCCESSFUL,
  VALIDATION_FAILED,
}

/**
 * Interface for error logs related to the manifest
 */
export interface ManifestErrorLog {
  requiredAttributesError: string[];
  optionalAttributesError: string[];
  otherError?: string[];
}

export class InvoiceMetadata implements IInvoiceMetadata {
  invoicePdfName = '';

  manifestDocument: ManifestDocument = new ManifestDocument({});

  constructor(input: IInvoiceMetadata) {
    Object.assign(this, input);
  }
}

// application classes
export enum URJANET_ROW_TYPES {
  ACCOUNT = 'ACCOUNT',
  CHARGE = 'CHARGE',
  METER = 'METER',
  USAGE = 'USAGE',
}
export interface IUrjanetRow {
  AccountNumber: string;
  StatementDate: string;
  StatementCreatedDate: string;
  DueByDate: string;
  StatementType: string;
  UsageAmount: string;
  EnergyUnit: string;
  MeasurementType: string;
  RateComponents: string;
  MeterId: string;
  AccountId: string;
  StatementId: string;
  SiteCode: string;
  SiteName: string;
  RowType: string;
  IntervalStart: string;
  IntervalEnd: string;
  UtilityProvider: string;
  ChargeAmount: string;
  ChargeAmountCurrency: string;
  ChargeId: string;
  ChargeRateCurrency: string;
  ChargeUnitsUsed: string;
  ChargeUsageUnit: string;
  MeterNumber: string;
  ServiceType: string;
}
// UrjanetRow based on https://w.amazon.com/bin/view/Sustainability/NetZeroCarbon/NetZeroTech/NZT-Footprint/UtilitiesSourceOfTruth/UrjanetDataIngestion/
export class UrjanetRow implements IUrjanetRow {
  AccountNumber: string = '';

  StatementDate: string = '';

  StatementCreatedDate: string = '';

  DueByDate: string = '';

  StatementType: string = '';

  UsageAmount: string = '';

  EnergyUnit: string = '';

  MeasurementType: string = '';

  RateComponents: string = '';

  MeterId: string = '';

  AccountId: string = '';

  StatementId: string = '';

  SiteCode: string = '';

  SiteName: string = '';

  RowType: string = '';

  IntervalStart: string = '';

  IntervalEnd: string = '';

  UtilityProvider: string = '';

  ChargeAmount: string = '';

  ChargeAmountCurrency: string = '';

  ChargeId: string = '';

  ChargeRateCurrency: string = '';

  ChargeUnitsUsed: string = '';

  ChargeUsageUnit: string = '';

  MeterNumber: string = '';

  ServiceType: string = '';

  // metadata properties
  Separator: string = ',';

  // optionally supports an override separator for use in CSV output. defaults to ','
  constructor(separator?: string) {
    if (separator) this.Separator = separator;
  }

  // combine the csv header and csv row for this object
  toCSVWithHeader(): string {
    return [this.getCSVHeader(), this.toCSV()].join('\n');
  }

  // make a CSV row of this object
  // WARNING this is naive object-to-csv conversion. values containing commas are
  //   surrounded by double quotes. e.g. 'Seattle, WA' => '"Seattle, WA"'
  // WARNING it is assumed the order of columns in output is unimportant,
  //   so long as the output has headers. we use default array sort to ensure the header and
  //   values are in the same order.
  toCSV(): string {
    return this.getSortedKeys()
      .map((x) => this[x as keyof IUrjanetRow]) // translate key to its value
      .map((x) => (x.toString().includes(',') ? `"${x}"` : x)) // naive csv support
      .join(this.Separator);
  }

  // make a CSV header row for this object
  getCSVHeader(): string {
    return this.getSortedKeys().join(this.Separator);
  }

  // get sorted array of this object's property keys, minus meta properties like Separator
  getSortedKeys(): string[] {
    return Object.keys(this)
      .filter((x) => x !== 'Separator')
      .sort();
  }
}

export class Template {
  templateId?: string; // utilityid(#siteid(#accountid))

  validFrom?: number; // yyyymmdd

  updatedDate?: number; // epoch

  updatedBy?: string; // user (name, username, email?)

  utilityId?: string;

  siteId?: string;

  accountId?: string;

  templateMap?: TemplateMap;

  createdFromInvoiceId?: string; // id of the invoice used during template creation

  templateUpdateLog?: TemplateLog[]; // template logs for updates/writes

  constructor(input: Omit<Template, 'templateId'>) {
    Object.assign(this, input);
    // explicitly construct templateid from params
    let templateid = input.utilityId;
    templateid += input.siteId ? `#${input.siteId}` : '';
    templateid += input.siteId && input.accountId ? `#${input.accountId}` : '';
    if (typeof templateid !== 'string') {
      throw new Error('Cannot assign non-string value to Template.templateId');
    } else {
      this.templateId = templateid;
    }
  }
}

export interface TemplateLog {
  username: string;
  timestamp: number; // NOTE: This timestamp is stored in seconds
}

export enum Services {
  Textract = 'Textract',
}

export enum APIs {
  AnalyzeDocument = 'ANALYZE_DOCUMENT',
}

export enum ValueRefTypes {
  KeyValue = 'KeyValue',
  TableValue = 'TableValue',
  // TableOffsetValue = 'TableOffsetValue',
  HeaderBasedTableValue = 'HeaderBasedTableValue',
  StaticValue = 'StaticValue',
  ManifestValue = 'ManifestValue',
}

export const enum Source {
  NONE = 'none',
  MANIFEST = 'manifest',
  INVOICE = 'invoice',
  INVOICE_TABLE = 'invoiceTable',
  STATIC = 'static',
  NULL = 'null',
}

export interface Subterm {
  separator: string;
  startTerm: number;
  endTerm: number;
}

// I dislike that we explicitly name the type as a property, but we need to SerDe the type when working with DynamoDB.
interface GenericTemplateValueRef {
  ref: {
    [key: string]: string | number | number[];
  };
  subterm?: Subterm;
  type: ValueRefTypes;
}

export interface StaticValueRef extends GenericTemplateValueRef {
  type: ValueRefTypes.StaticValue;
  ref: {
    value: string; // static value to use
  };
}

export interface ManifestValueRef extends GenericTemplateValueRef {
  type: ValueRefTypes.ManifestValue;
  ref: {
    value: string; // column name in manifest file
  };
}

interface TextractValueRef extends GenericTemplateValueRef {
  readonly service: Services.Textract;
  api: APIs;
  type: ValueRefTypes.KeyValue | ValueRefTypes.TableValue;
  page?: number;
  ref: {
    [key: string]: string | number | number[];
  };
}

export interface TextractAnalyzeDocumentKeyValueRef extends TextractValueRef {
  readonly type: ValueRefTypes.KeyValue;
  readonly api: APIs.AnalyzeDocument;
  ref: {
    key: string;
  };
}

interface TextractAnalyzeDocumentTableCellValueRef extends TextractValueRef {
  readonly type: ValueRefTypes.TableValue;
  ref: {
    tableNumber: number;
    cell: number[];
  };
}

// Reference to a table cell that represents a header associated with the selected cell
export interface HeaderRef {
  header: string; // Text in cell that represents header
  offset: [number, number]; // [x-coordinate, y-coordinate] offset of header from the table value
}

// Reference to a cell in a table
export interface CellRef {
  cellCoordinate: [number, number]; // [x-coordinate, y-coordinate] of cell
  headerRefs: Array<HeaderRef>; // headers associated with this cell
  cellText?: string; // Used by UI to track text content of cell, for display purposes
}

// Reference to a specific table in a list of tables in an invoice
export interface TableRef {
  tableIndex: number;
  // String(s) that represents text values of headers associated with a cell
  headers: Array<string>;
}

export interface TextractAnalyzeDocumentHeaderBasedTableValueRef {
  readonly service: Services.Textract;
  readonly type: ValueRefTypes.HeaderBasedTableValue;
  readonly api: APIs.AnalyzeDocument;
  page?: number;
  tableRef: TableRef;
  cellRef: CellRef;
  subterm?: Subterm;
}

export interface TemplateValue {
  source?: Source;
  valueRef?:
    | TextractAnalyzeDocumentKeyValueRef
    | TextractAnalyzeDocumentTableCellValueRef
    | TextractAnalyzeDocumentHeaderBasedTableValueRef
    | StaticValueRef
    | ManifestValueRef;
  include: boolean;
}

export type ProcessedTemplateValue = string | number | undefined;

export interface AugmentedTemplateValue extends TemplateValue {
  previewValue: any;
}

export interface Meter {
  meterId?: TemplateValue;
  meterNumber?: TemplateValue;
  serviceType?: TemplateValue;
  usageAmount?: TemplateValue;
  usages: Usage[];
  charges: Charge[];
  tesseractId?: string;
}

export interface Charge {
  chargeAmount?: TemplateValue;
  chargeAmountCurrency?: TemplateValue;
  chargeId?: TemplateValue;
  chargeRateCurrency?: TemplateValue;
  chargeUnitsUsed?: TemplateValue;
  chargeUsageUnit?: TemplateValue;
  tesseractId?: string;
}

export interface Usage {
  usageAmount?: TemplateValue;
  energyUnit?: TemplateValue;
  measurementType?: TemplateValue;
  rateComponents?: TemplateValue;
  tesseractId?: string;
}

export interface Account {
  accountNumber: TemplateValue;
  statementDate: TemplateValue;
  statementCreatedDate: TemplateValue;
  dueByDate: TemplateValue;
  statementType: TemplateValue;
  accountId: TemplateValue;
  statementId: TemplateValue;
  siteCode: TemplateValue;
  siteName: TemplateValue;
  intervalStart: TemplateValue;
  intervalEnd: TemplateValue;
  utilityProvider: TemplateValue;
}

export interface TemplateMap {
  account: Account;
  charges: Array<Charge>;
  meters: Array<Meter>;
}

export interface PartialTemplateMap {
  account: RecursivePartial<Account>;
  charges: Array<RecursivePartial<Charge>>;
  meters: Array<RecursivePartial<Meter>>;
}

export type InvoiceOverrideValue = string;
export type ProcessedInvoiceValue = string;

// TODO: import these types from a Smithy interstitiary
export type ProcessedInvoiceAccount = {
  accountId: ProcessedInvoiceValue;
  billStart: ProcessedInvoiceValue;
  billEnd: ProcessedInvoiceValue;
  totalChargeAmount: ProcessedInvoiceValue;
  chargeCurrency: ProcessedInvoiceValue;
  totalUsageAmount: ProcessedInvoiceValue;
  usageUnit: ProcessedInvoiceValue;
};

export type ProcessedInvoiceMeter = {
  meterId: ProcessedInvoiceValue;
  meterType: ProcessedInvoiceValue;
  usageAmount: ProcessedInvoiceValue; // we delete this before sending in POST
  usageUnit: ProcessedInvoiceValue;
  readFromDate: ProcessedInvoiceValue;
  readToDate: ProcessedInvoiceValue;
  chargeAmount: ProcessedInvoiceValue;
  chargeCurrency: ProcessedInvoiceValue;
};

export type ProcessedInvoiceData = {
  account: ProcessedInvoiceAccount;
  meters: Array<ProcessedInvoiceMeter>;
};

export interface InvoiceOverrideProvenance {
  userId: ProcessedInvoiceValue;
  overrideTs: number;
}

export interface InvoiceOverrideMetadata {
  invoiceId: string;
  blueprintResult: ProcessedInvoiceData;
  overrideProvenance: InvoiceOverrideProvenance;
}

export interface InvoiceOverrideMap {
  invoiceDataOverride: InvoiceOverrideMetadata;
}

// TODO: consume these as exported TS types from Tesseract BE package
export enum INVOICE_QUERY_KEYS {
  keystoneProcessingStatus = 'keystone_processing_status',
  templateId = 'template_id',
  utilityId = 'vendor_number',
  utilityType = 'utility_type',
  lastEvaluatedKey = 'last_evaluated_key',
  limit = 'limit',
}

export type InvoiceQueryKey = keyof typeof INVOICE_QUERY_KEYS;
export type InvoiceQueryValue = string | number;
export type GetInvoiceProps = {
  [key in INVOICE_QUERY_KEYS]?: InvoiceQueryValue;
};
export type InvoiceQueryArgs = Partial<Record<INVOICE_QUERY_KEYS, InvoiceQueryValue>>;

import { Box, Popover } from '@amzn/awsui-components-react';
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';
import React from 'react';
import { PIPELINE_STATUS, PIPELINE_STATUS_DESCRIPTIONS, PIPELINE_STATUS_TYPES } from 'src/interface/pipeline';
import { toSentenceCase } from 'src/utils/strings';

interface PipelineStatusIndicatorProps {
  status?: PIPELINE_STATUS;
  renderPopover?: boolean;
}

export const PipelineStatusIndicator = ({ status, renderPopover }: PipelineStatusIndicatorProps) => {
  if (!status) return <span>No status found</span>;

  const statusIndicator = (
    <StatusIndicator type={PIPELINE_STATUS_TYPES[status]}>{toSentenceCase(status)}</StatusIndicator>
  );

  return renderPopover ? (
    <Box>
      <Popover header="Pipeline Status" content={PIPELINE_STATUS_DESCRIPTIONS[status]}>
        {statusIndicator}
      </Popover>
    </Box>
  ) : (
    statusIndicator
  );
};

import { Box, Button, Header, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { IInvoiceMetadata } from 'src/interface/type-def';
import { InvoiceSelectionTable } from './InvoiceSelectionTable';

// Maximum number of invoices a user can select to reprocess during template verification
export const MAX_ALLOWED_SELECTED_INVOICES = 10;

interface InvoiceSelectionModalProps {
  isVisible: boolean;
  onDismiss: () => void;
  isLoadingInvoices: boolean;
  allInvoices: Array<IInvoiceMetadata>;
  selectedInvoiceIds: Array<string>;
  onAddInvoice: (invoiceId: string) => void;
  onRemoveInvoice: (invoiceId: string) => void;
}

export const InvoiceSelectionModal = ({
  isVisible,
  onDismiss,
  isLoadingInvoices,
  allInvoices,
  selectedInvoiceIds,
  onAddInvoice,
  onRemoveInvoice,
}: InvoiceSelectionModalProps) => (
  <Modal
    data-testid="invoice-selection-modal"
    visible={isVisible}
    size="max"
    onDismiss={onDismiss}
    header={<Header variant="h2">Select up to {MAX_ALLOWED_SELECTED_INVOICES} invoices to reprocess</Header>}
    footer={
      <Box float="right">
        <SpaceBetween direction="horizontal" size="xs">
          <Button variant="link" onClick={onDismiss}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onDismiss}>
            Done
          </Button>
        </SpaceBetween>
      </Box>
    }
  >
    <SpaceBetween direction="vertical" size="s">
      <Box variant="p">
        Selected invoices: {selectedInvoiceIds.length}/{MAX_ALLOWED_SELECTED_INVOICES}
      </Box>
      <InvoiceSelectionTable
        isLoadingInvoices={isLoadingInvoices}
        allInvoices={allInvoices}
        selectedInvoiceIds={selectedInvoiceIds}
        onAddInvoice={onAddInvoice}
        onRemoveInvoice={onRemoveInvoice}
      />
    </SpaceBetween>
  </Modal>
);

import { Box, Button, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { IInvoiceMetadata } from 'src/interface/type-def';

interface ReprocessInvoicesForTemplateModalProps {
  isVisible: boolean;
  onDismiss: () => void;
  onReprocessAll: () => void;
  allInvoices: Array<IInvoiceMetadata>;
  isSubmittingReprocessRequest: boolean;
}

export const ReprocessInvoicesForTemplateModal = ({
  isVisible,
  onDismiss,
  onReprocessAll,
  allInvoices,
  isSubmittingReprocessRequest,
}: ReprocessInvoicesForTemplateModalProps) => (
  <Modal
    visible={isVisible}
    onDismiss={onDismiss}
    header={`Reprocess all ${allInvoices.length} invoices?`}
    footer={
      <Box float="right">
        <SpaceBetween direction="horizontal" size="xs">
          <Button variant="link" onClick={onDismiss} loading={isSubmittingReprocessRequest}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onReprocessAll} loading={isSubmittingReprocessRequest}>
            Reprocess all
          </Button>
        </SpaceBetween>
      </Box>
    }
  >
    {`Tesseract will reprocess all ${allInvoices.length} invoices that this template applies to. It may take several minutes for all invoices to finish reprocesssing.`}
  </Modal>
);

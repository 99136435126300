import React from 'react';
import { BlueprintConfiguration } from '../types';
import { ColumnLayout, Container, Header, SpaceBetween } from '@amzn/awsui-components-react';
import { ValueWithLabel } from 'src/components/common-components/keyValuePair/ValueWithLabel';
import { getDatePickerValueFromTimestamp } from 'src/utils/datePickerUtils';
import { BlueprintConfigurationSummary } from 'src/components/blueprints/BlueprintConfigurationSummary';

interface BlueprintSummaryProps {
  blueprint: BlueprintConfiguration;
}

export const BlueprintSummary = ({ blueprint }: BlueprintSummaryProps) => (
  <SpaceBetween direction="vertical" size="m">
    <Container header={<Header variant="h2">Blueprint Metadata</Header>}>
      <ColumnLayout columns={3} variant="text-grid">
        <ValueWithLabel
          label="Blueprint name"
          labelDataTestId="blueprint-name-label"
          valueDataTestId="blueprint-name-value"
        >
          {blueprint.blueprintName}
        </ValueWithLabel>

        <ValueWithLabel
          label="Blueprint description"
          labelDataTestId="blueprint-description-label"
          valueDataTestId="blueprint-description-value"
        >
          {blueprint.blueprintDescription}
        </ValueWithLabel>

        <ValueWithLabel label="Valid from" labelDataTestId="valid-from-label" valueDataTestId="valid-from-value">
          {getDatePickerValueFromTimestamp(blueprint.validFrom)}
        </ValueWithLabel>
      </ColumnLayout>
    </Container>

    <Container header={<Header variant="h2">Blueprint Schema</Header>}>
      <BlueprintConfigurationSummary blueprintConfigurationSettings={blueprint.blueprintConfigurationSettings} />
    </Container>
  </SpaceBetween>
);

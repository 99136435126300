import { DatePicker, FormField } from '@amzn/awsui-components-react';
import React, { useMemo } from 'react';

// The Polaris `DatePicker` component expects time to be formatted like: `YYYY-MM-DD`.
const DEFAULT_TEMPLATE_VALID_FROM_DATE = '1970-01-01';

interface TemplateValidFromDateSelectProps {
  validFromTimestamp?: number;
  onDateChange: (value: string) => void;
}

export const TemplateValidFromDateSelect = ({ validFromTimestamp, onDateChange }: TemplateValidFromDateSelectProps) => {
  const datePickerValue = useMemo(() => {
    if (!validFromTimestamp) return DEFAULT_TEMPLATE_VALID_FROM_DATE;

    const validFromDateAsString = new Date(validFromTimestamp).toISOString();
    // DatePicker component expects the format YYYY-MM-DD
    return validFromDateAsString.split('T')[0];
  }, [validFromTimestamp]);

  return (
    <FormField
      label="Valid from date"
      description="This template will be applied to invoices created on or after this date"
      constraintText="Defaults to 1970-01-01 (YYYY-MM-DD)"
    >
      <DatePicker locale="en-US" onChange={(e) => onDateChange(e.detail.value)} value={datePickerValue} />
    </FormField>
  );
};

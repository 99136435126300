import { useCallback, useReducer, useRef, useState } from 'react';
import {
  InvoiceOverrideMetadata,
  ProcessedInvoiceAccount,
  ProcessedInvoiceData,
  ProcessedInvoiceMeter,
  ProcessedInvoiceValue,
} from 'src/interface/type-def';
import { useFetchAuthenticatedUser } from 'src/components/auth/hooks/useFetchAuthenticatedUser';
import { postInvoiceOverride } from 'src/client/api-gateway';
import { formDateToISO, formDateToEpochSeconds } from 'src/utils/dates';

type AccountFieldName = keyof ProcessedInvoiceAccount;
type MeterFieldName = keyof ProcessedInvoiceMeter;

type AccountErrorFields = Set<AccountFieldName>;
type MeterErrorFields = Array<Set<MeterFieldName>>;

type AccountFormFieldState = {
  [k in keyof ProcessedInvoiceAccount]: ProcessedInvoiceValue;
};

type MeterFormFieldState = {
  [k in keyof ProcessedInvoiceMeter]: ProcessedInvoiceValue;
};

type ErrorState<T> = { fields: T; count: number };
type AccountErrorsState = ErrorState<AccountErrorFields>;
type MeterErrorsState = ErrorState<MeterErrorFields>;

type SetFieldProps<T = string> = {
  name: T;
  value: ProcessedInvoiceValue;
};

type SetMultiFieldProps<T = string> = SetFieldProps<T> & {
  index: number;
};

export type SetAccountFieldProps = SetFieldProps<AccountFieldName>;

export type SetMeterFieldProps = SetMultiFieldProps<MeterFieldName>;

type SetErrorCommonProps<T = string> = {
  name: T;
  invalid: boolean;
};

type SetMultiFieldErrorProps<T = string> = SetErrorCommonProps<T> & {
  index: number;
};

export type SetAccountErrorProps = SetErrorCommonProps<AccountFieldName>;
export type SetMeterErrorProps = SetMultiFieldErrorProps<MeterFieldName>;

type AddFieldProps = unknown;
type RemoveFieldProps = { index: number };

export type AddMeterFieldProps = AddFieldProps;
export type RemoveMeterFieldProps = RemoveFieldProps;

interface InvoiceOverrideFormState {
  account: AccountFormFieldState;
  meters: Array<MeterFormFieldState>;
  accountErrors: AccountErrorsState;
  meterErrors: MeterErrorsState;
}

export interface UpdateInvoiceOverrideFormState {
  handleSubmit: (event: any) => Promise<void>;
  handleReset: () => void;

  isLoading: boolean;
  isSubmitting: boolean;
  isSubmitDisabled: boolean;
  isSubmitSuccess: boolean;

  accountFields: ProcessedInvoiceAccount;
  meterFields: Array<ProcessedInvoiceMeter>;

  accountErrors: AccountErrorsState['fields'];
  accountErrorCount: number;
  meterErrors: MeterErrorsState['fields'];
  meterErrorCount: number;

  setAccountValue: (field: SetAccountFieldProps) => void;
  setMeterValue: (field: SetMeterFieldProps) => void;

  setAccountError: (field: SetAccountErrorProps) => void;
  setMeterError: (field: SetMeterErrorProps) => void;

  addMeter: (args?: AddMeterFieldProps) => void;
  removeMeter: (args: RemoveMeterFieldProps) => void;
}

export interface UseHydrateInvoiceOverrideFormProps {
  values: ProcessedInvoiceData;
  invoiceId: string;
  onSubmitSuccess?: () => void;
  onSubmitError?: (error?: string) => void;
}

// TODO: these required field sets should probably live in some HOC
// for template + invoice form state management
export const RequiredAccountFields = new Set<AccountFieldName>(['accountId']);

export const RequiredMeterFields = new Set<MeterFieldName>(['meterId', 'meterType']);

const initializeAccountValues = (account: ProcessedInvoiceAccount): ProcessedInvoiceAccount => ({
  ...account,
  billStart: formDateToISO(account?.billStart),
  billEnd: formDateToISO(account?.billEnd),
});

const initializeMeterValues = (meters: Array<ProcessedInvoiceMeter>): Array<ProcessedInvoiceMeter> =>
  meters.map((meter) => {
    meter.meterId = meter?.meterId?.toString() ?? '';
    meter.meterType = meter?.meterType?.toString() ?? '';
    meter.usageUnit = meter?.usageUnit?.toString() ?? '';
    meter.readFromDate = formDateToISO(meter.readFromDate);
    meter.readToDate = formDateToISO(meter.readToDate);
    meter.chargeAmount = meter?.chargeAmount?.toString() ?? '';
    meter.chargeCurrency = meter?.chargeCurrency?.toString() ?? '';
    return meter;
  });

const initializeAccountErrors = (account: ProcessedInvoiceAccount): AccountErrorsState => {
  const accountErrors: AccountErrorsState = { fields: new Set(), count: 0 };
  Object.entries(account).forEach(([name, value]) => {
    const fieldName = name as AccountFieldName;
    if (RequiredAccountFields.has(fieldName) && value?.length < 1) {
      accountErrors.fields.add(fieldName);
      accountErrors.count += 1;
    }
  });
  return accountErrors;
};

const initializeMeterErrors = (initialMeters: Array<ProcessedInvoiceMeter>) => {
  const meters = initializeMeterValues(initialMeters);
  const metersErrors: MeterErrorsState = { fields: [], count: 0 };
  meters.forEach((meter, index) => {
    const fields = new Set<MeterFieldName>();
    Object.entries(meter).forEach(([name, value]) => {
      const fieldName = name as MeterFieldName;
      if (RequiredMeterFields.has(fieldName) && value?.length < 1) fields.add(name as MeterFieldName);
    });

    metersErrors.fields[index] = fields;
    metersErrors.count += fields.size;
  });
  return metersErrors;
};

enum ActionTypes {
  SET_ACCOUNT_VALUE = 'SET_ACCOUNT_VALUE',
  SET_ACCOUNT_ERROR = 'SET_ACCOUNT_ERROR',
  SET_METER_VALUE = 'SET_METER_VALUE',
  SET_METER_ERROR = 'SET_METER_ERROR',
  ADD_METER = 'ADD_METER',
  REMOVE_METER = 'REMOVE_METER',
  RESET_ALL = 'RESET_ALL',
}

interface SetAccountValueAction {
  type: ActionTypes.SET_ACCOUNT_VALUE;
  name: AccountFieldName;
  value: ProcessedInvoiceValue;
}

interface SetAccountErrorAction {
  type: ActionTypes.SET_ACCOUNT_ERROR;
  name: AccountFieldName;
  invalid: boolean;
}

interface SetMeterValueAction {
  type: ActionTypes.SET_METER_VALUE;
  name: MeterFieldName;
  value: ProcessedInvoiceValue;
  index: number;
}

interface SetMeterErrorAction {
  type: ActionTypes.SET_METER_ERROR;
  name: MeterFieldName;
  invalid: boolean;
  index: number;
}

interface AddMeterAction {
  type: ActionTypes.ADD_METER;
}

interface RemoveMeterAction {
  type: ActionTypes.REMOVE_METER;
  index: number;
}

interface ResetAllAction {
  type: ActionTypes.RESET_ALL;
  values: ProcessedInvoiceData;
}

type InvoiceOverrideFormAction =
  | SetAccountValueAction
  | SetAccountErrorAction
  | SetMeterValueAction
  | SetMeterErrorAction
  | AddMeterAction
  | RemoveMeterAction
  | ResetAllAction;

const createInitialReducerState = (initialValues: ProcessedInvoiceData): InvoiceOverrideFormState => {
  const account = initializeAccountValues(initialValues.account);
  const meters = initializeMeterValues(initialValues.meters);

  const accountErrors = initializeAccountErrors(account);
  const meterErrors = initializeMeterErrors(meters);

  return {
    account,
    meters,
    accountErrors,
    meterErrors,
  };
};

const InvoiceOverrideFormFieldReducer = (
  state: InvoiceOverrideFormState,
  action: InvoiceOverrideFormAction
): InvoiceOverrideFormState => {
  const { type } = action;
  switch (type) {
    case ActionTypes.SET_ACCOUNT_VALUE: {
      const { name, value } = action;
      return {
        ...state,
        account: {
          ...state.account,
          [name]: value,
        },
      };
    }
    case ActionTypes.SET_ACCOUNT_ERROR: {
      const { name, invalid } = action;
      const previouslyInvalid = state.accountErrors.fields.has(name) ?? false;
      // if the state hasn't changed, don't mutate
      if ((invalid && previouslyInvalid) || (!invalid && !previouslyInvalid)) return state;

      const updatedFields = new Set(Array.from(state.accountErrors.fields));
      invalid ? updatedFields.add(name) : updatedFields.delete(name);
      return {
        ...state,
        accountErrors: {
          ...state.accountErrors,
          fields: updatedFields,
          count: updatedFields.size,
        },
      };
    }
    case ActionTypes.SET_METER_VALUE: {
      const { name, value, index } = action;
      return {
        ...state,
        meters: state.meters.map((meter, idx) => {
          if (idx !== index) return meter;
          return {
            ...meter,
            [name]: value,
          };
        }),
      };
    }
    case ActionTypes.SET_METER_ERROR: {
      const { name, invalid, index } = action;
      const previouslyInvalid = state.meterErrors.fields[index]?.has(name) ?? false;
      // if the state hasn't changed, don't mutate
      if ((invalid && previouslyInvalid) || (!invalid && !previouslyInvalid)) return state;

      const countMod = invalid ? 1 : -1;
      return {
        ...state,
        meterErrors: {
          ...state.meterErrors,
          fields: state.meterErrors.fields.map((meter, idx) => {
            if (idx !== index) return meter;
            invalid ? meter.add(name) : meter.delete(name);
            return meter;
          }),
          count: state.meterErrors.count + countMod,
        },
      };
    }
    case ActionTypes.ADD_METER: {
      return {
        ...state,
        meters: [
          ...state.meters,
          {
            meterId: '',
            meterType: '',
            usageAmount: '',
            usageUnit: '',
            readFromDate: '',
            readToDate: '',
            chargeAmount: '',
            chargeCurrency: '',
          },
        ],
        meterErrors: {
          ...state.meterErrors,
          fields: [...state.meterErrors.fields, new Set(Array.from(RequiredMeterFields))],
          count: state.meterErrors.count + RequiredMeterFields.size,
        },
      };
    }
    case ActionTypes.REMOVE_METER: {
      const { index } = action;
      const removedMeterErrorCount = state.meterErrors.fields[index]?.size ?? 0;

      return {
        ...state,
        meters: state.meters.filter((meter, idx) => idx !== index),
        meterErrors: {
          ...state.meterErrors,
          fields: state.meterErrors.fields.filter((meter, idx) => idx !== index),
          count: state.meterErrors.count - removedMeterErrorCount,
        },
      };
    }
    case ActionTypes.RESET_ALL: {
      const { values } = action;
      return createInitialReducerState(values);
    }
    default:
      return state;
  }
};

export const useHydrateInvoiceOverrideForm = ({
  values,
  invoiceId,
  onSubmitSuccess = () => {},
  onSubmitError = (error?: string) => {},
}: UseHydrateInvoiceOverrideFormProps): UpdateInvoiceOverrideFormState => {
  const { account, meters } = values;

  const [formState, formDispatch] = useReducer(InvoiceOverrideFormFieldReducer, values, createInitialReducerState);

  const initialValuesRef = useRef<string>(JSON.stringify(values));

  const { isFetchingUser, fetchUserCredentials } = useFetchAuthenticatedUser();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState<boolean>(false);

  const setAccountValue = useCallback((props: SetAccountFieldProps) => {
    formDispatch({ type: ActionTypes.SET_ACCOUNT_VALUE, ...props });
  }, []);

  const setAccountError = useCallback((props: SetAccountErrorProps) => {
    formDispatch({ type: ActionTypes.SET_ACCOUNT_ERROR, ...props });
  }, []);

  const setMeterValue = useCallback((props: SetMeterFieldProps) => {
    formDispatch({ type: ActionTypes.SET_METER_VALUE, ...props });
  }, []);

  const setMeterError = useCallback((props: SetMeterErrorProps) => {
    formDispatch({ type: ActionTypes.SET_METER_ERROR, ...props });
  }, []);

  const addMeter = useCallback((props: AddMeterFieldProps) => {
    formDispatch({ type: ActionTypes.ADD_METER });
  }, []);

  const removeMeter = useCallback((props: RemoveMeterFieldProps) => {
    formDispatch({ type: ActionTypes.REMOVE_METER, ...props });
  }, []);

  const handleReset = () => {
    const initialValues: ProcessedInvoiceData = JSON.parse(initialValuesRef.current);
    formDispatch({ type: ActionTypes.RESET_ALL, values: initialValues });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!isSubmitDisabled) {
      setIsSubmitting(true);
      try {
        const user = await fetchUserCredentials();
        if (user === null) throw new Error('Could not fetch user alias');
        const formValues: ProcessedInvoiceData = {
          account: {
            ...formState.account,
            billStart: formDateToEpochSeconds(formState.account?.billStart),
            billEnd: formDateToEpochSeconds(formState.account?.billEnd),
          },
          meters: formState.meters.map((meter) => ({
            ...meter,
            readFromDate: formDateToEpochSeconds(meter.readFromDate),
            readToDate: formDateToEpochSeconds(meter.readToDate),
          })),
        };

        const overrideBody: InvoiceOverrideMetadata = {
          overrideProvenance: {
            userId: user,
            overrideTs: Date.now(),
          },
          blueprintResult: formValues,
          invoiceId,
        };

        const res = await postInvoiceOverride(overrideBody);

        setIsSubmitSuccess(true);
        onSubmitSuccess();
      } catch (err) {
        console.log('error submitting invoice override: ', err);
        setIsSubmitSuccess(false);
        onSubmitError((err as string).toString());
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const isSubmitDisabled = formState.accountErrors.count > 0 || formState.meterErrors.count > 0;

  return {
    handleSubmit,
    handleReset,
    isLoading,
    isSubmitting,
    isSubmitDisabled,
    isSubmitSuccess,
    accountFields: formState.account,
    meterFields: formState.meters,
    accountErrors: formState.accountErrors.fields,
    accountErrorCount: formState.accountErrors.count,
    meterErrors: formState.meterErrors.fields,
    meterErrorCount: formState.meterErrors.count,
    setAccountValue,
    setMeterValue,
    setAccountError,
    setMeterError,
    addMeter,
    removeMeter,
  };
};

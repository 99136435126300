import { useCollection } from '@amzn/awsui-collection-hooks';
import { Alert, Button, Header, Table, TableProps, TextFilter } from '@amzn/awsui-components-react';
import React, { useMemo } from 'react';
import { PageKeyValues, TextractKeyValue } from 'src/helpers/textract';
import { filterDuplicateAndEmptyTextractKeys } from './textractUtils';
import { TextWithBadge } from '../common-components/text/TextWithBadge';

const MIN_COLUMN_WIDTH_PX = 125;

const getPageNumberTableRow = (pageNumber: number): TextractKeyValueTableRow => ({
  key: `Page ${pageNumber}`,
  value: '',
  pageNumber,
  isPageNumber: true,
});

const isSelectedRow = (
  item: TextractKeyValueTableRow,
  selectedKey?: {
    keyName: string;
    pageNumber: number;
  }
) => item.key === selectedKey?.keyName && item.pageNumber === selectedKey?.pageNumber;

interface TextractKeyValueTableRow extends TextractKeyValue {
  isPageNumber?: boolean;
}

export interface TextractKeyValuePairsProps {
  textractKeyValuePairs: Array<PageKeyValues>;
  onApplyKeyValuePair: (keyValue: TextractKeyValue) => void;
  errorMessage?: string;
  selectedKey?: {
    keyName: string;
    pageNumber: number;
  };
}

export const TextractKeyValuePairs = ({
  textractKeyValuePairs,
  onApplyKeyValuePair,
  errorMessage,
  selectedKey,
}: TextractKeyValuePairsProps) => {
  const tableItems: Array<TextractKeyValueTableRow> = useMemo(() => {
    let items: Array<TextractKeyValueTableRow> = [];

    textractKeyValuePairs.forEach((page) => {
      const { keyValues, pageNumber } = page;
      // Add row that renders this page number
      items.push(getPageNumberTableRow(pageNumber));

      const dedupedTextractKeys = filterDuplicateAndEmptyTextractKeys(keyValues).sort((keyA, keyB) =>
        keyA.key.localeCompare(keyB.key)
      );
      items = items.concat(dedupedTextractKeys);
    });

    return items;
  }, [textractKeyValuePairs]);

  const { items, filteredItemsCount, filterProps, collectionProps } = useCollection(tableItems, {
    filtering: {
      empty: 'No keys found for this invoice.',
      noMatch: 'No matching keys found.',
    },
  });

  const columnDefs: Array<TableProps.ColumnDefinition<TextractKeyValueTableRow>> = [
    {
      id: 'key',
      header: 'Key',
      cell: (item: TextractKeyValueTableRow) => {
        if (item.isPageNumber) return <b>{item.key}</b>;
        if (isSelectedRow(item, selectedKey)) {
          return <TextWithBadge text={item.key} badgeColor="blue" badgeText="Selected" />;
        }

        return item.key;
      },
      minWidth: selectedKey
        ? 215 // For most key names, ensures the cell containing both the name and "Selected" badge will not wrap
        : MIN_COLUMN_WIDTH_PX,
    },
    {
      id: 'value',
      header: 'Value',
      cell: (item: TextractKeyValueTableRow) => item.value,
      minWidth: MIN_COLUMN_WIDTH_PX,
    },
    {
      id: 'apply',
      header: 'Apply',
      cell: (item: TextractKeyValueTableRow) => {
        if (item.isPageNumber) return null;
        if (isSelectedRow(item, selectedKey)) {
          return (
            <Button
              data-testid="apply-key-value-pair-btn--disabled"
              variant="inline-link"
              ariaLabel="Applied key"
              disabled
            >
              Applied
            </Button>
          );
        }

        return (
          <Button
            data-testid="apply-key-value-pair-btn"
            variant="inline-link"
            ariaLabel="Apply key to property"
            onClick={(e) => {
              e.preventDefault();
              onApplyKeyValuePair(item);
            }}
          >
            Apply
          </Button>
        );
      },
      minWidth: MIN_COLUMN_WIDTH_PX,
    },
  ];

  if (errorMessage) {
    return (
      <Alert type="error" header="Error loading invoice data">
        There was an error loading key-value pairs for this invoice: ${errorMessage}
      </Alert>
    );
  }

  return (
    <Table
      {...collectionProps}
      header={
        <Header
          counter={
            filterProps.filteringText ? `(${filteredItemsCount}/${tableItems.length})` : `(${tableItems.length})`
          }
        >
          Key-Value pairs
        </Header>
      }
      columnDefinitions={columnDefs}
      items={items}
      filter={<TextFilter {...filterProps} filteringPlaceholder="Filter by key or value" />}
      stickyColumns={{ first: 0, last: 1 }}
      wrapLines
    />
  );
};

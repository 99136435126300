import React from 'react';
import { ValueWithLabel } from 'src/components/common-components/keyValuePair/ValueWithLabel';

interface TabularDataCellFieldProps {
  cellText: string;
}

export const TabularDataCellField = ({ cellText }: TabularDataCellFieldProps) => (
  <ValueWithLabel
    label="Selected cell"
    labelDataTestId="tabular-data-cell-label"
    valueDataTestId="tabular-data-cell-value"
  >
    {cellText || <i>No table cell selected</i>}
  </ValueWithLabel>
);

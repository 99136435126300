import { useCallback, useState } from 'react';
import { reprocessInvoicesForBlueprint } from 'src/client/api-gateway';

export const useReprocessInvoicesForBlueprint = () => {
  const [isReprocessRequestLoading, setIsReprocessRequestLoading] = useState<boolean>(false);
  const [reprocessRequestError, setReprocessRequestError] = useState<string>('');
  const [failedInvoiceIds, setFailedInvoiceIds] = useState<Array<string>>([]);

  const reprocessAllInvoices = useCallback(async (invoiceIds: Array<string>) => {
    setIsReprocessRequestLoading(true);
    setFailedInvoiceIds([]);

    try {
      const reprocessInvoicesResponse = await reprocessInvoicesForBlueprint(invoiceIds);
      const { failedInvoiceIds } = reprocessInvoicesResponse;
      setFailedInvoiceIds(failedInvoiceIds);
      return failedInvoiceIds;
    } catch (e) {
      const errorMsg = e as string;
      setReprocessRequestError(errorMsg);
      throw new Error(errorMsg);
    } finally {
      setIsReprocessRequestLoading(false);
    }
  }, []);

  return {
    isReprocessRequestLoading,
    reprocessAllInvoices,
    failedInvoiceIds,
    reprocessRequestError,
  };
};

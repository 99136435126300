// The Polaris `DatePicker` component expects time to be formatted like: `YYYY-MM-DD`.
const DEFAULT_BLUEPRINT_VALID_FROM_DATE = '2000-01-01';

/**
 * Converts the given timestamp in epoch seconds (ex. a blueprint's `validFrom` date) to a string in the format
 * expected by Cloudscape's DatePicker component.
 * @param timestamp time in epoch seconds
 * @returns string representation of the date
 */
export const getDatePickerValueFromTimestamp = (timestamp?: number): string => {
  if (!timestamp) return DEFAULT_BLUEPRINT_VALID_FROM_DATE;
  const validFromDateAsString = new Date(timestamp * 1000).toISOString();

  // DatePicker component expects the format YYYY-MM-DD
  return validFromDateAsString.split('T')[0];
};

export const getDefaultBlueprintValidFromDate = () => {
  const date = new Date(DEFAULT_BLUEPRINT_VALID_FROM_DATE);
  return date.getTime() / 1000;
};

/**
 * This is a helper function to convert milliseconds to seconds.
 * @param time
 * @returns time in seconds
 */
export const convertMilliSecondsToSeconds = (time: number) => {
  // The default date in milliseconds since it is already in miliseconds
  const defaultValidFromMilliseconds = new Date(DEFAULT_BLUEPRINT_VALID_FROM_DATE).getTime();

  // If we have the default date then there is no need to convert to seconds
  if (time === defaultValidFromMilliseconds) {
    return time;
  }
  // Convert to seconds
  return Math.abs(time / 1000);
};

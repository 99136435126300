import { useCallback, useState } from 'react';
import { reprocessInvoicesForBlueprint } from 'src/client/api-gateway';

export interface UseReprocessInvoicesState {
  isLoading: boolean;
  reprocessInvoices: (invoiceIds: Array<string>, isTemplateVerification: boolean) => Promise<void>;
}

export function useReprocessInvoices() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const reprocessInvoices = useCallback(async (invoiceIds: Array<string>, isTemplateVerification: boolean = false) => {
    setIsLoading(true);
    try {
      await reprocessInvoicesForBlueprint(invoiceIds);
    } catch (e) {
      setErrorMessage('There was an error reprocessing these invoices');
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    isLoading,
    reprocessInvoices,
  };
}

import { Box, Button, ColumnLayout, Container, Header, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { IInvoiceMetadata, IManifestDocument, Template } from 'src/interface/type-def';
import { numericDate } from '../../../utils/dates';
import { TemplateMapSummary } from '../template-map-summary';

interface ReviewAndCreateProps {
  setActiveStepIndex: Function;
  newTemplate: Template;
  manifest?: IInvoiceMetadata;
}

export default function ReviewAndCreate(props: ReviewAndCreateProps) {
  const { setActiveStepIndex, newTemplate } = props;

  return (
    <SpaceBetween size="xs">
      <Container
        header={
          <Header variant="h2" actions={<Button onClick={() => setActiveStepIndex(0)}>Edit</Button>}>
            New Template Details
          </Header>
        }
      >
        <ColumnLayout
          columns={3}
          variant="text-grid"
          // todo: Create a reusable component to display this list within the column
        >
          <div>
            <Box variant="small">Utility Provider</Box>
            <div>{newTemplate.utilityId}</div>
          </div>
          <div>
            <Box variant="small">Site</Box>
            <div>{newTemplate.siteId}</div>
          </div>
          <div>
            <Box variant="small">Account</Box>
            <div>{newTemplate.accountId}</div>
          </div>
          <div>
            <Box variant="small">Invoice Used (ID)</Box>
            <div>{newTemplate.createdFromInvoiceId}</div>
          </div>
          <div>
            <Box variant="small">Valid Date</Box>
            <div>{numericDate(newTemplate.validFrom) || ''}</div>
          </div>
        </ColumnLayout>
      </Container>
      <Container header={<Header>Template Map Summary</Header>}>
        <TemplateMapSummary templateMap={newTemplate.templateMap!} invoice={null} textractResults={null} />
      </Container>
    </SpaceBetween>
  );
}

import { CURRENCY, USAGE_UNIT, SERVICE_TYPE } from 'src/components/common-components/form/constants';
import { TEMPLATE_VALUE_FIELD_TYPE, WaterUtilityTemplateDefinition } from './WaterTemplateConfiguration';

// TODO: This will eventually be returned by a dedicated API, rather than hardcoded here.
export const WATER_TEMPLATE_DEFINITION: WaterUtilityTemplateDefinition = {
  account: {
    fieldType: TEMPLATE_VALUE_FIELD_TYPE.Object,
    nestedFields: {
      accountId: {
        fieldType: TEMPLATE_VALUE_FIELD_TYPE.Standard,
      },
      locationCode: {
        fieldType: TEMPLATE_VALUE_FIELD_TYPE.Standard,
      },
      siteName: {
        fieldType: TEMPLATE_VALUE_FIELD_TYPE.Standard,
      },
      statementDate: {
        fieldType: TEMPLATE_VALUE_FIELD_TYPE.Standard,
      },
      intervalStart: {
        fieldType: TEMPLATE_VALUE_FIELD_TYPE.Standard,
      },
      intervalEnd: {
        fieldType: TEMPLATE_VALUE_FIELD_TYPE.Standard,
      },
    },
  },
  charges: {
    fieldType: TEMPLATE_VALUE_FIELD_TYPE.Object,
    nestedFields: {
      chargeCurrency: {
        fieldType: TEMPLATE_VALUE_FIELD_TYPE.Select,
        selectElements: Object.values(CURRENCY),
      },
      chargeAmount: {
        fieldType: TEMPLATE_VALUE_FIELD_TYPE.List,
        listMemberFields: {
          fieldType: TEMPLATE_VALUE_FIELD_TYPE.Standard,
        },
      },
    },
  },
  meters: {
    fieldType: TEMPLATE_VALUE_FIELD_TYPE.Object,
    nestedFields: {
      meterId: {
        fieldType: TEMPLATE_VALUE_FIELD_TYPE.Standard,
      },
      serviceType: {
        fieldType: TEMPLATE_VALUE_FIELD_TYPE.Select,
        selectElements: Object.values(SERVICE_TYPE),
      },
      meterChargeAmount: {
        fieldType: TEMPLATE_VALUE_FIELD_TYPE.Standard,
      },
      meterChargeCurrency: {
        fieldType: TEMPLATE_VALUE_FIELD_TYPE.Select,
        selectElements: Object.values(CURRENCY),
      },
      meterUsageUnit: {
        fieldType: TEMPLATE_VALUE_FIELD_TYPE.Select,
        selectElements: Object.values(USAGE_UNIT),
      },
      meterUsageAmount: {
        fieldType: TEMPLATE_VALUE_FIELD_TYPE.Standard,
      },
    },
  },
};

import { CURRENCY, USAGE_UNIT } from 'src/components/common-components/form/constants';
import { BlueprintPropertyDefinitionFieldType, BlueprintUtilityDefinition } from 'src/components/blueprints/types';

// TODO: Fetch the blueprint definition from an API instead of hardcoding in UI
export const WATER_BLUEPRINT_DEFINITION: BlueprintUtilityDefinition = {
  billing: {
    fieldType: BlueprintPropertyDefinitionFieldType.Object,
    nestedFields: {
      billingAccountIdSetting: {
        fieldType: BlueprintPropertyDefinitionFieldType.Prompt,
      },
      billingStartDateSetting: {
        fieldType: BlueprintPropertyDefinitionFieldType.Prompt,
      },
      billingEndDateSetting: {
        fieldType: BlueprintPropertyDefinitionFieldType.Prompt,
      },
      billingChargeAmountSetting: {
        fieldType: BlueprintPropertyDefinitionFieldType.Prompt,
      },
      billingChargeCurrencySetting: {
        fieldType: BlueprintPropertyDefinitionFieldType.Static,
        selectElements: Object.values(CURRENCY),
      },
    },
  },
  meter: {
    fieldType: BlueprintPropertyDefinitionFieldType.Object,
    nestedFields: {
      meterIdSetting: {
        fieldType: BlueprintPropertyDefinitionFieldType.Prompt,
      },
      meterReadFromDateSetting: {
        fieldType: BlueprintPropertyDefinitionFieldType.Prompt,
      },
      meterReadToDateSetting: {
        fieldType: BlueprintPropertyDefinitionFieldType.Prompt,
      },
      meterChargeAmountSetting: {
        fieldType: BlueprintPropertyDefinitionFieldType.Prompt,
      },
      meterChargeCurrencySetting: {
        fieldType: BlueprintPropertyDefinitionFieldType.Static,
        selectElements: Object.values(CURRENCY),
      },
      meterUsageAmountSetting: {
        fieldType: BlueprintPropertyDefinitionFieldType.Prompt,
      },
      meterUsageUnitSetting: {
        fieldType: BlueprintPropertyDefinitionFieldType.Static,
        selectElements: Object.values(USAGE_UNIT),
      },
    },
  },
};

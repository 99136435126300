import { Alert, Container, Header, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useReprocessInvoices } from 'src/components/manifests/hooks/useReprocessInvoices';
import { usePollInvoices } from 'src/components/manifests/hooks/usePollInvoices';
import { useFetchInvoiceProcessingResults } from 'src/components/manifests/hooks/useFetchInvoiceProcessingResults';
import { BlueprintConfiguration } from 'src/components/blueprints/types';
import { useFetchInvoicesForBlueprint } from 'src/components/blueprints/hooks/useFetchInvoicesForBlueprint';
import { InvoiceReprocessingProgressTracker } from 'src/components/templates/templateVerification/InvoiceReprocessingProgressTracker';
import { InvoiceReprocessingTable } from 'src/components/templates/templateVerification/InvoiceReprocessingTable';
import { InvoiceResultsTabs } from 'src/components/templates/templateVerification/InvoiceResultsTabs';
import { InvoiceSelectionModal } from 'src/components/templates/templateVerification/InvoiceSelectionModal';

const POLLING_INTERVAL_MS = 10000; // 10 seconds

export interface VerifyBlueprintProps {
  blueprint?: BlueprintConfiguration;
}

export const VerifyBlueprint = ({ blueprint }: VerifyBlueprintProps) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isReprocessingStatusVisible, setIsReprocessingStatusVisible] = useState<boolean>(false);
  const [selectedInvoiceIds, setSelectedInvoiceIds] = useState<Array<string>>([]);
  const [selectedInvoiceIdFromTable, setSelectedInvoiceIdFromTable] = useState<string>('');

  const {
    isLoadingInvoices,
    invoices: allInvoices,
    setInvoices: setAllInvoices,
    fetchInvoicesForBlueprint,
  } = useFetchInvoicesForBlueprint();
  const {
    isLoadingResults: isLoadingProcessingResults,
    results: invoiceProcessingResults,
    fetchInvoiceProcessingResults,
  } = useFetchInvoiceProcessingResults();
  const { isLoading: isReprocessRequestLoading, reprocessInvoices } = useReprocessInvoices();
  const {
    isPolling: isPollingInvoices,
    invoiceIdsToPoll,
    triggerInvoicePolling,
    pollingTimeoutError,
  } = usePollInvoices(POLLING_INTERVAL_MS, setAllInvoices);

  useEffect(() => {
    if (blueprint && blueprint.id) {
      fetchInvoicesForBlueprint(blueprint);
    }
  }, [blueprint]);

  const selectedInvoices = useMemo(
    () => allInvoices.filter((invoice) => selectedInvoiceIds.includes(invoice.invoicePdfName)),
    [allInvoices, selectedInvoiceIds]
  );

  const selectedInvoiceFromTable = useMemo(
    () => allInvoices.find((invoice) => invoice.invoicePdfName === selectedInvoiceIdFromTable),
    [allInvoices, selectedInvoiceIdFromTable]
  );

  const handleReprocessInvoices = useCallback(
    async (invoiceIds: Array<string>) => {
      setIsReprocessingStatusVisible(true);
      await reprocessInvoices(invoiceIds, true);
      // After initiating request to reprocess selected invoices, poll these invoices for status updates
      triggerInvoicePolling(invoiceIds);
    },
    [selectedInvoices]
  );

  const handleViewInvoiceDetails = useCallback(async (invoiceId: string) => {
    setSelectedInvoiceIdFromTable(invoiceId);
    await fetchInvoiceProcessingResults(invoiceId);
  }, []);

  const removeSelectedInvoiceId = useCallback(
    (invoiceId: string) => {
      const invoiceIndex = selectedInvoiceIds.indexOf(invoiceId);

      if (invoiceIndex === -1) {
        return;
      }

      const updatedInvoiceIds = [...selectedInvoiceIds];
      updatedInvoiceIds.splice(invoiceIndex, 1);
      setSelectedInvoiceIds(updatedInvoiceIds);
    },
    [selectedInvoiceIds]
  );

  const addSelectedInvoiceId = useCallback(
    (invoiceId: string) => {
      const updatedInvoiceIds = [...selectedInvoiceIds];
      updatedInvoiceIds.push(invoiceId);

      setSelectedInvoiceIds(updatedInvoiceIds);
    },
    [selectedInvoiceIds]
  );

  return (
    <Container
      data-testid="verify-blueprint-container"
      header={
        <Header
          variant="h2"
          description="Select up to 10 invoices to reprocess, and manually verify the output results when available."
        >
          Verify Blueprint
        </Header>
      }
    >
      <InvoiceSelectionModal
        isVisible={isModalVisible}
        onDismiss={() => setIsModalVisible(false)}
        isLoadingInvoices={isLoadingInvoices}
        allInvoices={allInvoices}
        selectedInvoiceIds={selectedInvoiceIds}
        onAddInvoice={addSelectedInvoiceId}
        onRemoveInvoice={removeSelectedInvoiceId}
      />
      <SpaceBetween size="s" direction="vertical">
        <Alert statusIconAriaLabel="Info" header="This Blueprint has been saved">
          <div>
            Your changes have been saved. To verify your changes, we recommend selecting up to 10 invoices to reprocess.
            From this page, you can evaluate the output results and view any errors that occur after reprocessing.
          </div>
        </Alert>
        {isReprocessingStatusVisible && (
          <InvoiceReprocessingProgressTracker
            selectedInvoiceIds={selectedInvoiceIds}
            invoiceIdsToPoll={invoiceIdsToPoll}
            isReprocessingInvoices={isReprocessRequestLoading || isPollingInvoices}
            pollingTimeoutError={pollingTimeoutError}
          />
        )}
        <InvoiceReprocessingTable
          invoices={selectedInvoices}
          selectedInvoiceId={selectedInvoiceIdFromTable}
          openSelectedInvoicesModal={() => setIsModalVisible(true)}
          isReprocessingInvoices={isReprocessRequestLoading || isPollingInvoices}
          onReprocessInvoices={handleReprocessInvoices}
          onViewInvoiceDetails={handleViewInvoiceDetails}
        />
        <div>
          {selectedInvoiceIdFromTable && selectedInvoiceFromTable && (
            <InvoiceResultsTabs
              invoice={selectedInvoiceFromTable}
              processingResults={JSON.stringify(invoiceProcessingResults, null, '\t')}
              isLoadingProcessingResults={isLoadingProcessingResults}
            />
          )}
        </div>
      </SpaceBetween>
    </Container>
  );
};

import { Amplify } from 'aws-amplify';
import { Auth } from '@aws-amplify/auth';
import { RestAPI } from '@aws-amplify/api-rest';
import React, { useContext, useEffect, useState } from 'react';
import {
  AppLayout,
  AppLayoutProps,
  ContentLayout,
  Flashbar,
  FlashbarProps,
  SideNavigation,
} from '@amzn/awsui-components-react';
import awsExports from '../aws-exports';
import Routes, { PAGE_ROUTES } from './Routes';
import { TopNavigationBar } from './top-navbar';
import { ErrorBoundary } from './common-components/error-boundary';
import { getDynamicAwsExports } from 'src/utils/env';
import { HelpContext, HelpProvider } from './help/HelpProvider';
import { Login } from './auth/Login';
import { useFetchAuthenticatedUser } from './auth/hooks/useFetchAuthenticatedUser';
import { SplitPanelContext, SplitPanelProvider } from './split-panel/SplitPanelProvider';
import { useHistory, useLocation } from 'react-router-dom';

Amplify.configure(getDynamicAwsExports(awsExports));
Auth.configure(getDynamicAwsExports(awsExports));
RestAPI.configure(getDynamicAwsExports(awsExports));

interface AppContextType {
  setNotificationsItems: React.Dispatch<React.SetStateAction<FlashbarProps.MessageDefinition[]>>;
  user: string;
}

export const AppContext = React.createContext<AppContextType>({
  setNotificationsItems: () => {},
  user: '',
});

export const App: React.FC = () => {
  const [user, setUser] = useState<string | null>(null);
  const [notificationsItems, setNotificationsItems] = useState<FlashbarProps.MessageDefinition[]>([]);

  const { isFetchingUser, fetchUserCredentials } = useFetchAuthenticatedUser();

  useEffect(() => {
    fetchUserCredentials().then((user) => {
      if (!user) return;
      setUser(user);
    });
  }, []);

  return (
    <>
      {user && !isFetchingUser ? (
        <div>
          <AppContext.Provider value={{ setNotificationsItems, user }}>
            <SplitPanelProvider>
              <HelpProvider>
                <TopNavigationBar />
                <AppContent notificationsItems={notificationsItems} />
              </HelpProvider>
            </SplitPanelProvider>
          </AppContext.Provider>
        </div>
      ) : (
        <ErrorBoundary>
          <Login isFetchingUser={isFetchingUser} />
        </ErrorBoundary>
      )}
    </>
  );
};

interface AppContentProps {
  notificationsItems: Array<FlashbarProps.MessageDefinition>;
}

const HOMEPAGE_HREF = '';

export const AppContent: React.FC<AppContentProps> = ({ notificationsItems }) => {
  const history = useHistory();
  const location = useLocation();

  const {
    splitPanel,
    setSplitPanel,
    splitPanelSize,
    setSplitPanelSize,
    splitPanelPosition,
    setSplitPanelPosition,
    isSplitPanelOpen,
    showSplitPanel,
  } = useContext(SplitPanelContext);
  const { helpContent, isSuppressed, isOpen, showHelp } = useContext(HelpContext);

  const [activeHref, setActiveHref] = useState<string>(HOMEPAGE_HREF);

  // Ensure `activeHref` defaults to the page the user lands on
  useEffect(() => {
    const { pathname } = location;
    // `pathname` for the homepage returns `/`, but we want to store it as an empty string
    setActiveHref(pathname === '/' ? HOMEPAGE_HREF : pathname);
  }, [location]);

  return (
    <AppLayout
      data-layout-id="appLayout"
      headerSelector='[data-layout-id="mainNavigation"]'
      notifications={<Flashbar items={notificationsItems} />}
      content={
        <ContentLayout disableOverlap>
          <Routes />
        </ContentLayout>
      }
      // navigationHide
      navigation={
        <SideNavigation
          header={{
            href: HOMEPAGE_HREF,
            text: 'Tesseract',
          }}
          items={[
            { type: 'link', text: 'Home', href: HOMEPAGE_HREF },
            { type: 'link', text: 'Blueprints', href: PAGE_ROUTES.blueprints },
            { type: 'link', text: 'Invoices', href: PAGE_ROUTES.invoices },
          ]}
          onFollow={(event) => {
            if (!event.detail.external) {
              const { href } = event.detail;
              event.preventDefault();
              setActiveHref(href);
              history.push(href);
            }
          }}
          activeHref={activeHref}
        />
      }
      onSplitPanelToggle={(event) => {
        showSplitPanel(event.detail.open);
      }}
      onSplitPanelResize={(event) => {
        setSplitPanelSize(event.detail.size);
      }}
      onSplitPanelPreferencesChange={(event) => {
        setSplitPanelPosition(event.detail.position);
      }}
      splitPanel={splitPanel}
      splitPanelOpen={isSplitPanelOpen}
      splitPanelSize={splitPanelSize}
      splitPanelPreferences={{ position: splitPanelPosition }}
      onToolsChange={(e) => showHelp(e.detail.open)}
      tools={helpContent}
      toolsOpen={isOpen}
      toolsHide={isSuppressed}
    />
  );
};

export default App;

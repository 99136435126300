import { ColumnLayout } from '@amzn/awsui-components-react';
import React from 'react';
import { ValueWithLabel } from 'src/components/common-components/keyValuePair/ValueWithLabel';
import { generateTemplateId } from '../../create/createUtils';
import { TemplateType } from '../../types';
import { ManifestDocument } from 'src/interface/type-def';

interface TemplateIdSummaryProps {
  templateType: TemplateType;
  manifestDocument: ManifestDocument;
}

export const TemplateIdSummary = ({ templateType, manifestDocument }: TemplateIdSummaryProps) => (
  <ColumnLayout columns={4} variant="text-grid">
    <ValueWithLabel label="Template ID" labelDataTestId="templateId-label" valueDataTestId="templateId-value">
      {generateTemplateId(templateType, manifestDocument)}
    </ValueWithLabel>
    <ValueWithLabel
      label="Utility ID"
      labelDataTestId="utilityId-label"
      valueDataTestId="utilityId-value"
      infoText="The template's utility ID is this invoice's vendor number"
    >
      {manifestDocument.vendorNumber}
    </ValueWithLabel>
    <ValueWithLabel
      label="Site ID"
      labelDataTestId="siteId-label"
      valueDataTestId="siteId-value"
      infoText="The template's site ID is this invoice's location code. Only populated for site and account templates."
    >
      {templateType === TemplateType.SITE || templateType === TemplateType.ACCOUNT
        ? manifestDocument.locationCode
        : '-'}
    </ValueWithLabel>
    <ValueWithLabel
      label="Account ID"
      labelDataTestId="accountId-label"
      valueDataTestId="accountId-value"
      infoText="The template's account ID is this invoice's account number. Only populated for account templates."
    >
      {templateType === TemplateType.ACCOUNT ? manifestDocument.accountNumber : '-'}
    </ValueWithLabel>
  </ColumnLayout>
);

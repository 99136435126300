import React, { useState, useEffect, useCallback, useRef } from 'react';
import { getInvoices, getManifestsWithQuery, GetInvoiceQueryProps } from 'src/client/api-gateway';
import { IInvoiceMetadata, InvoiceMetadata, InvoiceQueryValue } from 'src/interface/type-def';
import { ANY_PROCESSING_STATUS_FILTER_OPTION } from '../manifest-table-config';

type FetchManifestsArgs = GetInvoiceQueryProps & { resetQueryParams?: boolean };
export interface UseFetchManifests {
  allManifests: Array<IInvoiceMetadata>;
  manifestsLoading: boolean;
  lastEvaluatedKey: string;
  processingStatus: string;
  setProcessingStatus: (status: string) => void;
  queryParams: GetInvoiceQueryProps;
  setQueryParams: React.Dispatch<React.SetStateAction<GetInvoiceQueryProps>>;
  fetchManifests: (args: FetchManifestsArgs) => void;
}

const coercePipelineStatus = (status?: InvoiceQueryValue | ANY_PROCESSING_STATUS_FILTER_OPTION.ANY_STATUS) =>
  !status || status === ANY_PROCESSING_STATUS_FILTER_OPTION.ANY_STATUS ? {} : { pipelineStatus: status };

export function useFetchManifests(defaultProcessingStatus?: string): UseFetchManifests {
  const [allManifests, setAllManifests] = useState<Array<IInvoiceMetadata>>([]);
  const [manifestsLoading, setManifestsLoading] = useState<boolean>(false);

  const [queryParams, setQueryParams] = useState<GetInvoiceQueryProps>({
    templateId: '',
    utilityId: '',
    utilityType: '',
  });

  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<string>('');
  const [processingStatus, setProcessingStatus] = useState<string>(defaultProcessingStatus || '');

  const fetchManifests = useCallback(
    async (args: FetchManifestsArgs = {}) => {
      const { resetQueryParams = false, keystoneProcessingStatus: paramProcessingStatus, ...params } = args;
      const conditionalProcessingStatus = resetQueryParams
        ? ANY_PROCESSING_STATUS_FILTER_OPTION.ANY_STATUS
        : paramProcessingStatus ?? processingStatus;

      setManifestsLoading(true);
      getManifestsWithQuery({
        ...(!resetQueryParams && { ...queryParams }),
        ...params,
      })
        .then((data) => {
          const { invoiceMetadataList = [], lastEvaluatedKey = '' } = data;
          let updatedManifests: Array<IInvoiceMetadata> = invoiceMetadataList;

          if (!resetQueryParams && conditionalProcessingStatus !== ANY_PROCESSING_STATUS_FILTER_OPTION.ANY_STATUS) {
            updatedManifests = updatedManifests.filter(
              (invoice) => invoice.processingStatus === conditionalProcessingStatus
            );
          }

          setAllManifests(updatedManifests);
          setProcessingStatus(conditionalProcessingStatus.toString());
          setQueryParams((state) =>
            resetQueryParams
              ? {}
              : {
                  ...state,
                  ...params,
                }
          );
          setLastEvaluatedKey(lastEvaluatedKey ? JSON.stringify(lastEvaluatedKey) : '');
        })
        .catch((err) => {
          setAllManifests([]);
          setLastEvaluatedKey('');
          setProcessingStatus(ANY_PROCESSING_STATUS_FILTER_OPTION.ANY_STATUS);
        })
        .finally(() => {
          setManifestsLoading(false);
        });
    },
    [processingStatus, allManifests, queryParams, setQueryParams]
  );

  useEffect(() => {
    fetchManifests({ lastEvaluatedKey: '', resetQueryParams: true });
  }, []);

  return {
    allManifests,
    manifestsLoading,
    lastEvaluatedKey,
    processingStatus,
    setProcessingStatus,
    queryParams,
    setQueryParams,
    fetchManifests,
  };
}

import { Box, Popover } from '@amzn/awsui-components-react';
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';
import React from 'react';
import { toSentenceCase } from 'src/utils/strings';
import {
  PROCESSING_STATUS,
  PROCESSING_STATUS_DESCRIPTIONS,
  PROCESSING_STATUS_TYPES,
} from '../../interface/processingStatus';

interface ProcessingStatusIndicatorProps {
  status?: PROCESSING_STATUS;
  renderPopover?: boolean;
}

export const ProcessingstatusIndicator = ({ status, renderPopover }: ProcessingStatusIndicatorProps) => {
  if (!status) return <span>No status found</span>;

  const statusIndicator = (
    <StatusIndicator type={PROCESSING_STATUS_TYPES[status]}>{toSentenceCase(status)}</StatusIndicator>
  );

  return renderPopover ? (
    <Box>
      <Popover header="Pipeline Status" content={PROCESSING_STATUS_DESCRIPTIONS[status]}>
        {statusIndicator}
      </Popover>
    </Box>
  ) : (
    statusIndicator
  );
};

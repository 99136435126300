import React, { useMemo } from 'react';
import { Input, Table, TableProps } from '@amzn/awsui-components-react';
import {
  BlueprintBillingConfigurationSettings,
  BlueprintMeterConfigurationSettings,
  BlueprintPropertyValueSetting,
  BlueprintPropertyDefinition,
  BlueprintPropertyValueType,
} from 'src/components/blueprints/types';
import { BlueprintPropertyTypeText } from '../BlueprintPropertyTypeText';

enum BlueprintPromptsTableColumnId {
  propertyName = 'propertyName',
  type = 'type',
  input = 'input',
}

interface BlueprintPromptsTableItem {
  propertyName: string;
  propertyValue: BlueprintPropertyValueSetting;
  propertyDefinition: BlueprintPropertyDefinition | null;
}

const getColumnDefinitions = (
  onSelectDropdownItem: (propertyName: string, value: string) => void
): Array<TableProps.ColumnDefinition<BlueprintPromptsTableItem>> => [
  {
    id: BlueprintPromptsTableColumnId.propertyName,
    header: 'Property',
    cell: (item) => item.propertyName,
    width: '250px',
  },
  {
    id: BlueprintPromptsTableColumnId.type,
    header: 'Property type',
    cell: (item) => <BlueprintPropertyTypeText propertyName={item.propertyName} type={item.propertyValue.type} />,
    width: '100px',
  },
  {
    id: BlueprintPromptsTableColumnId.input,
    header: 'Input',
    cell: (item) =>
      item.propertyValue.type === BlueprintPropertyValueType.Prompt
        ? item.propertyValue.prompt
        : item.propertyValue.value,
    editConfig: {
      ariaLabel: 'Input',
      editIconAriaLabel: 'editable',
      errorIconAriaLabel: 'Input error',
      editingCell: (item, { currentValue, setValue }) => {
        const { propertyValue } = item;
        return (
          <Input
            autoFocus
            value={currentValue ?? propertyValue.prompt}
            placeholder={`Prompt for ${item.propertyName}`}
            onChange={(event) => setValue(event.detail.value)}
          />
        );
      },
    },
  },
];

interface BlueprintPromptsTableProps {
  blueprintProperties: BlueprintBillingConfigurationSettings | BlueprintMeterConfigurationSettings;
  blueprintPropertyDefinition: BlueprintPropertyDefinition;
  onSaveProperty: (propertyName: string, templateValue: BlueprintPropertyValueSetting) => void;
}

export const BlueprintPromptsTable = ({
  blueprintProperties,
  blueprintPropertyDefinition,
  onSaveProperty,
}: BlueprintPromptsTableProps) => {
  const tableItems: Array<BlueprintPromptsTableItem> = useMemo(
    () =>
      Object.keys(blueprintProperties).map((propertyName) => ({
        propertyName,
        propertyValue: blueprintProperties[propertyName],
        propertyDefinition: blueprintPropertyDefinition.nestedFields
          ? blueprintPropertyDefinition.nestedFields[propertyName]
          : null,
      })),
    [blueprintProperties, blueprintPropertyDefinition.nestedFields]
  );

  const handleSubmitEdit = (
    item: BlueprintPromptsTableItem,
    _column: TableProps.ColumnDefinition<BlueprintPromptsTableItem>,
    newValue: any
  ) => {
    const { propertyName } = item;
    const newPrompt = newValue as string;
    onSaveProperty(propertyName, {
      type: BlueprintPropertyValueType.Prompt,
      prompt: newPrompt,
    });
  };

  const handleSelectDropdownItem = (propertyName: string, selectedValue: string) => {
    onSaveProperty(propertyName, {
      type: BlueprintPropertyValueType.Static,
      value: selectedValue,
    });
  };

  const columnDefinitions = getColumnDefinitions(handleSelectDropdownItem);

  return <Table items={tableItems} columnDefinitions={columnDefinitions} submitEdit={handleSubmitEdit} />;
};

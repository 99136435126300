import { TextractKeyValue } from 'src/helpers/textract';

export const filterDuplicateAndEmptyTextractKeys = (keyValues: Array<TextractKeyValue>) => {
  const seenKeys = new Set<string>();

  return keyValues.filter((keyValuePair) => {
    const { key } = keyValuePair;
    if (!key) return false;

    const keyIsSeen = seenKeys.has(key);
    seenKeys.add(key);

    return !keyIsSeen;
  });
};

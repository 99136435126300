import { Header, SpaceBetween, SplitPanel } from '@amzn/awsui-components-react';
import * as React from 'react';
import { useEffect, useContext } from 'react';
import { useParams } from 'react-router';
import { PDFViewer } from '../common-components/PDFViewer';
import { InvoiceOverrideForm } from './overrides/InvoiceOverrideForm';
import { SplitPanelContext } from '../split-panel/SplitPanelProvider';
import SplitPanelI18nStrings from '../split-panel/strings';
import { useFetchInvoiceOverride } from './hooks/useFetchInvoiceOverride';
import { useFetchPdf } from '../common-components/hooks/useFetchPdf';

export interface ManifestOverrideParams {
  id: string;
}

const ManifestOverrideSplitPanel: React.FC = ({ children }) => (
  <SplitPanel data-testid="splitPanel" i18nStrings={SplitPanelI18nStrings} header="Invoice Override Panel">
    {children}
  </SplitPanel>
);

export const ManifestOverride: React.FC = () => {
  const { id } = useParams<ManifestOverrideParams>();

  const { splitPanel, setSplitPanel, showSplitPanel } = useContext(SplitPanelContext);
  const { results, manifest, fetchInvoiceOverride, loading, errorMessage, isOverridable } = useFetchInvoiceOverride();
  const { pdf, fetchPdf, pdfErrorMessage, pdfLoading } = useFetchPdf();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) fetchInvoiceOverride(id);
    return () => {
      isMounted = false;
    };
  }, [id, fetchInvoiceOverride]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setSplitPanel(
        <ManifestOverrideSplitPanel>
          {results && isOverridable ? (
            <InvoiceOverrideForm id={id} results={results} />
          ) : loading ? (
            <section>Loading invoice details...</section>
          ) : !isOverridable ? (
            <section>
              This invoice cannot be overridden yet. Please create a template and attempt processing first
            </section>
          ) : (
            <section>Sorry, there was an error loading the invoice: {errorMessage}</section>
          )}
        </ManifestOverrideSplitPanel>
      );
      showSplitPanel(true);

      if (manifest) {
        fetchPdf(manifest.manifestDocument.s3FilePath);
      }
    }
    return () => {
      setSplitPanel(undefined);
      showSplitPanel(false);
      isMounted = false;
    };
  }, [id, setSplitPanel, showSplitPanel, loading, errorMessage, results, manifest, fetchPdf, isOverridable]);

  return (
    <SpaceBetween size="l">
      <Header variant="h2">Invoice Override</Header>
      <PDFViewer file={pdf} loading={pdfLoading} errorMessage={pdfErrorMessage} />
    </SpaceBetween>
  );
};

import { SpaceBetween, StatusIndicator, Button } from '@amzn/awsui-components-react';
import React from 'react';
import { Meter } from 'src/interface/type-def';
import { FieldType } from './map-to-schema';
import { TemplatePropertySuggestedValues } from '../../templateSuggestions/templateSuggestionsUtils';

interface MetersSummaryProps {
  meters: Meter[];
  makeActive: (index: number, fieldType: FieldType) => void;
  removeItemFromList: (index: number, fieldType: FieldType, meterIndex?: number) => void;
  suggestedTemplateValues: TemplatePropertySuggestedValues | null;
}

export const MetersSummary = ({
  meters,
  makeActive,
  removeItemFromList,
  suggestedTemplateValues,
}: MetersSummaryProps) => (
  <div>
    {meters.map((meter, idx) => (
      <SpaceBetween size="s" direction="horizontal" key={idx}>
        <span>{meter.meterId ? <StatusIndicator type="success" /> : <StatusIndicator type="error" />}</span>
        <Button iconName="edit" variant="inline-icon" onClick={() => makeActive(idx, FieldType.METERS)} />
        <Button iconName="close" variant="inline-icon" onClick={() => removeItemFromList(idx, FieldType.METERS)} />
        <span>Meter #{idx}</span>
      </SpaceBetween>
    ))}
  </div>
);

import { useCallback, useState } from 'react';
import {
  BlueprintConfiguration,
  BlueprintBillingConfigurationSettings,
  BlueprintMeterConfigurationSettings,
  BlueprintPropertyValueSetting,
} from 'src/components/blueprints/types';
import cloneDeep from 'lodash/cloneDeep';

export const useConfigureBlueprint = (initialBlueprint: BlueprintConfiguration) => {
  const [blueprint, setBlueprint] = useState<BlueprintConfiguration>(initialBlueprint);

  const updateBillingProperty = useCallback(
    (propertyName: string, value: BlueprintPropertyValueSetting) => {
      if (!(propertyName in blueprint.blueprintConfigurationSettings.billing)) return;

      const updatedBlueprint = cloneDeep(blueprint);
      updatedBlueprint.blueprintConfigurationSettings.billing[
        propertyName as keyof BlueprintBillingConfigurationSettings
      ] = value;

      setBlueprint(updatedBlueprint);
    },
    [blueprint]
  );

  const updateMeterProperty = useCallback(
    (propertyName: string, value: BlueprintPropertyValueSetting) => {
      if (!(propertyName in blueprint.blueprintConfigurationSettings.meter)) return;

      const updatedBlueprint = cloneDeep(blueprint);
      updatedBlueprint.blueprintConfigurationSettings.meter[propertyName as keyof BlueprintMeterConfigurationSettings] =
        value;

      setBlueprint(updatedBlueprint);
    },
    [blueprint]
  );

  return {
    blueprint,
    setBlueprint,
    updateBillingProperty,
    updateMeterProperty,
  };
};

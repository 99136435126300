import { Button, Header, Pagination, Table, TableProps, TextFilter } from '@amzn/awsui-components-react';
import React, { useMemo } from 'react';
import { IInvoiceMetadata } from 'src/interface/type-def';
import { MAX_ALLOWED_SELECTED_INVOICES } from './InvoiceSelectionModal';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { filterTableRowByText, getTextFilterCounterText } from 'src/utils/tableUtils';
import { TEMPLATE_VERIFICATION_COLUMN_IDS, getCommonColumnDefinitions } from './tableConfig';
import { EmptyTableState } from 'src/components/common-components/table/EmptyTableState';

const DEFAULT_PAGE_SIZE = 10;

const getColumnDefinitions = (
  selectedInvoiceIds: Array<string>,
  onAddInvoice: (invoiceId: string) => void,
  onRemoveInvoice: (invoiceId: string) => void
): Array<TableProps.ColumnDefinition<IInvoiceMetadata>> => [
  ...getCommonColumnDefinitions(selectedInvoiceIds),
  {
    id: 'selectInvoice',
    header: 'Select',
    cell: (e) =>
      selectedInvoiceIds.includes(e.invoicePdfName) ? (
        <Button
          data-testid="remove-selected-invoice"
          variant="inline-link"
          onClick={() => onRemoveInvoice(e.invoicePdfName)}
        >
          Remove
        </Button>
      ) : (
        <Button
          data-testid="add-selected-invoice"
          disabled={selectedInvoiceIds.length >= MAX_ALLOWED_SELECTED_INVOICES}
          variant="inline-link"
          onClick={() => onAddInvoice(e.invoicePdfName)}
        >
          Select
        </Button>
      ),
    minWidth: 100,
  },
];

export interface InvoiceSelectionTableProps {
  isLoadingInvoices: boolean;
  allInvoices: Array<IInvoiceMetadata>;
  selectedInvoiceIds: Array<string>;
  onAddInvoice: (invoiceId: string) => void;
  onRemoveInvoice: (invoiceId: string) => void;
}

// TODO: Create generic component to render a table of Invoices with consistent set of base columns
// Asana: https://app.asana.com/0/1205742096370708/1206817059823918/f
export const InvoiceSelectionTable = ({
  isLoadingInvoices,
  allInvoices,
  selectedInvoiceIds,
  onAddInvoice,
  onRemoveInvoice,
}: InvoiceSelectionTableProps) => {
  const {
    items: tableItems,
    filteredItemsCount = 0,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(allInvoices, {
    filtering: {
      empty: <EmptyTableState title="No invoices" subtitle="There are no invoices for this template" />,
      noMatch: <EmptyTableState title="No invoices" subtitle="There are no invoices that match this filter" />,
      filteringFunction: (invoice: IInvoiceMetadata, filteringText: string) =>
        filterTableRowByText(invoice.invoicePdfName, filteringText) ||
        filterTableRowByText(invoice.manifestDocument.locationCode, filteringText) ||
        filterTableRowByText(invoice.manifestDocument.accountNumber, filteringText),
    },
    pagination: {
      pageSize: DEFAULT_PAGE_SIZE,
    },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: TEMPLATE_VERIFICATION_COLUMN_IDS.LAST_STATUS_DATE,
          sortingComparator: (invoiceA, invoiceB) => (invoiceB.lastStatusDate || 0) - (invoiceA.lastStatusDate || 0),
        },
      },
    },
  });

  const columnDefinitions = useMemo(
    () => getColumnDefinitions(selectedInvoiceIds, onAddInvoice, onRemoveInvoice),
    [selectedInvoiceIds, onAddInvoice, onRemoveInvoice]
  );

  return (
    <Table
      {...collectionProps}
      columnDefinitions={columnDefinitions}
      header={<Header variant="h2">{`Invoices (${allInvoices.length})`}</Header>}
      items={tableItems}
      loading={isLoadingInvoices}
      loadingText="Loading invoices"
      stickyColumns={{ first: 0, last: 1 }}
      pagination={<Pagination {...paginationProps} />}
      filter={
        <TextFilter
          {...filterProps}
          filteringPlaceholder="Search by invoice ID, location code, or account number"
          countText={getTextFilterCounterText(filteredItemsCount)}
        />
      }
    />
  );
};

import { useCallback, useState } from 'react';
import { validateBlueprintName as blueprintNameValidator } from 'src/components/blueprints/validators';

export const useValidateBlueprintMetadata = () => {
  const [blueprintNameError, setBlueprintNameError] = useState<string>('');

  const validateBlueprintName = useCallback((name: string) => {
    if (!name) {
      setBlueprintNameError('Blueprint name is required');
      return;
    }

    const isValid = blueprintNameValidator(name);
    if (!isValid) {
      setBlueprintNameError('Blueprint name is not valid');
      return;
    }

    setBlueprintNameError('');
  }, []);

  return {
    blueprintNameError,
    validateBlueprintName,
  };
};

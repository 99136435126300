import { SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { BreadcrumbGroupWithRouting } from 'src/components/breadcrumbs/BreadcrumbGroupWithRouting';
import { BLUEPRINT_BREADCRUMBS, ROOT_BREADCRUMB } from 'src/components/breadcrumbs/breadcrumbs';
import { BlueprintsTable } from './BlueprintsTable';

export const BlueprintsPage = () => (
  <SpaceBetween size="l">
    <BreadcrumbGroupWithRouting
      items={[
        {
          text: ROOT_BREADCRUMB.homepage.text,
          href: ROOT_BREADCRUMB.homepage.getHref(),
        },
        {
          text: BLUEPRINT_BREADCRUMBS.blueprints.text,
          href: BLUEPRINT_BREADCRUMBS.blueprints.getHref(),
        },
      ]}
    />

    <BlueprintsTable />
  </SpaceBetween>
);

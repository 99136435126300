import { Popover } from '@amzn/awsui-components-react';
import { Cell } from 'amazon-textract-response-parser/dist/cjs';
import React from 'react';
import { ApplyTabularDataCellButton, TABULAR_DATA_CELL_TYPES } from './ApplyTabularDataCellButton';

interface ApplyTabularDataCellProps {
  cell: Cell;
  pageNumber: number;
  tableNumber: number;
  onApplyCell?: (
    cellType: TABULAR_DATA_CELL_TYPES,
    selectedCell: Cell,
    pageNumber: number,
    tableNumber: number
  ) => void;
}

export const ApplyTabularDataCell: React.FC<ApplyTabularDataCellProps> = ({
  cell,
  pageNumber,
  tableNumber,
  onApplyCell,
  children,
}) => (
  <Popover
    position="top"
    size="large"
    triggerType="custom"
    content={
      <ApplyTabularDataCellButton
        cell={cell}
        pageNumber={pageNumber}
        tableNumber={tableNumber}
        onApplyCell={onApplyCell}
      />
    }
  >
    {children}
  </Popover>
);

import { Header, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useCallback, useState } from 'react';
import { BreadcrumbGroupWithRouting } from 'src/components/breadcrumbs/BreadcrumbGroupWithRouting';
import { ROOT_BREADCRUMB, BLUEPRINT_BREADCRUMBS } from 'src/components/breadcrumbs/breadcrumbs';
import { InvoiceMetadataTable } from 'src/components/manifests/table/InvoiceMetadataTable';
import { IInvoiceMetadata } from 'src/interface/type-def';

export const CreateBlueprintPage = () => {
  const [selectedInvoice, setSelectedInvoice] = useState<IInvoiceMetadata | null>(null);

  const handleInvoiceSelection = useCallback((selectedInvoices: Array<IInvoiceMetadata>) => {
    if (selectedInvoices.length === 1) {
      setSelectedInvoice(selectedInvoices[0]);
    }
  }, []);

  return (
    <SpaceBetween size="l">
      <BreadcrumbGroupWithRouting
        items={[
          {
            text: ROOT_BREADCRUMB.homepage.text,
            href: ROOT_BREADCRUMB.homepage.getHref(),
          },
          {
            text: BLUEPRINT_BREADCRUMBS.blueprints.text,
            href: BLUEPRINT_BREADCRUMBS.blueprints.getHref(),
          },
          {
            text: BLUEPRINT_BREADCRUMBS.createBlueprint.text,
            href: BLUEPRINT_BREADCRUMBS.createBlueprint.getHref(),
          },
        ]}
      />

      <Header
        variant="h1"
        description="To get started, select an invoice to use for reference to create a new blueprint."
      >
        Create a Blueprint
      </Header>

      <InvoiceMetadataTable variant="createBlueprint" onItemSelect={handleInvoiceSelection} />
    </SpaceBetween>
  );
};

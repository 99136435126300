import { useCallback, useState } from 'react';
import { IInvoiceMetadata, Template } from 'src/interface/type-def';
import { getInvoicesByTemplateId } from 'src/client/api-gateway';
import { WaterTemplate } from '../waterTemplates/types/WaterTemplateConfiguration';

export interface UseFetchInvoicesForTemplateState {
  isLoadingInvoices: boolean;
  invoices: Array<IInvoiceMetadata>;
  errorMessage: string | null;
  fetchInvoicesForTemplate: (template: Template | WaterTemplate) => Promise<void>;
  setInvoices: React.Dispatch<React.SetStateAction<IInvoiceMetadata[]>>;
}

export const useFetchInvoicesForTemplate = () => {
  const [isLoadingInvoices, setIsLoadingInvoices] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [invoices, setInvoices] = useState<Array<IInvoiceMetadata>>([]);

  const fetchInvoicesForTemplate = useCallback(async (template: Template | WaterTemplate) => {
    setIsLoadingInvoices(true);

    try {
      const invoiceResponse = await getInvoicesByTemplateId(template.templateId || '');
      setInvoices(invoiceResponse.invoiceMetadataList);
    } catch (e) {
      setErrorMessage(e as string);
    } finally {
      setIsLoadingInvoices(false);
    }
  }, []);

  return {
    isLoadingInvoices,
    invoices,
    errorMessage,
    setInvoices,
    fetchInvoicesForTemplate,
  };
};

import { Container, Header, Tabs } from '@amzn/awsui-components-react';
import React from 'react';
import { InvoiceProcessingResults } from './InvoiceProcessingResults';
import { IInvoiceMetadata } from 'src/interface/type-def';

enum TAB_IDS {
  INVOICE_PROCESSING_RESULTS = 'invoiceProcessingResults',
  INVOICE_PROCESSING_ERRORS = 'invoiceProcessingErrors',
}

interface InvoiceResultsTabsProps {
  invoice: IInvoiceMetadata;
  processingResults: string;
  isLoadingProcessingResults: boolean;
}

export const InvoiceResultsTabs = ({
  invoice,
  processingResults,
  isLoadingProcessingResults,
}: InvoiceResultsTabsProps) => (
  <Container header={<Header variant="h3">{`View processing results for invoice ${invoice.invoicePdfName}`}</Header>}>
    <Tabs
      tabs={[
        {
          label: 'Processing Results',
          id: TAB_IDS.INVOICE_PROCESSING_RESULTS,
          content: (
            <InvoiceProcessingResults
              processingResults={processingResults}
              isLoadingProcessingResults={isLoadingProcessingResults}
            />
          ),
        },
      ]}
    />
  </Container>
);

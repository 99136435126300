import { SplitPanelProps } from '@amzn/awsui-components-react';

const SplitPanelI18nStrings: SplitPanelProps['i18nStrings'] = {
  closeButtonAriaLabel: 'Close',
  openButtonAriaLabel: 'Open',
  preferencesCancel: 'Cancel',
  preferencesConfirm: 'Confirm',
  preferencesPositionBottom: 'Bottom',
  preferencesPositionDescription: 'Where the split panel will be anchored',
  preferencesPositionLabel: 'Split Panel Position',
  preferencesPositionSide: 'Side',
  preferencesTitle: 'Split Panel Preferences',
};

export default SplitPanelI18nStrings;

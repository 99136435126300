import cloneDeep from 'lodash/cloneDeep';
import { Cell } from 'amazon-textract-response-parser/dist/cjs';
import { TextractKeyValue } from 'src/helpers/textract';
import { HeaderRef, ManifestDocument, TemplateValue } from 'src/interface/type-def';
import {
  getDefaultStaticTemplateValue,
  getDefaultKeyValueTemplateValue,
  getDefaultManifestTemplateValue,
  getDefaultTabularDataTemplateValue,
  isHeaderBasedTableValueRef,
  isKeyValueRef,
  isManifestValueRef,
  isStaticValueRef,
} from 'src/utils/valueRefUtils';
import { TABULAR_DATA_CELL_TYPES } from '../../create/tabularDataMapping/ApplyTabularDataCellButton';
import { getOffsetForHeaderCell } from '../../create/createUtils';

export const usePropertyEditor = () => {
  const getKeyValuePairTemplateValue = (existingTemplateValue: TemplateValue, keyValue: TextractKeyValue) => {
    let updatedTemplateValue: TemplateValue;
    // If the property is not configured, or is not a key-value pair, initialize the templateValue
    if (!existingTemplateValue.valueRef || !isKeyValueRef(existingTemplateValue.valueRef)) {
      updatedTemplateValue = getDefaultKeyValueTemplateValue();
    } else {
      updatedTemplateValue = cloneDeep(existingTemplateValue);
    }

    if (!updatedTemplateValue.valueRef || !isKeyValueRef(updatedTemplateValue.valueRef)) return;

    updatedTemplateValue.valueRef.ref.key = keyValue.key;
    updatedTemplateValue.valueRef.page = keyValue.pageNumber;

    return updatedTemplateValue;
  };

  const getTabularDataTemplateValue = (
    existingTemplateValue: TemplateValue,
    cellType: TABULAR_DATA_CELL_TYPES,
    selectedCell: Cell,
    pageNumber: number,
    tableNumber: number
  ) => {
    let updatedTemplateValue: TemplateValue;
    // If the property is not configured, or is not a tabular data cell, initialize the templateValue
    if (!existingTemplateValue.valueRef || !isHeaderBasedTableValueRef(existingTemplateValue.valueRef)) {
      updatedTemplateValue = getDefaultTabularDataTemplateValue();
    } else {
      updatedTemplateValue = cloneDeep(existingTemplateValue);
    }

    if (!updatedTemplateValue.valueRef || !isHeaderBasedTableValueRef(updatedTemplateValue.valueRef)) return;

    // TODO: Transformation logic should likely be centralized on the backend
    const sanitizedCellText = selectedCell.text.trim().replace(/[.*:]/g, '');

    switch (cellType) {
      case TABULAR_DATA_CELL_TYPES.ApplyCell: {
        updatedTemplateValue.valueRef.page = pageNumber;
        updatedTemplateValue.valueRef.cellRef = {
          ...updatedTemplateValue.valueRef.cellRef,
          cellCoordinate: [selectedCell.rowIndex, selectedCell.columnIndex],
          cellText: selectedCell.text,
        };
        break;
      }
      case TABULAR_DATA_CELL_TYPES.AddCellHeader: {
        const existingCellRef = updatedTemplateValue.valueRef.cellRef;
        const headerCellOffset = getOffsetForHeaderCell(
          existingCellRef,
          selectedCell.columnIndex,
          selectedCell.rowIndex
        );
        const selectedHeaderCellRef: HeaderRef = {
          header: sanitizedCellText,
          offset: headerCellOffset,
        };

        updatedTemplateValue.valueRef.cellRef.headerRefs = [
          ...(updatedTemplateValue.valueRef.cellRef.headerRefs || []),
          selectedHeaderCellRef,
        ];

        break;
      }
      case TABULAR_DATA_CELL_TYPES.AddTableHeader: {
        updatedTemplateValue.valueRef.tableRef = {
          ...updatedTemplateValue.valueRef.tableRef,
          tableIndex: tableNumber,
          headers: [...(updatedTemplateValue.valueRef.tableRef.headers || []), sanitizedCellText],
        };

        break;
      }
      default: {
        break;
      }
    }

    return updatedTemplateValue;
  };

  const getManifestColumnTemplateValue = (existingTemplateValue: TemplateValue, columnName: keyof ManifestDocument) => {
    let updatedTemplateValue: TemplateValue;
    // If the property is not configured, or is not a manifest column name, initialize the templateValue
    if (!existingTemplateValue.valueRef || !isManifestValueRef(existingTemplateValue.valueRef)) {
      updatedTemplateValue = getDefaultManifestTemplateValue();
    } else {
      updatedTemplateValue = cloneDeep(existingTemplateValue);
    }

    if (!updatedTemplateValue.valueRef || !isManifestValueRef(updatedTemplateValue.valueRef)) return;

    updatedTemplateValue.valueRef.ref.value = columnName;

    return updatedTemplateValue;
  };

  const getStaticTemplateValue = (existingTemplateValue: TemplateValue, staticValue: string) => {
    let updatedTemplateValue: TemplateValue;
    // If the property is not configured, or is not a static value, initialize the templateValue
    if (!existingTemplateValue.valueRef || !isStaticValueRef(existingTemplateValue.valueRef)) {
      updatedTemplateValue = getDefaultStaticTemplateValue();
    } else {
      updatedTemplateValue = cloneDeep(existingTemplateValue);
    }

    if (!updatedTemplateValue.valueRef || !isStaticValueRef(updatedTemplateValue.valueRef)) return;

    updatedTemplateValue.valueRef.ref.value = staticValue;

    return updatedTemplateValue;
  };

  return {
    getKeyValuePairTemplateValue,
    getTabularDataTemplateValue,
    getManifestColumnTemplateValue,
    getStaticTemplateValue,
  };
};

import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Button,
  Header,
  Icon,
  Pagination,
  SpaceBetween,
  Table,
  TableProps,
  TextFilter,
} from '@amzn/awsui-components-react';
import React from 'react';
import { IInvoiceMetadata } from 'src/interface/type-def';
import { numericDate } from 'src/utils/dates';
import { paginationLabels } from './BlueprintsTableConfig';
import { Link } from 'react-router-dom';
import { filterTableRowByText, getTextFilterCounterText } from 'src/utils/tableUtils';
import { PAGE_ROUTES } from 'src/components/Routes';
import { EmptyTableState } from 'src/components/common-components/table/EmptyTableState';
import { ProcessingstatusIndicator } from 'src/components/manifests/ProcessingStatusIndicator';

const DEFAULT_TABLE_PAGE_SIZE = 20;

enum BLUEPRINT_INVOICES_TABLE_COLUMN_IDS {
  INVOICE_ID = 'invoiceId',
  LAST_DOCUMENT_INSIGHT = 'lastDocumentInsight',
  PROCESSING_STATUS = 'processingStatus',
  LOCATION_CODE = 'locationCode',
  ACCOUNT_NUMBER = 'accountNumber',
}

const columnDefinitions: Array<TableProps.ColumnDefinition<IInvoiceMetadata>> = [
  {
    id: BLUEPRINT_INVOICES_TABLE_COLUMN_IDS.INVOICE_ID,
    header: 'Invoice ID',
    cell: (invoice) => (
      <Link
        to={{
          pathname: `${PAGE_ROUTES.invoice}/${invoice.invoicePdfName}`,
          state: { manifest: invoice },
        }}
        target="_blank"
      >
        {invoice.invoicePdfName}
      </Link>
    ),
    sortingField: BLUEPRINT_INVOICES_TABLE_COLUMN_IDS.INVOICE_ID,
  },
  {
    id: BLUEPRINT_INVOICES_TABLE_COLUMN_IDS.LAST_DOCUMENT_INSIGHT,
    header: 'Last document insight',
    cell: (invoice) => numericDate(invoice.lastDocumentInsightGeneratedTime, true),
    sortingField: BLUEPRINT_INVOICES_TABLE_COLUMN_IDS.LAST_DOCUMENT_INSIGHT,
  },
  {
    id: BLUEPRINT_INVOICES_TABLE_COLUMN_IDS.PROCESSING_STATUS,
    header: 'Status',
    cell: (invoice) => <ProcessingstatusIndicator status={invoice.processingStatus} renderPopover />,
    minWidth: 200,
    sortingField: BLUEPRINT_INVOICES_TABLE_COLUMN_IDS.PROCESSING_STATUS,
  },
  {
    id: BLUEPRINT_INVOICES_TABLE_COLUMN_IDS.LOCATION_CODE,
    header: 'Location code',
    cell: (invoice) => invoice.manifestDocument.locationCode,
    sortingComparator: (invoiceA, invoiceB) =>
      invoiceB.manifestDocument.locationCode.localeCompare(invoiceA.manifestDocument.locationCode),
  },
  {
    id: BLUEPRINT_INVOICES_TABLE_COLUMN_IDS.ACCOUNT_NUMBER,
    header: 'Account number',
    cell: (invoice) => invoice.manifestDocument.accountNumber,
    sortingField: BLUEPRINT_INVOICES_TABLE_COLUMN_IDS.ACCOUNT_NUMBER,
    sortingComparator: (invoiceA, invoiceB) =>
      invoiceB.manifestDocument.accountNumber.localeCompare(invoiceA.manifestDocument.accountNumber),
  },
];

interface BlueprintInvoicesTableProps {
  allInvoices: Array<IInvoiceMetadata>;
  isLoadingInvoices: boolean;
  onClickReprocessAll: () => void;
  onRefreshTable: () => void;
}

export const BlueprintInvoicesTable = ({
  allInvoices,
  isLoadingInvoices,
  onClickReprocessAll,
  onRefreshTable,
}: BlueprintInvoicesTableProps) => {
  const {
    items,
    collectionProps,
    paginationProps,
    filterProps,
    filteredItemsCount = 0,
  } = useCollection(allInvoices, {
    filtering: {
      empty: <EmptyTableState title="No invoices" subtitle="There are no invoices for this blueprint" />,
      noMatch: <EmptyTableState title="No invoices" subtitle="There are no invoices that match this filter" />,
      filteringFunction: (invoice: IInvoiceMetadata, filteringText: string) =>
        filterTableRowByText(invoice.invoicePdfName, filteringText) ||
        filterTableRowByText(invoice.manifestDocument.locationCode, filteringText) ||
        filterTableRowByText(invoice.manifestDocument.accountNumber, filteringText),
    },
    pagination: {
      pageSize: DEFAULT_TABLE_PAGE_SIZE,
    },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: BLUEPRINT_INVOICES_TABLE_COLUMN_IDS.LAST_DOCUMENT_INSIGHT,
          sortingComparator: (invoiceA, invoiceB) =>
            (invoiceB.lastDocumentInsightGeneratedTime || 0) - (invoiceA.lastDocumentInsightGeneratedTime || 0),
        },
      },
    },
  });

  return (
    <Table
      {...collectionProps}
      header={
        <Header
          variant="h2"
          description="This blueprint will be applied when reprocessing these invoices."
          actions={
            <SpaceBetween direction="horizontal" size="s">
              <Button ariaLabel="refresh" onClick={onRefreshTable} loading={isLoadingInvoices}>
                <Icon name="refresh" size="normal" variant="normal" />
              </Button>
              <Button
                variant="primary"
                onClick={onClickReprocessAll}
                loading={isLoadingInvoices}
                disabled={allInvoices.length <= 0}
              >{`Reprocess all (${allInvoices.length})`}</Button>
            </SpaceBetween>
          }
        >
          {`Invoices for this blueprint (${allInvoices.length})`}
        </Header>
      }
      columnDefinitions={columnDefinitions}
      stickyHeader
      loading={isLoadingInvoices}
      loadingText="Loading invoices for this blueprint"
      items={items}
      filter={
        <TextFilter
          {...filterProps}
          filteringPlaceholder="Search by invoice ID, location code, or account number"
          countText={getTextFilterCounterText(filteredItemsCount)}
        />
      }
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
    />
  );
};

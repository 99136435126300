import React from 'react';
import { useHistory } from 'react-router-dom';

import { BreadcrumbGroup, BreadcrumbGroupProps } from '@amzn/awsui-components-react';

export const BreadcrumbGroupWithRouting: React.FC<BreadcrumbGroupProps> = ({ onClick, ...breadcrumbGroupProps }) => {
  const history = useHistory();

  const handleBreadcrumbClick = (e: CustomEvent) => {
    e.preventDefault();

    history.push(e.detail.href);

    if (onClick) {
      onClick(e);
    }
  };

  return (
    <BreadcrumbGroup
      {...breadcrumbGroupProps}
      onClick={handleBreadcrumbClick}
      ariaLabel={breadcrumbGroupProps.ariaLabel || 'Breadcrumbs'}
    />
  );
};

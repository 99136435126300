import { ExpandableSection, ExpandableSectionProps, Badge } from '@amzn/awsui-components-react';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { IInvoiceMetadata } from 'src/interface/type-def';

interface InvoiceOverrideSectionProps extends Pick<ExpandableSectionProps, 'defaultExpanded'> {
  headerText: string;
  errorCount?: number;
}

export const InvoiceOverrideSection: React.FC<InvoiceOverrideSectionProps> = (props) => {
  const { headerText, children, errorCount = 0, ...additionalProps } = props;

  return (
    <ExpandableSection
      {...additionalProps}
      headerText={headerText}
      variant="stacked"
      headerActions={errorCount > 0 ? <Badge color="red">{errorCount}</Badge> : null}
    >
      {children}
    </ExpandableSection>
  );
};

import { HelpPanel } from '@amzn/awsui-components-react';
import React from 'react';

export const BlueprintsTableHelpContent = (
  <HelpPanel header={<h2>Blueprints</h2>}>
    <div>
      <p>STUBBED PLACEHOLDER</p>
    </div>
  </HelpPanel>
);

import { Alert, Flashbar, ProgressBar } from '@amzn/awsui-components-react';
import React from 'react';

export interface InvoiceReprocessingProgressTrackerProps {
  selectedInvoiceIds: Array<string>;
  invoiceIdsToPoll: Array<string>;
  isReprocessingInvoices: boolean;
  pollingTimeoutError?: boolean;
}

export const InvoiceReprocessingProgressTracker = ({
  selectedInvoiceIds,
  invoiceIdsToPoll,
  isReprocessingInvoices,
  pollingTimeoutError,
}: InvoiceReprocessingProgressTrackerProps) => {
  const progressBarValue = ((selectedInvoiceIds.length - invoiceIdsToPoll.length) / selectedInvoiceIds.length) * 100;

  if (pollingTimeoutError) {
    return (
      <Alert type="error" header="Timeout error" data-testid="reprocessing-timeout-alert">
        Tesseract timed out while checking for status updates. You can try to reprocess these invoices again. Or, if
        you'd like to reprocess an individual invoice, navigate to the Invoice Details page by clicking the invoice's
        ID.
      </Alert>
    );
  }

  return (
    <Flashbar
      items={[
        {
          content: (
            <ProgressBar
              value={progressBarValue}
              variant="flash"
              additionalInfo={`${invoiceIdsToPoll.length}/${selectedInvoiceIds.length} invoices remaining`}
              description={
                isReprocessingInvoices
                  ? 'Reprocessing all invoices can take a few minutes to complete.'
                  : 'View the processing results for each invoice'
              }
              label={isReprocessingInvoices ? 'Reprocessing invoices' : 'Reprocessing complete'}
            />
          ),
          type: isReprocessingInvoices ? 'in-progress' : 'success',
          dismissible: false,
          id: 'invoiceReprocessingProgressBar',
        },
      ]}
    />
  );
};

/**
 * Returns true if the given tableText should be included when filtering by the given filteringText.
 *
 * @param tableText text from a cell in a table row
 * @param filteringText text to filter the table by
 * @returns true if the given tableText should be included
 */
export const filterTableRowByText = (tableText: string, filteringText: string) =>
  tableText.toLocaleLowerCase().includes(filteringText.toLocaleLowerCase());

export const getTextFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

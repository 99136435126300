import { Box, Button, Modal, SpaceBetween, TextContent } from '@amzn/awsui-components-react';
import React from 'react';

interface CancelWizardModalProps {
  isVisible: boolean;
  onDismiss: () => void;
  onDone: () => void;
}

export const CancelWizardModal = ({ isVisible, onDismiss, onDone }: CancelWizardModalProps) => (
  <Modal
    visible={isVisible}
    onDismiss={onDismiss}
    header="Leave blueprint wizard?"
    footer={
      <Box float="right">
        <SpaceBetween direction="horizontal" size="s">
          <Button onClick={onDismiss}>Cancel</Button>
          <Button variant="primary" onClick={onDone}>
            Leave
          </Button>
        </SpaceBetween>
      </Box>
    }
  >
    <TextContent>
      <p>Once you leave this page, any unsaved changes to this blueprint will be lost.</p>
    </TextContent>
  </Modal>
);

import { IInvoiceMetadata, ProcessedInvoiceData } from 'src/interface/type-def';
import { PROCESSING_OVERRIDABLE_STATUS } from 'src/interface/pipeline';

// TODO: this should probably just be a property on the invoice metadata
export const isInvoiceOverridable = (invoice: IInvoiceMetadata | null | undefined) => {
  let overridable = false;
  if (invoice?.processingStatus) {
    const permittedStatuses = new Set(Object.values(PROCESSING_OVERRIDABLE_STATUS));
    overridable = permittedStatuses.has(invoice.processingStatus as any);
  }
  return overridable;
};

import React, { useContext, useEffect, useMemo } from 'react';
import { WaterAccount } from '../types/WaterTemplateConfiguration';
import { Header, Table, TableProps } from '@amzn/awsui-components-react';
import { IInvoiceMetadata } from 'src/interface/type-def';
import { TextractResultsState } from 'src/components/textract/hooks/useFetchInvoiceTextractResults';
import { CreateWaterTemplateContext, WaterAccountTableItem } from '../CreateWaterTemplate';
import {
  getKeyFromValueRef,
  getValueFromValueRef,
  isHeaderBasedTableValueRef,
  isKeyValueRef,
} from 'src/utils/valueRefUtils';
import { SubtermPopoverText } from '../subterm/SubtermPopoverText';
import { STANDARD_PROPERTY_EDITOR_SOURCE } from './StandardPropertyEditor';

export enum ConfigureAccountTableVariant {
  Editable = 'editable', // Allows table rows to be selectable
  Readonly = 'readonly',
}

interface ConfigureAccountPropertiesProps {
  invoice: IInvoiceMetadata | null;
  account: WaterAccount;
  textractResults: TextractResultsState | null;
  variant?: ConfigureAccountTableVariant;
  originalAccount?: WaterAccount; // If updating a template, represents the existing Account configuration, before the user has made changes in the UI
}

export const ConfigureAccountProperties = ({
  invoice,
  account,
  textractResults,
  variant = ConfigureAccountTableVariant.Editable,
  originalAccount,
}: ConfigureAccountPropertiesProps) => {
  const createWaterTemplateContext = useContext(CreateWaterTemplateContext);
  const { selectedRow, setSelectedRow } = createWaterTemplateContext;

  const isEditable = variant === ConfigureAccountTableVariant.Editable;

  useEffect(() => {
    // By default, select the first property in the table
    setSelectedRow(tableItems[0]);
  }, []);

  const tableItems: Array<WaterAccountTableItem> = useMemo(
    () =>
      Object.keys(account).map((accountKey) => {
        const propertyName = accountKey as keyof WaterAccount;
        return {
          propertyName,
          templateValue: account[propertyName],
        };
      }),
    [account]
  );

  const selectedTableRow: WaterAccountTableItem | undefined = useMemo(() => {
    const selectedRowPropertyName = selectedRow?.propertyName;
    return tableItems.find((item) => item.propertyName === selectedRowPropertyName);
  }, [tableItems, selectedRow]);

  const columnDefs: Array<TableProps.ColumnDefinition<WaterAccountTableItem>> = [
    {
      id: 'propertyName',
      header: 'Property',
      cell: (e) => e.propertyName,
    },
    {
      id: 'source',
      header: 'Source',
      cell: (e) => {
        const { valueRef, source } = e.templateValue;
        if (!valueRef || !source) return '-';

        if (isKeyValueRef(valueRef)) {
          return STANDARD_PROPERTY_EDITOR_SOURCE.InvoiceKeyValue;
        }
        if (isHeaderBasedTableValueRef(valueRef)) {
          return STANDARD_PROPERTY_EDITOR_SOURCE.InvoiceTable;
        }
        return source;
      },
    },
    {
      id: 'key',
      header: 'Key',
      cell: (e) => getKeyFromValueRef(e.templateValue) || '-',
    },
    {
      id: 'value',
      header: 'Value',
      cell: (e) => getValueFromValueRef(e.templateValue, textractResults, invoice?.manifestDocument) || '-',
    },
    {
      id: 'subterm',
      header: 'Subterm',
      cell: (e) => <SubtermPopoverText subterm={e.templateValue.valueRef?.subterm} />,
    },
  ];

  return (
    <Table
      header={<Header>Account properties</Header>}
      columnDefinitions={columnDefs}
      items={tableItems}
      selectionType={isEditable ? 'single' : undefined}
      selectedItems={isEditable && selectedRow ? [selectedTableRow as WaterAccountTableItem] : undefined}
      onSelectionChange={isEditable ? (e) => setSelectedRow(e.detail.selectedItems[0]) : undefined}
    />
  );
};

export const API_NAME = 'TesseractFrontendAPI';
export const CORAL_API_NAME = 'TesseractCoralFrontendAPI';
export const BASE_DOMAIN_NAME = 'tesseract.sustainability.amazon.dev';

export enum Stage {
  DEV = 'dev',
  BETA = 'beta',
  GAMMA = 'gamma',
  PROD = 'prod',
}

export enum DEV_ENV_VALUES {
  identityPoolId = 'DEV_IDENTITY_POOL_ID',
  userPoolId = 'DEV_USER_POOL_ID',
  userPoolWebClientId = 'DEV_USER_POOL_WEB_CLIENT_ID',
  userPoolOAuthDomain = 'DEV_USER_POOL_OAUTH_DOMAIN',
  apiGatewayEndpoint = 'DEV_API_GATEWAY_ENDPOINT',
  coralEndpoint = 'DEV_CORAL_ENDPOINT',
}

type RemoteStages = Exclude<Stage, Stage.DEV>;

interface StageValues {
  identityPoolId: string;
  userPoolId: string;
  userPoolWebClientId: string;
  userPoolOAuthDomain: string;
  apiGatewayEndpoint: string;
  coralEndpoint: string;
}

type RemoteStageValues = Record<RemoteStages, StageValues>;

export const remoteStageConfig: RemoteStageValues = {
  [Stage.PROD]: {
    identityPoolId: 'us-east-1:6a5666c0-5a8b-4b59-a28b-c5c8dd00dfc2',
    userPoolId: 'us-east-1_fcDRnI5BC',
    userPoolWebClientId: 'nrvgpcbtjgai3gsrn6c26n0mv',
    userPoolOAuthDomain: 'taproot-tesseract-website-prod.auth.us-east-1.amazoncognito.com',
    apiGatewayEndpoint: 'https://gsbubl70ja.execute-api.us-east-1.amazonaws.com/prod',
    coralEndpoint: 'https://kr1wiyn9h3.execute-api.us-east-1.amazonaws.com/prod',
  },
  [Stage.GAMMA]: {
    identityPoolId: 'us-east-1:ef036562-d40f-45fd-be04-cf375777aa93',
    userPoolId: 'us-east-1_ATH8uvEGb',
    userPoolWebClientId: '5f8esdj7blhfq56rnkfb6cjvvc',
    userPoolOAuthDomain: 'taproot-tesseract-website-gamma.auth.us-east-1.amazoncognito.com',
    apiGatewayEndpoint: 'https://wjpcs8lk7k.execute-api.us-east-1.amazonaws.com/gamma',
    coralEndpoint: 'https://lo1ccchjbl.execute-api.us-east-1.amazonaws.com/gamma',
  },
  [Stage.BETA]: {
    identityPoolId: 'us-west-2:90745689-2fc5-4687-8d79-5d4ba3c1e1d0',
    userPoolId: 'us-west-2_iv8zLQSvE',
    userPoolWebClientId: '6j8tsqe6n8djk6gcm80722k9pj',
    userPoolOAuthDomain: 'taproot-tesseract-website-beta.auth.us-west-2.amazoncognito.com',
    apiGatewayEndpoint: 'https://6w880jpgf0.execute-api.us-west-2.amazonaws.com/beta',
    coralEndpoint: 'https://97np0n1dsg.execute-api.us-west-2.amazonaws.com/beta',
  },
};

export const getStage = (): Stage => {
  switch (window.location.hostname) {
    case BASE_DOMAIN_NAME:
      return Stage.PROD;
    case `${Stage.GAMMA}.${BASE_DOMAIN_NAME}`:
      return Stage.GAMMA;
    case `${Stage.BETA}.${BASE_DOMAIN_NAME}`:
      return Stage.BETA;
    default:
      return Stage.DEV;
  }
};

export const getProjectRegion = () => {
  switch (getStage()) {
    case Stage.BETA:
      return 'us-west-2';
    default:
      return 'us-east-1';
  }
};

export const getDevStageEnvOverrides = (): StageValues => ({
  identityPoolId: process.env.DEV_IDENTITY_POOL_ID ?? remoteStageConfig[Stage.GAMMA].identityPoolId,
  userPoolId: process.env.DEV_USER_POOL_ID ?? remoteStageConfig[Stage.GAMMA].userPoolId,
  userPoolWebClientId: process.env.DEV_USER_POOL_WEB_CLIENT_ID ?? remoteStageConfig[Stage.GAMMA].userPoolWebClientId,
  userPoolOAuthDomain: process.env.DEV_USER_POOL_OAUTH_DOMAIN ?? remoteStageConfig[Stage.GAMMA].userPoolOAuthDomain,
  apiGatewayEndpoint: process.env.DEV_API_GATEWAY_ENDPOINT ?? remoteStageConfig[Stage.GAMMA].apiGatewayEndpoint,
  coralEndpoint: process.env.DEV_CORAL_ENDPOINT ?? remoteStageConfig[Stage.GAMMA].coralEndpoint,
});

export const getDynamicAwsExports = (defaultExports: any) => {
  const currentStage = getStage();
  const config = currentStage === Stage.DEV ? getDevStageEnvOverrides() : remoteStageConfig[currentStage];

  const { identityPoolId, userPoolId, userPoolWebClientId, userPoolOAuthDomain, apiGatewayEndpoint, coralEndpoint } =
    config;

  return {
    ...defaultExports,
    aws_project_region: getProjectRegion(),
    aws_cognito_identity_pool_id: identityPoolId,
    aws_cognito_region: getProjectRegion(),
    aws_user_pools_id: userPoolId,
    aws_user_pools_web_client_id: userPoolWebClientId,
    oauth: {
      ...defaultExports.oauth,
      domain: userPoolOAuthDomain,
      redirectSignIn: `${window.location.origin}`,
      redirectSignOut: `${window.location.origin}`,
    },
    Auth: {
      identityPoolId,
      region: getProjectRegion(),
      userPoolId,
      userPoolWebClientId,
    },
    aws_cloud_logic_custom: [
      {
        name: API_NAME,
        endpoint: apiGatewayEndpoint,
        region: getProjectRegion(),
      },
      {
        name: CORAL_API_NAME,
        endpoint: coralEndpoint,
        region: getProjectRegion(),
      },
    ],
  };
};

import React, { useCallback, useState } from 'react';
import { upsertBlueprint as upsertBlueprintApiCall } from 'src/client/api-gateway';
import { BlueprintConfiguration } from 'src/components/blueprints/types';

export const useUpsertBlueprint = () => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submissionError, setSubmissionError] = useState<string>('');

  const upsertBlueprint = useCallback(async (blueprint: BlueprintConfiguration, user: string) => {
    setIsSubmitting(true);
    setSubmissionError('');

    try {
      const response = await upsertBlueprintApiCall(blueprint, user);
      return response.blueprintId;
    } catch (e) {
      const errorMsg = e as string;
      setSubmissionError(errorMsg);
      throw new Error(errorMsg);
    } finally {
      setIsSubmitting(false);
    }
  }, []);

  return {
    isSubmitting,
    submissionError,
    upsertBlueprint,
  };
};

import { PAGE_ROUTES } from 'src/components/Routes';

// Root breadcrumb
export const ROOT_BREADCRUMB = {
  homepage: {
    text: 'Tesseract',
    getHref: () => '',
  },
};

// Breadcrumbs with the `Invoices` page as the root
export const INVOICE_BREADCRUMBS = {
  invoices: {
    text: 'Invoices',
    getHref: () => PAGE_ROUTES.invoices,
  },
  invoiceDetails: {
    text: 'Invoice Details',
    getHref: (invoiceId: string) => `${PAGE_ROUTES.invoices}/${invoiceId}`,
  },
  invoiceOverride: {
    text: 'Invoice Override',
    getHref: (invoiceId: string) => ``,
  },
};

// Breadcrumbs with the `Blueprints` page as the root
export const BLUEPRINT_BREADCRUMBS = {
  blueprints: {
    text: 'Blueprints',
    getHref: () => PAGE_ROUTES.blueprints,
  },
  blueprintDetails: {
    text: 'Blueprint Details',
    getHref: (blueprintId: string) => `${PAGE_ROUTES.blueprint}/${blueprintId}`,
  },
  createBlueprint: {
    text: 'Create Blueprint',
    getHref: () => `${PAGE_ROUTES.createBlueprint}`,
  },
  createBlueprintWizard: {
    text: 'Create Blueprint Wizard',
    getHref: (invoiceId: string) => `${PAGE_ROUTES.createBlueprintWizard}/${invoiceId}`,
  },
  updateBlueprintWizard: {
    text: 'Update Blueprint Wizard',
    getHref: (blueprintId: string) => `${PAGE_ROUTES.updateBlueprint}/${blueprintId}`,
  },
};

import { WizardProps } from '@amzn/awsui-components-react';

export const getCreateTemplateWizardI18nStrings = (activeStepIdx: number): WizardProps['i18nStrings'] => ({
  stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
  collapsedStepsLabel: (stepNumber, stepCount) => `Step ${stepNumber} of ${stepCount}`,
  cancelButton: 'Cancel',
  previousButton: 'Previous',
  nextButton: activeStepIdx === 2 ? `Save and Next` : `Next`,
  submitButton: 'Done',
  optional: 'optional',
});

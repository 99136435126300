import { useCallback, useState } from 'react';
import { useInterval } from 'usehooks-ts';
import { IInvoiceMetadata } from 'src/interface/type-def';
import { PIPELINE_STATUS } from 'src/interface/pipeline';
import { getInvoice } from 'src/client/api-gateway';

export const INVOICE_POLLING_TIMEOUT_MS = 300000; // 5 minutes

export const isInvoiceProcessingWorkflowComplete = (invoice: IInvoiceMetadata, pollingStartTimestamp: number) => {
  const { lastStatusDate, pipelineStatus } = invoice;
  return (
    lastStatusDate &&
    lastStatusDate > pollingStartTimestamp &&
    (pipelineStatus === PIPELINE_STATUS.PUBLISHING_SUCCESS ||
      pipelineStatus === PIPELINE_STATUS.PUBLISHING_FAILED ||
      pipelineStatus === PIPELINE_STATUS.PROCESSING_FAILED ||
      pipelineStatus === PIPELINE_STATUS.VALIDATION_FAILED ||
      pipelineStatus === PIPELINE_STATUS.MANIFEST)
  );
};

export function usePollInvoices(
  delayMs: number,
  setAllInvoices: React.Dispatch<React.SetStateAction<IInvoiceMetadata[]>>
) {
  const [isPolling, setIsPolling] = useState<boolean>(false);
  const [pollingStartTimestamp, setPollingStartTimestamp] = useState<number>(0);
  const [invoiceIdsToPoll, setInvoiceIdsToPoll] = useState<Array<string>>([]);

  const [pollingTimeoutError, setPollingTimeoutError] = useState<boolean>(false);

  useInterval(
    () => {
      const fetchInvoiceRequests: Array<Promise<IInvoiceMetadata>> = invoiceIdsToPoll.map((invoiceId) =>
        getInvoice(invoiceId)
      );

      Promise.all(fetchInvoiceRequests)
        .then((invoiceResponses) => {
          // Filter out invoices that we no longer need to poll
          const updatedInvoiceIdsToPoll = invoiceResponses
            .filter((invoice) => !isInvoiceProcessingWorkflowComplete(invoice, pollingStartTimestamp))
            .map((invoice) => invoice.invoicePdfName);

          if (!updatedInvoiceIdsToPoll.length) {
            setIsPolling(false);
          }
          setInvoiceIdsToPoll(updatedInvoiceIdsToPoll);

          // In list of all invoices, update entries for the invoices we've just polled
          setAllInvoices((prevAllInvoices) =>
            prevAllInvoices.map((existingInvoice) => {
              const updatedInvoice = invoiceResponses.find(
                (invoiceResponse) => invoiceResponse.invoicePdfName === existingInvoice.invoicePdfName
              );
              return updatedInvoice || existingInvoice;
            })
          );

          // Stop polling if we've exceeded the timeout threshold. At this point, there is probably an invoice
          // that was not reprocessed to begin with, so we don't want to continue polling for status updates.
          const currTimestamp = new Date().getTime();
          if (currTimestamp - pollingStartTimestamp >= INVOICE_POLLING_TIMEOUT_MS) {
            setIsPolling(false);
            setPollingTimeoutError(true);
          }
        })
        .catch((e) => console.error(e));
    },
    isPolling ? delayMs : null
  );

  const triggerInvoicePolling = useCallback(
    (invoiceIds: Array<string>) => {
      setPollingStartTimestamp(new Date().getTime());
      setIsPolling(true);
      setInvoiceIdsToPoll(invoiceIds);
      setPollingTimeoutError(false);
    },
    [isPolling, setIsPolling]
  );

  return {
    isPolling,
    setIsPolling,
    invoiceIdsToPoll,
    triggerInvoicePolling,
    pollingTimeoutError,
  };
}

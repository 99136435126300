import React, { useState, useCallback, useRef } from 'react';
import { Alert, Box, Button, ColumnLayout, Grid, SpaceBetween, Spinner } from '@amzn/awsui-components-react';
import usePanZoom from 'use-pan-and-zoom';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

export interface PDFViewerProps {
  file: string;
  loading?: boolean;
  errorMessage?: string;
}

interface PDFViewerErrorIndicatorProps {
  message?: string;
}

const PDFViewerLoadingIndicator: React.FC = () => (
  <Box fontSize="heading-xl" textAlign="center">
    <Spinner size="large" />
    <p>Loading...</p>
  </Box>
);

const PDFViewerErrorIndicator: React.FC<PDFViewerErrorIndicatorProps> = ({
  message = "File extension must be of type '.pdf'",
}) => (
  <Alert statusIconAriaLabel="Error" type="error" header="Failed to load PDF">
    {message}
  </Alert>
);

export function PDFViewer({ file, loading = false, errorMessage }: PDFViewerProps) {
  const [totalPages, setTotalPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const { transform, zoom, panZoomHandlers, setZoom, setContainer } = usePanZoom({
    maxZoom: 3,
    minZoom: 0.25,
  });

  const handlePageNumberDecrease = () => setPageNumber((previousPageNumber) => previousPageNumber - 1);
  const handlePageNumberIncrease = () => setPageNumber((previousPageNumber) => previousPageNumber + 1);

  const handleZoomIn = () => setZoom((previousZoom) => previousZoom + 0.25);
  const handleZoomOut = () => setZoom((previousZoom) => previousZoom - 0.25);
  const handleZoomReset = () => setZoom(1);

  return (
    <section className="pdf__container">
      {loading ? (
        <PDFViewerLoadingIndicator />
      ) : (
        <>
          <Document
            file={file}
            renderMode="svg"
            loading={<PDFViewerLoadingIndicator />}
            error={<PDFViewerErrorIndicator message={errorMessage} />}
            onLoadSuccess={({ numPages }) => setTotalPages(numPages)}
          >
            <div className="pdf__content" ref={(el) => setContainer(el)} {...panZoomHandlers}>
              <div style={{ transform }}>
                <Page pageNumber={pageNumber} error={<PDFViewerErrorIndicator message={errorMessage} />} />
              </div>
            </div>
          </Document>

          {totalPages > 0 && (
            <section data-testid="PdfControls" className="pdf__controls">
              <div className="pdf__control">
                <Button disabled={pageNumber === 1} onClick={handlePageNumberDecrease} variant="primary">
                  Previous Page
                </Button>{' '}
                <Button disabled={pageNumber >= totalPages} onClick={handlePageNumberIncrease} variant="primary">
                  Next Page
                </Button>
              </div>
              <div>
                <Button disabled={zoom <= 0.25} onClick={handleZoomOut} variant="normal" iconName="zoom-out" />{' '}
                <Button disabled={zoom === 1} onClick={handleZoomReset} variant="normal">
                  Reset
                </Button>{' '}
                <Button disabled={zoom >= 3.0} onClick={handleZoomIn} variant="normal" iconName="zoom-in" />
              </div>
            </section>
          )}
        </>
      )}
    </section>
  );
}

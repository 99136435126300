import { Box, Button, Header, SpaceBetween, Table, TableProps } from '@amzn/awsui-components-react';
import React from 'react';
import { IInvoiceMetadata } from 'src/interface/type-def';
import { getCommonColumnDefinitions } from './tableConfig';

const getColumnDefinitions = (
  selectedInvoiceId: string,
  onViewInvoiceDetails: (invoiceId: string) => void
): Array<TableProps.ColumnDefinition<IInvoiceMetadata>> => [
  ...getCommonColumnDefinitions(),
  {
    id: 'results',
    header: 'Results',
    cell: (e) =>
      e.invoicePdfName === selectedInvoiceId ? (
        <Button data-testid="view-invoice-results-selected" disabled variant="inline-link">
          Viewing
        </Button>
      ) : (
        <Button
          data-testid="view-invoice-results"
          variant="inline-link"
          onClick={() => onViewInvoiceDetails(e.invoicePdfName)}
        >
          View
        </Button>
      ),
  },
];

export interface InvoiceReprocessingTableProps {
  invoices: Array<IInvoiceMetadata>;
  selectedInvoiceId: string;
  openSelectedInvoicesModal: () => void;
  isReprocessingInvoices: boolean;
  onReprocessInvoices: (invoiceIds: Array<string>) => void;
  onViewInvoiceDetails: (invoiceId: string) => void;
}

export const InvoiceReprocessingTable = ({
  invoices,
  selectedInvoiceId,
  openSelectedInvoicesModal,
  isReprocessingInvoices,
  onReprocessInvoices,
  onViewInvoiceDetails,
}: InvoiceReprocessingTableProps) => (
  <Table
    columnDefinitions={getColumnDefinitions(selectedInvoiceId, onViewInvoiceDetails)}
    header={
      <Header
        variant="h2"
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button loading={isReprocessingInvoices} onClick={openSelectedInvoicesModal}>
              Select invoices
            </Button>
            <Button
              disabled={!invoices.length}
              loading={isReprocessingInvoices}
              variant="primary"
              onClick={() => onReprocessInvoices(invoices.map((invoice) => invoice.invoicePdfName))}
            >
              {`Reprocess invoices (${invoices.length})`}
            </Button>
          </SpaceBetween>
        }
      >
        Selected invoices ({invoices.length})
      </Header>
    }
    items={invoices}
    stickyColumns={{ first: 0, last: 1 }}
    empty={
      <Box
        data-testid="invoice-reprocessing-table-empty-slot"
        margin={{ vertical: 'xs' }}
        textAlign="center"
        color="inherit"
      >
        <SpaceBetween direction="vertical" size="xs">
          <span>
            <b>No invoices selected</b>{' '}
          </span>
          <Button data-testid="select-invoices-btn" onClick={openSelectedInvoicesModal}>
            Select invoices
          </Button>
        </SpaceBetween>
      </Box>
    }
  />
);

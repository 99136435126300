import { StatusIndicatorProps } from '@amzn/awsui-components-react';

export enum PIPELINE_STATUS {
  MANIFEST = 'MANIFEST', // a manifest was processed that referenced this invoiceId
  GENERATING_EXECUTION_MANIFEST = 'GENERATING_EXECUTION_MANIFEST', // Prior to executing the Invoice Processing workflow, Tesseract generates an execution manifest file
  INGESTED = 'INGESTED', // invoice uploaded in S3
  TEXTRACTING = 'TEXTRACTING', // invoice sent to Textract
  TEXTRACT_FAILED = 'TEXTRACT_FAILED', // textract job failed
  PROCESSING_FAILED = 'PROCESSING_FAILED', // runtime error applying templates
  PROCESSING_SUCCESS = 'PROCESSING_SUCCESS', // output written to S3
  PROCESSING_BLOCKED = 'PROCESSING_BLOCKED', // templates missing
  PROCESSED_WARNING = 'PROCESSED_WARNING', // processing complete, but failed to process optional attributes
  VALIDATION_FAILED = 'VALIDATION_FAILED', // data validation error aggregating invoice data
  VALIDATION_SUCCESS = 'VALIDATION_SUCCESS', // invoice data aggregated to domain model
  PUBLISHING_FAILED = 'PUBLISHING_FAILED', // error publishing invoice data
  PUBLISHING_SUCCESS = 'PUBLISHING_SUCCESS', // invoice data published
}

export enum PIPELINE_STATUS_DESCRIPTIONS {
  MANIFEST = 'There is a manifest that references this invoice, but a PDF has not been processed',
  GENERATING_EXECUTION_MANIFEST = 'Tesseract is preparing to process this invoice',
  INGESTED = 'Invoice has been uploaded in S3',
  TEXTRACTING = 'Invoice is being processed by Textract',
  TEXTRACT_FAILED = 'Invoice processing Textract job failed',
  PROCESSING_FAILED = 'There was an error applying template to this invoice',
  PROCESSING_SUCCESS = 'This invoice was processed successfully',
  PROCESSING_BLOCKED = 'As of the last request to reprocess this invoice, there is no template for this invoice',
  PROCESSED_WARNING = 'Processing complete, but failed to process optional attributes',
  VALIDATION_FAILED = 'There was a data validation error aggregating invoice data',
  VALIDATION_SUCCESS = 'The invoice data was validated and transformed successfully',
  PUBLISHING_FAILED = 'There was an error publishing invoice data',
  PUBLISHING_SUCCESS = 'The utility data was published successfully',
}

export enum PIPELINE_OVERRIDABLE_STATUS {
  TEXTRACT_FAILED = 'TEXTRACT_FAILED',
  PROCESSING_FAILED = 'PROCESSING_FAILED',
  PROCESSING_SUCCESS = 'PROCESSING_SUCCESS',
  PROCESSING_BLOCKED = 'PROCESSING_BLOCKED',
  PROCESSED_WARNING = 'PROCESSED_WARNING',
  VALIDATION_FAILED = 'VALIDATION_FAILED',
  VALIDATION_SUCCESS = 'VALIDATION_SUCCESS',
  PUBLISHING_FAILED = 'PUBLISHING_FAILED',
  PUBLISHING_SUCCESS = 'PUBLISHING_SUCCESS',
}

export enum PROCESSING_OVERRIDABLE_STATUS {
  PROCESSING_SUCCESSFUL = 'PROCESSING_SUCCESSFUL',
  PROCESSING_FAILED = 'PROCESSING_FAILED',
  VALIDATION_SUCCESSFUL = 'VALIDATION_SUCCESSFUL',
  VALIDATION_FAILED = 'VALIDATION_FAILED',
}

export const PIPELINE_STATUS_TYPES: { [key in PIPELINE_STATUS]: StatusIndicatorProps.Type } = {
  MANIFEST: 'pending',
  GENERATING_EXECUTION_MANIFEST: 'in-progress',
  INGESTED: 'in-progress',
  TEXTRACTING: 'in-progress',
  TEXTRACT_FAILED: 'error',
  PROCESSING_FAILED: 'error',
  PROCESSING_SUCCESS: 'success',
  PROCESSING_BLOCKED: 'stopped',
  PROCESSED_WARNING: 'warning',
  VALIDATION_FAILED: 'error',
  VALIDATION_SUCCESS: 'success',
  PUBLISHING_FAILED: 'error',
  PUBLISHING_SUCCESS: 'success',
};

export type PipelineHistory = {
  [key in PIPELINE_STATUS]?: number;
};

const PIPELINE_STATUS_SEQUENCE: Array<PIPELINE_STATUS> = [
  PIPELINE_STATUS.MANIFEST,
  PIPELINE_STATUS.GENERATING_EXECUTION_MANIFEST,
  PIPELINE_STATUS.INGESTED,
  PIPELINE_STATUS.TEXTRACTING,
  PIPELINE_STATUS.TEXTRACT_FAILED,
  PIPELINE_STATUS.PROCESSING_FAILED,
  PIPELINE_STATUS.PROCESSING_BLOCKED,
  PIPELINE_STATUS.PROCESSED_WARNING,
  PIPELINE_STATUS.PROCESSING_SUCCESS,
  PIPELINE_STATUS.VALIDATION_FAILED,
  PIPELINE_STATUS.VALIDATION_SUCCESS,
  PIPELINE_STATUS.PUBLISHING_FAILED,
  PIPELINE_STATUS.PUBLISHING_SUCCESS,
];

export const pipelineStatusSortingComparator = (
  a: PIPELINE_STATUS | undefined,
  b: PIPELINE_STATUS | undefined
): number => (a ? PIPELINE_STATUS_SEQUENCE.indexOf(a) : -1) - (b ? PIPELINE_STATUS_SEQUENCE.indexOf(b) : -1);

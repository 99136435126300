import React, { useCallback, useState } from 'react';
import { getInvoiceProcessingResults } from 'src/client/api-gateway';

export interface UseFetchInvoiceProcessingResultsState {
  isLoadingResults: boolean;
  results: string;
  errorMessage: string;
  fetchInvoiceProcessingResults: (invoiceId: string) => Promise<void>;
}

export function useFetchInvoiceProcessingResults() {
  const [isLoadingResults, setIsLoadingResults] = useState<boolean>(false);
  const [results, setResults] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const fetchInvoiceProcessingResults = useCallback(async (invoiceId: string) => {
    setIsLoadingResults(true);
    setResults('');
    setErrorMessage('');

    try {
      const processingResults = await getInvoiceProcessingResults(invoiceId);
      setResults(processingResults);
    } catch (e) {
      setErrorMessage(e as string);
    } finally {
      setIsLoadingResults(false);
    }
  }, []);

  return {
    isLoadingResults,
    results,
    errorMessage,
    fetchInvoiceProcessingResults,
  };
}

import { useCallback, useState } from 'react';
import { Auth } from '@aws-amplify/auth';

export interface UseFederatedSignInState {
  isAuthenticating: boolean;
  isSignInError: boolean;
  signInWithAmazonFederate: () => Promise<void>;
}

export const useFederatedSignIn = (): UseFederatedSignInState => {
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false);
  const [isSignInError, setIsSignInError] = useState<boolean>(false);

  const signInWithAmazonFederate = useCallback(async () => {
    setIsAuthenticating(true);
    setIsSignInError(false);

    try {
      await Auth.federatedSignIn({ customProvider: 'AmazonFederate' });
    } catch (e) {
      console.error(`There was an error signing in:`, e);
      setIsSignInError(true);
    } finally {
      setIsAuthenticating(false);
    }
  }, []);

  return {
    isAuthenticating,
    isSignInError,
    signInWithAmazonFederate,
  };
};

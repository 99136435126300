import React from 'react';
import { Cell } from 'amazon-textract-response-parser/dist/cjs';
import { STANDARD_PROPERTY_EDITOR_SOURCE } from './StandardPropertyEditor';
import { TextractResultsState } from 'src/components/textract/hooks/useFetchInvoiceTextractResults';
import { TextractKeyValue } from 'src/helpers/textract';
import { TABULAR_DATA_CELL_TYPES } from '../../create/tabularDataMapping/ApplyTabularDataCellButton';
import { ManifestDocument } from 'src/interface/type-def';
import { TextractKeyValuePairs } from 'src/components/textract/TextractKeyValuePairs';
import { TextractTabularData } from 'src/components/textract/TextractTabularData';
import { ManifestDocumentSelector } from './ManifestDocumentSelector';

interface StandardPropertyValueSelectorProps {
  source: string;
  textractResults: TextractResultsState | null;
  manifestDocument: ManifestDocument | null;
  onApplyKeyValuePair: (keyValue: TextractKeyValue) => void;
  onApplyTabularDataCell: (
    cellType: TABULAR_DATA_CELL_TYPES,
    selectedCell: Cell,
    pageNumber: number,
    tableNumber: number
  ) => void;
  onApplyManifestColumn: (columnName: keyof ManifestDocument) => void;
  selectedKey?: { keyName: string; pageNumber: number }; // Should be defined if source is key-value pair
  selectedColumnName?: string; // Should be defined if source is manifest file
}

export const StandardPropertyValueSelector = ({
  source,
  textractResults,
  manifestDocument,
  onApplyKeyValuePair,
  onApplyTabularDataCell,
  onApplyManifestColumn,
  selectedKey,
  selectedColumnName,
}: StandardPropertyValueSelectorProps) => (
  <>
    {source === STANDARD_PROPERTY_EDITOR_SOURCE.InvoiceKeyValue && (
      <TextractKeyValuePairs
        textractKeyValuePairs={textractResults?.pageKeyValuesList || []}
        onApplyKeyValuePair={onApplyKeyValuePair}
        selectedKey={selectedKey}
      />
    )}

    {source === STANDARD_PROPERTY_EDITOR_SOURCE.InvoiceTable && (
      <TextractTabularData
        textractPageTables={textractResults?.pageTablesList || []}
        onApplyTabularDataCell={onApplyTabularDataCell}
      />
    )}

    {source === STANDARD_PROPERTY_EDITOR_SOURCE.Manifest && (
      <ManifestDocumentSelector
        manifestDocument={manifestDocument || null}
        onApplyColumn={onApplyManifestColumn}
        selectedColumnName={selectedColumnName}
      />
    )}
  </>
);

import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Container,
  Header,
  NonCancelableCustomEvent,
  SpaceBetween,
  Spinner,
  SplitPanel,
  Wizard,
  WizardProps,
} from '@amzn/awsui-components-react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AppContext } from 'src/components/App';
import { useFetchPdf } from 'src/components/common-components/hooks/useFetchPdf';
import { IInvoiceMetadata } from 'src/interface/type-def';
import { BlueprintConfiguration, BlueprintPropertyValueType } from 'src/components/blueprints/types';
import { CancelWizardModal } from 'src/components/templates/waterTemplates/CancelWizardModal';
import { PAGE_ROUTES } from 'src/components/Routes';
import { useUpsertBlueprint } from 'src/components/blueprints/hooks/useUpsertBlueprint';
import { FlashMessage } from 'src/components/common-components/FlashMessage';
import { useConfigureBlueprint } from 'src/components/blueprints/hooks/useConfigureBlueprint';
import { ConfigureBlueprintMetadata } from 'src/components/blueprints/blueprintMetadata/ConfigureBlueprintMetadata';
import { BlueprintPromptsEditor } from 'src/components/blueprints/prompts/BlueprintPromptsEditor';
import { useValidateBlueprintMetadata } from 'src/components/blueprints/hooks/useValidateBlueprintMetadata';
import { getBlueprintWizardI18nStrings } from 'src/components/blueprints/strings';
import { BlueprintSummary } from 'src/components/blueprints/blueprintSummary/BlueprintSummary';
import { SplitPanelContext } from 'src/components/split-panel/SplitPanelProvider';
import SplitPanelI18nStrings from 'src/components/split-panel/strings';
import { PDFComponent } from 'src/components/common-components/pdf-component';
import { getInvoice } from 'src/client/api-gateway';
import { WATER_BLUEPRINT_DEFINITION } from 'src/components/blueprints/blueprintDefinition/waterBlueprintDefinition';
import { BreadcrumbGroupWithRouting } from 'src/components/breadcrumbs/BreadcrumbGroupWithRouting';
import { ROOT_BREADCRUMB, BLUEPRINT_BREADCRUMBS } from 'src/components/breadcrumbs/breadcrumbs';
import { VerifyBlueprint } from 'src/components/blueprints/blueprintVerification/VerifyBlueprint';
import { convertMilliSecondsToSeconds, getDefaultBlueprintValidFromDate } from 'src/utils/datePickerUtils';

// Default initial blueprint state when creating a blueprint
const EMPTY_BLUEPRINT: BlueprintConfiguration = {
  id: '',
  keystoneBlueprintId: '',
  blueprintName: '',
  blueprintDescription: '',
  validFrom: getDefaultBlueprintValidFromDate(),
  lastUpdatedBy: '',
  updateLog: [],
  blueprintConfigurationSettings: {
    billing: {
      billingAccountIdSetting: {
        type: BlueprintPropertyValueType.Prompt,
        prompt: '',
      },
      billingStartDateSetting: {
        type: BlueprintPropertyValueType.Prompt,
        prompt: '',
      },
      billingEndDateSetting: {
        type: BlueprintPropertyValueType.Prompt,
        prompt: '',
      },
      billingChargeAmountSetting: {
        type: BlueprintPropertyValueType.Prompt,
        prompt: '',
      },
      billingChargeCurrencySetting: {
        type: BlueprintPropertyValueType.Prompt,
        value: '',
      },
      billingUsageAmountSetting: {
        type: BlueprintPropertyValueType.Prompt,
        prompt: '',
      },
      billingUsageUnitSetting: {
        type: BlueprintPropertyValueType.Prompt,
        value: '',
      },
    },
    meter: {
      meterIdSetting: {
        type: BlueprintPropertyValueType.Prompt,
        prompt: '',
      },
      meterTypeSetting: {
        type: BlueprintPropertyValueType.Prompt,
        prompt: '',
      },
      meterReadFromDateSetting: {
        type: BlueprintPropertyValueType.Prompt,
        prompt: '',
      },
      meterReadToDateSetting: {
        type: BlueprintPropertyValueType.Prompt,
        prompt: '',
      },
      meterChargeAmountSetting: {
        type: BlueprintPropertyValueType.Prompt,
        prompt: '',
      },
      meterChargeCurrencySetting: {
        type: BlueprintPropertyValueType.Prompt,
        value: '',
      },
      meterUsageAmountSetting: {
        type: BlueprintPropertyValueType.Prompt,
        prompt: '',
      },
      meterUsageUnitSetting: {
        type: BlueprintPropertyValueType.Prompt,
        value: '',
      },
    },
  },
};

const getDefaultEmptyBlueprint = (vendorId: string) => ({
  ...EMPTY_BLUEPRINT,
  id: vendorId,
});

const CreateBlueprintWizardSplitPanel: React.FC = ({ children }) => (
  <SplitPanel data-testid="createBlueprintSplitPanel" i18nStrings={SplitPanelI18nStrings} header="View Invoice">
    {children}
  </SplitPanel>
);

interface UseLocationState {
  invoice?: IInvoiceMetadata; // Defined if creating a blueprint; represents the invoice being used to create the blueprint
  blueprint?: BlueprintConfiguration; // Defined if updating a blueprint
}

export const CreateBlueprintWizard = () => {
  const history = useHistory();

  const { user } = useContext(AppContext);
  const { setSplitPanel, showSplitPanel } = useContext(SplitPanelContext);

  const { state } = useLocation<UseLocationState>();
  const { blueprint: existingBlueprint, invoice: existingInvoice } = state || {};
  const isUpdatingBlueprint = !!existingBlueprint;

  const { pdf, pdfLoading, fetchPdf } = useFetchPdf();
  const { isSubmitting, submissionError, upsertBlueprint } = useUpsertBlueprint();

  // This hook returns the `blueprint` being created or updated, and helper functions to update the blueprint
  // and its properties
  const { blueprint, setBlueprint, updateBillingProperty, updateMeterProperty } = useConfigureBlueprint(
    existingBlueprint || getDefaultEmptyBlueprint(existingInvoice?.manifestDocument.vendorNumber || '')
  );
  // This hook contains the validation logic and error state for the `Configure Blueprint Metadata`
  // step of the wizard
  const { blueprintNameError, validateBlueprintName } = useValidateBlueprintMetadata();

  // Invoice used to create the blueprint
  const [invoice, setInvoice] = useState<IInvoiceMetadata | null>(existingInvoice || null);
  const [isLoadingInvoice, setIsLoadingInvoice] = useState<boolean>(false);

  // Modal that renders when user clicks "Cancel" button in Wizard
  const [isCancelModalVisible, setIsCancelModalVisible] = useState<boolean>(false);

  // Wizard-specific state
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
  const [stepErrorMessage, setStepErrorMessage] = useState<string>('');

  const fetchInvoice = useCallback((invoiceId: string) => {
    if (!invoiceId) return;

    setIsLoadingInvoice(true);

    getInvoice(invoiceId)
      .then((res) => setInvoice(res))
      .catch((err) => console.error(`Failed to fetch invoice ${invoiceId}: ${err}`))
      .finally(() => setIsLoadingInvoice(false));
  }, []);

  // Initialize the split panel state, which renders the invoice PDF
  useEffect(() => {
    setSplitPanel(
      <CreateBlueprintWizardSplitPanel>
        <PDFComponent pdfFile={pdf} pdfFileLoading={pdfLoading} />
      </CreateBlueprintWizardSplitPanel>
    );
    showSplitPanel(true);

    return () => {
      setSplitPanel(undefined);
      showSplitPanel(false);
    };
  }, [pdf, pdfLoading, setSplitPanel, showSplitPanel]);

  // Fetch initial set of data needed to create or update this blueprint
  useEffect(() => {
    const invoiceIdForBlueprint = existingInvoice?.invoicePdfName;

    if (!invoiceIdForBlueprint) return;

    fetchPdf(invoiceIdForBlueprint);

    if (isUpdatingBlueprint) {
      fetchInvoice(invoiceIdForBlueprint);
    }
  }, []);

  const handleBlueprintNameChange = useCallback(
    (input: string) => {
      setBlueprint((prevBlueprint) => ({ ...prevBlueprint, blueprintName: input }));
      validateBlueprintName(input);
    },
    [setBlueprint, validateBlueprintName]
  );

  const handleBlueprintDescriptionChange = useCallback(
    (description: string) => {
      setBlueprint((prevBlueprint) => ({ ...prevBlueprint, blueprintDescription: description }));
    },
    [setBlueprint]
  );

  const handleValidFromDateChange = useCallback(
    (validFromDate: string) => {
      setBlueprint((prevBlueprint) => ({ ...prevBlueprint, validFrom: Date.parse(validFromDate) / 1000 }));
    },
    [setBlueprint]
  );

  const onNavigate = async ({ detail }: NonCancelableCustomEvent<WizardProps.NavigateDetail>) => {
    const { requestedStepIndex } = detail;

    if (requestedStepIndex === 1) {
      if (blueprintNameError || !blueprint.blueprintName) {
        setStepErrorMessage('Please input a valid blueprint name before proceeding to the next step.');
        return;
      }
      setStepErrorMessage('');
    }

    if (requestedStepIndex === 3) {
      try {
        await upsertBlueprint(blueprint, user);
        setActiveStepIndex(requestedStepIndex);
      } catch (e) {
        console.error(e);
        // Don't navigate to the next page if there was an error submitting this blueprint.
        return;
      }
    }

    setActiveStepIndex(requestedStepIndex);
  };

  const getWizardBreadcrumbs = () => {
    const breadcrumbs = [
      {
        text: ROOT_BREADCRUMB.homepage.text,
        href: ROOT_BREADCRUMB.homepage.getHref(),
      },
      {
        text: BLUEPRINT_BREADCRUMBS.blueprints.text,
        href: BLUEPRINT_BREADCRUMBS.blueprints.getHref(),
      },
    ];

    if (isUpdatingBlueprint) {
      breadcrumbs.push({
        text: BLUEPRINT_BREADCRUMBS.updateBlueprintWizard.text,
        href: BLUEPRINT_BREADCRUMBS.updateBlueprintWizard.getHref(blueprint.id),
      });
    } else {
      breadcrumbs.push(
        {
          text: BLUEPRINT_BREADCRUMBS.createBlueprint.text,
          href: BLUEPRINT_BREADCRUMBS.createBlueprint.getHref(),
        },
        {
          text: BLUEPRINT_BREADCRUMBS.createBlueprintWizard.text,
          href: BLUEPRINT_BREADCRUMBS.createBlueprintWizard.getHref(invoice?.invoicePdfName || ''),
        }
      );
    }

    return breadcrumbs;
  };

  if (isLoadingInvoice) {
    return (
      <Container>
        <Box textAlign="center">
          <SpaceBetween direction="vertical" size="s">
            <Spinner size="large" />
            Loading invoice details
          </SpaceBetween>
        </Box>
      </Container>
    );
  }

  return (
    <SpaceBetween size="l" direction="vertical">
      <BreadcrumbGroupWithRouting items={getWizardBreadcrumbs()} />
      {isUpdatingBlueprint && (
        <Alert type="info">
          You are updating blueprint{' '}
          <Link
            to={{
              pathname: `${PAGE_ROUTES.blueprint}/${existingBlueprint.id}`,
              search: `?validFrom=${existingBlueprint.validFrom}`,
            }}
            target="_blank"
          >
            {existingBlueprint.blueprintName}
          </Link>
        </Alert>
      )}
      {isCancelModalVisible && (
        <CancelWizardModal
          isVisible={isCancelModalVisible}
          onDismiss={() => setIsCancelModalVisible(false)}
          onDone={() => history.push(PAGE_ROUTES.blueprints)}
        />
      )}

      <Wizard
        i18nStrings={getBlueprintWizardI18nStrings(activeStepIndex)}
        onCancel={() => setIsCancelModalVisible(true)}
        onSubmit={() =>
          history.push({
            pathname: `${PAGE_ROUTES.blueprint}/${blueprint.id}`,
            search: `?validFrom=${blueprint.validFrom}`,
          })
        }
        onNavigate={onNavigate}
        activeStepIndex={activeStepIndex}
        isLoadingNextStep={isSubmitting}
        steps={[
          {
            title: 'Configure Metadata',
            content: (
              <ConfigureBlueprintMetadata
                blueprint={blueprint}
                vendorName={invoice?.manifestDocument.vendorName || ''}
                vendorId={invoice?.manifestDocument.vendorNumber || ''}
                onBlueprintNameChange={handleBlueprintNameChange}
                blueprintNameError={blueprintNameError}
                onBlueprintDescriptionChange={handleBlueprintDescriptionChange}
                onValidFromChange={handleValidFromDateChange}
              />
            ),
            description:
              'This blueprint will be applied when processing invoices from the same vendor as the invoice you selected to use.',
            errorText: stepErrorMessage ?? undefined,
          },
          {
            title: 'Configure Blueprint',
            content: (
              <Container header={<Header variant="h2">Prompt Editor</Header>}>
                <SpaceBetween direction="vertical" size="m">
                  <Alert type="info">
                    After editing your prompts, you can review and save this blueprint from the next step. Then, you can
                    test how your prompts perform against different invoices. You can always come back to this step to
                    modify your prompts as needed.
                  </Alert>
                  <BlueprintPromptsEditor
                    blueprint={blueprint}
                    blueprintDefinition={WATER_BLUEPRINT_DEFINITION}
                    onSaveBillingProperty={updateBillingProperty}
                    onSaveMeterProperty={updateMeterProperty}
                  />
                </SpaceBetween>
              </Container>
            ),
            description:
              'The prompt for each field should describe how to extract the relevant data from the invoice. In a later step, you can test to see how your prompts perform against different invoices.',
          },
          {
            title: 'Review and Save Blueprint',
            content: (
              <SpaceBetween direction="vertical" size="s">
                <BlueprintSummary blueprint={blueprint} />
                {isSubmitting && (
                  <FlashMessage message="Saving changes to this blueprint" type="in-progress" dismissible={false} />
                )}
                {submissionError && (
                  <FlashMessage
                    header="There was an error submitting this blueprint"
                    message={`Error: ${submissionError}`}
                    type="error"
                  />
                )}
              </SpaceBetween>
            ),
          },
          {
            title: 'Verify Blueprint',
            content: <VerifyBlueprint blueprint={blueprint} />,
          },
        ]}
      />
    </SpaceBetween>
  );
};

import React from 'react';
import { Box, Header, SpaceBetween, Table } from '@amzn/awsui-components-react';
import {
  BlueprintBillingConfigurationSettings,
  BlueprintMeterConfigurationSettings,
  BlueprintPropertyValueSettings,
  BlueprintPropertyValueType,
} from './types';
import { BlueprintPropertyTypeText } from 'src/components/blueprints/BlueprintPropertyTypeText';

interface BlueprintDisplayRow {
  field: string;
  type: string;
  prompt?: string;
  value?: any;
}

const createDisplayRows = (
  bluePrintMapping: BlueprintBillingConfigurationSettings | BlueprintMeterConfigurationSettings
) =>
  Object.entries(bluePrintMapping).map((mapping) => {
    const rowMapping: BlueprintDisplayRow = {
      field: mapping[0],
      type: mapping[1].type,
      prompt: mapping[1].prompt,
      value: mapping[1].value,
    };
    return rowMapping;
  });

export const BlueprintConfigurationSummary = ({
  blueprintConfigurationSettings,
}: {
  blueprintConfigurationSettings: BlueprintPropertyValueSettings;
}) => (
  <SpaceBetween size="l">
    <Header>Billing</Header>
    {Object.keys(blueprintConfigurationSettings.billing).length ? (
      <BlueprintBillingConfigurationDisplay billing={blueprintConfigurationSettings.billing} />
    ) : (
      <p>No billing account</p>
    )}
    <Header>Meters</Header>
    {Object.keys(blueprintConfigurationSettings.meter).length > 0 ? (
      <BlueprinMetersConfigurationDisplay meter={blueprintConfigurationSettings.meter} />
    ) : (
      <p>No meters</p>
    )}
  </SpaceBetween>
);

const BlueprintBillingConfigurationDisplay = ({ billing }: { billing: BlueprintBillingConfigurationSettings }) => {
  const displayRows = createDisplayRows(billing);
  return (
    <div>
      <BlueprintConfigurationSummaryTable items={displayRows} />
    </div>
  );
};

const BlueprinMetersConfigurationDisplay = ({ meter }: { meter: BlueprintMeterConfigurationSettings }) => {
  const displayRows = createDisplayRows(meter);
  return (
    <div>
      <BlueprintConfigurationSummaryTable items={displayRows} />
    </div>
  );
};

const BlueprintConfigurationSummaryTable = ({ items }: { items: BlueprintDisplayRow[] }) => (
  <Table
    columnDefinitions={[
      {
        id: 'field',
        header: 'Field',
        cell: (item) => item.field || '-',
      },
      {
        id: 'type',
        header: 'Type',
        cell: (item) => (
          <BlueprintPropertyTypeText propertyName={item.field} type={item.type as BlueprintPropertyValueType} />
        ),
      },
      {
        id: 'propertyValue',
        header: 'Property Value',
        cell: (item) => item.value || item.prompt,
      },
    ]}
    items={items}
    empty={
      <Box textAlign="center" color="inherit">
        <b>No resources</b>
        <Box padding={{ bottom: 's' }} variant="p" color="inherit">
          No resources to display.
        </Box>
      </Box>
    }
  />
);

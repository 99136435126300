import { ValueRef } from 'src/components/templates/create/mapToSchema/map-to-schema';
import { getMatchingKeyValuePairForKey } from 'src/components/templates/templateSuggestions/templateSuggestionsUtils';
import { TextractResultsState } from 'src/components/textract/hooks/useFetchInvoiceTextractResults';
import {
  TextractAnalyzeDocumentKeyValueRef,
  ValueRefTypes,
  TextractAnalyzeDocumentHeaderBasedTableValueRef,
  StaticValueRef,
  ManifestValueRef,
  TemplateValue,
  APIs,
  Services,
  Source,
  ManifestDocument,
} from 'src/interface/type-def';

export const getDefaultKeyValueTemplateValue = (): TemplateValue => ({
  source: Source.INVOICE,
  valueRef: {
    type: ValueRefTypes.KeyValue,
    api: APIs.AnalyzeDocument,
    service: Services.Textract,
    ref: {
      key: '',
    },
  },
  include: true,
});

export const getDefaultTabularDataTemplateValue = (): TemplateValue => ({
  source: Source.INVOICE,
  valueRef: {
    type: ValueRefTypes.HeaderBasedTableValue,
    api: APIs.AnalyzeDocument,
    service: Services.Textract,
    page: 0,
    tableRef: {
      tableIndex: 0,
      headers: [],
    },
    cellRef: {
      cellCoordinate: [0, 0],
      headerRefs: [],
    },
  },
  include: true,
});

export const getDefaultManifestTemplateValue = (): TemplateValue => ({
  source: Source.MANIFEST,
  valueRef: {
    type: ValueRefTypes.ManifestValue,
    ref: {
      value: '',
    },
  },
  include: true,
});

export const getDefaultStaticTemplateValue = (): TemplateValue => ({
  source: Source.STATIC,
  valueRef: {
    type: ValueRefTypes.StaticValue,
    ref: {
      value: '',
    },
  },
  include: true,
});

export const isKeyValueRef = (valueRef: ValueRef): valueRef is TextractAnalyzeDocumentKeyValueRef =>
  valueRef.type === ValueRefTypes.KeyValue;

export const isHeaderBasedTableValueRef = (
  valueRef: ValueRef
): valueRef is TextractAnalyzeDocumentHeaderBasedTableValueRef => valueRef.type === ValueRefTypes.HeaderBasedTableValue;

export const isStaticValueRef = (valueRef: ValueRef): valueRef is StaticValueRef =>
  valueRef.type === ValueRefTypes.StaticValue;

export const isManifestValueRef = (valueRef: ValueRef): valueRef is ManifestValueRef =>
  valueRef.type === ValueRefTypes.ManifestValue;

export const getKeyFromValueRef = (templateValue: TemplateValue | null) => {
  if (!templateValue) return '';

  const { valueRef } = templateValue;
  if (!valueRef) return '';

  if (isKeyValueRef(valueRef)) {
    return valueRef.ref.key;
  }
  if (isManifestValueRef(valueRef)) {
    return valueRef.ref.value;
  }

  return '';
};

export const getValueFromValueRef = (
  templateValue: TemplateValue,
  textractResults: TextractResultsState | null,
  manifestDocument?: ManifestDocument
): string => {
  const { valueRef } = templateValue;
  if (!valueRef) return '';

  if (isKeyValueRef(valueRef)) {
    const keyValuePair = getMatchingKeyValuePairForKey(
      textractResults?.pageKeyValuesList || [],
      valueRef.ref.key,
      valueRef.page
    );
    return keyValuePair?.value || '';
  }
  if (isHeaderBasedTableValueRef(valueRef)) {
    return valueRef.cellRef.cellText || `${valueRef.cellRef.cellCoordinate}`;
  }
  if (isManifestValueRef(valueRef)) {
    const manifestColumnName = valueRef.ref.value;
    return manifestDocument && manifestColumnName in manifestDocument
      ? manifestDocument[manifestColumnName as keyof ManifestDocument]
      : '';
  }
  if (isStaticValueRef(valueRef)) {
    return valueRef.ref.value;
  }

  return '';
};

export const getHeadersFromValueRef = (templateValue: TemplateValue): Array<string> => {
  if (!templateValue.valueRef || !isHeaderBasedTableValueRef(templateValue.valueRef)) {
    return [];
  }

  return templateValue.valueRef.cellRef.headerRefs.map((headerRef) => headerRef.header);
};

export const getTableHeadersFromValueRef = (templateValue: TemplateValue): Array<string> => {
  if (!templateValue.valueRef || !isHeaderBasedTableValueRef(templateValue.valueRef)) {
    return [];
  }

  return templateValue.valueRef.tableRef.headers;
};

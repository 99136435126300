import React, { useCallback, useState } from 'react';
import { getPresigneds3Url } from 'src/helpers/storage';

export interface UseFetchPdf {
  pdf: string;
  pdfLoading: boolean;
  fetchPdf: (filepath: string) => Promise<void>;
  pdfErrorMessage: string;
}

export function useFetchPdf() {
  const [pdf, setPdf] = useState<string>('');
  const [pdfLoading, setPdfLoading] = useState(false);
  const [pdfErrorMessage, setPdfErrorMessage] = useState<string>('');

  const fetchPdf = useCallback(async (invoiceId: string) => {
    setPdf('');
    setPdfErrorMessage('');

    if (!invoiceId) return;

    try {
      setPdfLoading(true);
      const pdfResponse = await getPresigneds3Url(invoiceId);
      setPdf(pdfResponse);
    } catch (e) {
      setPdf('');
      setPdfErrorMessage(e as string);
    } finally {
      setPdfLoading(false);
    }
  }, []);

  return {
    pdf,
    pdfLoading,
    fetchPdf,
    pdfErrorMessage,
  };
}

import React from 'react';
import { Account } from 'src/interface/type-def';
import { FieldType } from './map-to-schema';
import { TableOfProperties } from './TableOfProperties';
import { TemplatePropertySuggestedValues } from '../../templateSuggestions/templateSuggestionsUtils';

interface AccountSummaryProps {
  account: Partial<Account>;
  editTemplateValue: Function;
  excludeTemplateValue: Function;
  suggestedTemplateValues: TemplatePropertySuggestedValues | null;
}

export const AccountSummary = ({
  account,
  editTemplateValue,
  excludeTemplateValue,
  suggestedTemplateValues,
}: AccountSummaryProps) => (
  <TableOfProperties
    fieldType={FieldType.ACCOUNT}
    obj={account}
    editTemplateValue={editTemplateValue}
    excludeTemplateValue={excludeTemplateValue}
    suggestedTemplateValues={suggestedTemplateValues}
  />
);

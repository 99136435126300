import React from 'react';
import { Alert, Box, Header, SpaceBetween, Spinner, Table, TextContent } from '@amzn/awsui-components-react';
import { Account, Charge, IInvoiceMetadata, Meter, TemplateMap, Usage, ValueRefTypes } from 'src/interface/type-def';
import { WaterTemplateMap } from './waterTemplates/types/WaterTemplateConfiguration';
import { isLegacyTemplateMap } from './create/createUtils';
import { WaterTemplateMapSummary } from './waterTemplates/templateSummary/WaterTemplateMapSummary';
import { TextractResultsState } from '../textract/hooks/useFetchInvoiceTextractResults';

export const TemplateMapSummary = ({
  templateMap,
  isLoading = false,
  invoice,
  textractResults,
}: {
  templateMap: TemplateMap | WaterTemplateMap;
  isLoading?: boolean;
  invoice: IInvoiceMetadata | null;
  textractResults: TextractResultsState | null;
}) => {
  // Render legacy template map
  if (isLegacyTemplateMap(templateMap)) {
    return (
      <SpaceBetween size="l">
        <Header>Account</Header>
        <TemplateAccount account={templateMap.account} />
        <Header>Charges</Header>
        {templateMap.charges.length ? <TemplateCharges charges={templateMap.charges} /> : <p>No charges</p>}
        <Header>Meters</Header>
        {templateMap.meters.length ? <TemplateMeters meters={templateMap.meters} /> : <p>No meters</p>}
      </SpaceBetween>
    );
  }

  if (isLoading) {
    return (
      <>
        <Spinner />
        <Box variant="span" margin={{ left: 'xs' }}>
          Loading template map...
        </Box>
      </>
    );
  }

  if (!invoice || !textractResults) {
    return <Alert type="error">There was en error loading this template. Please try again.</Alert>;
  }

  return (
    <SpaceBetween size="s" direction="vertical">
      <Alert type="info">
        Note: The value for each property below is based on the invoice the template is created from. When re-processing
        other invoices, the extracted values may differ.
      </Alert>
      <WaterTemplateMapSummary templateMap={templateMap} invoice={invoice} textractResults={textractResults} />
    </SpaceBetween>
  );
};

const TemplateAccount = ({ account }: { account: Account }) => (
  <div>
    <TemplateMapTable items={Object.entries(account)} />
  </div>
);

const TemplateCharges = ({ charges }: { charges: Charge[] }) => {
  const rows = charges.map((charge, idx) => <TemplateCharge charge={charge} key={idx} />);
  return <div>{rows}</div>;
};

const TemplateCharge = ({ charge }: { charge: Charge }) => (
  <div>
    <TemplateMapTable items={Object.entries(charge)} />
  </div>
);

const TemplateUsages = ({ usages }: { usages: Usage[] }) => {
  const rows = usages.map((usage, idx) => <TemplateUsage usage={usage} key={idx} />);
  return <div>{rows}</div>;
};

const TemplateUsage = ({ usage }: { usage: Usage }) => (
  <div>
    <TemplateMapTable items={Object.entries(usage)} />
  </div>
);

/**
 * Meter properties:
 * meterId: TemplateValue;
  meterNumber: TemplateValue;
  serviceType: TemplateValue;
  usageAmount: TemplateValue;
 * @param param0 
 * @returns 
 */
const TemplateMeter = ({ meter, index }: { meter: Meter; index: number }) => (
  <div>
    <p>Meter #{index}</p>
    <div>
      <TemplateMapTable items={Object.entries(meter)} />
    </div>
    <p>Meter #{index} (Usages)</p>
    <TemplateCharges charges={meter.charges} />
    <p>Meter #{index} (Charges)</p>
    <TemplateUsages usages={meter.usages} />
  </div>
);
const TemplateMeters = ({ meters }: { meters: Meter[] }) => {
  const meterRows = meters.map((meter, idx) => <TemplateMeter meter={meter} key={idx} index={idx} />);

  return <div>{meterRows}</div>;
};

const TemplateMapTable = ({ items }: { items: [string, any][] }) => (
  <Table
    columnDefinitions={[
      {
        id: 'output',
        header: 'Output field',
        cell: (item) => item[0] || '-',
      },
      {
        id: 'source',
        header: 'Source',
        cell: (item) => item[1]?.source || '-',
      },
      {
        id: 'mapping',
        header: 'Mapping',
        cell: (item) => {
          const source = item[1]?.source || '';
          const valueRef = item[1]?.valueRef;

          const fieldMapping =
            valueRef?.type === ValueRefTypes.HeaderBasedTableValue
              ? {
                  tableRef: valueRef?.tableRef,
                  cellRef: valueRef?.cellRef,
                }
              : valueRef?.ref || '';
          return fieldMapping ? JSON.stringify(fieldMapping, null, 2) : '-';
        },
      },
      {
        id: 'subterm',
        header: 'Subterm',
        cell: (item) => (item[1]?.valueRef?.subterm ? JSON.stringify(item[1].valueRef.subterm) : 'None (full text)'),
      },
    ]}
    items={items}
    empty={
      <Box textAlign="center" color="inherit">
        <b>No resources</b>
        <Box padding={{ bottom: 's' }} variant="p" color="inherit">
          No resources to display.
        </Box>
      </Box>
    }
  />
);

import { useCollection } from '@amzn/awsui-collection-hooks';
import { Header, Pagination, Table, TableProps, TextFilter } from '@amzn/awsui-components-react';
import React from 'react';
import { TemplateLog } from 'src/interface/type-def';
import { convertSecondsToMilliseconds, numericDate } from 'src/utils/dates';
import { paginationLabels } from '../template-table-config';
import { filterTableRowByText, getTextFilterCounterText } from 'src/utils/tableUtils';

const DEFAULT_PAGE_SIZE = 20;

enum TEMPLATE_LOG_TABLE_COLUMN_IDS {
  USERNAME = 'username',
  TIMESTAMP = 'timestamp',
}

const columnDefinitions: Array<TableProps.ColumnDefinition<TemplateLog>> = [
  {
    id: TEMPLATE_LOG_TABLE_COLUMN_IDS.USERNAME,
    header: 'Username',
    cell: (item: TemplateLog) => item.username || '-',
    sortingField: TEMPLATE_LOG_TABLE_COLUMN_IDS.USERNAME,
  },
  {
    id: TEMPLATE_LOG_TABLE_COLUMN_IDS.TIMESTAMP,
    header: 'Update time',
    // NOTE: `TemplateLog` timestamps are stored in seconds (rather than ms) on the backend
    cell: (item: TemplateLog) => numericDate(convertSecondsToMilliseconds(item.timestamp), true),
    sortingField: TEMPLATE_LOG_TABLE_COLUMN_IDS.TIMESTAMP,
  },
];

interface TemplateLogTableProps {
  templateLogEntries: Array<TemplateLog>;
}

export const TemplateLogTable = ({ templateLogEntries }: TemplateLogTableProps) => {
  const {
    items,
    collectionProps,
    paginationProps,
    filterProps,
    filteredItemsCount = 0,
  } = useCollection(templateLogEntries, {
    filtering: {
      filteringFunction: (templateLog: TemplateLog, filteringText: string) =>
        filterTableRowByText(templateLog.username, filteringText),
      empty: 'This template has not been updated yet.',
      noMatch: 'No updates found for this user.',
    },
    pagination: {
      pageSize: DEFAULT_PAGE_SIZE,
    },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: TEMPLATE_LOG_TABLE_COLUMN_IDS.TIMESTAMP,
          sortingComparator: (templateLogA, templateLogB) => templateLogB.timestamp - templateLogA.timestamp,
        },
      },
    },
  });

  return (
    <Table
      {...collectionProps}
      header={<Header variant="h2">{`Template update log (${templateLogEntries.length})`}</Header>}
      columnDefinitions={columnDefinitions}
      stickyHeader
      items={items}
      filter={
        <TextFilter
          {...filterProps}
          filteringPlaceholder="Search by username"
          countText={getTextFilterCounterText(filteredItemsCount)}
        />
      }
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
    />
  );
};

import { Alert, Box, Button, FormField, Input, Modal, SpaceBetween, Textarea } from '@amzn/awsui-components-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ValueSplitBySeparator } from './ValueSplitBySeparator';
import { Subterm } from 'src/interface/type-def';

interface SubtermEditorModalProps {
  propertyName: string;
  value: string; // Value that the subterm will be applied to
  existingSubterm?: Subterm;
  isModalVisible: boolean;
  onSave: (subterm: string, startIdx: number, endIdx: number) => void;
  onDismiss: () => void;
}

export const SubtermEditorModal = ({
  propertyName,
  value,
  existingSubterm,
  isModalVisible,
  onSave,
  onDismiss,
}: SubtermEditorModalProps) => {
  const [separator, setSeparator] = useState<string>(existingSubterm ? existingSubterm.separator : '');
  const [startTermIdx, setStartTermIdx] = useState<number>(existingSubterm ? existingSubterm.startTerm : -1);
  const [endTermIdx, setEndTermIdx] = useState<number>(existingSubterm ? Math.max(existingSubterm.endTerm - 1, 0) : -1);

  const valueSplitBySeparator: Array<string> = useMemo(() => {
    const chunks = separator ? value.split(separator) : [value];
    if (chunks.length <= 1) return chunks;

    return chunks
      .map((chunk, idx) => (idx + 1 < chunks.length ? `${chunk}${separator}` : chunk))
      .filter((chunk) => Boolean(chunk));
  }, [value, separator]);

  const handleSeparatorChange = useCallback((newSeparator: string) => {
    setSeparator(newSeparator);
    // Reset indices, since they may not be valid given the new separator
    setStartTermIdx(-1);
    setEndTermIdx(-1);
  }, []);

  const handleChunkClick = useCallback(
    (chunkIdx: number) => {
      // If the user hasn't set a start term, treat the clicked chunk as their desired chunk
      if (startTermIdx === -1) {
        setStartTermIdx(chunkIdx);
        setEndTermIdx(chunkIdx);
        return;
      }

      // If the user clicks an already-selected chunk, determine if we can shift the selection
      // window. Otherwise, treat the click as "unselecting" the selected chunk.
      if (chunkIdx === startTermIdx) {
        // Move start term idx forward by 1 if possible
        if (chunkIdx + 1 < valueSplitBySeparator.length && chunkIdx + 1 <= endTermIdx) {
          setStartTermIdx((currStartTermIdx) => currStartTermIdx + 1);
        } else {
          setStartTermIdx(-1);
          setEndTermIdx(-1);
        }
        return;
      }

      if (chunkIdx === endTermIdx) {
        // Try to treat the click as deselecting the already-selected end chunk, by moving
        // endIdx backward by 1
        if (chunkIdx - 1 >= 0 && chunkIdx - 1 >= startTermIdx) {
          setEndTermIdx((currEndTermIdx) => currEndTermIdx - 1);
          // If the already-selected end chunk is the first element in the list, or is the same as
          // the start chunk, treat the click as deselecting the current subterm.
        } else {
          setStartTermIdx(-1);
          setEndTermIdx(-1);
        }
        return;
      }

      // If the user has already selected a start term, treat the clicked chunk as the start term if
      // its index comes before the current startTermIdx. Otherwise, treat it as the end term.
      if (chunkIdx < startTermIdx) {
        setStartTermIdx(chunkIdx);
      } else {
        setEndTermIdx(chunkIdx);
      }
    },
    [valueSplitBySeparator, startTermIdx, endTermIdx]
  );

  return (
    <Modal
      visible={isModalVisible}
      header={`Configure subterm for ${propertyName}`}
      size="large"
      onDismiss={onDismiss}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onDismiss}>
              Cancel
            </Button>
            <Button variant="primary" onClick={() => onSave(separator, startTermIdx, endTermIdx)}>
              Save
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      {value ? (
        <SpaceBetween size="m" direction="vertical">
          <FormField label="Separator">
            <Input value={separator} onChange={(e) => handleSeparatorChange(e.detail.value)} />
          </FormField>

          <FormField label={`Select the values to use for ${propertyName}`}>
            <ValueSplitBySeparator
              splitValue={valueSplitBySeparator}
              onChunkClick={handleChunkClick}
              startTermIdx={startTermIdx}
              endTermIdx={endTermIdx}
            />
          </FormField>

          <FormField label="Your selection">
            {startTermIdx === -1 ? (
              <i>Please select the subterm of the value you'd like Tesseract to use</i>
            ) : (
              <Textarea
                readOnly
                rows={5}
                value={JSON.stringify({ separator, startTerm: startTermIdx, endTerm: endTermIdx + 1 }, undefined, 2)}
              />
            )}
          </FormField>
        </SpaceBetween>
      ) : (
        <Alert
          type="error"
          header={`${propertyName} has not been configured`}
        >{`Please select a value for ${propertyName} before adding a subterm`}</Alert>
      )}
    </Modal>
  );
};

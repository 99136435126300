import { Alert, Button } from '@amzn/awsui-components-react';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { PAGE_ROUTES } from 'src/components/Routes';
import { Template, TemplateLog } from 'src/interface/type-def';
import { convertSecondsToMilliseconds, numericDate } from 'src/utils/dates';
import { TemplateType } from '../../types';

interface ExistingTemplateAlertProps {
  existingTemplate: Template;
  templateType: TemplateType;
}

export const ExistingTemplateAlert = ({ existingTemplate, templateType }: ExistingTemplateAlertProps) => {
  const getTemplateTypeString = useCallback(() => {
    switch (templateType) {
      case TemplateType.UTILITY: {
        return 'utility template';
      }
      case TemplateType.SITE: {
        return 'site template';
      }
      case TemplateType.ACCOUNT: {
        return 'account template';
      }
      default:
        console.error(`Invalid templateType ${templateType} provided`);
        break;
    }

    return '';
  }, [existingTemplate.templateId]);

  const lastTemplateUpdate: TemplateLog | null = existingTemplate.templateUpdateLog
    ? existingTemplate.templateUpdateLog[0]
    : null;
  const templateTypeString = getTemplateTypeString();

  return (
    <Alert
      data-testid="existing-template-alert"
      type="info"
      header={`There is an existing ${templateTypeString}`}
      action={
        <Link
          to={{
            pathname: `${PAGE_ROUTES.template}/${existingTemplate.templateId}`,
          }}
          target="_blank"
        >
          <Button iconName="external">View template</Button>
        </Link>
      }
    >
      {`There is an existing ${templateTypeString} for this invoice, last updated on ${numericDate(
        convertSecondsToMilliseconds(lastTemplateUpdate?.timestamp || 0),
        true
      )}. If you create a new ${templateTypeString}, it will overwrite the existing one. View the template to edit the existing configuration.`}
    </Alert>
  );
};

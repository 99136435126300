import { Alert, Container, Header, SpaceBetween, Tabs } from '@amzn/awsui-components-react';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import {
  TEMPLATE_VALUE_FIELD_TYPE,
  TemplateFieldType,
  WaterAccount,
  WaterCharge,
  WaterMeter,
  WaterTemplate,
  WaterUtilityTemplateDefinition,
} from './types/WaterTemplateConfiguration';
import { ConfigureAccountProperties } from './propertyEditor/ConfigureAccountProperties';
import { IInvoiceMetadata, TemplateValue } from 'src/interface/type-def';
import { TextractResultsState } from 'src/components/textract/hooks/useFetchInvoiceTextractResults';
import { StandardPropertyEditor } from './propertyEditor/StandardPropertyEditor';
import { initialMapState } from 'src/data-store/initial-state-new-template';
import { TemplatePropertySuggestedValues } from '../templateSuggestions/templateSuggestionsUtils';
import { ConfigureMeterProperties } from './propertyEditor/ConfigureMeterProperties';
import { DropdownPropertyEditor } from './propertyEditor/DropdownPropertyEditor';
import { ConfigureChargeProperties } from './propertyEditor/ConfigureChargeProperties';
import { PropertySubitemSelector } from './propertyListEditor/PropertySubitemSelector';

export enum TEMPLATE_PROPERTY_TAB_IDS {
  Account = 'account',
  Charges = 'charges',
  Meters = 'meters',
}

// If the given propertyName's templateValue is a list, returns it.
// Otherwise, returns empty array.
const getTemplateValueListForProperty = (
  template: WaterTemplate,
  selectedTabId: TEMPLATE_PROPERTY_TAB_IDS,
  propertyName: string
) => {
  let templateValue: Array<TemplateValue> | TemplateValue | null = null;

  if (selectedTabId === TEMPLATE_PROPERTY_TAB_IDS.Account) {
    const accountKey = propertyName as keyof WaterAccount;
    templateValue = template.templateMap.account[accountKey];
  } else if (selectedTabId === TEMPLATE_PROPERTY_TAB_IDS.Charges) {
    const chargeKey = propertyName as keyof WaterCharge;
    templateValue = chargeKey !== 'tesseractId' ? template.templateMap.charge[chargeKey] || null : null;
  } else if (selectedTabId === TEMPLATE_PROPERTY_TAB_IDS.Meters) {
    const meterKey = propertyName as keyof WaterMeter;
    templateValue = meterKey !== 'tesseractId' ? template.templateMap.meter[meterKey] : null;
  }

  return Array.isArray(templateValue) ? [...templateValue] : [];
};

export interface WaterAccountTableItem {
  propertyName: keyof WaterAccount;
  templateValue: TemplateValue;
}

export interface WaterChargeTableItem {
  propertyName: keyof WaterCharge;
  templateValue: TemplateValue | Array<TemplateValue>;
}

export interface WaterMeterTableItem {
  propertyName: keyof WaterMeter;
  templateValue: TemplateValue;
}

interface CreateWaterTemplateProps {
  template: WaterTemplate;
  templateDefinition: WaterUtilityTemplateDefinition;
  invoice: IInvoiceMetadata | null;
  textractResults: TextractResultsState | null;
  suggestedTemplateValues: TemplatePropertySuggestedValues | null;
  onSaveAccountProperty: (
    propertyName: keyof WaterAccount,
    templateValue: TemplateValue | Array<TemplateValue>
  ) => void;
  onSaveChargeProperty: (
    propertyName: keyof WaterCharge,
    templateValue: TemplateValue | Array<TemplateValue>,
    templateValueItemIdx?: number
  ) => void;
  onSaveMeterProperty: (propertyName: keyof WaterMeter, templateValue: TemplateValue | Array<TemplateValue>) => void;
}

interface CreateWaterTemplateContextState {
  selectedRow: WaterAccountTableItem | WaterChargeTableItem | WaterMeterTableItem | null;
  setSelectedRow: (row: WaterAccountTableItem | WaterChargeTableItem | WaterMeterTableItem) => void;
  selectedRowFieldType: TemplateFieldType | null;
  setSelectedRowFieldType: (fieldType: TemplateFieldType) => void;
  selectedRowTemplateValue: TemplateValue;
  setSelectedRowTemplateValue: (templateValue: TemplateValue) => void;
}

const defaultCreateWaterTemplateContextValue: CreateWaterTemplateContextState = {
  selectedRow: null,
  setSelectedRow: () => {},
  selectedRowFieldType: null,
  setSelectedRowFieldType: () => {},
  selectedRowTemplateValue: initialMapState,
  setSelectedRowTemplateValue: () => {},
};

export const CreateWaterTemplateContext = createContext(defaultCreateWaterTemplateContextValue);

export const CreateWaterTemplate = ({
  template,
  templateDefinition,
  invoice,
  textractResults,
  suggestedTemplateValues,
  onSaveAccountProperty,
  onSaveChargeProperty,
  onSaveMeterProperty,
}: CreateWaterTemplateProps) => {
  const [selectedTabId, setSelectedTabId] = useState<TEMPLATE_PROPERTY_TAB_IDS>(TEMPLATE_PROPERTY_TAB_IDS.Account);

  // Selected Account, Charge, or Meter property
  const [selectedRow, setSelectedRow] = useState<
    WaterAccountTableItem | WaterChargeTableItem | WaterMeterTableItem | null
  >(null);
  // FieldType for the selected row
  const [selectedRowFieldType, setSelectedRowFieldType] = useState<TemplateFieldType | null>(null);
  // TemplateValue for the selected row
  const [selectedRowTemplateValue, setSelectedRowTemplateValue] = useState<TemplateValue>(initialMapState);
  // If the selected row's templateValue is a list (ex. A charge's `chargeAmount`), stores the index of
  // the currently selected element in that list.
  const [selectedRowItemIndex, setSelectedRowItemIndex] = useState<number>(-1);

  useEffect(() => {
    if (!selectedRow) return;
    const { propertyName, templateValue } = selectedRow;

    // Based on the template definition, find and set the selected property's templateFieldType, which
    // defines the property editor UX to render
    if (selectedTabId === TEMPLATE_PROPERTY_TAB_IDS.Account) {
      const accountFieldTypes = templateDefinition.account.nestedFields || {};
      const matchingAccountProperty = Object.keys(accountFieldTypes).find((key) => key === propertyName);
      setSelectedRowFieldType(matchingAccountProperty ? accountFieldTypes[matchingAccountProperty] : null);
    } else if (selectedTabId === TEMPLATE_PROPERTY_TAB_IDS.Charges) {
      const chargeFieldTypes = templateDefinition.charges.nestedFields || {};
      const matchingChargeProperty = Object.keys(chargeFieldTypes).find((key) => key === propertyName);
      setSelectedRowFieldType(matchingChargeProperty ? chargeFieldTypes[matchingChargeProperty] : null);
    } else if (selectedTabId === TEMPLATE_PROPERTY_TAB_IDS.Meters) {
      const meterFieldTypes = templateDefinition.meters.nestedFields || {};
      const matchingMeterProperty = Object.keys(meterFieldTypes).find((key) => key === propertyName);
      setSelectedRowFieldType(matchingMeterProperty ? meterFieldTypes[matchingMeterProperty] : null);
    }

    // Set the selected property's templateValue, which populates data in the property editor UX
    // If the selected property is an array, default to selecting the first element
    if (Array.isArray(templateValue)) {
      setSelectedRowTemplateValue(templateValue[0]);
      setSelectedRowItemIndex(0);
    } else {
      setSelectedRowTemplateValue(templateValue || initialMapState);
    }
  }, [selectedRow, selectedTabId, templateDefinition]);

  // Invoked on-click of the "Save" button for a property. Updates the parent's `template` state for the given
  // `propertyName` with the given `templateValue`. Optionally, if the property is an array of templateValues,
  // `subitemIdx` represents the index to update with the given `templateValue`.
  const onSaveProperty = useCallback(
    (propertyName: string, templateValue: TemplateValue | Array<TemplateValue>, subitemIdx?: number) => {
      if (!propertyName) return;

      if (selectedTabId === TEMPLATE_PROPERTY_TAB_IDS.Account) {
        onSaveAccountProperty(propertyName as keyof WaterAccount, templateValue);
      } else if (selectedTabId === TEMPLATE_PROPERTY_TAB_IDS.Charges) {
        onSaveChargeProperty(propertyName as keyof WaterCharge, templateValue, subitemIdx);
      } else if (selectedTabId === TEMPLATE_PROPERTY_TAB_IDS.Meters) {
        onSaveMeterProperty(propertyName as keyof WaterMeter, templateValue);
      }
    },
    [selectedTabId, onSaveAccountProperty, onSaveChargeProperty, onSaveMeterProperty]
  );

  // If the selected property is an array of templateValues (ex. `chargeAmount` in a `Charge`), creates a new, empty
  // templateValue for the new list element and updates the parent's `template` state.
  const onAddSubitemToProperty = useCallback(() => {
    // Existing templateValue list to which we'll append the new templateValue
    const templateValueForProperty = getTemplateValueListForProperty(
      template,
      selectedTabId,
      selectedRow?.propertyName || ''
    );

    // Add a new, empty templateValue to the end of the existing list for this property
    const newTemplateValue = { ...initialMapState };
    templateValueForProperty.push(newTemplateValue);

    // Auto-select the newly-added list element in the UI
    setSelectedRowItemIndex(templateValueForProperty.length - 1);
    setSelectedRowTemplateValue(newTemplateValue);

    // Overwrite this property's templateValue with the newly-updated list
    onSaveProperty(selectedRow?.propertyName || '', templateValueForProperty);
  }, [selectedRowItemIndex, selectedRow, onSaveProperty]);

  // If the selected property is an array of templateValues (ex. `chargeAmount` in a `Charge`), removes the
  // templateValue at index `subitemIdx` and updates the parent's `template` state.
  const onRemoveSubitemFromProperty = useCallback(() => {
    // Existing templateValue list to which we'll remove the existing templateValue
    const templateValueForProperty = getTemplateValueListForProperty(
      template,
      selectedTabId,
      selectedRow?.propertyName || ''
    );

    if (selectedRowItemIndex < 0 || selectedRowItemIndex >= templateValueForProperty.length) return;

    // Remove the templateValue at `selectedRowItemIndex`
    templateValueForProperty.splice(selectedRowItemIndex, 1);

    let updatedSubitemIdxToSelect = selectedRowItemIndex;
    // If removing the last element in the list, auto-select the preceding element
    if (updatedSubitemIdxToSelect && updatedSubitemIdxToSelect === templateValueForProperty.length) {
      updatedSubitemIdxToSelect -= 1;
    }
    setSelectedRowItemIndex(updatedSubitemIdxToSelect);
    setSelectedRowTemplateValue(templateValueForProperty[updatedSubitemIdxToSelect]);

    // Overwrite this property's templateValue with the newly-updated list
    onSaveProperty(selectedRow?.propertyName || '', templateValueForProperty);
  }, [selectedRowItemIndex, selectedRow, onSaveProperty]);

  const onSubitemClick = useCallback(
    (itemIdx: number) => {
      const templateValueListForProperty = getTemplateValueListForProperty(
        template,
        selectedTabId,
        selectedRow?.propertyName || ''
      );
      if (itemIdx >= 0 && itemIdx < templateValueListForProperty.length) {
        setSelectedRowTemplateValue(templateValueListForProperty[itemIdx]);
      }
      setSelectedRowItemIndex(itemIdx);
    },
    [template, selectedTabId, selectedRow]
  );

  const numPropertiesWithSuggestions = Object.keys(suggestedTemplateValues || {}).length;

  return (
    <CreateWaterTemplateContext.Provider
      value={{
        selectedRow,
        setSelectedRow,
        selectedRowFieldType,
        setSelectedRowFieldType,
        selectedRowTemplateValue,
        setSelectedRowTemplateValue,
      }}
    >
      <Container header={<Header variant="h2">Configure template map</Header>}>
        <SpaceBetween direction="vertical" size="m">
          {numPropertiesWithSuggestions > 0 && (
            <Alert type="info" header="There are auto-detected suggestions for this template">
              Tesseract has generated suggested mappings for {numPropertiesWithSuggestions} properties in this template.
              When editing these properties, you can choose to use any of these suggestions, or proceed to manually
              configure the mapping.
            </Alert>
          )}
          <Tabs
            onChange={(e) => setSelectedTabId(e.detail.activeTabId as TEMPLATE_PROPERTY_TAB_IDS)}
            tabs={[
              {
                id: TEMPLATE_PROPERTY_TAB_IDS.Account,
                label: 'Account',
                content: (
                  <ConfigureAccountProperties
                    invoice={invoice}
                    account={template.templateMap.account}
                    textractResults={textractResults}
                  />
                ),
              },
              {
                id: TEMPLATE_PROPERTY_TAB_IDS.Charges,
                label: 'Charges',
                content: (
                  <ConfigureChargeProperties
                    invoice={invoice}
                    charge={template.templateMap.charge}
                    textractResults={textractResults}
                  />
                ),
              },
              {
                id: TEMPLATE_PROPERTY_TAB_IDS.Meters,
                label: 'Meters',
                content: (
                  <ConfigureMeterProperties
                    invoice={invoice}
                    // TODO: Support multi-meter use case. Today, we only support configuring one meter.
                    meter={template.templateMap.meter}
                    textractResults={textractResults}
                  />
                ),
              },
            ]}
          />

          {selectedRowFieldType?.fieldType === TEMPLATE_VALUE_FIELD_TYPE.List && (
            <>
              <PropertySubitemSelector
                propertyName={selectedRow?.propertyName || ''}
                allTemplateValues={getTemplateValueListForProperty(
                  template,
                  selectedTabId,
                  selectedRow?.propertyName || ''
                )}
                selectedOptionIdx={selectedRowItemIndex}
                onSubitemClick={onSubitemClick}
                onAddSubitem={onAddSubitemToProperty}
                onRemoveSubitem={onRemoveSubitemFromProperty}
              />
              <StandardPropertyEditor
                propertyName={selectedRow?.propertyName || ''}
                templateValue={selectedRowTemplateValue}
                setTemplateValue={(value: TemplateValue) => setSelectedRowTemplateValue(value)}
                textractResults={textractResults}
                suggestedTemplateValues={suggestedTemplateValues}
                invoice={invoice}
                onSaveProperty={onSaveProperty}
                templateValueSubitemIdx={selectedRowItemIndex}
              />
            </>
          )}

          {selectedRowFieldType?.fieldType === TEMPLATE_VALUE_FIELD_TYPE.Standard && (
            <StandardPropertyEditor
              propertyName={selectedRow?.propertyName || ''}
              templateValue={selectedRowTemplateValue}
              setTemplateValue={(value: TemplateValue) => setSelectedRowTemplateValue(value)}
              textractResults={textractResults}
              suggestedTemplateValues={suggestedTemplateValues}
              invoice={invoice}
              onSaveProperty={onSaveProperty}
              templateValueSubitemIdx={selectedRowItemIndex}
            />
          )}

          {selectedRowFieldType?.fieldType === TEMPLATE_VALUE_FIELD_TYPE.Select && (
            <DropdownPropertyEditor
              propertyName={selectedRow?.propertyName || ''}
              selectElements={selectedRowFieldType.selectElements || []}
              templateValue={selectedRowTemplateValue}
              setTemplateValue={(value: TemplateValue) => setSelectedRowTemplateValue(value)}
              onSaveProperty={onSaveProperty}
              templateValueSubitemIdx={selectedRowItemIndex}
            />
          )}
        </SpaceBetween>
      </Container>
    </CreateWaterTemplateContext.Provider>
  );
};

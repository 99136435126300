import * as React from 'react';
import { Flashbar, FlashbarProps } from '@amzn/awsui-components-react/polaris';

interface FlashMessageProps {
  message: string | React.ReactNode;
  type: FlashbarProps.Type;
  action?: React.ReactNode;
  header?: string;
  dismissible?: boolean;
}

export const FlashMessage = ({ message, type, action, header, dismissible = true }: FlashMessageProps) => {
  const [messages, setMessages] = React.useState<Array<FlashbarProps.MessageDefinition>>([
    {
      header,
      type,
      dismissible,
      dismissLabel: dismissible ? 'Dismiss message' : undefined,
      action,
      onDismiss: () => setMessages([]),
      content: <>{message}</>,
    },
  ]);

  return <Flashbar items={messages} />;
};

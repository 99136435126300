import { Button, ExpandableSection, FormField, Select, SelectProps, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useCallback, useMemo } from 'react';
import { TemplateValue } from 'src/interface/type-def';

const getOptionDefinitionForItem = (propertyName: string, itemIdx: number): SelectProps.Option => ({
  label: `${propertyName} #${itemIdx + 1}`,
  value: `${itemIdx}`,
});

interface PropertySubitemSelectorProps {
  propertyName: string;
  allTemplateValues: Array<TemplateValue>;
  selectedOptionIdx: number;
  onSubitemClick: (itemIdx: number) => void;
  onAddSubitem: () => void;
  onRemoveSubitem: () => void;
}

export const PropertySubitemSelector = ({
  propertyName,
  allTemplateValues,
  selectedOptionIdx,
  onSubitemClick,
  onAddSubitem,
  onRemoveSubitem,
}: PropertySubitemSelectorProps) => {
  const selectOptions: Array<SelectProps.Option> = useMemo(
    () => [...Array(allTemplateValues.length)].map((_, idx) => getOptionDefinitionForItem(propertyName, idx)),
    [propertyName, allTemplateValues]
  );

  const selectedOption = useMemo(() => {
    if (selectedOptionIdx > selectOptions.length || selectedOptionIdx < 0) return null;
    return selectOptions[selectedOptionIdx];
  }, [propertyName, selectOptions, selectedOptionIdx]);

  const handleSelectChange = useCallback(
    (option: SelectProps.Option) => {
      if (!option.value) return;

      const optionIdx = +option.value;
      onSubitemClick(optionIdx);
    },
    [onSubitemClick]
  );

  return (
    <ExpandableSection variant="container" headerText={`First, select the ${propertyName}`} defaultExpanded>
      <SpaceBetween direction="horizontal" size="m" alignItems="end">
        <FormField
          label="Select item to edit"
          description={`If there is more than one ${propertyName}, you can add to this list.`}
        >
          <Select
            options={selectOptions}
            selectedOption={selectedOption}
            onChange={(e) => handleSelectChange(e.detail.selectedOption)}
          />
        </FormField>
        {selectOptions.length > 1 && <Button onClick={onRemoveSubitem}>Remove</Button>}
        <Button variant="primary" iconName="add-plus" iconAlign="left" onClick={onAddSubitem}>
          Add {propertyName}
        </Button>
      </SpaceBetween>
    </ExpandableSection>
  );
};

import { Template, TemplateValue } from 'src/interface/type-def';

/**
 * Types related to the tempaltes for water invoices
 */
export interface WaterTemplateMap {
  account: WaterAccount;
  charge: WaterCharge;
  meter: WaterMeter;
}

export interface WaterTemplate extends Omit<Template, 'templateMap'> {
  templateMap: WaterTemplateMap;
}

export interface WaterAccount {
  accountId: TemplateValue;
  locationCode: TemplateValue;
  siteName: TemplateValue;
  statementDate: TemplateValue;
  intervalStart: TemplateValue;
  intervalEnd: TemplateValue;
}

export interface WaterCharge {
  tesseractId: string;
  chargeCurrency: TemplateValue;
  chargeAmount: Array<TemplateValue>;
}

export interface WaterMeter {
  tesseractId: string;
  meterId: TemplateValue;
  serviceType: TemplateValue;
  meterChargeAmount: TemplateValue;
  meterChargeCurrency: TemplateValue;
  meterUsageUnit: TemplateValue;
  meterUsageAmount: TemplateValue;
}

export enum TEMPLATE_VALUE_FIELD_TYPE {
  Standard = 'standard',
  Object = 'object',
  Select = 'select',
  List = 'list',
}

export interface TemplateFieldType {
  fieldType: TEMPLATE_VALUE_FIELD_TYPE;
  selectElements?: Array<string>; // Populated if fieldType = `select`
  listMemberFields?: TemplateFieldType; // Populated if fieldType = `list`
  nestedFields?: {
    [propertyName: string]: TemplateFieldType; // Populated if fieldType = `object`
  };
}

export interface WaterUtilityTemplateDefinition {
  account: TemplateFieldType;
  charges: TemplateFieldType;
  meters: TemplateFieldType;
}

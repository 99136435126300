import { ButtonDropdown, ButtonDropdownProps } from '@amzn/awsui-components-react';
import React from 'react';
import { Cell } from 'amazon-textract-response-parser/dist/cjs';
import { CreateTemplateContext } from 'src/components/templates/create/index';
import {
  APIs,
  HeaderRef,
  Services,
  Source,
  TextractAnalyzeDocumentHeaderBasedTableValueRef,
  ValueRefTypes,
} from 'src/interface/type-def';
import { getOffsetForHeaderCell } from '../createUtils';

export enum TABULAR_DATA_CELL_TYPES {
  ApplyCell = 'applyCell',
  AddCellHeader = 'addCellHeader',
  AddTableHeader = 'addTableHeader',
}

export const TABULAR_DATA_CELL_BUTTONS: Array<ButtonDropdownProps.Item> = [
  {
    text: 'Add as selected cell',
    id: TABULAR_DATA_CELL_TYPES.ApplyCell,
  },
  {
    text: 'Add as cell row/column header',
    id: TABULAR_DATA_CELL_TYPES.AddCellHeader,
  },
  {
    text: 'Add as table header',
    id: TABULAR_DATA_CELL_TYPES.AddTableHeader,
  },
];

// Default `valueRef` properties to set when updating a any header-based cell mapping
export const DEFAULT_HEADER_BASED_VALUE_REF: Pick<
  TextractAnalyzeDocumentHeaderBasedTableValueRef,
  'service' | 'api' | 'type'
> = {
  service: Services.Textract,
  api: APIs.AnalyzeDocument,
  type: ValueRefTypes.HeaderBasedTableValue,
};

export interface ApplyTabularDataCellButtonProps {
  cell: Cell;
  pageNumber: number;
  tableNumber: number;
  onApplyCell?: (
    cellType: TABULAR_DATA_CELL_TYPES,
    selectedCell: Cell,
    pageNumber: number,
    tableNumber: number
  ) => void;
}

export const ApplyTabularDataCellButton = ({
  cell,
  pageNumber,
  tableNumber,
  onApplyCell,
}: ApplyTabularDataCellButtonProps) => {
  const createTemplateContext = React.useContext(CreateTemplateContext);
  const { subTemplateMapState, setSubTemplateMapState } = createTemplateContext;

  const handleDropdownItemClick = (e: CustomEvent<ButtonDropdownProps.ItemClickDetails>) => {
    const buttonId = e.detail.id;

    if (onApplyCell) {
      onApplyCell(buttonId as TABULAR_DATA_CELL_TYPES, cell, pageNumber, tableNumber);
      return;
    }

    // The legacy template creation workflow uses the code below to update state via CreateTemplateContext.
    // Since we're updating this property's tabular data, ensure the key-value pair
    // for this property is reset
    createTemplateContext.textractResultKey = '';

    if (buttonId === TABULAR_DATA_CELL_TYPES.ApplyCell) {
      createTemplateContext.textractResultTableKey = cell.text;

      setSubTemplateMapState({
        ...subTemplateMapState,
        source: Source.INVOICE_TABLE,
        valueRef: {
          ...subTemplateMapState.valueRef,
          ...DEFAULT_HEADER_BASED_VALUE_REF,
          page: pageNumber,
          tableRef: {
            ...subTemplateMapState.valueRef?.tableRef,
          },
          cellRef: {
            ...subTemplateMapState.valueRef?.cellRef,
            cellCoordinate: [cell.rowIndex, cell.columnIndex],
            cellText: cell.text,
          },
        },
      });
    } else if (buttonId === TABULAR_DATA_CELL_TYPES.AddCellHeader) {
      if (!subTemplateMapState.valueRef?.cellRef) return;

      const appliedCellRef = subTemplateMapState.valueRef?.cellRef;
      const headerCellOffset = getOffsetForHeaderCell(appliedCellRef, cell.columnIndex, cell.rowIndex);
      const selectedHeaderCellRef: HeaderRef = {
        header: cell.text.trim().replace(/[.*:]/g, ''),
        offset: headerCellOffset,
      };

      setSubTemplateMapState({
        ...subTemplateMapState,
        source: Source.INVOICE_TABLE,
        valueRef: {
          ...subTemplateMapState.valueRef,
          ...DEFAULT_HEADER_BASED_VALUE_REF,
          tableRef: {
            ...subTemplateMapState.valueRef?.tableRef,
          },
          cellRef: {
            ...subTemplateMapState.valueRef?.cellRef,
            headerRefs: [...(subTemplateMapState.valueRef?.cellRef?.headerRefs || []), selectedHeaderCellRef],
          },
        },
      });
    } else if (buttonId === TABULAR_DATA_CELL_TYPES.AddTableHeader) {
      setSubTemplateMapState({
        ...subTemplateMapState,
        source: Source.INVOICE_TABLE,
        valueRef: {
          ...subTemplateMapState.valueRef,
          ...DEFAULT_HEADER_BASED_VALUE_REF,
          tableRef: {
            ...subTemplateMapState.valueRef?.tableRef,
            tableIndex: tableNumber,
            headers: [
              ...(subTemplateMapState.valueRef?.tableRef?.headers || []),
              cell.text.trim().replace(/[.*:]/g, ''),
            ],
          },
          cellRef: {
            ...subTemplateMapState.valueRef?.cellRef,
          },
        },
      });
    }
  };

  return (
    <ButtonDropdown
      data-testid="apply-tabular-data-cell-btn"
      items={TABULAR_DATA_CELL_BUTTONS}
      variant="primary"
      onItemClick={handleDropdownItemClick}
    >
      Apply cell
    </ButtonDropdown>
  );
};

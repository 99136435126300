import { ExpandableSection, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { CreateTemplateContext } from 'src/components/templates/create';
import { CellRef, TableRef } from 'src/interface/type-def';
import { TabularDataCellField } from './TabularDataCellField';
import { TabularDataHeadersField } from './TabularDataHeadersField';

export interface MapHeaderBasedTableValueProps {
  selectedCellText: string | [number, number];
  tableRef: TableRef | null;
  cellRef: CellRef | null;
}

export const MapHeaderBasedTableValue = ({ selectedCellText, tableRef, cellRef }: MapHeaderBasedTableValueProps) => {
  const createTemplateContext = React.useContext(CreateTemplateContext);
  const { subTemplateMapState, setSubTemplateMapState } = createTemplateContext;

  const { headerRefs } = cellRef || {};
  const { headers: tableHeaders } = tableRef || {};

  const onRemoveCellHeader = (headerIdx: number) => {
    const updatedHeaderRefs = [...(subTemplateMapState.valueRef?.cellRef?.headerRefs || [])];
    if (headerIdx >= updatedHeaderRefs.length) {
      return;
    }

    updatedHeaderRefs.splice(headerIdx, 1);

    setSubTemplateMapState({
      ...subTemplateMapState,
      valueRef: {
        ...subTemplateMapState.valueRef,
        cellRef: {
          ...subTemplateMapState.valueRef?.cellRef,
          headerRefs: updatedHeaderRefs,
        },
      },
    });
  };

  const onRemoveTableHeader = (headerIdx: number) => {
    const updatedTableHeaders = [...(subTemplateMapState.valueRef?.tableRef?.headers || [])];
    if (headerIdx >= updatedTableHeaders.length) {
      return;
    }

    updatedTableHeaders.splice(headerIdx, 1);

    setSubTemplateMapState({
      ...subTemplateMapState,
      valueRef: {
        ...subTemplateMapState.valueRef,
        tableRef: {
          ...subTemplateMapState.valueRef?.tableRef,
          // Set `tableIndex` to 0 if the update list of table headers is empty
          tableIndex: updatedTableHeaders.length ? subTemplateMapState.valueRef?.tableRef?.tableIndex : 0,
          headers: updatedTableHeaders,
        },
      },
    });
  };

  const cellText = Array.isArray(selectedCellText)
    ? `Cell coordinates: ${selectedCellText.toString()}`
    : selectedCellText;

  return (
    <>
      <ExpandableSection
        headerText="Configure cell"
        headerDescription="Configuration to help Tesseract identify the correct cell in the table"
        defaultExpanded
      >
        <SpaceBetween direction="vertical" size="s">
          <TabularDataCellField cellText={cellText} />
          <TabularDataHeadersField
            headers={headerRefs?.map((headerRef) => headerRef.header) || []}
            label="Cell header(s)"
            emptyMessage={cellText ? 'No cell headers selected' : 'Select a cell before adding a cell header'}
            onRemoveHeader={onRemoveCellHeader}
          />
        </SpaceBetween>
      </ExpandableSection>

      <ExpandableSection
        headerText="Identify table"
        headerDescription="Configuration to help Tesseract identify the correct table the cell is in"
        defaultExpanded
      >
        <TabularDataHeadersField
          headers={tableHeaders || []}
          label="Table header(s)"
          emptyMessage={cellText ? 'No table headers selected' : 'Select a cell before adding a table header'}
          onRemoveHeader={onRemoveTableHeader}
          isCellHeader={false}
        />
      </ExpandableSection>
    </>
  );
};

import React from 'react';
import { BlueprintPropertyValueType } from 'src/components/blueprints/types';
import { Box, Popover } from '@amzn/awsui-components-react';

interface BlueprintPropertyTypeTextProps {
  propertyName: string;
  type: BlueprintPropertyValueType;
}

export const BlueprintPropertyTypeText = ({ propertyName, type }: BlueprintPropertyTypeTextProps) => {
  if (type === BlueprintPropertyValueType.Prompt) {
    return (
      <Box>
        <Popover header="Prompt" content={`Prompt that describes how to extract ${propertyName} from an invoice.`}>
          <Box>Prompt</Box>
        </Popover>
      </Box>
    );
  }

  return (
    <Box>
      <Popover header="Static value" content={`Static value that will always be used for ${propertyName}.`}>
        <Box>Static</Box>
      </Popover>
    </Box>
  );
};

import React from 'react';
import { Popover } from '@amzn/awsui-components-react';
import { IInvoiceMetadata, TemplateValue } from 'src/interface/type-def';
import {
  getKeyFromValueRef,
  getValueFromValueRef,
  isHeaderBasedTableValueRef,
  isKeyValueRef,
} from 'src/utils/valueRefUtils';
import { STANDARD_PROPERTY_EDITOR_SOURCE } from './propertyEditor/StandardPropertyEditor';
import { TextractResultsState } from 'src/components/textract/hooks/useFetchInvoiceTextractResults';

const getSourceTextForTemplateValue = (templateValue: TemplateValue) => {
  const { valueRef, source } = templateValue;
  if (!valueRef || !source) return '-';

  if (isKeyValueRef(valueRef)) {
    return STANDARD_PROPERTY_EDITOR_SOURCE.InvoiceKeyValue;
  }
  if (isHeaderBasedTableValueRef(valueRef)) {
    return STANDARD_PROPERTY_EDITOR_SOURCE.InvoiceTable;
  }

  return source;
};

const getSourceCellForTemplateValueList = (propertyName: string, templateValue: Array<TemplateValue>): JSX.Element => {
  const allSources = (
    <ul>
      {templateValue.map((value, idx) => (
        <li key={idx}>{`${propertyName} #${idx + 1}: ${getSourceTextForTemplateValue(value)}`}</li>
      ))}
    </ul>
  );
  return (
    <Popover header="Sources" content={allSources}>
      View ({templateValue.length})
    </Popover>
  );
};

const getKeyTextForTemplateValue = (templateValue: TemplateValue) => getKeyFromValueRef(templateValue) || '-';

const getKeyCellForTemplateValueList = (propertyName: string, templateValue: Array<TemplateValue>): JSX.Element => {
  const allTemplateKeys = (
    <ul>
      {templateValue.map((value, idx) => (
        <li key={idx}>{`${propertyName} #${idx + 1}: ${getKeyTextForTemplateValue(value)}`}</li>
      ))}
    </ul>
  );
  return (
    <Popover header="Keys" content={allTemplateKeys}>
      View ({templateValue.length})
    </Popover>
  );
};

const getValueTextForTemplateValue = (
  templateValue: TemplateValue,
  textractResults: TextractResultsState | null,
  invoice: IInvoiceMetadata | null
) => getValueFromValueRef(templateValue, textractResults, invoice?.manifestDocument) || '-';

const getValueCellForTemplateValueList = (
  propertyName: string,
  templateValue: Array<TemplateValue>,
  textractResults: TextractResultsState | null,
  invoice: IInvoiceMetadata | null
) => {
  const allTemplateValues = (
    <ul>
      {templateValue.map((value, idx) => (
        <li key={idx}>{`${propertyName} #${idx + 1}: ${getValueTextForTemplateValue(
          value,
          textractResults,
          invoice
        )}`}</li>
      ))}
    </ul>
  );
  return (
    <Popover header="Values" content={allTemplateValues}>
      View ({templateValue.length})
    </Popover>
  );
};

// Returns table cell that renders the given templateValue's source
export const getPropertySourceCell = (
  propertyName: string,
  templateValue: TemplateValue | Array<TemplateValue>
): JSX.Element | string => {
  if (Array.isArray(templateValue)) return getSourceCellForTemplateValueList(propertyName, templateValue);

  return getSourceTextForTemplateValue(templateValue);
};

// Returns table cell that render's the given templateValue's key
// TODO: Not all sources (ex. Tabular data) have an explicit key, so those should be rendered differently.
export const getPropertyKeyCell = (propertyName: string, templateValue: TemplateValue | Array<TemplateValue>) => {
  if (Array.isArray(templateValue)) return getKeyCellForTemplateValueList(propertyName, templateValue);

  return getKeyTextForTemplateValue(templateValue);
};

// Returns table cell that render's the given templateValue's value
export const getPropertyValueCell = (
  propertyName: string,
  templateValue: TemplateValue | Array<TemplateValue>,
  textractResults: TextractResultsState | null,
  invoice: IInvoiceMetadata | null
) => {
  if (Array.isArray(templateValue))
    return getValueCellForTemplateValueList(propertyName, templateValue, textractResults, invoice);

  return getValueTextForTemplateValue(templateValue, textractResults, invoice);
};

import { Badge, Box } from '@amzn/awsui-components-react';
import React from 'react';

interface TextWithBadgeProps {
  text: string;
  badgeColor: 'blue' | 'grey' | 'green' | 'red'; // Derived from Cloudscape's `BadgeProps`
  badgeText: string;
}

export const TextWithBadge = ({ text, badgeColor, badgeText }: TextWithBadgeProps) => (
  <>
    {text}
    <Box display="inline" margin={{ left: 'xs' }}>
      <Badge color={badgeColor}>{badgeText}</Badge>
    </Box>
  </>
);

import { Box, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';

import './ValueSplitBySeparator.scss';

interface ValueSplitBySeparatorProps {
  splitValue: Array<string>;
  onChunkClick: (chunkIdx: number) => void;
  startTermIdx: number;
  endTermIdx: number;
}

export const ValueSplitBySeparator = ({
  splitValue,
  onChunkClick,
  startTermIdx,
  endTermIdx,
}: ValueSplitBySeparatorProps) => (
  <Box variant="span">
    <SpaceBetween direction="horizontal" size="xs">
      {splitValue.map((chunk, idx) => {
        const isSelectedChunk = endTermIdx === -1 ? idx === startTermIdx : idx >= startTermIdx && idx <= endTermIdx;
        return (
          <button
            key={`${chunk}-${idx}`}
            type="button"
            className={`template-value-chunk ${isSelectedChunk ? 'template-value-chunk--selected' : ''}`}
            onClick={() => onChunkClick(idx)}
          >
            {chunk}
          </button>
        );
      })}
    </SpaceBetween>
  </Box>
);

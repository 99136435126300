import { Box, Button, Popover } from '@amzn/awsui-components-react';
import React from 'react';

interface ValueWithLabelProps {
  label: string;
  labelDataTestId: string;
  valueDataTestId: string;
  infoText?: string;
}

export const ValueWithLabel: React.FC<ValueWithLabelProps> = ({
  label,
  labelDataTestId,
  valueDataTestId,
  infoText,
  children,
}) => (
  <div>
    <Box variant="awsui-key-label" data-testid={labelDataTestId}>
      {label}{' '}
      {infoText && (
        <Popover dismissButton={false} position="right" size="medium" triggerType="custom" content={infoText}>
          <Button formAction="none" variant="inline-icon" iconName="status-info" />
        </Popover>
      )}
    </Box>
    <div data-testid={valueDataTestId}>{children}</div>
  </div>
);

import { Box } from '@amzn/awsui-components-react';
import React, { ReactNode } from 'react';

interface EmptyTableStateProps {
  title: string;
  subtitle: string;
  action?: ReactNode;
}

export function EmptyTableState({ title, subtitle, action }: EmptyTableStateProps) {
  return (
    <Box data-testid="table-empty-state" textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      <Box variant="p" padding={{ bottom: 's' }} color="inherit">
        {subtitle}
      </Box>
      {action}
    </Box>
  );
}

import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { getInvoiceOverrideData } from 'src/client/api-gateway';
import { IInvoiceMetadata, ProcessedInvoiceData } from 'src/interface/type-def';
import { isInvoiceOverridable } from 'src/helpers/data';

export interface UseFetchInvoiceOverrideState {
  results: ProcessedInvoiceData | null;
  manifest: IInvoiceMetadata | null;
  loading: boolean;
  errorMessage: string;
  isOverridable: boolean;
  fetchInvoiceOverride: (id: string) => Promise<void>;
}

export function useFetchInvoiceOverride() {
  const [invoiceId, setInvoiceId] = useState<string>('');
  const [manifest, setManifest] = useState<IInvoiceMetadata | null>(null);
  const [results, setResults] = useState<ProcessedInvoiceData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const isOverridable = isInvoiceOverridable(manifest);

  const fetchInvoiceOverride = useCallback(async (id: string) => {
    setInvoiceId(id);
    setErrorMessage('');

    if (!id) return;

    setLoading(true);

    try {
      const { results, manifest } = await getInvoiceOverrideData(id);
      setManifest(manifest);
      setResults(results);
    } catch (e) {
      console.log('error calling useFetchInvoiceOverride: ', e);
      setErrorMessage(e as string);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    results,
    manifest,
    loading,
    errorMessage,
    fetchInvoiceOverride,
    isOverridable,
  };
}

import { Box, Popover } from '@amzn/awsui-components-react';
import React from 'react';
import { Subterm } from 'src/interface/type-def';

interface SubtermPopoverTextProps {
  subterm?: Subterm;
}

export const SubtermPopoverText = ({ subterm }: SubtermPopoverTextProps) => {
  if (!subterm) {
    return <>-</>;
  }

  return (
    <Box>
      <Popover header="Subterm" content={JSON.stringify(subterm, undefined, 2)}>
        Yes
      </Popover>
    </Box>
  );
};

import { useCallback, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import {
  WaterAccount,
  WaterCharge,
  WaterMeter,
  WaterTemplate,
} from 'src/components/templates/waterTemplates/types/WaterTemplateConfiguration';
import { TemplateValue } from 'src/interface/type-def';

export const useConfigureTemplate = (initalTemplate: WaterTemplate) => {
  const [template, setTemplate] = useState<WaterTemplate>(initalTemplate);

  const updateAccountProperty = useCallback(
    (propertyName: keyof WaterAccount, templateValue: TemplateValue | Array<TemplateValue>) => {
      // Currently, there are no Account-level properties for water templates that are stored as an array
      if (Array.isArray(templateValue)) return;

      const updatedTemplate = cloneDeep(template);
      updatedTemplate.templateMap.account[propertyName] = templateValue;
      setTemplate(updatedTemplate);
    },
    [template]
  );

  const updateChargeProperty = useCallback(
    (propertyName: keyof WaterCharge, templateValue: TemplateValue | Array<TemplateValue>, subitemIdx: number = -1) => {
      if (propertyName === 'tesseractId') return;

      const updatedTemplate = cloneDeep(template);
      const existingTemplateValue = updatedTemplate.templateMap.charge[propertyName];
      // Handle case where this property is stored as a list
      if (Array.isArray(existingTemplateValue)) {
        // If `templateValue` is an array, overwrite the existing value
        if (Array.isArray(templateValue)) {
          (updatedTemplate.templateMap.charge[propertyName] as Array<TemplateValue>) = templateValue;
          // If given a `subitemIdx`, overwrite the existing value at that index
        } else if (subitemIdx >= 0) {
          (updatedTemplate.templateMap.charge[propertyName] as Array<TemplateValue>)[subitemIdx] = templateValue;
        }
        // Handle case where this property is a single value that we will overwrite
      } else if (!Array.isArray(templateValue) && subitemIdx === -1) {
        (updatedTemplate.templateMap.charge[propertyName] as TemplateValue) = templateValue;
      }

      setTemplate(updatedTemplate);
    },
    [template]
  );

  const updateMeterProperty = useCallback(
    (propertyName: keyof WaterMeter, templateValue: TemplateValue | Array<TemplateValue>) => {
      // Currently, there are no Meter-level properties for water templates that are stored as an array
      if (propertyName === 'tesseractId' || Array.isArray(templateValue)) return;

      const updatedTemplate = cloneDeep(template);
      // For now, we only support the single-meter use case.
      // TODO: support multi-meter use case.
      updatedTemplate.templateMap.meter[propertyName] = templateValue;
      setTemplate(updatedTemplate);
    },
    [template]
  );

  return {
    template,
    setTemplate,
    updateAccountProperty,
    updateChargeProperty,
    updateMeterProperty,
  };
};

import React from 'react';
import { Box, ColumnLayout, SpaceBetween, Spinner } from '@amzn/awsui-components-react';
import { Link } from 'react-router-dom';
import { convertSecondsToMilliseconds, numericDate } from 'src/utils/dates';
import { Template } from 'src/interface/type-def';
import { WaterTemplate } from '../waterTemplates/types/WaterTemplateConfiguration';
import { PAGE_ROUTES } from 'src/components/Routes';

export interface TemplateDetailsProps {
  template: Template | WaterTemplate;
  parentTemplates: Array<Template | WaterTemplate>;
  parentTemplatesLoading: boolean;
}

export const TemplateDetails = ({ template, parentTemplates, parentTemplatesLoading }: TemplateDetailsProps) => {
  const { templateId, validFrom, utilityId, updatedDate, siteId, createdFromInvoiceId, accountId } = template;

  return (
    <ColumnLayout columns={4} variant="text-grid">
      <SpaceBetween size="l">
        <div>
          <Box variant="small">Template ID</Box>
          <div>{templateId || '-'}</div>
        </div>
        <div>
          <Box variant="small">Valid from</Box>
          <div>{numericDate(validFrom) || '-'}</div>
        </div>
      </SpaceBetween>

      <SpaceBetween size="l">
        <div>
          <Box variant="small">Utility ID</Box>
          <div>{utilityId || '-'}</div>
        </div>
        <div>
          <Box variant="small">Last updated</Box>
          <div>{numericDate(convertSecondsToMilliseconds(updatedDate || 0), true) || '-'}</div>
        </div>
      </SpaceBetween>

      <SpaceBetween size="l">
        <div>
          <Box variant="small">Site ID</Box>
          <div>{siteId || '-'}</div>
        </div>
        <div>
          <Box variant="small">Invoice used to create template</Box>
          <div>
            <Link to={`${PAGE_ROUTES.invoice}/${createdFromInvoiceId}`}>{createdFromInvoiceId || '-'}</Link>
          </div>
        </div>
      </SpaceBetween>

      <SpaceBetween size="l">
        <div>
          <Box variant="small">Account ID</Box>
          <div>{accountId || '-'}</div>
        </div>
        <div>
          <Box variant="small">Parent templates</Box>
          <div>
            <SpaceBetween size="s">
              {parentTemplatesLoading ? (
                <Spinner data-testid="parent-templates-loading-icon" />
              ) : (
                parentTemplates.map((parentTemplate, index) => (
                  <Link
                    key={index}
                    to={{
                      pathname: `${PAGE_ROUTES.template}/${parentTemplate.templateId}`,
                      state: { template: parentTemplate },
                    }}
                  >
                    {parentTemplate.templateId}
                  </Link>
                ))
              )}
            </SpaceBetween>
          </div>
        </div>
      </SpaceBetween>
    </ColumnLayout>
  );
};

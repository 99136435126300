import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TemplateValue } from 'src/interface/type-def';
import {
  Alert,
  Button,
  Container,
  FormField,
  Grid,
  Header,
  Select,
  SelectProps,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import { usePropertyEditor } from '../hooks/usePropertyEditor';
import { isStaticValueRef } from 'src/utils/valueRefUtils';

interface DropdownPropertyEditorProps {
  propertyName: string; // The name of the selected template property
  selectElements: Array<string>;
  templateValue: TemplateValue; // The selected property's TemplateValue, sent in the body of the upsertTemplate request
  setTemplateValue: (templateValue: TemplateValue) => void; // Update the selected property's TemplateValue
  onSaveProperty: (propertyName: string, templateValue: TemplateValue, templateValueSubitemIdx?: number) => void; // Invoked when saving an Account-level property
  templateValueSubitemIdx?: number; // If this property is stores as an array of TemplateValues, represents the index of the TemplateValue being edited
  originalTemplateValue?: TemplateValue; // If updating the field, represents the original configuration
}

export const DropdownPropertyEditor = ({
  propertyName,
  selectElements,
  templateValue,
  setTemplateValue,
  onSaveProperty,
  templateValueSubitemIdx,
  originalTemplateValue,
}: DropdownPropertyEditorProps) => {
  const { getStaticTemplateValue } = usePropertyEditor();

  const [selectedOption, setSelectedOption] = useState<SelectProps.Option | null>(null);
  const [isSaveConfirmationVisible, setIsSaveConfirmationVisible] = useState<boolean>(false);

  const selectOptions: Array<SelectProps.Option> = useMemo(() => {
    const options = selectElements.map((item) => ({
      label: item,
      value: item,
    }));
    options.sort();
    return options;
  }, [selectElements]);

  useEffect(() => {
    let defaultSelectedOption = null;
    // Populate the dropdown if this property already a selected value
    if (templateValue.valueRef && isStaticValueRef(templateValue.valueRef) && templateValue.valueRef.ref.value) {
      const { value } = templateValue.valueRef.ref;
      defaultSelectedOption = selectOptions.find((option) => option.value === value) || null;
    }
    setSelectedOption(defaultSelectedOption);
  }, [selectOptions, templateValue.valueRef]);

  useEffect(() => {
    setIsSaveConfirmationVisible(false);
  }, [templateValue, selectedOption]);

  const handleSelectedOptionChange = useCallback(
    (option: SelectProps.Option) => {
      setSelectedOption(option);
      // When updating this template, treat select items as static values
      const updatedTemplateValue = getStaticTemplateValue(templateValue, option.value || '');
      if (updatedTemplateValue) {
        setTemplateValue(updatedTemplateValue);
      }
    },
    [getStaticTemplateValue, setTemplateValue, templateValue]
  );

  return (
    <Grid gridDefinition={[{ colspan: { default: 6 } }, { colspan: { default: 6 } }]}>
      <Container header={<Header variant="h2">Edit property</Header>}>
        <SpaceBetween direction="vertical" size="s">
          <FormField label="Select an option">
            <Select
              options={selectOptions}
              selectedOption={selectedOption}
              placeholder={`Select a static value for ${propertyName}`}
              onChange={(e) => handleSelectedOptionChange(e.detail.selectedOption)}
            />
          </FormField>

          {isSaveConfirmationVisible && <Alert type="success">Your changes have been saved</Alert>}

          <SpaceBetween direction="horizontal" size="s">
            <Button
              variant="primary"
              onClick={() => {
                onSaveProperty(propertyName, templateValue, templateValueSubitemIdx);
                setIsSaveConfirmationVisible(true);
              }}
            >
              Save changes
            </Button>
            <Button iconName="redo">Reset</Button>
          </SpaceBetween>
        </SpaceBetween>
      </Container>
    </Grid>
  );
};

import { useCollection } from '@amzn/awsui-collection-hooks';
import { Header, Pagination, Table, TableProps, TextFilter } from '@amzn/awsui-components-react';
import React from 'react';
import { TemplateLog } from 'src/interface/type-def';
import { numericDate } from 'src/utils/dates';
import { paginationLabels } from 'src/components/blueprints/BlueprintsTableConfig';
import { filterTableRowByText, getTextFilterCounterText } from 'src/utils/tableUtils';

const DEFAULT_PAGE_SIZE = 20;

enum BLUEPRINT_LOG_TABLE_COLUMN_IDS {
  USERNAME = 'username',
  TIMESTAMP = 'timestamp',
}

const columnDefinitions: Array<TableProps.ColumnDefinition<TemplateLog>> = [
  {
    id: BLUEPRINT_LOG_TABLE_COLUMN_IDS.USERNAME,
    header: 'Username',
    cell: (item: TemplateLog) => item.username || '-',
    sortingField: BLUEPRINT_LOG_TABLE_COLUMN_IDS.USERNAME,
  },
  {
    id: BLUEPRINT_LOG_TABLE_COLUMN_IDS.TIMESTAMP,
    header: 'Update time',
    // NOTE: `TemplateLog` timestamps are stored in seconds (rather than ms) on the backend
    cell: (item: TemplateLog) => numericDate(item.timestamp, true),
    sortingField: BLUEPRINT_LOG_TABLE_COLUMN_IDS.TIMESTAMP,
  },
];

interface BlueprintLogTableProps {
  blueprintLogEntries: Array<TemplateLog>;
}

export const BlueprintLogTable = ({ blueprintLogEntries }: BlueprintLogTableProps) => {
  const {
    items,
    collectionProps,
    paginationProps,
    filterProps,
    filteredItemsCount = 0,
  } = useCollection(blueprintLogEntries, {
    filtering: {
      filteringFunction: (blueprintLog: TemplateLog, filteringText: string) =>
        filterTableRowByText(blueprintLog.username, filteringText),
      empty: 'This blueprint has not been updated yet.',
      noMatch: 'No updates found for this user.',
    },
    pagination: {
      pageSize: DEFAULT_PAGE_SIZE,
    },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: BLUEPRINT_LOG_TABLE_COLUMN_IDS.TIMESTAMP,
          sortingComparator: (templateLogA, templateLogB) => templateLogB.timestamp - templateLogA.timestamp,
        },
      },
    },
  });

  return (
    <Table
      {...collectionProps}
      header={<Header variant="h2">{`Blueprint update log (${blueprintLogEntries.length})`}</Header>}
      columnDefinitions={columnDefinitions}
      stickyHeader
      items={items}
      filter={
        <TextFilter
          {...filterProps}
          filteringPlaceholder="Search by username"
          countText={getTextFilterCounterText(filteredItemsCount)}
        />
      }
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
    />
  );
};

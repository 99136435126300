import { SpaceBetween, Tiles } from '@amzn/awsui-components-react';
import React from 'react';
import { TemplateType } from '../../types';

interface DefineTemplateMetadataProps {
  templateType: TemplateType;
  setTemplateType: (type: TemplateType) => void;
  isUpdatingTemplate: boolean;
}

export const DefineTemplateMetadata = ({
  templateType,
  setTemplateType,
  isUpdatingTemplate,
}: DefineTemplateMetadataProps) => (
  <SpaceBetween size="xs">
    <Tiles
      onChange={({ detail }) => setTemplateType(detail.value as TemplateType)}
      value={templateType}
      columns={4}
      items={[
        {
          label: 'Utility Template',
          description: 'A utility template is applied to all invoices with the same vendor',
          value: TemplateType.UTILITY,
          disabled: isUpdatingTemplate && templateType !== TemplateType.UTILITY,
        },
        {
          label: 'Site Template',
          description: 'A site template is applied to all invoices with the same vendor and site',
          value: TemplateType.SITE,
          disabled: isUpdatingTemplate && templateType !== TemplateType.SITE,
        },
        {
          label: 'Account Template',
          description: 'An account template is applied to all invoices with the same vendor, site, and account number',
          value: TemplateType.ACCOUNT,
          disabled: isUpdatingTemplate && templateType !== TemplateType.ACCOUNT,
        },
        {
          label: 'New Template',
          description: 'Create a new template (advanced)',
          value: TemplateType.ADVANCED,
          disabled: isUpdatingTemplate && templateType !== TemplateType.ADVANCED,
        },
      ]}
    />
  </SpaceBetween>
);

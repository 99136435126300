import { WaterTemplate } from 'src/components/templates/waterTemplates/types/WaterTemplateConfiguration';
import { Template, TemplateValue } from 'src/interface/type-def';

export const initialMapState: TemplateValue = {
  source: undefined,
  valueRef: undefined,
  include: true,
};

// Default empty state for a new water template
export const emptyWaterTemplate: WaterTemplate = {
  utilityId: '',
  siteId: '',
  accountId: '',
  validFrom: 19700101,
  createdFromInvoiceId: '',
  templateMap: {
    account: {
      accountId: { ...initialMapState },
      locationCode: { ...initialMapState },
      siteName: { ...initialMapState },
      statementDate: { ...initialMapState },
      intervalStart: { ...initialMapState },
      intervalEnd: { ...initialMapState },
    },
    charge: {
      tesseractId: '',
      chargeCurrency: { ...initialMapState },
      chargeAmount: [{ ...initialMapState }],
    },
    meter: {
      tesseractId: '',
      meterId: { ...initialMapState },
      serviceType: { ...initialMapState },
      meterChargeAmount: { ...initialMapState },
      meterChargeCurrency: { ...initialMapState },
      meterUsageUnit: { ...initialMapState },
      meterUsageAmount: { ...initialMapState },
    },
  },
};

export const initialTemplateState: Template = {
  utilityId: '',
  siteId: '',
  accountId: '',
  validFrom: 19700101,
  createdFromInvoiceId: '',
  templateMap: {
    account: {
      accountNumber: { ...initialMapState },
      statementDate: { ...initialMapState },
      statementCreatedDate: { ...initialMapState },
      dueByDate: { ...initialMapState },
      statementType: { ...initialMapState },
      accountId: { ...initialMapState },
      statementId: { ...initialMapState },
      siteCode: { ...initialMapState },
      siteName: { ...initialMapState },
      intervalStart: { ...initialMapState },
      intervalEnd: { ...initialMapState },
      utilityProvider: { ...initialMapState },
    },
    charges: [
      {
        // TODO: Remove after tesseractId is generated in the backend
        tesseractId: 'u124',
        chargeAmount: { ...initialMapState },
        chargeAmountCurrency: { ...initialMapState },
        chargeId: { ...initialMapState },
        chargeRateCurrency: { ...initialMapState },
        chargeUnitsUsed: { ...initialMapState },
        chargeUsageUnit: { ...initialMapState },
      },
    ],
    meters: [
      {
        // TODO: Remove after tesseractId is generated in the backend
        tesseractId: 'usdf',
        meterId: { ...initialMapState },
        meterNumber: { ...initialMapState },
        serviceType: { ...initialMapState },
        usageAmount: { ...initialMapState },
        usages: [],
        charges: [],
      },
    ],
  },
};

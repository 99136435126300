import React from 'react';
import { Link } from 'react-router-dom';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { numericDate } from '../../utils/dates';
import { Template } from 'src/interface/type-def';
import { PAGE_ROUTES } from '../Routes';

export enum TEMPLATE_TABLE_COLUMN_KEYS {
  templateId = 'templateId',
  utilityId = 'utilityId',
  siteId = 'siteId',
  accountId = 'accountId',
  updatedDate = 'updatedDate',
  validFrom = 'validFrom',
}

const DEFAULT_ROW_VALUE = '-';

export const templateColumnDefinitions: TableProps.ColumnDefinition<Template>[] = [
  {
    id: TEMPLATE_TABLE_COLUMN_KEYS.templateId,
    header: 'Template ID',
    cell: (item) => (
      <Link
        to={{
          pathname: `${PAGE_ROUTES.template}/${item.templateId}`,
          state: { template: item },
        }}
        target="_blank"
      >
        {item.templateId}
      </Link>
    ),
    minWidth: '100px',
  },
  {
    id: TEMPLATE_TABLE_COLUMN_KEYS.utilityId,
    header: 'Utility ID',
    cell: (item) => item.utilityId || DEFAULT_ROW_VALUE,
    minWidth: '100px',
  },
  {
    id: TEMPLATE_TABLE_COLUMN_KEYS.siteId,
    header: 'Site ID',
    cell: (item) => item.siteId || DEFAULT_ROW_VALUE,
    minWidth: '100px',
  },
  {
    id: TEMPLATE_TABLE_COLUMN_KEYS.accountId,
    header: 'Account ID',
    cell: (item) => item.accountId || DEFAULT_ROW_VALUE,
    minWidth: '100px',
  },
  {
    id: TEMPLATE_TABLE_COLUMN_KEYS.updatedDate,
    header: 'Updated Date',
    cell: (item) => numericDate(item.updatedDate),
    minWidth: '100px',
  },
  {
    id: TEMPLATE_TABLE_COLUMN_KEYS.validFrom,
    header: 'Valid From',
    cell: (item) => numericDate(item.validFrom),
    minWidth: '100px',
  },
];

export const collectionPreferencesProps = {
  title: 'Preferences',
  confirmLabel: 'Confirm',
  cancelLabel: 'Cancel',
  pageSizePreference: {
    title: 'Select page size',
    options: [
      { value: 20, label: '20 templates' },
      { value: 30, label: '30 templates' },
      { value: 50, label: '50 templates' },
    ],
  },
  visibleContentPreference: {
    title: 'Select visible content',
    options: [
      {
        label: 'Main distribution properties',
        options: [
          { id: 'templateId', label: 'Template ID', editable: false },
          { id: 'utilityId', label: 'Utility ID' },
          { id: 'siteId', label: 'Site ID' },
          { id: 'accountId', label: 'Account ID' },
          { id: 'updatedDate', label: 'Updated Date' },
          { id: 'validFrom', label: 'Valid From' },
        ],
      },
    ],
  },
};

export const paginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page',
};

export function getMatchesCountText(count: number) {
  return count === 1 ? `1 match` : `${count} matches`;
}

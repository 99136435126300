import React from 'react';
import ReactDOM from 'react-dom';
import { Spinner } from '@amzn/awsui-components-react/polaris';
import { HashRouter as BrowserRouter } from 'react-router-dom';

import { App } from './components/App';
import { ErrorBoundary } from './components/common-components/error-boundary/index';
import './index.scss';

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={<Spinner size="large" />}>
      <BrowserRouter>
        <ErrorBoundary isPage>
          <App />
        </ErrorBoundary>
      </BrowserRouter>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('tesseract-root')
);
